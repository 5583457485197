import React from "react";
import { combineLatest, } from "rxjs";
import { Formik } from "formik";
import * as Yup from "yup";
import { DialogTitle, DialogContent, FormGroup, FormHelperText, Grid, Input, Typography, AppBar, Toolbar, IconButton, TextField, } from "@material-ui/core";
import { KeyboardDatePicker, DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { AuthContext } from "../../../../shared/store/authProvider";
import { SubscriptionArray } from "../../../../shared/services/dataService";
import { ResultStatus, CrudAction, API_ENDPOINT, ENTITY_TYPE } from "../../../../shared/types/enums";
import PageLoadingComponent from "../../../../shared/components/page/pageLoadingComponent";
import PageErrorComponent from "../../../../shared/components/page/pageErrorComponent";
import { MatIconService } from "../../../../shared/services/theme/matIconService";
import LayoutService from "../../../../shared/services/layoutService";
import ApiService from "../../../../shared/services/apiService";
import ImportTemplateCreateService from "./importTemplateCreateService";
import RolePermissionService from "../../../../shared/role-permissions/rolePermissionService";
import OktaAuthService from "../../../../public/authentication/oktaAuthService";

class ImportTemplateCreateComponent extends React.Component {
  static contextType = AuthContext;
  oSubscriptions = new SubscriptionArray();


  constructor(props) {
    super(props);
    // init state
    this.state = {
      fetchResult: ResultStatus.NOT_LOADED,
      openDeleteConfirm: false,
      isEditing: this.props.inputAction === CrudAction.UPDATE,
      isReadOnly: this.props.inputAction === CrudAction.UPDATE,
    };
  }

  componentWillUnmount() {
    this.oSubscriptions.cancelAll();
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {

    this.oSubscriptions.cancelAll();
    this.setState({ fetchResult: ResultStatus.LOADING });

    this.oSubscriptions.add(
      combineLatest([
        ImportTemplateCreateService.fetchTemplateTypeDataAsOBS(this.context.user.tenantId),
      ]).subscribe(
        // success
        ([_templateTypeData]) => {
          _templateTypeData = (_templateTypeData || []).filter(x => x.entityid === ENTITY_TYPE.PROFILE || x.entityid === ENTITY_TYPE.TRANSACTION || x.entityid === ENTITY_TYPE.SALES_REP);

          this.setState({ templateTypeData: _templateTypeData },
            // change the state after all the above are assigned
            () => { this.setState({ fetchResult: ResultStatus.LOADED }); }
          );
        },
        // onError
        (error) => {
          console.log("Error:", error);
          this.setState({ fetchResult: ResultStatus.ERROR });
        }
      )
    );
  }

  getInitialValues() {
    if (this.props.inputAction === CrudAction.UPDATE) {
      return {
        templateId: this.props.modalAgNode.templateId,
        templateName: this.props.modalAgNode.templateName,
        templateDescription: this.props.modalAgNode.templateDescription,
        templateTypeId: this.props.modalAgNode.templateTypeId,
        startDate: this.props.modalAgNode.startDate !== undefined ? new Date(this.props.modalAgNode.startDate) : null,
        isActive: this.props.modalAgNode.isActive,
        endDate: this.props.modalAgNode.endDate !== undefined ? new Date(this.props.modalAgNode.endDate) : null,
        filePath: this.props.modalAgNode.filePath
      };
    } else {
      return {
        templateId: 0,
        templateName: "",
        templateDescription: "",
        templateTypeId: 2,
        startDate: new Date(),
        endDate: null,
        isActive: true,
        filePath: "",
      };
    }
  }

  validationSchema = Yup.object().shape({});
  getValidationSchema() {
    this.validationSchema = Yup.object().shape({
      templateName: Yup.string().required("Required").min(3, "Must be at least 3 characters long").max(256, "Must be 256 characters or less"),
      templateDescription: Yup.string().min(3, "Must be at least 3 characters long").max(256, "Must be 256 characters or less"),
      startDate: Yup.date().nullable().min("1/1/2000", "On or after 1/1/2000").max("1/1/2099", "On or before 1/1/2099"),
      endDate: Yup.date().nullable().min("1/1/2000", "On or after 1/1/2000").max("1/1/2099", "On or before 1/1/2099"),
    });
    return this.validationSchema;
  }

  handleSubmit = async (_formikProps) => {
    if (!_formikProps.isSubmitting && _formikProps.isValid) {
      await this.validationSchema.validate(_formikProps.values, { abortEarly: false })
        .then((x) => {

          // 1) set the status
          this.setState({ fetchResult: ResultStatus.SAVING, });

          // 2) extract the formik values into a FormData object
          const formData = new FormData();
          formData.append("tenantId", this.context.user.tenantId);
          formData.append("templateName", _formikProps.values.templateName);
          formData.append("templateDescription", _formikProps.values.templateDescription);
          formData.append("templateTypeId", _formikProps.values.templateTypeId);
          formData.append("startDate", _formikProps.values.startDate !== null ? _formikProps.values.startDate.toJSON().slice(0, 10) : "");
          formData.append("endDate", _formikProps.values.endDate !== null ? _formikProps.values.endDate.toJSON().slice(0, 10) : "");
          formData.append("isActive", _formikProps.values.isActive);

          // 3) determine the action and assign the appropriate props
          let actionVerb = "";
          let targetUrl = "";
          if (this.props.inputAction === CrudAction.CREATE) {
            // CREATE
            formData.append("filePath", this.state.selectedFileName);
            formData.append("efile", this.state.selectedFile);
            formData.append("templateId", 0);
            actionVerb = "POST";
            targetUrl = `${process.env.REACT_APP_TENANT_HTTP}/ImportTemplate/CreateTemplate?userId=${this.context.user.userId}`;
          } else {
            // UPDATE
            if (this.state.selectedFileName) {
              formData.append("filePath", this.state.selectedFileName);
              formData.append("efile", this.state.selectedFile);
            } else {
              formData.append("filePath", "");
              formData.append("efile", "");
            }
            formData.append("templateId", this.props.modalAgNode.templateId);
            actionVerb = "POST";
            targetUrl = `${process.env.REACT_APP_TENANT_HTTP}/ImportTemplate?userId=${this.context.user.userId}`;
          }

          // Do not set the Content-type in the headers below.  Let the browser set it. For example
          // Content-Type: multipart/form-data; boundary=----WebKitFormBoundary3sy40ozPAfPAnNRy
          // The browser handles and controls the random string at the end of the boundary
          const headers = {
            Authorization: `Bearer ` + OktaAuthService.getAccessToken(),
          };

          // 4) save to Api and subscribe for the result
          ApiService.setOBS(actionVerb, API_ENDPOINT.TENANT, targetUrl, formData, headers)
            .subscribe(
              (successResult) => {
                if (successResult) {
                  this.setState({ fetchResult: ResultStatus.SUCCESS });
                  this.props.refreshImportTemplateList(true);
                  this.props.onClose(false);
                } else {
                  console.error("Error: falsey successResult while saving Import Template", successResult);
                  this.setState({ fetchResult: ResultStatus.ERROR });
                }
              },
              (errorResult) => {
                console.error("Error while saving Import Template", errorResult);
                this.setState({ fetchResult: ResultStatus.ERROR });
              }
            );
        })
        .catch((erroObj) => {
          erroObj.inner.forEach(err => { _formikProps.setFieldError(err.path, err.message); });
        });
    }
  }

  render() {
    const { classes } = this.props;
    if (RolePermissionService.IMPORT_TEMPLATE_DETAIL.cannotView && this.props.inputAction === CrudAction.UPDATE) {
      return RolePermissionService.getAccessDeniedComponent(classes, () => { this.props.onClose() }); // this is required to prevent Url navigation
    } else {
      switch (this.state.fetchResult) {
        case ResultStatus.NOT_LOADED:
        case ResultStatus.LOADING:
          return <PageLoadingComponent small classes={classes} label="Loading Import Template Details" />;
        case ResultStatus.SAVING:
          return <PageLoadingComponent small classes={classes} label="Saving Import Template Details" />;
        case ResultStatus.SUCCESS:
        case ResultStatus.LOADED:
          return (
            <>
              <Formik initialValues={this.getInitialValues()} validationSchema={this.getValidationSchema()} validationSchemaOptions={{ showMultipleFieldErrors: true }}>
                {(fProps) => (
                  <form>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DialogTitle disableTypography id="dialogTitle">
                        <AppBar position="static">
                          <Toolbar variant="dense">
                            <Typography variant="h6" className={classes.root}>Import Template Details</Typography>
                            {this.props.inputAction === CrudAction.UPDATE ? RolePermissionService.IMPORT_TEMPLATE_DETAIL.cannotEdit ? LayoutService.getIconButton(false, MatIconService.CLOSE, "close",
                              () => { this.props.onClose(false) }, "secondary")
                              : LayoutService.getReadOnlyActions(this, !this.state.isEditing, () => { this.props.onClose(false) }, () => { this.handleSubmit(fProps) })
                              : LayoutService.getReadOnlyActions(this, !this.state.isEditing, () => { this.props.onClose(false) }, () => { this.handleSubmit(fProps) })
                            }
                          </Toolbar>
                        </AppBar>
                      </DialogTitle>
                      <DialogContent style={{ paddingTop: "5px", paddingLeft: "25px", paddingRight: "25px" }}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "templateName", "Template Name")} fullWidth />
                          </Grid>
                          <Grid item xs={12} sm={6}>{LayoutService.getSelectControl(this.state.isReadOnly, classes, fProps, "templateTypeId", "Template Type *", this.state.templateTypeData, "entityid", "entityname", "100%")}</Grid>
                          <Grid item xs={12}><TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "templateDescription", "Template Description", false)} fullWidth /></Grid>
                          <Grid item xs={12} sm={4}><KeyboardDatePicker {...LayoutService.getDateProps(this.state.isReadOnly, classes, fProps, "startDate", "Start Date", false)} format="yyyy-MM-dd" style={{ minWidth: "47%" }} /></Grid>
                          <Grid item xs={12} sm={4}><KeyboardDatePicker {...LayoutService.getDateProps(this.state.isReadOnly, classes, fProps, "endDate", "End Date", false)} format="yyyy-MM-dd" style={{ minWidth: "47%" }} /></Grid>
                          <Grid item xs={12} sm={4}><FormGroup style={{ paddingLeft: "4px", paddingTop: "8px" }}>
                            {LayoutService.getSwitch(this.state.isReadOnly, classes, fProps, "isActive", "Active")}
                          </FormGroup>
                          </Grid>
                          <Grid item xs={12}>
                            <FormHelperText>Attachment</FormHelperText>
                            <Input type="file" onChange={(event) => this.setState({ selectedFile: event.target.files[0], selectedFileName: event.target.files[0].name })} fullWidth />
                            {this.props.inputAction === CrudAction.UPDATE ?
                              <div style={{ display: "flex" }}>
                                <Typography variant="body1" className={classes.root} color="secondary" display="inline">Attached File:</Typography>
                                <Typography variant="body1" className={classes.root} display="inline">{this.props.modalAgNode.filePath}</Typography>
                              </div>
                              : null}
                          </Grid>
                        </Grid>
                      </DialogContent>
                    </MuiPickersUtilsProvider>
                  </form>
                )}
              </Formik>
            </>
          );

        case ResultStatus.ERROR:
        default:
          return (
            <PageErrorComponent small label="Error Loading Import Template" classes={classes} onRetry={() => { this.fetchData(); }} onClose={() => this.props.onClose(false)} />
          );
      }
    }
  }
}

/** HOC */
export default LayoutService.getHocComponenet(ImportTemplateCreateComponent);