import React, { Component } from "react";
import PropTypes from 'prop-types';
import icon_bad from "../../public/images/icon_bad.png";
import icon_process from "../../public/images/icon_process.png";
import icon_verygood from "../../public/images/icon_verygood.png";
import icon_blank from "../../public/images/icon_blank.png";
import icon_inqueue from "../../public/images/icon_inqueue.png";
//import UnknownFile from "../../public/images/UnkownFile.png";

export default class FileStatusImageRenderer extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }
 debugger;
  render() {
    let imageUrl = null;
  // Determine image URL based on the status value
  switch (this.props.value) {
    case 'In Queue':
      imageUrl = icon_inqueue;
      break;
    case 'In Progress':
      imageUrl = icon_process;
      break;
    case 'Success':
      imageUrl = icon_verygood;
      break;
    case 'Failed':
        imageUrl = icon_bad;
        break;
    default:
      imageUrl = icon_blank; // Default i'mage for unknown statuses
      break;
  }

  return (
    <img src={imageUrl} alt={this.props.value} style={{ width: '30px', height: '30px' }} />
  );
};
}

// FileStatusImageRenderer.propTypes = {
//   value: PropTypes.string.isRequired, // Status value
// };

//export default FileStatusImageRenderer;