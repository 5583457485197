import React from "react";
import { combineLatest, from, BehaviorSubject } from "rxjs";
import { filter, mergeMap, debounceTime, distinctUntilChanged, } from "rxjs/operators";
import { Typography, Dialog, DialogTitle, DialogContent, AppBar, Toolbar, InputBase, Box } from "@material-ui/core";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css";

import { AuthContext } from "../../store/authProvider";
import { API_ENDPOINT, CrudAction, ENTITY_TYPE, ResultStatus } from "../../types/enums";
import { DataService, SubscriptionArray } from "../../services/dataService";
import LookupService from "../../services/lookupService";
import LayoutService from "../../services/layoutService";

import PageLoadingComponent from "../page/pageLoadingComponent";
import DialogErrorFragmentComponent from "../page/dialogErrorFragmentComponent";
import { MatIconService } from "../../services/theme/matIconService";
import PossibleMatchesDialogService from "./possibleMatchesDialogServise";
import AgGridRadioButtonCellRendererComponent from "../elements/agGridRadioButtonCellRendererComponent";
import { AgGridUtil } from "../../services/ag-grid/agGridUtil";
import ChildMessageRendererComponent from "../childMessageRendererComponent";
import ProfileDetailDialogComponent from "../../../private/profile-center/profile-detail/profileDetailDialogComponent";
import { AgGridColumnExt } from "../../services/ag-grid/agGridColumnExt";
import SectionComponent from "../sectionComponent";

class PossibleMatchesDialogComponent extends React.Component {

    static contextType = AuthContext;

    // ept till component's unMount
    scopedSubscriptions = new SubscriptionArray();
    // apiSubscriptions are canclled before retry also
    apiSubscriptions = new SubscriptionArray();

    constructor(props) {
        super(props);

        // init state
        this.state = {
            searchResult: ResultStatus.NOT_LOADED,
            profileList: [],
            selectedProfile: null,
            errorMessage: null,
            agGridUnmatchedDataUtils: new AgGridUtil("lastName", {
                isSelectedCellRenderer: AgGridRadioButtonCellRendererComponent,
                childMessageRendererComponent: ChildMessageRendererComponent,
            }),
            agGridPossibleMatchUtils: new AgGridUtil("lastName", {
                isSelectedCellRenderer: AgGridRadioButtonCellRendererComponent,
                childMessageRendererComponent: ChildMessageRendererComponent,
            }),
        };
    }

    componentWillUnmount() {
        this.scopedSubscriptions.cancelAll();
        this.apiSubscriptions.cancelAll();
    }

    searchSubject = new BehaviorSubject("");

    componentDidMount() {
        this.fetchData(this.props.recordId, this.props.entityId);
    }

    fetchData = (_recordId, _entityId) => {
        this.apiSubscriptions.cancelAll();

        if (DataService.hasValidValue(_recordId)) {
            this.setState({ searchResult: ResultStatus.LOADING, profileList: [], selectedProfile: null });
            this.apiSubscriptions.add(
                combineLatest([
                    PossibleMatchesDialogService.getPossibleMatches(this.context.user.tenantId, _recordId, _entityId),
                    LookupService.getFormattedCountriesAsOBS(this.context, null),
                ]
                ).subscribe(
                    ([_profileList, _countryList]) => {
                        if (DataService.hasElements(_profileList)) {
                            _profileList.forEach(x => {
                                x.isSelected = false;
                                x.countryName = _countryList.find(c => c.id == x.country)?.value;
                            });
                        }
                        this.setState({
                            searchResult: DataService.hasElements(_profileList) ? ResultStatus.SUCCESS : ResultStatus.LOADED,//-> no records
                            profileList: _profileList,
                            possibleMatchCount: _profileList.length
                        });
                    },
                    (err) => { this.setState({ searchResult: ResultStatus.ERROR }); }
                )
            );
        } else {
            this.setState({ searchResult: ResultStatus.NOT_LOADED });
        }
        return [];
    }

    methodFromParent = (row_col, node) => {
        this.setState({ modalAgNode: node, profileIdForDetailsDialog: node.prid }, () => {
            this.setState({ showProfileDetailDialog: true })
        });
    }

    // render
    render() {
        const { classes } = this.props;
        return (
            <>
                <DialogTitle style={{ padding: 0 }} id="dialog-title">
                    <AppBar position="static">
                        <Toolbar>
                            <Typography variant="h6" className={classes.root}>Manual Match Center</Typography>
                            {this.props.entityId === ENTITY_TYPE.TRANSACTION ? <></> : LayoutService.getIconButton(false,
                                MatIconService.PARENT, "Mark as Permanent", () => { this.props.markAsPermenant() }, "inherit")}
                            {LayoutService.getIconButton(DataService.isNullOrUndefined(this.state.selectedProfile), MatIconService.OK, "Save", () => {
                                if (DataService.hasValue(this.state.selectedProfile)) {
                                    var retObj = {};
                                    const keys = Object.keys(this.state.selectedProfile);
                                    keys.forEach(key => { retObj[key.toLowerCase()] = this.state.selectedProfile[key]; });
                                    this.setState({
                                        searchResult: ResultStatus.NOT_LOADED, //-> no records
                                        profileList: [],
                                    });

                                    this.props.onClose(retObj);
                                } else { this.props.onClose(null) }
                            }, "inherit", "keyActionSave")}
                            {LayoutService.getIconButton(false, MatIconService.CANCEL, "Cancel", () => { this.props.onClose(null) }, "secondary", "keyActionCancel1")}
                        </Toolbar>
                    </AppBar>
                </DialogTitle>

                <DialogContent>
                    <Box style={{ paddingLeft: 16, paddingRight: 16, paddingTop: 0, paddingBottom: 8, minHeight: "60vh", maxHeight: "60vh" }}>
                        {this.renderContent(classes)}
                    </Box>
                </DialogContent>
                <ProfileDetailDialogComponent inputAction={CrudAction.UPDATE} modalAgNode={{ ...this.state.modalAgNode, "prid": this.state.profileIdForDetailsDialog }}
                    open={this.state.showProfileDetailDialog || false}
                    onClose={() => this.setState({ showProfileDetailDialog: false })}
                />
            </>
        );
    }

    renderContent = (classes) => {
        switch (this.state.searchResult) {
            case ResultStatus.SAVING: return <PageLoadingComponent small classes={classes} label="Saving..." />;
            case ResultStatus.LOADING: return <PageLoadingComponent small classes={classes} label="Searching..." />;
            case ResultStatus.NOT_LOADED: return <DialogErrorFragmentComponent small classes={classes} description="Not a valid Match Id" />;
            case ResultStatus.LOADED: return <DialogErrorFragmentComponent small classes={classes} description="No Records Found" />;
            case ResultStatus.SUCCESS:
                return (
                    <>
                        <Typography style={{ marginTop: 8, paddingLeft: 8, paddingRight: 8, backgroundColor: "#FF8A8A", color: "#6E0101" }} variant="h6" align="center">Customer Unmatched Data</Typography>
                        {/* <SectionComponent classes={classes} align={"center"} color={"secondary"} label={"Customer Unmatched Data"} marginTop="8px" /> */}
                        <div style={{ height: `112px`, width: `100%` }} {...LayoutService.getAgGridTheme()}>
                            <AgGridReact
                                columnDefs={PossibleMatchesDialogService.getUnmatchedProfileColumnDefs(this)}
                                rowData={PossibleMatchesDialogService.getAsMatchCenterRow(this.props.unmatchedProfileObj)}
                                pagination={false}
                                paginationPageSize={50}
                                frameworkComponents={this.state.agGridUnmatchedDataUtils.frameworkComponents}
                                onGridReady={(gridOptions) => {
                                    this.state.agGridUnmatchedDataUtils.setGridParams(gridOptions, false);
                                    // this.state.agGridUnmatchedDataUtils.autoSizeColumns();
                                }}
                                gridOptions={{
                                    context: { componentParent: this },
                                    ...AgGridColumnExt.getGridOptions(40),
                                }}

                                onCellFocused={(e) => {
                                    if (this.state.agGridPossibleMatchUtils.gridColumnNameMap.has(e.column.colId.toLowerCase())) {
                                        this.state.agGridPossibleMatchUtils.gridApi.ensureColumnVisible(e.column.colId);
                                        this.state.agGridPossibleMatchUtils.gridApi.flashCells({ columns: [e.column.colId] }); // since only one row
                                    }
                                }}
                            ></AgGridReact>
                        </div>

                        <SectionComponent classes={classes} align={"center"} label={`Possible Matches = ${this.state.possibleMatchCount}`} marginTop="32px" />
                        <div style={{ height: `360px`, width: `100%` }} {...LayoutService.getAgGridTheme()}>
                            <AgGridReact
                                rowData={this.state.profileList}
                                columnDefs={PossibleMatchesDialogService.getColumnDefs(this, (_data) => {
                                    this.setState({ selectedProfile: _data });
                                })}
                                suppressClickEdit={true}
                                pagination={true}
                                paginationPageSize={25}
                                frameworkComponents={this.state.agGridPossibleMatchUtils.frameworkComponents}
                                onGridReady={(params) => {
                                    this.state.agGridPossibleMatchUtils.setGridParams(params, false);
                                    this.state.agGridPossibleMatchUtils.pinColumn("isSelected");
                                }}
                                gridOptions={{
                                    context: { componentParent: this },
                                    ...AgGridColumnExt.getGridOptions(40),
                                }}
                                onCellFocused={(e) => {
                                    if (this.state.agGridUnmatchedDataUtils.gridColumnNameMap.has(e.column.colId.toLowerCase())) {
                                        this.state.agGridUnmatchedDataUtils.gridApi.ensureColumnVisible(e.column.colId);
                                        this.state.agGridUnmatchedDataUtils.gridApi.flashCells({ columns: [e.column.colId] }); // since only one row
                                    }
                                }}
                            />
                        </div>

                    </>
                );
            case ResultStatus.ERROR:
            default:
                return (<DialogErrorFragmentComponent classes={classes} description="An error occured while Searching" onRetry={() => { this.fetchData(this.props.matchId, this.props.entityId); }} />);
        }
    }

}
export default LayoutService.getHocComponenet(PossibleMatchesDialogComponent);