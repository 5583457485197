import React from "react";
import { combineLatest, from, BehaviorSubject } from "rxjs";
import { filter, mergeMap, debounceTime, distinctUntilChanged, } from "rxjs/operators";
import { Typography, Dialog, DialogTitle, DialogContent, AppBar, Toolbar, InputBase, Box } from "@material-ui/core";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css";

import { AuthContext } from "../../../../../shared/store/authProvider";
import { ResultStatus } from "../../../../../shared/types/enums";
import { DataService, SubscriptionArray } from "../../../../../shared/services/dataService";
import LayoutService from "../../../../../shared/services/layoutService";

import PageLoadingComponent from "../../../../../shared/components/page/pageLoadingComponent";
import DialogErrorFragmentComponent from "../../../../../shared/components/page/dialogErrorFragmentComponent";
import { MatIconService } from "../../../../../shared/services/theme/matIconService";
import LinkedProfilesConfirmationService from "./linkedProfilesConfirmationService";
import AgGridRadioButtonCellRendererComponent from "../../../../../shared/components/elements/agGridRadioButtonCellRendererComponent";
import { AgGridUtil } from "../../../../../shared/services/ag-grid/agGridUtil";

class LinkedProfilesConfirmComponent extends React.Component {

    static contextType = AuthContext;

    // ept till component's unMount
    scopedSubscriptions = new SubscriptionArray();
    // apiSubscriptions are canclled before retry also
    apiSubscriptions = new SubscriptionArray();

    constructor(props) {
        super(props);

        // init state
        this.state = {
            searchResult: ResultStatus.NOT_LOADED,
            profileList: [],
            selectedProfile: null,
            lastSearchedValue: '',
            errorMessage: null,
            data: {},
            finalParent: "",
            finalParentId: "",
            finalChild: "",
            swap: false,
            showConfirmLinkedProfilesDialog: false,
            agGridRecipientUtils: new AgGridUtil("lastName", {
                isSelectedCellRenderer: AgGridRadioButtonCellRendererComponent,
            }),
        };
    }

    componentWillUnmount() {
        this.scopedSubscriptions.cancelAll();
        this.apiSubscriptions.cancelAll();
    }

    searchSubject = new BehaviorSubject("");

    componentDidMount() {
        // var searchOBS = this.searchSubject.pipe(
        //     debounceTime(750),
        //     distinctUntilChanged(),
        //     mergeMap((_searchValue) => from(this.searchRecipient(_searchValue)))
        // );

        // this.scopedSubscriptions.add(searchOBS.subscribe());
        this.fetchData();
    }
    fetchData = () => {
        // if(this.props.selectedProfile !== null)
        // {

        // }
        var linkedProfileConfirmParams = {
            parentId: this.props.selectedProfile.porziogstprofileid,
            childId: this.props.loadedProfile,
            from: "ProfileSearch",
            selectedPrID: "" + this.props.selectedProfile.prid,
            hasParent: true,
            isFormUnmatched: true,
            loadedProfileParent: "",
            loadedProfileLinks: "",
            searchedProfileParent: "",
            searchedProfileLinks: "",
            finalParent: "",
            finalChild: "",
            finalSwapParent: "",
            finalSwapChild: "",
            finalParentId: "",
            finalSwapParentId: ""
        }
        this.apiSubscriptions.cancelAll();
        this.setState({ fetchResult: ResultStatus.LOADING, data: [] });

        /**
         * rxjs <combineLatest> operator takes multiple obs as argument and emits their result in the same order
         * this operator is very handy as we don't have to independently wait for each result
         */
        this.apiSubscriptions.add(
            combineLatest([
                // ProfileExpandedViewService.getObs(true, this.props.modalAgNode, this.context),
                LinkedProfilesConfirmationService.profileConfirmAsOBS(this.context.user.tenantId, linkedProfileConfirmParams),
            ]).subscribe(
                // success
                ([_data]) => {
                    this.setState(
                        {
                            data: _data,
                        },
                        // change the state after all the above are assigned
                        () => {
                            this.setState({
                                finalParent: this.state.data.finalParent,
                                finalParentId: this.state.data.finalParentId,
                                finalChild: this.state.data.finalChild,
                                fetchResult: ResultStatus.LOADED
                            });
                            console.log(this.state.data);
                        }
                    );
                },
                // onError
                (error) => {
                    console.log("Error:", error);
                    this.setState({ fetchResult: ResultStatus.ERROR });
                }
            )
        );
    }

    // render
    render() {
        const { classes } = this.props;
        return (
            <Dialog open={this.props.open || false} fullWidth={true} maxWidth='lg' scroll={false ? "paper" : "body"}>
                <DialogTitle style={{ padding: 0 }} id="dialog-title">
                    <AppBar position="static">
                        <Toolbar>
                            <Typography variant="h6" className={classes.root}>Link Confirmation</Typography>

                            {LayoutService.getIconButton(DataService.isNullOrUndefined(this.state.data), MatIconService.SWAP, "Swap", () => {
                                this.setState({ swap: !this.state.swap }, () => {
                                    if (this.state.swap) {
                                        this.setState({
                                            finalParent: this.state.data.finalSwapParent,
                                            finalParentId: this.state.data.finalSwapParentId,
                                            finalChild: this.state.data.finalSwapChild,
                                        });
                                    }
                                    else {
                                        this.setState({
                                            finalParent: this.state.data.finalParent,
                                            finalParentId: this.state.data.finalParentId,
                                            finalChild: this.state.data.finalChild,
                                        });
                                    }
                                })
                            }, "inherit")}
                            {LayoutService.getIconButton(DataService.isNullOrUndefined(this.state.data), MatIconService.OK, "Submit", () => {
                                this.props.onClose(this.state.finalParentId);
                            }, "inherit")}
                            {LayoutService.getIconButton(false, MatIconService.CANCEL, "Cancel", () => { this.props.onClose(null) }, "secondary", "keyActionCancel1")}
                        </Toolbar>
                    </AppBar>
                </DialogTitle>

                <DialogContent>
                    <Box style={{ paddingLeft: 16, paddingRight: 32, paddingTop: 8, paddingBottom: 32, minHeight: "60vh", maxHeight: "60vh" }}>
                        <Typography variant="h6" className={classes.root} align="center">Please confirm the following to proceed.</Typography>
                        <br></br>
                        <span style={{ textDecoration: "underline" }}><Typography variant="subtitle1" gutterBottom={true} className={classes.root}>Current Links from Profile Tab</Typography></span>
                        <Typography variant="subtitle2" gutterBottom={true} className={classes.root}>Parent Profile:
                            <Typography variant="body2" gutterBottom={true} className={classes.root}>{this.state.data.loadedProfileParent} </Typography>
                        </Typography>
                        <Typography variant="subtitle2" gutterBottom={true} className={classes.root}>Linked Profile(s):
                            <Typography variant="body2" gutterBottom={true} className={classes.root}>{this.state.data.loadedProfileLinks} </Typography>
                        </Typography>
                        <span style={{ textDecoration: "underline" }}><Typography variant="subtitle1" gutterBottom={true} className={classes.root}>Current Links from Search</Typography></span>
                        <Typography variant="subtitle2" gutterBottom={true} className={classes.root}>Parent Profile:
                            <Typography variant="body2" gutterBottom={true} className={classes.root}>{this.state.data.searchedProfileParent} </Typography>
                        </Typography>
                        <Typography variant="subtitle2" gutterBottom={true} className={classes.root}>Linked Profile(s):
                            <Typography variant="body2" gutterBottom={true} className={classes.root}>{this.state.data.searchedProfileLinks} </Typography>
                        </Typography>
                        <span style={{ textDecoration: "underline" }}><Typography variant="subtitle1" gutterBottom={false} className={classes.root}>Final result after this Link Confirmation</Typography></span>
                        <Typography variant="subtitle2" gutterBottom={true} className={classes.root}>Parent Profile:
                            <Typography variant="body2" gutterBottom={true} className={classes.root}>{this.state.finalParent} </Typography>
                        </Typography>
                        <Typography variant="subtitle2" gutterBottom={true} className={classes.root}>Linked Profile(s):
                            <Typography variant="body2" gutterBottom={true} className={classes.root}>{this.state.finalChild} </Typography>
                        </Typography>
                    </Box>
                </DialogContent>
            </Dialog >
        );
    }

}
export default LayoutService.getHocComponenet(LinkedProfilesConfirmComponent);