import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DataService } from "../../services/dataService";
import { AgGridUtil } from "../../services/ag-grid/agGridUtil";
import { Typography } from "@material-ui/core";

export default class AgGridDatePickerCellRendererComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = { selDate: this.props.data[this.props.colDef.field] ? this.props.data[this.props.colDef.field] : null };
    }

    render() {
        const specialObj = this.props.data[AgGridUtil.specialProperty];
        const notHasSpecialProperty = DataService.isNullOrUndefined(specialObj);
        const isEditing = notHasSpecialProperty ? true : specialObj[AgGridUtil.editingProperty] === true;
        const isReadOnly = notHasSpecialProperty ? false : specialObj[AgGridUtil.readOnlyProperty] === true;
        const isDisabled = isReadOnly || !isEditing;

        return (
            <>
                {/* <MuiPickersUtilsProvider utils={DateFnsUtils}> */}
                    <DatePicker
                        readOnly={isDisabled} value={this.state.selDate} format="yyyy-MM-dd"
                        onChange={(e) => { this.onStateChangeRequested(e); }}
                        InputProps={{
                            className: "grid-date-picker",
                            disableUnderline: true,
                        }}
                    />
                {/* </MuiPickersUtilsProvider > */}
            </>
        );
    }

    onStateChangeRequested = (e) => {

        //console.log(e);
        this.setState({ selDate: e }, // invert the checked state
            () => {
                //console.log(this.state.selDate);
                var newProps = { ...this.props };
                newProps.oldValue = this.props.data[this.props.colDef.field];
                newProps.newValue = this.state.selDate;

                // this.props.data[this.props.colDef.field] = newProps.newValue; // update the datasource
                this.props.node.setDataValue(this.props.colDef.field, newProps.newValue);  // update the datasource

                // call the callback
                if (this.props.onChange) {
                    this.props.onChange(this, this.state.selDate, newProps);
                }
            }
        );
    }
}
