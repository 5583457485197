import { AgGridColumnExt } from "../../../shared/services/ag-grid/agGridColumnExt";
import ApiService from "../../../shared/services/apiService";
import { API_ENDPOINT } from "../../../shared/types/enums";

export default class ComplianceReportMappingService {

  static getPorzioFieldListAsOBS = (_tenantId) => {
    return ApiService.postOBS(
      API_ENDPOINT.TENANT,
      `/ComplianceMapping/FetchReportMappings/${_tenantId}`,
      JSON.stringify({
        pageNumber: 1,
        pageSize: 50000,
        searchText: "",
        entityId: "",
        fieldId: ""
      }),
    );
  };

  static getEntityListAsOBS = (_tenantId) => {
    return ApiService.getOBS(
      API_ENDPOINT.TENANT,
      `/ComplianceMapping/FetchEntitiesForDefaultMapping/${_tenantId}`
    );
  };

  static getYearsByReportId = (_tenantId, _reportId) => {
    let $url = `/ComplianceMapping/FetchReportTemplateYears/${_tenantId}`
    if (_reportId !== undefined) {
      $url = `/ComplianceMapping/FetchReportTemplateYears/${_tenantId}?reportId=${_reportId}`
    }
    return ApiService.getOBS(API_ENDPOINT.TENANT, $url);
  };

  static getComplianceEntityFieldsAsOBS = (_tenantId, _entityId) => {
    return ApiService.getOBS(
      API_ENDPOINT.TENANT,
      `/ComplianceMapping/FetchEntityFields/${_tenantId}/${_entityId}?entityId=${_entityId}`
    );
  };

  static getRows() {
    return [];
  }

  static getColumnDefs() {
    return [
      {
        headerName: "Report Name",
        field: "reportName",
        sortable: true,
        filter: "text",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,

      },
      {
        headerName: "Template Year",
        field: "templateYear",
        sortable: true,
        filter: "text",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
      },
      {
        headerName: "Field Name",
        field: "fieldName",
        sortable: true,
        filter: "agTextColumnFilter",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        cellRenderer: "childMessageRendererComponent",
      },
      {
        headerName: "Field Value",
        field: "customerFieldValue",
        sortable: true,
        filter: "agTextColumnFilter",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
      },
      {
        headerName: "Porzio GST Field Value",
        field: "porzioGSTFieldValue",
        sortable: true,
        filter: "agTextColumnFilter",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
      },
      {
        headerName: "Report Value",
        field: "reportMappingName",
        sortable: true,
        filter: "agTextColumnFilter",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
      },
      {
        headerName: "Reportable",
        field: "reportable",
        sortable: true,
        filter: "agTextColumnFilter",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 115,
      },
      AgGridColumnExt.GET(false, "text", "isCustomMappingText", "Custom Mapping").defaultWidth(128).SET()
    ];
  }
}
