import React from "react";
import {
  withStyles,
  DialogTitle,
  DialogContent,
  Tabs,
  Tab,
  Box,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  Divider,
} from "@material-ui/core";

import PersonalSettingsModalComponent from "./personalSettings/personalSettingsModalComponent";
import AuditComponent from "./audit/auditComponent";
import AuthenticationModalComponent from "./authentication/authenticationModalComponent";
import { ResultStatus, CrudAction } from "../../../../shared/types/enums";
import { MatClassService } from "../../../../shared/services/theme/matClassService";
import PageLoadingComponent from "../../../../shared/components/page/pageLoadingComponent";
import PageErrorComponent from "../../../../shared/components/page/pageErrorComponent";
import { MatIconService } from "../../../../shared/services/theme/matIconService";
import LayoutService from "../../../../shared/services/layoutService";
import RolePermissionService from "../../../../shared/role-permissions/rolePermissionService";

class UserDialogComponent extends React.Component {

  constructor(props) {
    super(props);

    // bind
    this.onValueChange = this.onValueChange.bind(this);

    // init state
    this.state = {
      data: [],
      fetchResult: ResultStatus.NOT_LOADED,
      selectedTabIndex: 0,
      // build the state with the error model
      model: LayoutService.buildWithErrorModel({
        taskName: "",
        taskPriority: "",
        taskAssignedTo: "Pradeep",
        startDate: "01/01/2020",
        dueDate: "31/12/2020",
        status: "Active",
      }),
    };
  }

  componentWillUnmount() { }

  componentDidMount() {
    this.setState({ fetchResult: ResultStatus.SUCCESS, });
  }

  onTabChange = (event, newValue) => {
    this.setState({ selectedTabIndex: newValue });
  };

  onValueChange(_event) {
    const key = _event.target.id;
    const value = _event.target.value;

    // set value
    this.setState({ model: { [key]: value, }, });

    // set validation message
    switch (key) {
      case "taskName": this.setModelError(key, value === "", "Task Name is required"); break;
      case "taskPriority": this.setModelError(key, value === "", "Task Priority is required"); break;
      case "taskAssignedTo": this.setModelError(key, value === "", "Assigned To is required"); break;
      case "startDate": this.setModelError(key, value === "", "Start Date is required"); break;
      case "dueDate": this.setModelError(key, value === "", "Due Date is required"); break;
      case "status": this.setModelError(key, value === "", "Status is required"); break;
      default: console.log("User Dialog Component default");
    }
  }

  setModelError(_key, _hasError, _errorMsg) {
    this.setState({
      model: {
        [_key + "_error"]: _hasError ? _errorMsg : null,
      },
    });
  }

  buildProps(_key, _label) {
    return {
      label: _label,
      id: _key,
      name: _key,
      defaultValue: this.state.model[_key],
      error: this.state.model[_key + "_error"] !== null,
      helperText: this.state.model[_key + "_error"],
      onChange: this.onValueChange,
    };
  }

  /**Render */
  render() {
    const { classes } = this.props;
    if (RolePermissionService.USER_LIST.cannotView) {
      return RolePermissionService.getAccessDeniedComponent(classes, () => { this.props.onClose(false); });
    } else {
      switch (this.state.fetchResult) {
        case ResultStatus.NOT_LOADED:
        case ResultStatus.LOADING:
          return (<PageLoadingComponent small classes={classes} label="Loading Personal Settings" />);
        case ResultStatus.SUCCESS:
          return (
            <React.Fragment>
              {/* Header */}
              <DialogTitle disableTypography id="dialogTitle">
                <AppBar position="static">
                  <Toolbar>
                    <Typography variant="h6" className={classes.root}>Personal Settings</Typography>
                    <IconButton color="secondary" onClick={() => { this.props.onClose(false); }}>{MatIconService.CANCEL}</IconButton>
                  </Toolbar>
                </AppBar>
              </DialogTitle>
              <DialogContent>
                {this.props.inputAction === CrudAction.UPDATE ? (
                  <>
                    <Tabs value={this.state.selectedTabIndex} onChange={this.onTabChange}
                      indicatorColor="primary" textColor="inherit" variant="fullWidth" scrollButtons="auto" >
                      <Tab label="Personal Information" id="taskTab" />
                    </Tabs>

                    <div role="tabpanel" hidden={this.state.selectedTabIndex !== 0} id="taskTabPanel" >
                      <Divider />
                      <Box style={{ paddingLeft: 16, paddingRight: 32, paddingTop: 8, paddingBottom: 32, minHeight: "65vh", maxHeight: "65vh", minWidth: "80vh", }}>
                        <PersonalSettingsModalComponent userId={this.props.userId} inputAction={this.props.inputAction} refreshParentGrid={this.props.refreshParentGrid} onClose={this.props.onClose} />
                      </Box>
                    </div>
                  </>
                ) : (
                  <Box style={{ paddingLeft: 16, paddingRight: 16, paddingTop: 8, paddingBottom: 32, minHeight: "65vh", maxHeight: "65vh", minWidth: "80vh", }}>
                    <PersonalSettingsModalComponent userId={null} inputAction={this.props.inputAction} refreshParentGrid={this.props.refreshParentGrid} onClose={this.props.onClose} />
                  </Box>
                )}
              </DialogContent>
            </React.Fragment>
          );

        case ResultStatus.ERROR:
        default:
          return (
            <PageErrorComponent small label="Error Loading Task Details" classes={classes} onRetry={() => { }} />
          );
      }
    }
  }
}
/** HOC */
export default LayoutService.getHocComponenet(UserDialogComponent);