import { ALIGNMENT, ENTITY_FIELD_TYPE } from "../../types/enums";
import { DataService } from "../dataService";
import MatSpecService from "../theme/matSpecService";

/**
 * Author : Pradeep_Rajendran@epam.com
 */
export class AgGridColumnExt {

    /**
     * holds the true column definintion
     */
    columnRef = {};

    columnType = ENTITY_FIELD_TYPE.TEXT;

    constructor(_isEditable, _filter, _fieldName, _headerText, _sortable = true, _resizable = true) {
        this.columnRef = ({
            editable: _isEditable,
            headerName: _headerText,
            field: _fieldName,
            resizable: _resizable,
            sortable: _sortable,
            cellStyle: { "border-right": "1px solid lightgray" }
        });

        if (!DataService.isNullOrUndefined(_filter)) { this.columnRef["filter"] = _filter; }
    }

    /**
     * Shorthand for crearing a new instance
     * @param {*} _isEditable 
     * @param {*} _filter 
     * @param {*} _fieldName 
     * @param {*} _headerText 
     * @param {*} _sortable 
     * @param {*} _resizable 
     */
    static GET = (_isEditable, _filter, _fieldName, _headerText, _sortable = true, _resizable = true) => {
        return new AgGridColumnExt(_isEditable, _filter, _fieldName, _headerText, _sortable, _resizable);
    }

    /**
     * Resolves any conflicts that are chained,
     * Finalises and returns the actual reference
     */
    SET = () => {
        // Todo conflict resolution
        return this.columnRef;
    }

    /** * Displays the cell's content as tooltip on mouse hover */
    tooltip = () => { this.columnRef["tooltipField"] = this.columnRef["field"]; return this; }

    /**
     * Show tooltip when hovered over the header
     * @param {*} _value for empty/null value, the header text will be used as tooltip
     */
    headerTooltip = (_value = null) => {
        this.columnRef["headerTooltip"] = DataService.isStringNullOrEmpty(_value) ? this.columnRef["headerName"] : _value;
        return this;
    }

    /**
     * Makes the column readonly if true is passed
     * @param {*} _value 
     */
    isReadOnly = (_value) => { this.columnRef["editable"] = _value === true; return this; }

    /**
     * .validateEditablity((e) => {return e.data.addressid === "" || e.data.addressid <= 0; }) // editable only for new rows
     * @param {*} _func 
     * @returns 
     */
    validateEditablity = (_func) => { this.columnRef["editable"] = _func; return this; }


    /**
     * Appends a new css styles to the existing cell style
     * @param {*} _value 
     */
    addCellStyle = (_value) => {
        this.columnRef["cellStyle"] = { ...this.columnRef["cellStyle"], ..._value };
        return this;
    }

    cellClassRules = (_value) => {
        this.columnRef["cellClassRules"] = _value;
        return this;
    }
    
    cellClass = (_value) => {
        this.columnRef["cellClass"] = _value;
        return this;
    }

    noCellHighlight = () => {
        this.columnRef["cellClass"] = "no-cell-highlight";
        return this;
    }

    /**
     * Sets the cell cursor
     * @param {*} _value eg: "pointer"
     */
    cursor = (_value) => {
        this.columnRef["cellStyle"] = { ...this.columnRef["cellStyle"], ...{ "cursor": _value } };
        return this;
    }

    /**
     * Aligns the contet to Left/Right/Center/Justify
     * @param {*} _textAlignment 
     */
    alignText = (_textAlignment = ALIGNMENT.LEFT) => {
        var textAlignment = "left";
        switch (_textAlignment) {
            case ALIGNMENT.RIGHT: textAlignment = "right"; break;
            case ALIGNMENT.CENTER: textAlignment = "center"; break;
            case ALIGNMENT.JUSTIFY: textAlignment = "justify"; break;
            case ALIGNMENT.LEFT:
            default: textAlignment = "left"; break;
        }
        this.columnRef["cellStyle"] = { ...this.columnRef["cellStyle"], ...{ "text-align": textAlignment } };

        return this;
    }

    /** * Applies Underline Style to the Text Content in the Cell */
    underlineText = () => {
        this.columnRef["cellStyle"] = { ...this.columnRef["cellStyle"], ...{ "text-decoration": "underline" } };
        return this;
    }
    /** * Center Aligns the Text Content in the Cell */
    centerAlignText = () => { return this.alignText(ALIGNMENT.CENTER); }
    /** * Center Aligns the Text Content in the Cell */
    rightAlignText = () => { return this.alignText(ALIGNMENT.RIGHT); }
    /** * Left Aligns the Text Content in the Cell */
    leftAlignText = () => { return this.alignText(ALIGNMENT.LEFT); }
    /** * Justifies Text Content in the Cell */
    justifyText = () => { return this.alignText(ALIGNMENT.JUSTIFY); }

    /** * filed name of this in the data rows */
    setFieldName = (_value) => { this.columnRef["field"] = _value; return this; }

    /** * Sets the readonly mode of the cell */
    editable = (_value = true) => { this.columnRef["editable"] = _value; return this; }

    /** * Enables/disables column resizing */
    resizable = (_value = true) => { this.columnRef["resizable"] = _value; return this; }

    /** * Enables/disables sorting & shows/hides the sorting icon accordingly */
    sortable = (_value) => { this.columnRef["sortable"] = _value; return this; }

    /**
     * determines what to look for when the column filter is applied
     * @param {*} _value "text" | true etc,..
     */
    filter = (_value) => { this.columnRef["filter"] = _value; return this; }

    /**
     * determines what to look for when the column filter is applied
     * @param {*} _filter "text" | true etc,..
     * @param {*} _filterOptions filter options
     */
    filterOptions = (_filter, _filterOptions) => {
        this.columnRef["filter"] = _filter;
        this.columnRef["filterOptions"] = _filterOptions;
        return this;
    }

    /**
     * Css Class to be applied to the header cell
     * @param {*} _value 
     */
    headerClass = (_value) => { this.columnRef["headerClass"] = _value; return this; }

    /**
     * Weightage for the column Width
     * @param {*} _value works in conjuction with other columns flex property
     */
    flexWeight = (_value) => { this.columnRef["flex"] = _value; return this; }

    /**
     * Width at begining
     * @param {*} _value 
     */
    defaultWidth = (_value) => { this.columnRef["width"] = _value; return this; }

    /**
     * Will not resize below this width
     * @param {*} _value 
     */
    minWidth = (_value) => { this.columnRef["minWidth"] = _value; return this; }

    /**
     * Will not resize beyound this width
     * @param {*} _value 
     */
    maxWidth = (_value) => { this.columnRef["maxWidth"] = _value; return this; }

    /**
     * Will allow to resize between the min & max widths
     * @param {*} _value 
     */
    minMaxWidth = (_minWidth, _maxWidth) => { return this.minWidth(_minWidth).maxWidth(_maxWidth); }


    /**
     * Sets a fixed width and is not resizable
     * @param {*} _value 
     */
    fixedWidth = (_value) => { this.minWidth(_value).maxWidth(_value).defaultWidth(_value).resizable(false); return this; }

    /**
     * Sets a default width at startup and will restrict resizing below that width
     * (i.e) allows only to grow in size
     * @param {*} _value 
     */
    minAndDefaultWidth = (_value) => { this.minWidth(_value).defaultWidth(_value); return this; }

    /**
     * Sets a default width at startup and will restrict resizing beyond that width
     * (i.e) allows only to shrink in size
     * @param {*} _value 
     */
    maxAndDefaultWidth = (_value) => { this.maxWidth(_value).defaultWidth(_value); return this; }


    /**
     * Set the cell renderer property of the column
     * @param {*} _value 
     * @param {*} _params cellRendererParams
     */
    cellRenderer = (_value, _params = null) => {
        this.columnRef["cellRenderer"] = _value;
        if (_params) { this.columnRef["cellRendererParams"] = _params; }
        return this;
    }


    /**
     * 
     * @param {*} _value text/etc,...
     * @param {*} _params 
     */
    filterParams = (_value, _textComparator) => {
        this.columnRef["filter"] = _value;
        this.columnRef["filterParams"] = {
            textCustomComparator: _textComparator
        }
    }


    enableValue = (_value = true) => { this.columnRef["enableValue"] = _value; return this; }

    /**
     * Used for Pivoting
     * @param {*} _value 
     */
    pivot = (_value = true) => {
        this.columnRef["pivot"] = _value;
        // this.columnRef["pivotMode"] = _value;
        return this;
    }

    /**
     * Used for groupDefaultExpanded
     * @param {*} _value 
     */
    groupDefaultExpanded = (_value) => {
        this.columnRef["groupDefaultExpanded"] = _value;
        this.columnRef["enableRowGroup"] = _value > 0;
        return this;
    }

    comparator =  (_callback) => {
        this.columnRef["comparator"] = _callback;
        return this;
    }

    /**
     * value setter
     * @param {*} _value callback when value is changed
     */
    valueSetter = (_callback) => {
        this.columnRef["valueSetter"] = _callback;
        return this;
    }


    /**
     * makes this into a dropdown
     * @param {*} _mappings parsed values eg: [{ 1: "Afganistan", 2 : "Argentina" }] | UsageTip: DataService.arrayToObject(_componentThisRef.state.countryList, "id", "value");
     * @param {*} _cellEditorParams  eg: cellHeight: _cellHeight,
     * @param {*} _cellEditor pass the cell-editor agSelectCellEditor
     */
    makeDropDown = (_mappings, _cellEditorParams = {}, _cellEditor = "agSelectCellEditor", _arrSort = []) => {
        this.columnType = ENTITY_FIELD_TYPE.LOV;

        delete this.columnRef.valueSetter; // remove this prop, if exists

        this.columnRef["refData"] = _mappings;
        this.columnRef["cellEditor"] = _cellEditor;
        this.columnRef["cellEditorParams"] = {
            ..._cellEditorParams,
            values: _arrSort.length === 0 ? DataService.extractKeysAsNumbers(_mappings) : _arrSort, // extract the keys as numbers
        };

        return this;
    }

    /**
     * 
     * @param {*} _options 
     * @param {*} _optionKey 
     * @param {*} _optionLabel 
     * @param {*} _cellRenderer dropDownRenderer: AgGridDropdownWithStatusCellRenderer
     * @returns 
     */
    makeMaterialDropDown = (_options, _optionKey, _optionLabel, _showEmpty = false, _emptyValue = "", _cellRenderer = "dropDownRenderer") => {
        this.columnType = ENTITY_FIELD_TYPE.LOV;
        delete this.columnRef.valueSetter; // remove this prop, if exists
        return this.cellRenderer(_cellRenderer,
            { "options": _options, "optionKey": _optionKey, "optionLabel": _optionLabel, "showEmpty": _showEmpty, "emptyValue": _emptyValue }
        );
    }


    static getGridOptions = (_headerHeight = 48, _rowHeight = 32, _tooltipShowDelay = MatSpecService.gridTooltipEnterDelay) => {
        return {
            headerHeight: _headerHeight,
            rowHeight: _rowHeight,
            tooltipShowDelay: _tooltipShowDelay,
        };
    }

    //---
}