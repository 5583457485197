export class userModel {

  /*
   * ---------------------------------
   * userById : kulkarni@porziols.com
   * ---------------------------------
     {
          "id": 2129,
          "firstName": "Medha",
          "lastName": "Kulkarni",
          "userName": "kulkarni@porziols.com",
          "currencyId": 83,
          "defaultLanguageId": 1,
          "isPorzioUser": true,
          "userTypeId": 4,
          "userType": "Support User",
          "tenantId": 1376,
          "roleName": "Tenant Admin"
      }
    * ---------------------------------
    * tenantUserInfoById : ?userId=2129&tenantId=1376
    * ---------------------------------
     {
          "id": 2129,
          "loginName": "kulkarni@porziols.com",
          "firstName": "Medha",
          "lastName": "Kulkarni",
          "isActive": true,
          "currencyId": 83,
          "tenantId": 1376,
          "roleName": "Tenant Admin",
          "roleId": 1
      }
    *---------------------------------
    * tokenById
    * ---------------------------------
     eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6Imt1bGthcm5pQHBvcnppb2xzLmNvbSIsInJvbGUiOiJUZW5hbnQgQWRtaW4iLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3VzZXJkYXRhIjoiMTM3NiIsIlRlbmFudElkIjoiMTM3NiIsIkZpcnN0TmFtZSI6Ik1lZGhhIiwiTGFzdE5hbWUiOiJLdWxrYXJuaSIsIlVzZXJJZCI6IjIxMjkiLCJJc1Bvcnppb1VzZXIiOiJUcnVlIiwiVXNlclR5cGVJZCI6IjQiLCJDdXJyZW5jeUlkIjoiODMiLCJEZWZhdWx0TGFuZ3VhZ2VJZCI6IjEiLCJuYmYiOjE2MTk4ODExMTgsImV4cCI6MTYyMDQ4NTkxOCwiaWF0IjoxNjE5ODgxMTE4LCJhdWQiOiJ0ZW5hbnQifQ.JLU9pCgXdvDhDrdHLeh_3-Ha8fmR5Uyg6b_Gn4dRnz4
    *---------------------------------
   */

  constructor(
    _id, _firstName, _lastName, _userName, _currencyId, _defaultLanguageId, _isPorzioUser, _userTypeId, _userType,
    _tenantid, _tenantname, _uid, _workspaceID, _oktaGroupID,
    _isActive, _roleId, _roleName,
    _sessionToken, _sessionStart, _warnSessionTimeout, _logoutTimeout, _refreshTokenTime,
    _tenantList
  ) {

    this.userId = _id;
    this.firstName = _firstName;
    this.lastName = _lastName;
    this.userName = _userName;
    this.currencyId = _currencyId;
    this.defaultLanguageId = _defaultLanguageId;
    this.isPorzioUser = _isPorzioUser;
    this.userTypeId = _userTypeId;
    this.userType = _userType;

    this.tenantId = _tenantid;
    this.tenantName = _tenantname;
    this.uid = _uid;
    this.workspaceID = _workspaceID;
    this.oktaGroupID = _oktaGroupID;

    this.isActive = _isActive;
    this.roleId = _roleId;
    this.roleName = _roleName;

    this.sessionToken = _sessionToken;
    this.sessionStart = _sessionStart;
    this.warnSessionTimeout = _warnSessionTimeout;
    this.logoutTimeout = _logoutTimeout;
    this.refreshTokenTime = _refreshTokenTime;

    this.tenantList = _tenantList;
  }

  static getByOBJs = (userByIdObj, selTenantObj, tenantUserInfoByIdObj, sessionObj, tenantList) => {
    return new userModel(
      userByIdObj.id,
      userByIdObj.firstName,
      userByIdObj.lastName,
      userByIdObj.userName,
      userByIdObj.currencyId,
      userByIdObj.defaultLanguageId,
      userByIdObj.isPorzioUser,
      userByIdObj.userTypeId,
      userByIdObj.userType,

      selTenantObj.tenantid,
      selTenantObj.tenantName,
      selTenantObj.uid,
      tenantUserInfoByIdObj.workspaceID ?? userByIdObj.workspaceID,
      userByIdObj.oktaGroupID,

      tenantUserInfoByIdObj.isActive,
      tenantUserInfoByIdObj.roleId,
      tenantUserInfoByIdObj.roleName,

      sessionObj.sessionToken,
      sessionObj.sessionStart,
      sessionObj.warnSessionTimeout,
      sessionObj.logoutTimeout,
      sessionObj.refreshTokenTime,

      tenantList
    );
  }

  //---
}
