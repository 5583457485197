export default class ApiEndpointConfig {
    static getPath(value) {
        // The following line defines the URL to the authentication application (the WebApi2StarterKit or your application)
        const apiEndPoint = `${process.env.REACT_APP_IZENDA_IDENTITY_HTTP}/`;
        // The following line defines the URL to the IzendaAPI
        const izendaApiEndPoint = `${process.env.REACT_APP_IZENDA_CORE_HTTP}/`;
        switch (value) {
            case 'registerPorzioUser':
                return apiEndPoint + 'api/Account/RegisterPorzioUser';
            case 'createtenant':
                return apiEndPoint + 'api/Account/CreateTenant';
            case 'createuser':
                return apiEndPoint + 'api/Account/CreateUser';
            case 'gettenants':
                return apiEndPoint + 'api/Account/GetTenants';
            case 'izendaAPI':
                return izendaApiEndPoint + 'api';
            case 'authAPI':
                return apiEndPoint + 'api';
            default:
                return '';
        }
    }
}
