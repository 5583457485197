import React from "react";
import { withStyles, Grid } from "@material-ui/core";
import { MatClassService } from "../../shared/services/theme/matClassService";
import { AuthContext } from "../../shared/store/authProvider";
import TaskListComponent from "./task/tasksListComponent";
import NewsFeedComponent from "./news-feed/newsFeedComponent";
import EventCalendarComponent from "./event-calendar/eventCalendarComponent";

class HomeComponent extends React.Component {
  static contextType = AuthContext;
  /**Render */
  render() {
    const { classes } = this.props;
    return (
      <Grid container spacing={3} direction="row" className={classes.root} style={{ minHeight: "80vh" }}>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4} >
          <NewsFeedComponent></NewsFeedComponent>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          {this.context.user.tenantId === 1 ? <></> : <TaskListComponent></TaskListComponent>}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <EventCalendarComponent></EventCalendarComponent>
        </Grid>
      </Grid>
    );
  }
}

/** HOC */
export default withStyles(MatClassService)(HomeComponent);
