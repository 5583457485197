import React from "react";
import { withStyles } from "@material-ui/core";
import ViewFieldComponent from "./viewFieldComponent";
import { ResultStatus } from "../../../../shared/types/enums";
import { MatClassService } from "../../../../shared/services/theme/matClassService";
import PageLoadingComponent from "../../../../shared/components/page/pageLoadingComponent";
import PageErrorComponent from "../../../../shared/components/page/pageErrorComponent";

class ViewFieldDialogComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      fetchResult: ResultStatus.NOT_LOADED,
      selectedTabIndex: 0,
    };
  }
  componentWillUnmount() { }
  componentDidMount() {
    this.setState({
      fetchResult: ResultStatus.SUCCESS,
    });
  }

  /**Render */
  render() {
    const { classes } = this.props;

    switch (this.state.fetchResult) {
      case ResultStatus.NOT_LOADED:
      case ResultStatus.LOADING:
        return (<PageLoadingComponent small classes={classes} label="Loading View Fields" />);
      case ResultStatus.SUCCESS:
        return (
          <React.Fragment>
            <ViewFieldComponent modalAgNode={this.props.modalAgNode}
              inputAction={this.props.inputAction}
              refreshList={this.props.refreshList}
              onClose={this.props.onClose}
              isPorzio={this.props.isPorzio}
            />
          </React.Fragment>
        );

      case ResultStatus.ERROR:
      default:
        return (<PageErrorComponent small label="Error Loading Task Details" classes={classes} onRetry={() => { }} onClose={() => { this.props.onClose(true); }} />);
    }
  }
}
/** HOC */
export default withStyles(MatClassService)(ViewFieldDialogComponent);
