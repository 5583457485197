import React from "react";
import { from, of, combineLatest, BehaviorSubject, } from "rxjs";
import { filter, mergeMap, debounceTime, distinctUntilChanged } from "rxjs/operators";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  Box,
  DialogTitle,
  DialogContent,
  FormGroup,
  Grid,
  Typography,
  AppBar,
  Toolbar,
  TextField,
} from "@material-ui/core";
import { AuthContext } from "../../../shared/store/authProvider";
import { ResultStatus, CrudAction, API_ENDPOINT } from "../../../shared/types/enums";
import { SubscriptionArray } from "../../../shared/services/dataService";
import PageLoadingComponent from "../../../shared/components/page/pageLoadingComponent";
import PageErrorComponent from "../../../shared/components/page/pageErrorComponent";
import LayoutService from "../../../shared/services/layoutService";
import ApiService from "../../../shared/services/apiService";
import ProductDetailsService from "./productDetailsService";
import LookupService from "../../../shared/services/lookupService";
import RolePermissionService from "../../../shared/role-permissions/rolePermissionService";
import { MatIconService } from "../../../shared/services/theme/matIconService";

let searchSubject = new BehaviorSubject("");
let SearchResultObservable = searchSubject.pipe(
  filter((val) => val.hasOwnProperty("newValue")),
  debounceTime(200),
  distinctUntilChanged(),
  mergeMap((val) => from(fetchProfilesBySearchBoxText(val)))
);

const fetchProfilesBySearchBoxText = async (searchBoxParams) => {

  const prom = new Promise(resolve => {
    combineLatest([
      ProductDetailsService.getNdcCodeExistsInRegistry(searchBoxParams.user.tenantId, searchBoxParams.newValue),
      ProductDetailsService.getDuplicateNdcCode(searchBoxParams.user.tenantId, searchBoxParams.newValue),
    ]).subscribe(
      // success
      ([_regNdcResult, _dupNdcResult]) => {
        let warningMessage = " "; //Note that a single space prepopulates the pushdown area to proactively make space for any warnings
        let isRegWarning = false;
        let isDupWarning = false;

        console.log("searchBoxParams", searchBoxParams);
        console.log("_regNdcResult", _regNdcResult);
        console.log("_dupNdcResult", _dupNdcResult);

        if (searchBoxParams.newValue === "" || searchBoxParams.newValue === undefined) { // Let empty strings pass without warning
          warningMessage = " ";
        } else {
          if (_regNdcResult.length >= 1) {
            if (_regNdcResult[0].ndcCode === searchBoxParams.newValue) {
              isRegWarning = false; // Good News: This NDC is in the database, no warning needed
            } else {
              isRegWarning = true; // Warning: This NDC is not the database
            }
          } else {
            isRegWarning = true; // Warning: This NDC is not in the database
          }

          if (searchBoxParams.inputAction === CrudAction.UPDATE) {
            if (searchBoxParams.newValue === searchBoxParams.previousNdc) {
              isDupWarning = false;  // There is no need to warn if an update duplicates itself
            } else {
              isDupWarning = _dupNdcResult;
            }
          } else {
            isDupWarning = _dupNdcResult;
          }
          console.log("isRegWarning", isRegWarning);
          console.log("isDupWarning", isDupWarning);

          if (isRegWarning && isDupWarning) {
            warningMessage = "Warning: NDC is invalid and duplicates another Product";
          } else {
            if (isRegWarning) {
              warningMessage = "Warning: NDC is invalid";
            }
            if (isDupWarning) {
              warningMessage = "Warning: NDC duplicates another Product";
            }
          }
        }

        resolve(warningMessage);
      },
      // onError
      (error) => {
        console.log("Error:", error);
        this.setState({ fetchResult: ResultStatus.ERROR });
      }
    )
  });
  const strWarning = await prom;
  console.log("strWarning", strWarning);
  return strWarning;
}

class ProductDetailsComponent extends React.Component {
  static contextType = AuthContext;
  apiSubscriptions = new SubscriptionArray();
  scopedSubscriptions = new SubscriptionArray();

  constructor(props) {
    super(props);
    // init state
    this.state = {
      isReadOnly: this.props.inputAction === CrudAction.UPDATE ? true : false,
      isEditing: this.props.inputAction === CrudAction.UPDATE ? true : false,
      data: [],
      openDeleteConfirm: false,
      ndcWarning: " ",
    };
  }

  componentWillUnmount() {
    this.apiSubscriptions.cancelAll();
    this.scopedSubscriptions.cancelAll();
  }

  componentDidMount() {

    // search subscriptions
    this.scopedSubscriptions.add(
      SearchResultObservable.subscribe((searchResultJson) => {
        this.setState({ ndcWarning: searchResultJson });
      })
    );
    searchSubject.next({
      newValue: this.props.inputAction === CrudAction.UPDATE ? this.props.modalAgNode.ndc : "",
      ...this.context,
      inputAction: this.props.inputAction,
      previousNdc: this.props.inputAction === CrudAction.UPDATE ? this.props.modalAgNode.ndc : "",
    })

    this.fetchData();
  }

  fetchData = (_ignoreCache = false) => {
    this.apiSubscriptions.cancelAll();
    this.setState({ fetchResult: ResultStatus.LOADING, data: [] });
    /**
     * rxjs <combineLatest> operator takes multiple obs as argument and emits their result in the same order
     * this operator is very handy as we don't have to independently wait for each result
     */
    this.apiSubscriptions.add(
      combineLatest([
        (this.props.inputAction === CrudAction.CREATE ? of(null) : ProductDetailsService.getProductDetailsAsOBS(this.context.user.tenantId, this.props.modalAgNode.id)),
        ProductDetailsService.getBrandDataAsOBS(this.context.user.tenantId, 1),
        ProductDetailsService.getCategoryDataAsOBS(this.context.user.tenantId, 2),
      ]).subscribe(
        // success
        ([_productDetailsData, _brandData, _categoryData]) => {
          this.setState(
            {
              productDetailsData: _productDetailsData,
              brandData: this.props.inputAction===CrudAction.CREATE?_brandData.filter(x => x.isActive): _brandData,
              categoryData: _categoryData,
            },
            // change the state after all the above are assigned
            () => {
              this.setState({ fetchResult: ResultStatus.LOADED });
            }
          );
        },
        // onError
        (error) => {
          console.log("Error:", error);
          this.setState({ fetchResult: ResultStatus.ERROR });
        }
      )
    );
  }

  handleChangeNdc = (_fProps, e) => {
    const newValue = e.target.value;
    _fProps.handleChange(e);
    searchSubject.next({
      newValue,
      ...this.context,
      inputAction: this.props.inputAction,
      previousNdc: this.props.inputAction === CrudAction.UPDATE ? this.props.modalAgNode.ndc : "",
    });
  };

  getInitialValues() {
    if (this.props.inputAction === CrudAction.CREATE) {
      return {
        productName: "",
        categoryId: "",
        ndc: "",
        udi: "",
        brandId: null,
        therapeuticArea: "",
        isUSFederal: true,
        isActive: true,
      };

    } else {
      return {
        productName: this.props.modalAgNode.name,
        categoryId: this.state.categoryData.find(x => x.lovKey === this.props.modalAgNode.productCategory) ? this.state.categoryData.find(x => x.lovKey === this.props.modalAgNode.productCategory).lovId : "",
        ndc: this.props.modalAgNode.ndc,
        udi : this.props.modalAgNode.udi,
        brandId: this.props.modalAgNode.brandId,
        therapeuticArea: this.props.modalAgNode.therapeuticArea,
        isUSFederal: this.state.productDetailsData.isUSFederal,
        isActive: this.props.modalAgNode.isActive,
      };
    }
  }

  validationSchema = Yup.object().shape({});
  getValidationSchema() {
    this.validationSchema = Yup.object().shape({
      productName: Yup.string()
        .required("Required")
        .min(3, "Must be at least 3 characters long")
        .max(256, "Must be 256 characters or less")
        .test(
          "checkDuplicateProductName",
          "Product Name already exists",
          async (_formikValue) => {
            if (this.props.inputAction === CrudAction.UPDATE) {
              if (_formikValue === this.props.modalAgNode.name) {
                return true;
              }
            }
            try {
              const result = await fetch(
                `${process.env.REACT_APP_TENANT_HTTP}/Products/Exists/${this.context.user.tenantId}/${_formikValue}`
              );
              const isExists = await result.json();
              console.log("is Product Name exists: ", isExists);
              return !isExists;
            } catch (error) {
              console.log("Product Exists Error:", error);
              this.setState({
                fetchResult: ResultStatus.ERROR,
              });
            }
          }
        ),
      //categoryId: Yup.number().required("Required").typeError('Required'),
      //brandId: Yup.number().required("Required").typeError('Required'),
      ndc: Yup.string().max(256, "Must be 256 characters or less"),
      udi : Yup.string().max(100, "Must be 100 characters or less"),
      therapeuticArea: Yup.string().max(256, "Must be 256 characters or less"),
      isUSFederal: Yup.boolean().required("Required"),
      isActive: Yup.boolean().required("Required"),
    });
    return this.validationSchema;
  }

  handleSubmit = async (_formikProps) => {
    console.log(_formikProps);
    if (!_formikProps.isSubmitting && _formikProps.isValid) {
      await this.validationSchema.validate(_formikProps.values, { abortEarly: false })
        .then((x) => {
          // 0) clear the CACHE,so next time if requried this new object will also be fetched
          LookupService.clearPRODUCTS();

          // 1) set the status
          this.setState({ fetchResult: ResultStatus.SAVING });

          // 2) extract the formik values into an object
          var mappedObj = {
            name: _formikProps.values.productName,
            productCategory: this.state.categoryData.find(x => x.lovId === _formikProps.values.categoryId)?.lovKey,
            brandId: _formikProps.values.brandId === null ? 0 : _formikProps.values.brandId,
            ndc: _formikProps.values.ndc,
            udi : _formikProps.values.udi,
            therapeuticArea: _formikProps.values.therapeuticArea,
            isUSFederal: _formikProps.values.isUSFederal,
            isActive: _formikProps.values.isActive,
          }

          // 3) determine the action and assign the appropriate props
          let actionVerb = "";
          let targetUrl = "";
          if (this.props.inputAction === CrudAction.UPDATE) {
            // UPDATE
            actionVerb = "PUT";
            targetUrl = `/Products/${this.context.user.tenantId}/${this.props.modalAgNode.id}`;
            mappedObj.id = this.props.modalAgNode.id;
            mappedObj.createdBy = this.props.modalAgNode.createdBy;
            mappedObj.createdDate = this.props.modalAgNode.createdDate;
            mappedObj.updatedBy = this.context.user.userId;
            mappedObj.updatedDate = new Date().toISOString().slice(0, 10);
          } else {
            // CREATE
            actionVerb = "POST";
            targetUrl = `/Products/${this.context.user.tenantId}`;
            mappedObj.id = 0;
            mappedObj.createdBy = this.context.user.userId;
            mappedObj.createdDate = new Date().toISOString().slice(0, 10);
          }

          // 4) save to Api and subscribe for the result
          ApiService.setOBS(
            actionVerb,
            API_ENDPOINT.TENANT,
            targetUrl,
            JSON.stringify(mappedObj)
          ).subscribe(
            (successResult) => {
              if (successResult) {
                this.setState({ fetchResult: ResultStatus.SUCCESS });
                this.props.refreshProductList(true);
                this.props.onClose(false);
              } else {
                console.error("Error: falsey successResult while saving Product", successResult);
                this.setState({ fetchResult: ResultStatus.ERROR });
              }
            },
            (errorResult) => {
              console.error("Error while saving Product", errorResult);
              this.setState({ fetchResult: ResultStatus.ERROR });
            }
          );
        })
        .catch((erroObj) => {
          erroObj.inner.forEach(err => { _formikProps.setFieldError(err.path, err.message); });
        });
    }
  }
  
  render() {
    const { classes } = this.props;

    if (RolePermissionService.PRODUCT_DETAIL.cannotView && this.props.inputAction === CrudAction.UPDATE) {
      return RolePermissionService.getAccessDeniedComponent(classes, () => { this.props.history.goBack() }); // this is required to prevent Url navigation
    } else {
      switch (this.state.fetchResult) {
        case ResultStatus.LOADING:
          return (
            <PageLoadingComponent
              small
              classes={classes}
              label="Loading Product Details"
            />
          );
        case ResultStatus.SAVING:
          return (
            <PageLoadingComponent
              small
              classes={classes}
              label="Saving Product Details"
            />
          );
        case ResultStatus.SUCCESS:
        case ResultStatus.LOADED:
          return (
            <>
              <Formik
                initialValues={this.getInitialValues()}
                validationSchema={this.getValidationSchema()}
                validationSchemaOptions={{ showMultipleFieldErrors: true }}
              >
                {(fProps) => (
                  <form>
                    <DialogTitle disableTypography id="dialogTitle">
                      <AppBar position="static">
                        <Toolbar variant="dense">
                          <Typography variant="h6" className={classes.root}>Product Details</Typography>
                          {this.props.inputAction === CrudAction.UPDATE ? RolePermissionService.PRODUCT_DETAIL.cannotEdit ? LayoutService.getIconButton(false, MatIconService.CLOSE, "close",
                            () => { this.props.onClose(false) }, "secondary")
                            : LayoutService.getReadOnlyActions(this, !this.state.isEditing, () => { this.props.onClose(false) }, () => { this.handleSubmit(fProps) })
                            : LayoutService.getReadOnlyActions(this, !this.state.isEditing, () => { this.props.onClose(false) }, () => { this.handleSubmit(fProps) })
                          }
                          {/* {LayoutService.getReadOnlyActions(this, !this.state.isEditing, () => { this.props.onClose(false) }, () => { this.handleSubmit(fProps) })} */}
                        </Toolbar>
                      </AppBar>
                    </DialogTitle>
                    {/* Dialog Content */}
                    <DialogContent>
                      <Box
                        style={{
                          paddingLeft: 16,
                          paddingRight: 16,
                          paddingTop: 16,
                          paddingBottom: 32,
                          minWidth: "40vh",
                          maxWidth: "60vh",
                        }}
                      >
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <TextField
                              {...LayoutService.getInputProps(this.state.isReadOnly,
                                classes,
                                fProps,
                                "productName",
                                "Product Name"
                              )}
                              style={{ minWidth: "95%" }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            {LayoutService.getSelectControl(this.state.isReadOnly,
                              classes,
                              fProps,
                              "categoryId",
                              "Product Category",
                              this.state.categoryData,
                              "lovId",
                              "lovKey",
                              "95%",
                              true
                            )}
                          </Grid>
                          <Grid item xs={12}>
                            {LayoutService.getSelectControl(this.state.isReadOnly,
                              classes,
                              fProps,
                              "brandId",
                              "Brand Name",
                              this.state.brandData,
                              "lovId",
                              "lovKey",
                              "95%"
                            )}
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              {...LayoutService.getInputProps2(this.state.isReadOnly,
                                classes,
                                fProps,
                                "ndc",
                                "NDC",
                                false,
                                "small",
                                this.state.ndcWarning
                              )}
                              onChange={(e) => this.handleChangeNdc(fProps, e)}
                              style={{ minWidth: "90%" }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              {...LayoutService.getInputProps(this.state.isReadOnly,
                                classes,
                                fProps,
                                "udi",
                                "Primary Device Indicator",
                                false
                              )}
                              style={{ minWidth: "95%" }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              {...LayoutService.getInputProps(this.state.isReadOnly,
                                classes,
                                fProps,
                                "therapeuticArea",
                                "Therapeutic Area",
                                false,
                              )}
                              style={{ minWidth: "95%" }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormGroup style={{ paddingLeft: "4px", paddingTop: "8px" }}>
                              {LayoutService.getSwitch(this.state.isReadOnly, classes, fProps, "isUSFederal", "US Federal Covered")}
                            </FormGroup>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormGroup style={{ paddingLeft: "4px", paddingTop: "8px" }}>
                              {LayoutService.getSwitch(this.state.isReadOnly, classes, fProps, "isActive", "Active")}
                            </FormGroup>
                          </Grid>
                        </Grid>
                      </Box>
                    </DialogContent>
                  </form>
                )}
              </Formik>
            </>
          );
        case ResultStatus.ERROR:
          return (
            <PageErrorComponent
              small
              label="Error Loading Product Details"
              classes={classes}
              onRetry={() => {
                this.SERVICE.fetchData(this, true);
              }}
              onClose={this.props.onClose}
            />
          );
        default:
          return (null);
      }
    }
  }
}
/** HOC */
export default LayoutService.getHocComponenet(ProductDetailsComponent);