import { API_ENDPOINT } from '../../shared/types/enums';
import ApiService from '../../shared/services/apiService';

export default class UserInfoService {

    static getByEmail = (userEmail) => {
        return ApiService.getOBS(API_ENDPOINT.IDENTITY, `/User/GetUserById/${userEmail}`);
    }

    static getUserTenants = (userId) => {
        return ApiService.getOBS(API_ENDPOINT.IDENTITY, `/User/GetUserTenants?userId=${userId}`);
    }

    static getUserTenantInfo = (userId, tenantid) => {
        return ApiService.getOBS(API_ENDPOINT.TENANT, `/User/GetTenantUserInfoById?userId=${userId}&tenantId=${tenantid}`);
    }

    static getSessionInfo = (userId, tenantid) => {
        return ApiService.getOBS(API_ENDPOINT.IDENTITY, `/User/GetTokenById/${tenantid}/${userId}`);
    }

    

    //---
}