import React from 'react';
import { AppBar, Box, CircularProgress, Dialog, DialogContent, DialogContentText, DialogTitle, Grid, Toolbar, Typography } from "@material-ui/core";
import { AuthContext } from "../../../shared/store/authProvider";
import { MatIconService } from "../../../shared/services/theme/matIconService";
import LayoutService from "../../../shared/services/layoutService";
import ApiService from "../../../shared/services/apiService";
import ToastService from "../../../shared/services/toastService";
import PageErrorComponent from "../../../shared/components/page/pageErrorComponent";
import { ResultStatus, API_ENDPOINT } from "../../../shared/types/enums";

class RerunValidationComponent extends React.Component {
    static contextType = AuthContext;

    state = {
        fetchResult: ResultStatus.SUCCESS,
    };

    handleRerunValidation = () => {
        // 1) set the status
        this.setState({ fetchResult: ResultStatus.SAVING });

        // 4) save to Api and subscribe for the result
        ApiService.setOBS("POST", API_ENDPOINT.CORE, `/Transactions/InstantRerunFileValidationForTransactions/${this.context.user.tenantId}/${this.props.agCellData.fileID}/${this.context.user.userId}`, JSON.stringify({})).subscribe(
            (successResult) => {
                if (successResult) {
                    ToastService.showSuccess("Re-run Validation Successful");
                    this.props.handleClose();
                } else {
                    this.setState({ fetchResult: ResultStatus.ERROR });
                }
            },
            (errorResult) => {
                this.setState({ fetchResult: ResultStatus.ERROR });
            }
        );
    }

    handleRevalidateQueue = () => {
        // 1) set the status
        this.setState({ fetchResult: ResultStatus.SAVING });

        // 4) save to Api and subscribe for the result
        ApiService.setOBS("POST", API_ENDPOINT.CORE, `/Transactions/AddRerunFileValidationProcessForTransactions/${this.context.user.tenantId}/${this.props.agCellData.fileID}/${encodeURIComponent(this.props.agCellData.fileName)}/${this.context.user.userId}`, JSON.stringify({}), undefined, "text").subscribe(
            (successResult) => {
                if (successResult.includes("success")) {
                    ToastService.showSuccess("Revalidation of Queue is Successful");
                    this.props.handleClose();
                } else {
                    ToastService.showError(successResult);
                    //this.setState({ fetchResult: ResultStatus.ERROR });
                    this.props.handleClose();
                }
            },
            (errorResult) => {
                console.log("Errorrrrrrrrrrrr", errorResult);
                this.setState({ fetchResult: ResultStatus.ERROR });
            }
        );
    }

    render() {
        const { classes } = this.props;

        switch (this.state.fetchResult) {
            case ResultStatus.SUCCESS:
            case ResultStatus.SAVING:
                return (
                    <div>
                        <Dialog
                            open={this.props.open || false}
                            onClose={this.props.handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                <AppBar position="static">
                                    <Toolbar>
                                        <Typography variant="h6" className={classes.root}>{this.props.reRunFileValidation ? "Rerun Validation" : "Rerun Validation"}</Typography>
                                        {LayoutService.getIconButton(false, MatIconService.OK, this.props.reRunFileValidation ? "Re-run File Validation" : "Rerun Validation", this.props.reRunFileValidation ? this.handleRerunValidation : this.handleRevalidateQueue, "inherit", "keyActionRerunValidation")}
                                        {LayoutService.getIconButton(false, MatIconService.CANCEL, "Cancel", this.props.handleClose, "secondary", "keyActionCancel")}
                                    </Toolbar>
                                </AppBar>
                            </DialogTitle>

                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    <Box style={{ paddingLeft: 16, paddingRight: 32, paddingTop: 8, paddingBottom: 32, }}  >
                                        <Grid container spacing={1} style={{ minHeight: "30vh" }}>
                                            {this.state.fetchResult === ResultStatus.SAVING ?
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" className={classes.root} align="center">Re-running File Validation for file:</Typography>
                                                </Grid>
                                                :
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" className={classes.root} align="center">Are you sure you want to Re-run File Validation? For file:</Typography>
                                                </Grid>
                                            }
                                            <Grid item xs={12}>
                                                <Typography variant="h6" className={classes.root} align="center">{this.props.agCellData.fileName}</Typography>
                                            </Grid>
                                            {this.state.fetchResult !== ResultStatus.SAVING ?
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" className={classes.root} align="center">This action cannot be reversed</Typography>
                                                </Grid>
                                                : null}
                                            {this.state.fetchResult === ResultStatus.SAVING ?
                                                <Grid item xs={12} >
                                                    <Grid direction="column" container justify="center" alignItems="center">
                                                        <CircularProgress thickness={3} size={"3rem"} color="secondary" />
                                                    </Grid>
                                                </Grid>
                                                : null}
                                        </Grid>
                                    </Box>
                                </DialogContentText>
                            </DialogContent>
                        </Dialog>
                    </div>
                );
            case ResultStatus.ERROR:
            default:
                return (<PageErrorComponent label="Error occurred during Re-run Validation" onClose={this.props.handleClose} classes={classes} onRetry={this.handleRerunValidation} />);
        }
    }
}

export default LayoutService.getHocComponenet(RerunValidationComponent);