import React, { Component } from "react";
import { DataService } from "../../../services/dataService"

export default class HealthIndicatorCellRenderer extends Component {

    render() {
        let style = undefined;
        // 1 -> error, 2 -> warning, 3 -> UnMatched
        if (this.props.data.healthindicator === 1) { style = "circle-error"; }
        else if (this.props.data.healthindicator === 2) { style = "circle-warning"; }
        else if (this.props.data.healthindicator === 3) { style = "circle-unmatched"; }
        // status check
        else if (this.props.hasApprovalFlag(this.props)) { style = "circle-approval"; }

        //---
        return (DataService.isStringNullOrEmpty(style) ? null : <div className={`commonCircleStyles ${style}`} >{""}</div>);

        //---
    }
}
