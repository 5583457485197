import ApiService from "../../../../shared/services/apiService";
import { API_ENDPOINT } from "../../../../shared/types/enums";
import { DataService } from "../../../../shared/services/dataService";
import { AgGridColumnExt } from "../../../../shared/services/ag-grid/agGridColumnExt";

export default class ComplianceReportMappingService {

  static getDefaultMappingsByEntityFieldIdListAsOBS = (_tenantId, _fieldId) => {
    return ApiService.postOBS(
      API_ENDPOINT.TENANT,
      `/ComplianceMapping/FetchDefaultMappingsByEntityFieldId/${_tenantId}`,
      JSON.stringify({
        pageNumber: 1,
        pageSize: 5000,
        searchText: "",
        entityId: "",
        fieldId: String(_fieldId),
        reportId: "",
        year: ""
      }),
    );
  };

  static getPorzioGSTFieldListAsOBS = (_tenantId, _fieldId) => {
    return ApiService.getOBS(
      API_ENDPOINT.TENANT,
      `/ComplianceMapping/FetchPorzioGSTFieldList/${_tenantId}/${_fieldId}`
    );
  };

  static getComplianceEntityFieldsAsOBS = (_tenantId, _entityId) => {
    return ApiService.getOBS(
      API_ENDPOINT.TENANT,
      `/ComplianceMapping/FetchEntityFields/${_tenantId}/${_entityId}`
    );
  };

  static getColumnDefs(_componentThisRef) {
    return [
      {
        headerName: "Field Value",
        field: "lovKey",
        sortable: true,
        filter: "agTextColumnFilter",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
      },
      AgGridColumnExt.GET(false, "text", "gstLovID", "Porzio GST Field Value", true).comparator(_componentThisRef.porzioGstFieldValueComparator).flexWeight(1)
        .makeMaterialDropDown(_componentThisRef.state.porzioGSTFieldList, "lovId", "lovKey", true, 0).SET(),
    ]
  }
}
