import ApiService from "../../services/apiService";
import { API_ENDPOINT } from "../../types/enums";

export default class FieldConfigurationService {

  static getDataCenterAvailableFieldsAsOBS = (_tenantId, _fileId, _sourceId, _entityId, _userId,_screenId) => {
    return ApiService.getOBS(
      API_ENDPOINT.CORE,
      `/DataCenter/AvailableFields/${_tenantId}/${_fileId}/${_sourceId}/${_entityId}/${_userId}/${_screenId}`
    );
  };

  static getDataCenterDisplayedFieldsAsOBS = (_tenantId, _sourceId, _entityId, _userId,_screenId) => {
    return ApiService.getOBS(
      API_ENDPOINT.CORE,
      `/DataCenter/DisplayedFields/${_tenantId}/${_sourceId}/${_entityId}/${_userId}/${_screenId}`
    );
  };

}