import ApiService from "../../../../shared/services/apiService";
import { API_ENDPOINT, ENTITY_TYPE } from "../../../../shared/types/enums";
import { AgGridColumnExt } from "../../../../shared/services/ag-grid/agGridColumnExt";

export default class ReportReferenceService {

    static getReportReferenceAsOBS = (_tenantId, _modalAgNode) => {//TODO : fileid : need to figure out and pass
        const targetUrl = `​​/RuleSummary/FetchReportReferncesByEntity/${_tenantId}?entityId=${ENTITY_TYPE.AGREEMENT}&id=${_modalAgNode.agreementId}&pageNumber=1&pageSize=1000`;

        return ApiService.getOBS(
            API_ENDPOINT.CORE,
            targetUrl,
        );
    };

    /**
     * Refer url for more details: https://www.ag-grid.com/javascript-grid-column-properties/
     * @param _componentThisRef pass {this} keyword from the calling componenet
     */
    static getColumnDefs(_componentThisRef) {
        return [
            AgGridColumnExt.GET(false, "text", "reportDetailID", "Report ID").minAndDefaultWidth(144).valueSetter(this.setRuleViolations).SET(),
            AgGridColumnExt.GET(false, "text", "reportLibrary", "Report Library").flexWeight(6).tooltip().valueSetter(this.setRuleViolations).SET(),
            AgGridColumnExt.GET(false, "text", "reportDetailName", "Report Name").flexWeight(6).tooltip().valueSetter(this.setRuleViolations).SET(),
            AgGridColumnExt.GET(false, "text", "finalDispositionName", "Final Disposition").flexWeight(4).tooltip().valueSetter(this.setRuleViolations).SET(),
            AgGridColumnExt.GET(false, "text", "reportCreatedDate", "Report Generation Date").flexWeight(4).tooltip().valueSetter(this.setRuleViolations).SET(),
        ];
    }
}