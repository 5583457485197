import React, { Component } from "react";
import { Checkbox } from "@material-ui/core";
import { AgGridBulkEditUtil } from "../bluk-edit/agGridBulkEditUtil";
import { DataService } from "../../../services/dataService";
import MatThemeService from "../../../services/theme/matThemeService";

export class AgGridErroredCheckboxCellRenderer extends Component {

    constructor(props) {
        super(props);

        if (DataService.isNullOrUndefined(this.props.node.data[this.props.column.colDef.field])) {
            // if null or undefined is passed then the textcontrol becomes uncontrolled,
            // to avoid that we are updating the underlying datasource's value to empty string 
            this.props.node.data[this.props.column.colDef.field] = "";
        }

        const _errorOrWarningConfig = this.props.getErrorOrWarningInfoCallback(this);
        this.state = {
            //|---> Mandatory Fields
            isEditing: false,
            isParentReadOnly: this.props.isParentReadOnly,
            isColumnReadOnly: this.props.isColumnReadOnly,
            value: this.props.node.data[this.props.column.colDef.field],
            parentRef: this.props.componentThisRef,
            errorOrWarningConfig: _errorOrWarningConfig,
            hasErrorOrWarning: AgGridBulkEditUtil.hasErrorOrWarning(this.props.componentThisRef.state.agGridBulkEditUtil, this.props, _errorOrWarningConfig),
            //<---|
        };
    }


    render() {

        return AgGridBulkEditUtil.getRenderer(this,
            // formattedValue callback
            () => { return this.state.value ? "Y" : "N"; },
            // editor callback
            () => {
                return (
                    <Checkbox size="small" style={{ padding: 0 }} indeterminate={false} checked={this.state.value}
                        onClick={() => { AgGridBulkEditUtil.applyChange(this, !this.state.value); }}
                    />
                );
            },
            { display: "flex", alignItems: "center", justifyContent: "center" },
            { textAlign: "center" }

        );
    }

    //---
}