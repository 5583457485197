import ApiService from "../../../shared/services/apiService";
import { API_ENDPOINT } from "../../../shared/types/enums";

export default class CustomFieldListService {

  static getCustomFieldListAsOBS = (_tenantId,) => {
    return ApiService.postOBS(
      API_ENDPOINT.TENANT,
      `/EntityField/FetchPagedFields?tenantId=${_tenantId}`,
      JSON.stringify({
        pageNumber: 1,
        pageSize: 1000,
        entityId: 0,
        searchText: "",
        isCustom: true,
        companyId: 0,
      }),
    );
  };

  static getRows() {
    return [];
  }

  static getColumnDefs() {
    return [
      {
        headerName: "Field Name",
        field: "field_Name",
        sortable: true,
        filter: "text",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 225,
        cellRenderer: "childMessageRendererComponent",
      },
      {
        headerName: "Description",
        field: "field_Description",
        sortable: true,
        filter: "text",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 150,
      },
      {
        headerName: "Entity",
        headerHeight: 35,
        field: "entityName",
        sortable: true,
        filter: "agTextColumnFilter",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 150,
        height: 30,
      },
      {
        headerName: "PLS Required",
        field: "plsrequired",
        sortable: true,
        filter: true,
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 150,
      },
      {
        headerName: "Field Type",
        field: "fielD_TYPE",
        sortable: true,
        filter: true,
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 150,
      },
      {
        headerName: "System Alias",
        field: "fielD_ALIASNAME",
        sortable: true,
        filter: "text",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 150,
      },
      {
        headerName: "Active",
        field: "active",
        sortable: true,
        filter: true,
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 75,
        cellRenderer: "activeRendererComponent",
      },
    ];
  }
}
