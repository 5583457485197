import ApiService from "../../../shared/services/apiService";
import { API_ENDPOINT } from "../../../shared/types/enums";

export default class ComplianceDefaultMappingService {

  static getPorzioFieldListAsOBS = (_tenantId) => {
    return ApiService.postOBS(
      API_ENDPOINT.TENANT,
      `/ComplianceMapping/FetchDefaultMappings/${_tenantId}`,
      JSON.stringify({
        pageNumber: 1,
        pageSize: 50000,
        searchText: "",
        entityId: "",
        fieldId: ""
      }),
    );
  };

  static getEntityListAsOBS = (_tenantId) => {
    return ApiService.getOBS(
      API_ENDPOINT.TENANT,
      `/ComplianceMapping/FetchEntitiesForDefaultMapping/${_tenantId}`
    );
  };

  static getComplianceEntityFieldsAsOBS = (_tenantId, _entityId) => {
    return ApiService.getOBS(
      API_ENDPOINT.TENANT,
      `/ComplianceMapping/FetchEntityFields/${_tenantId}/${_entityId}?entityId=${_entityId}`
    );
  };

  static getRows() {
    return [];
  }

  static getColumnDefs() {
    return [
      {
        headerName: "Field Name",
        field: "fieldName",
        sortable: true,
        filter: "text",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        // width: 225,
        cellRenderer: "childMessageRendererComponent",
      },
      {
        headerName: "Field Value",
        field: "fieldValue",
        sortable: true,
        filter: "text",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        // width: 150,
      },
      {
        headerName: "Porzio GST Field Value",
        field: "gstLovKey",
        sortable: true,
        filter: "agTextColumnFilter",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        // width: 150,
        // height: 30,
      },
    ];
  }
}
