import React from "react";
import { combineLatest } from "rxjs";
import { Formik } from "formik";
import * as Yup from "yup";

import { DataService, SubscriptionArray } from "../../../../shared/services/dataService";
import { AuthContext } from "../../../../shared/store/authProvider";
import { Grid, Tabs, Tab, Divider, Box } from "@material-ui/core";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css";
import { AgGridUtil } from "../../../../shared/services/ag-grid/agGridUtil";

import { API_ENDPOINT, ResultStatus, SELECT_MODE } from "../../../../shared/types/enums";
import PageLoadingComponent from "../../../../shared/components/page/pageLoadingComponent";
import PageErrorComponent from "../../../../shared/components/page/pageErrorComponent";
import AgGridDynamicCheckboxCellRendererComponent from "../../../../shared/components/elements/agGridDynamicCheckboxCellRendererComponent";
import LayoutService from "../../../../shared/services/layoutService";
import { RolePermissionsService } from "./rolePermissionsService";
import LookupService from "../../../../shared/services/lookupService";
import MatThemeService from "../../../../shared/services/theme/matThemeService";
import AgGridHierarchicalTextboxRendererComponent from "../../../../shared/components/elements/agGridHierarchicalTextboxRendererComponent";
import DialogErrorFragmentComponent from "../../../../shared/components/page/dialogErrorFragmentComponent"
import ApiService from "../../../../shared/services/apiService";
import PageDynamicHeaderComponent from "../../../../shared/components/page/pageDynamicHeaderComponent";
import { MatIconService } from "../../../../shared/services/theme/matIconService";

import RolePermissionService from "../../../../shared/role-permissions/rolePermissionService";
import ReportTemplateSubscriptionsComponent from "../../../tenant-profile-setup/report-template-subscriptions/reportTemplateSubscriptionsComponent";
import ToastService from "../../../../shared/services/toastService";
import AgGridCheckboxCellRendererComponent from "../../../../shared/components/elements/agGridCheckboxCellRendererComponent";
import AgGridSelectMenuComponent from "../../../../shared/components/agGridSelectMenuComponent";

class RolePermissionsComponent extends React.Component {
    static contextType = AuthContext;
    oSubscriptions = new SubscriptionArray();
    STORE = RolePermissionsService;

    reportSubscriptionComponentInfo = { ref: null };

    constructor(props) {
        super(props);
        this.state = {
            isReadOnly: true,
            modalAgNode: null,

            lookupFetchResult: ResultStatus.NOT_LOADED,
            selRoleId: '',
            rolesList: [],
            selSectionId: '',
            sectionList: [],
            permissionActionsList: [],
            dataErrorMessage: '',

            dataFetchResult: ResultStatus.NOT_LOADED,
            data: [],
            agPermissionGridUtils: new AgGridUtil("permissionName", {
                hierarchicalTextboxRendererComponent: AgGridHierarchicalTextboxRendererComponent,
                dynamicCheckBoxRendererComponent: AgGridDynamicCheckboxCellRendererComponent,
            }),

            anchorEl: null,
            openSelectMenu: false,
            reportFetchResult: ResultStatus.NOT_LOADED,
            reportTemplateRows: [],
            agReportGridUtils: new AgGridUtil("reportName", {
                isSubscriptionCellRenderer: AgGridCheckboxCellRendererComponent,
            }),
        };
    }

    componentDidMount() { this.fetchLookups(); }

    getInitialValues() {
        return {
            sectionId: this.state.selSectionId,
            roleId: this.state.selRoleId,
        };
    }

    validationSchema = Yup.object().shape({});
    getValidationSchema() {
        this.validationSchema = Yup.object().shape({
            sectionId: Yup.number().required("Required"),
            roleId: Yup.number().required("Required"),
        });
        return this.validationSchema;
    }

    getSelectionIcon = () => {
        return this.state.selSectionId !== LookupService.reportTemplateSubscriptionId ? null : ({
            title: "select", icon: MatIconService.MENU, onClick: (e) => { this.setState({ anchorEl: e.currentTarget, openSelectMenu: true }); },
            isReadOnly: this.state.isReadOnly || !this.isGridReady
        });
    }

    onTabChange = (event, _newTabIndex) => {//TODO
        //const currentTabRef = this.tabIndexMap.get(this.state.selectedTabIndex).ref;
        this.setState({
            //showConfirmDialog: false,
            selectedRptSubscriptionTabIdx: _newTabIndex,
            isReadOnly: true
        }, () => this.fetchData());
    }

    render() {
        const { classes } = this.props;
        if (RolePermissionService.ROLE_PERMISSIONS.cannotView) {
            return RolePermissionService.getAccessDeniedComponent(classes, () => { this.props.onClose(false); });
        } else {
            switch (this.state.lookupFetchResult) {
                // Loading
                case ResultStatus.NOT_LOADED:
                case ResultStatus.LOADING:
                    return (<PageLoadingComponent classes={classes} label="Loading Role Permissions" />);
                // Good to Render the components
                case ResultStatus.LOADED:
                case ResultStatus.SUCCESS:
                    return (
                        <Formik initialValues={this.getInitialValues()} validationSchema={this.getValidationSchema()} validationSchemaOptions={{ showMultipleFieldErrors: true }}>
                            {(fProps) => (
                                <form>
                                    {/* Render the Header */}
                                    <div className="IconBg marginTop10px tableTitleHead">
                                        {/* Header Componenet */}
                                        <AgGridSelectMenuComponent anchorEl={this.state.anchorEl} openSelectMenu={this.state.openSelectMenu}
                                            agGridUtils={this.state.agReportGridUtils} selectionColumnName={"isSubscription"}
                                            onClose={() => { this.setState({ anchorEl: null, openSelectMenu: false }); }}
                                        />
                                        <PageDynamicHeaderComponent classes={classes} label="Role Permissions"
                                            leftActions={[
                                                { icon: MatIconService.BACK, onClick: () => { this.props.history.goBack(); }, title: "Go Back" },
                                                { icon: MatIconService.RELOAD, iconColor: "secondary", title: "Reload", onClick: () => { this.setState({ isReadOnly: true }, () => { this.fetchData(); }); } },
                                                { ...this.getSelectionIcon() }
                                            ]}
                                            rightActions={
                                                // if not tenenat admin don't let them modify
                                                RolePermissionService.IS_NOT_TENANT_ADMIN ? [] : // TODO: Role Override 2/2 (enable/disable)
                                                    [
                                                        { icon: MatIconService.EDIT, iconColor: "default", onClick: () => { this.setState({ isReadOnly: false }) }, isReadOnly: !this.state.isReadOnly || RolePermissionService.ROLE_PERMISSIONS.cannotEdit, title: "Edit" },
                                                        { icon: MatIconService.OK, iconColor: "primary", onClick: () => { this.postData(); }, isReadOnly: this.state.isReadOnly || RolePermissionService.ROLE_PERMISSIONS.cannotEdit, title: "Save" },
                                                    ]}
                                        />

                                    </div>
                                    {/* Render the Select */}
                                    <Grid className={classes.flexNoShrink} container direction="column" style={{ marginBottom: 8 }}>
                                        <Grid container direction="row" alignItems="center">
                                            {LayoutService.getDropDown(false, classes.dialogControl, classes.menuPaper, fProps, this.validationSchema, "roleId", "Role", this.state.roleList, "id", "text", null, true, "48%",
                                                (_formikProps, _newRoleId) => { this.setState({ selRoleId: _newRoleId }, () => { this.fetchData() }); })
                                            }
                                            {LayoutService.getDropDown(false, classes.dialogControl, classes.menuPaper, fProps, this.validationSchema, "sectionId", "Section", this.state.sectionList, "id", "text", null, true, "48%",
                                                (_formikProps, _newSectionId) => {
                                                    this.setState({ selSectionId: _newSectionId }, () => { this.fetchData() });
                                                })
                                            }
                                        </Grid>
                                    </Grid>
                                    {/* Render the Grid */}
                                    {this.renderGrid(classes)}
                                </form>
                            )}
                        </Formik >
                    );
                // Error
                case ResultStatus.ERROR:
                default:
                    return (<PageErrorComponent label="Error Loading Lookups" classes={classes} onRetry={() => { this.fetchLookups(); }} />);
            }
        }
    }

    isGridReady = false;
    renderGrid(_classes) {
        if (this.state.selSectionId === LookupService.reportTemplateSubscriptionId) {
            if (this.isGridReady) { this.state.agReportGridUtils.disableEditability(this.state.isReadOnly); }

            switch (this.state.reportFetchResult) {
                case ResultStatus.NOT_LOADED:
                case ResultStatus.LOADING:
                    return (<PageLoadingComponent classes={_classes} label="Loading Report Template Subscriptions" />);
                case ResultStatus.SAVING:
                    return (<PageLoadingComponent classes={_classes} label="Saving Report Template Subscriptions" />);
                case ResultStatus.LOADED:
                case ResultStatus.SUCCESS:
                    return (
                        <>
                            <Tabs value={this.state.selectedRptSubscriptionTabIdx} onChange={this.onTabChange} indicatorColor="secondary" textColor="inherit" variant="scrollable" scrollButtons="auto"
                                style={{ backgroundColor: MatThemeService.getTabBG() }}>
                                <Tab label="Compliance Templates" id="complianceTab" />
                                <Tab label="Standard Templates" id="standardTemplatesTab" />
                            </Tabs>
                            <div role="tabpanel" id={"tabPanel"} >
                                <Divider />
                                <Box style={{ padding: "0px" }} >
                                <div {...LayoutService.getAgGridStyles(290)}>
                                        <AgGridReact
                                            columnDefs={this.STORE.getReportSubscriptionColumnDefs(this)}
                                            rowData={this.state.reportTemplateRows}
                                            pagination={true}
                                            paginationPageSize={50}
                                            frameworkComponents={this.state.agReportGridUtils.frameworkComponents}
                                            sideBar={true}
                                            gridOptions={{
                                                headerHeight: 48,
                                                suppressContextMenu: true,
                                                context: { componentParent: this },
                                            }}
                                            onGridReady={(params) => {
                                                params.api.closeToolPanel();
                                                this.isGridReady = true;
                                                this.state.agReportGridUtils.setGridParams(params, true);
                                                this.state.agReportGridUtils.disableEditability(this.state.isReadOnly);
                                            }}>
                                        </AgGridReact>
                                    </div>
                                </Box>
                            </div>
                        </>
                    );
                case ResultStatus.ERROR:
                default:
                    return (<PageErrorComponent label="Error Loading Report Templates" classes={_classes} onRetry={() => { this.fetchData(); }} />);
            }
        } else {
            if (this.isGridReady) { this.state.agPermissionGridUtils.disableEditability(this.state.isReadOnly); }

            switch (this.state.dataFetchResult) {
                case ResultStatus.NOT_LOADED: return (<></>);
                case ResultStatus.LOADING: return (<PageLoadingComponent classes={_classes} label="Loading Role-Permissions" />);
                // Good to Render the components
                case ResultStatus.LOADED:
                case ResultStatus.SUCCESS:
                    if (DataService.hasNoElements(this.state.data)) {
                        return (<DialogErrorFragmentComponent small classes={_classes} description={this.state.dataErrorMessage} />);
                    } else {
                        return (
                            <div id="MainRoleGrid">
                                <div {...LayoutService.getAgGridStyles(0, 75)} >
                                    <AgGridReact
                                        rowData={this.state.data}
                                        columnDefs={this.STORE.getColumnDefs(this)}
                                        frameworkComponents={this.state.agPermissionGridUtils.frameworkComponents}
                                        suppressClickEdit={true}
                                        gridOptions={{ context: { componentParent: this }, suppressContextMenu: true }}
                                        onGridReady={(params) => {
                                            this.isGridReady = true;
                                            this.state.agPermissionGridUtils.setGridParams(params, true);
                                            this.state.agPermissionGridUtils.disableEditability(this.state.isReadOnly);
                                        }}
                                    />
                                </div>
                            </div >
                        );
                    }
                // Error
                case ResultStatus.ERROR:
                default:
                    return (<DialogErrorFragmentComponent classes={_classes} description="Error Loading Data" onRetry={() => { this.fetchData(); }} />);
            }
        }

    }


    //---API---

    fetchLookups = () => {
        this.oSubscriptions.cancelAll();
        this.setState({
            lookupFetchResult: ResultStatus.LOADING,
            selRoleId: '',
            selSectionId: '',
            dataErrorMessage: '',
            selectedRptSubscriptionTabIdx :0,
            dataFetchResult: ResultStatus.NOT_LOADED,
            data: [],
        });

        // fetch the observables
        this.oSubscriptions.add(combineLatest([
            LookupService.getRolesAsOBS(this.context),
            LookupService.getPermissionSectionsAsOBS(),
            LookupService.getPermissionActionsAsOBS(this.context.user.tenantId)
        ]).subscribe(([_permissionRoles, _permissionSections, _permissionActionsList]) => {
            this.setState({
                // skip tenant role id
                roleList: DataService.getKeyValueCollection(_permissionRoles.filter(x => x.isActive && x.roleId !== RolePermissionService.TEANANT_ADMIN_ROLE_ID), "roleId", "roleName", false),
                sectionList: DataService.getKeyValueCollection(_permissionSections, "sectionId", "sectionName", false),
                permissionActionsList: _permissionActionsList,
            }, () => {
                this.setState({ lookupFetchResult: ResultStatus.LOADED });
                this.fetchData();
            });
        }),
            (error) => { this.setState({ lookupFetchResult: ResultStatus.ERROR, data: [] }); }
        );
    }

    fetchData = () => {
        this.oSubscriptions.cancelAll();
        this.isGridReady = false;

        // validate selection
        if (!(this.state.selRoleId > 0 && this.state.selSectionId > 0)) {
            var target = "";
            if (!(this.state.selRoleId > 0) && !(this.state.selSectionId > 0)) { target = "Role & Section" }
            else if (!(this.state.selRoleId > 0)) { target = "Role" }
            else { target = "Section" }

            this.setState({
                data: [],
                dataErrorMessage: `Select a valid ${target}`,
                dataFetchResult: ResultStatus.LOADED
            });
        } else {
            // if report template subscription is selected, then load the compontnt
            if (this.state.selSectionId === LookupService.reportTemplateSubscriptionId) {
                this.setState({ reportFetchResult: ResultStatus.LOADING, reportTemplateRows: [], data: [] });

                // save the subscription object
                this.oSubscriptions.add(
                    combineLatest([
                        this.STORE.fetchTemplates(this.state.selectedRptSubscriptionTabIdx, this.context.user.tenantId, this.state.selRoleId),
                    ]).subscribe(
                        ([_reportTemplateRows]) => {
                            this.setState({
                                reportTemplateRows: _reportTemplateRows,
                                reportFetchResult: ResultStatus.SUCCESS
                            });
                        },
                        (_error) => {
                            ToastService.showError("Error Occured.");
                            this.setState({ reportFetchResult: ResultStatus.ERROR });
                        }
                    )
                );
            } else {
                this.setState({ dataFetchResult: ResultStatus.LOADING, reportTemplateRows: [], data: [] });

                // fetch the observables
                this.oSubscriptions.add(combineLatest([
                    this.STORE.getPermissionsViewAsOBS(this.context.user.tenantId, this.state.selSectionId, this.state.selRoleId)
                ]).subscribe(([_permissionsData]) => {
                    // TODO: this temp, till the api is fixed
                    if (DataService.hasElements(_permissionsData)) {
                        _permissionsData = _permissionsData.filter(x => x.sectionId === this.state.selSectionId);
                        // get the parent items in the root
                        let _orderedPermissionData = _permissionsData.filter(x => x.parentId === 0) || [];
                        // get all parentIds sorted
                        let parentIds = DataService.getUniqueValues(_permissionsData, "parentId").sort((a, b) => { return a - b });
                        parentIds.forEach(parentId => {
                            const parentItem = _permissionsData.find(x => x.permissionId === parentId);
                            if (parentItem) {
                                const childItems = _permissionsData.filter(x => x.parentId === parentId);
                                // check if parent is already inseted
                                const parentsNewIndex = _orderedPermissionData.findIndex(x => x.permissionId === parentId);
                                if (parentsNewIndex < 0) { // if parent is not inserted
                                    _orderedPermissionData.push(parentItem); // push the parent
                                    (childItems || []).forEach(childItem => { // and all its children after that
                                        _orderedPermissionData.push(childItem);
                                    });
                                } else {
                                    const indexToInsert = parentsNewIndex + 1;
                                    _orderedPermissionData = DataService.insertItems(_orderedPermissionData, indexToInsert, childItems);
                                }
                            }
                        });
                        // sort it by the parent
                        _permissionsData = _orderedPermissionData;
                    }

                    this.setState({
                        data: _permissionsData //RolePermissionsService.transformObjectToRows(_permissionsData)
                    }, () => { this.setState({ dataFetchResult: ResultStatus.LOADED }) });
                }),
                    (error) => {
                        this.setState({
                            dataErrorMessage: "Select a valid Role & Section",
                            dataFetchResult: ResultStatus.ERROR
                        });
                    }
                );
            }
        }
    }

    postData = () => {
        this.oSubscriptions.cancelAll();
        if (this.state.selSectionId === LookupService.reportTemplateSubscriptionId) {
            let selectedIds = [];
            let allIds = [];
            this.state.agReportGridUtils.gridApi.forEachNode((node) => {
                allIds.push(node.data["reportId"]);
                if (node.data["isSubscription"] === true) {
                    selectedIds.push(node.data["reportId"]);
                }
            });

            if (DataService.hasNoElements(allIds)) {
                ToastService.showWarning("No Data to Save");
            } else {
                this.setState({ fetchResult: ResultStatus.SAVING });
                this.oSubscriptions.add(
                    this.STORE.saveReportTemplatesAsOBS(this.state.selectedRptSubscriptionTabIdx, this.context.user.tenantId, this.context.user.userId,
                        this.state.selRoleId, selectedIds.join()).subscribe(
                            (success) => {
                                ToastService.showSuccess("Successfully Saved");
                                this.fetchData();
                            },
                            (error) => {
                                ToastService.showError("Save Failed");
                                this.setState({ fetchResult: ResultStatus.LOADED }); // just show the UI
                            }
                        )
                );
            }
        } else {
            if (this.state.selSectionId > 0 && this.state.selRoleId > 0) {
                this.setState({ dataFetchResult: ResultStatus.LOADING });
                this.oSubscriptions.add(
                    ApiService.postOBS(
                        API_ENDPOINT.TENANT,
                        `/Permission/SavePermissions?tenantId=${this.context.user.tenantId}&sectionId=${this.state.selSectionId}&roleId=${this.state.selRoleId}`,
                        JSON.stringify(this.state.data)
                    ).subscribe(
                        (successResult) => {
                            if (successResult) { this.setState({ dataFetchResult: ResultStatus.SUCCESS }); }
                            else {
                                console.error("Error while saving vendor details", successResult);
                                this.setState({ dataFetchResult: ResultStatus.ERROR });
                            }
                        },
                        (errorResult) => {
                            console.error("Error while saving vendor details", errorResult);
                            this.setState({ dataFetchResult: ResultStatus.ERROR });
                        }
                    )
                );
            }
        }
    }


}
export default LayoutService.getHocComponenet(RolePermissionsComponent);