import React, { Component } from "react";
import { combineLatest, } from "rxjs";
import clsx from "clsx";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css";

import { AuthContext } from "../../../shared/store/authProvider";
import ApiService from "../../../shared/services/apiService";
import PageLoadingComponent from "../../../shared/components/page/pageLoadingComponent";
import PageErrorComponent from "../../../shared/components/page/pageErrorComponent";
import { SubscriptionArray, DataService } from "../../../shared/services/dataService";
import { ResultStatus, CrudAction, API_ENDPOINT } from "../../../shared/types/enums";
import LayoutService from "../../../shared/services/layoutService";

import { Typography, Divider, Grid, Tooltip, IconButton, MenuItem, Select } from "@material-ui/core";
import MatSpecService from "../../../shared/services/theme/matSpecService";
import { MatIconService } from "../../../shared/services/theme/matIconService";

import ChildMessageRendererComponent from "./compliance-default-details/childMessageRendererComponent";
import ComplianceReportMappingService from "./complianceReportMappingService";
import RolePermissionService from "../../../shared/role-permissions/rolePermissionService";
import ComplianceReportDetailsDialogComponent from "./compliance-report-details-dialog/complianceReportDetailsDialogComponent";


class ComplianceReportMappingComponent extends Component {
    static contextType = AuthContext;
    apiSubscriptions = new SubscriptionArray();

    constructor(props) {
        super(props);
        this.state = {
            frameworkComponents: {
                childMessageRendererComponent: ChildMessageRendererComponent,
            },
            showDialog: false,
            modalAgNode: null,
            selectedEntityId: 0,
            selectedFieldId: 0,
            selectedReportId: 0,
            selectedYearId: 0,
        };
    }

    componentWillUnmount() {
        this.apiSubscriptions.cancelAll();
    }

    componentDidMount() {
        this.fetchData();
    }

    /** API Fetch */
    fetchData = () => {
        this.apiSubscriptions.cancelAll();
        this.setState({ fetchResult: ResultStatus.LOADING, data: [] });

        this.apiSubscriptions.add(
            combineLatest([
                ComplianceReportMappingService.getPorzioFieldListAsOBS(this.context.user.tenantId),
                ComplianceReportMappingService.getEntityListAsOBS(this.context.user.tenantId),
                ComplianceReportMappingService.getYearsByReportId(this.context.user.tenantId, this.state.selectedReportId === 0 ? undefined : this.state.selectedReportId),
                ComplianceReportMappingService.getComplianceEntityFieldsAsOBS(this.context.user.tenantId, 1),
                ComplianceReportMappingService.getComplianceEntityFieldsAsOBS(this.context.user.tenantId, 2),
            ]).subscribe(
                // success
                ([_data, _entityList, _yearList, _profileFieldList, _transactionFieldList]) => {
                    let tempData = [];
                    let tempFieldList = [];
                    let pushedReportNames = [];
                    _data.forEach(x => {

                        // for display purposes
                        x.isCustomMappingText = x.isPZMapping === true ? "" : "Yes";

                        let flag = 0;
                        for (let i = 0; i < tempData.length; i++) {
                            if (tempData[i].reportId === x.reportId) {
                                flag = 1;
                            }
                        }
                        if (flag === 1) { flag = 0; }
                        else {
                            if (!pushedReportNames.includes(x.reportName)) {
                                pushedReportNames.push(x.reportName)
                                tempData.push(x)
                            }
                        }
                    })

                    _profileFieldList = _profileFieldList.sort((a, b) => a.fieldName.localeCompare(b.fieldName));
                    _transactionFieldList = _transactionFieldList.sort((a, b) => a.fieldName.localeCompare(b.fieldName));
                    tempData = tempData.sort((a, b) => a.reportName.localeCompare(b.reportName));

                    tempFieldList = [{ fieldId: 0, fieldName: "All" }, ..._profileFieldList, ..._transactionFieldList];
                    tempFieldList = tempFieldList.sort((a, b) => a.fieldName.localeCompare(b.fieldName));

                    _yearList = _yearList.filter((obj, pos, arr) => {
                        return arr.map(mapObj => mapObj["reportYear"]).indexOf(obj["reportYear"]) === pos;
                    });
                    
                    let _reportsList = DataService.getKeyValueCollection(tempData, "reportId", "reportName", false);
                    this.setState(
                        {
                            data: _data ? _data.sort((a, b) => a.reportName.localeCompare(b.reportName)) : [],
                            entityList: [{ entityId: 0, entityName: "All" }, ..._entityList],
                            fieldList: tempFieldList,
                            fieldListWithAll: tempFieldList,
                            reportsList: _reportsList,
                            reportsListForCreate: _reportsList,
                            reportsListWithAll: [{ id: 0, text: "All" }, ..._reportsList],
                            yearList: [{ id: 0, text: "All" }, ...DataService.getKeyValueCollection(_yearList, "reportPathId", "reportYear")],
                            profileFieldList: [{ fieldId: 0, fieldName: "All" }, ..._profileFieldList],
                            transactionFieldList: [{ fieldId: 0, fieldName: "All" }, ..._transactionFieldList],
                        },
                        // change the state after all the above are assigned
                        () => {
                            this.setState({ fetchResult: ResultStatus.LOADED });
                        }
                    );
                },
                // onError
                (error) => {
                    console.log("Error:", error);
                    this.setState({ fetchResult: ResultStatus.ERROR });
                }
            )
        );

    };

    onGridReady = (event) => {
        event.api.closeToolPanel();
        event.api.sizeColumnsToFit();
        this.gridApi = event.api;
        this.gridColumnApi = event.columnApi;
        this.setFilters(event.api);
    };

    setFilters = (_agGridApi) => {
        if (this.state.selectedReportId > 0) {
            this.gridApi.setFilterModel({
                reportName: {
                    filterType: 'text',
                    type: 'equals',
                    filter: this.state.reportsListWithAll.find(x => x.id === this.state.selectedReportId).text,
                },
            });
            this.fetchYear(this.state.selectedReportId);
        }
    }

    onEntitySelectClick = (event) => {
        console.log(event);
        this.setState({
            selectedEntityId: event.target.value,
            //selectedReportId: 0,
            //selectedYearId: 0,
        });

        switch (event.target.value) {
            case 0: this.setState({ fieldList: this.state.fieldListWithAll, selectedFieldId: 0, }); break;
            case 1: this.setState({ fieldList: this.state.profileFieldList, selectedFieldId: 0, }); break;
            case 2: this.setState({ fieldList: this.state.transactionFieldList, selectedFieldId: 0, }); break;
            default: this.setState({ selectedEntityId: event.target.value, selectedFieldId: 0, }); break;
        }

        this.gridApi.setFilterModel(null);
    }

    onFieldSelectClick = (event) => {
        this.setState({
            selectedFieldId: event.target.value,
            //selectedReportId: 0,
            //selectedYearId: 0,
        });
        this.gridApi.setFilterModel({
            fieldName: {
                filterType: 'text',
                type: 'equals',
                filter: event.target.value === 0 ? null : this.state.fieldList.find(x => x.fieldId === event.target.value).fieldName,
            },
            reportName: {
                filterType: 'text',
                type: 'equals',
                filter: this.state.selectedReportId === 0 ? null : this.state.reportsListWithAll.find(x => x.id === this.state.selectedReportId).text,
            },
            templateYear: {
                filterType: 'text',
                type: 'equals',
                filter: this.state.selectedYearId === 0 ? null : this.state.yearList.find(x => x.id === this.state.selectedYearId).text,
            },
        });
    }

    onReportSelectClick = (event) => {
        this.setState({
            selectedReportId: event.target.value,
            //selectedYearId: 0,
        });
        this.gridApi.setFilterModel({
            reportName: {
                filterType: 'text',
                type: 'equals',
                filter: event.target.value === 0 ? null : this.state.reportsListWithAll.find(x => x.id === event.target.value).text,
            },
            fieldName: {
                filterType: 'text',
                type: 'equals',
                filter: this.state.selectedFieldId === 0 ? null : this.state.fieldList.find(x => x.fieldId === this.state.selectedFieldId).fieldName,
            },
            templateYear: {
                filterType: 'text',
                type: 'equals',
                filter: this.state.selectedYearId === 0 ? null : this.state.yearList.find(x => x.id === this.state.selectedYearId).text,
            },
        });
        this.fetchYear(event.target.value);
    }

    fetchYear = (_reportId) => {
        ApiService.getOBS(
            API_ENDPOINT.TENANT,
            `/ComplianceMapping/FetchReportTemplateYears/${this.context.user.tenantId}?reportId=${_reportId}`
        ).subscribe(
            (successResult) => {
                let tempYearList = [];
                if (successResult) {
                    tempYearList = tempYearList.filter((obj, pos, arr) => {
                        return arr.map(mapObj => mapObj["reportYear"]).indexOf(obj["reportYear"]) === pos;
                    });
                    tempYearList = [...DataService.getKeyValueCollection(successResult, "reportPathId", "reportYear")];
                    this.setState({
                        fetchResult: ResultStatus.SUCCESS,
                        yearList: [{ id: 0, text: "All" }, ...tempYearList.sort((a, b) => a.text.localeCompare(b.text))],
                    });
                } else { this.setState({ fetchResult: ResultStatus.ERROR }); }
            },
            (errorResult) => {
                console.error("Error while saving Product", errorResult);
                this.setState({ fetchResult: ResultStatus.ERROR });
            }
        );
    }

    onYearSelectClick = (event) => {
        this.setState({ selectedYearId: event.target.value });
        this.gridApi.setFilterModel({
            templateYear: {
                filterType: 'text',
                type: 'equals',
                filter: event.target.value === 0 ? null : this.state.yearList.find(x => x.id === event.target.value).text,
            },
            reportName: {
                filterType: 'text',
                type: 'equals',
                filter: this.state.selectedReportId === 0 ? null : this.state.reportsListWithAll.find(x => x.id === this.state.selectedReportId).text,
            },
            fieldName: {
                filterType: 'text',
                type: 'equals',
                filter: this.state.selectedFieldId === 0 ? null : this.state.fieldList.find(x => x.fieldId === this.state.selectedFieldId).fieldName,
            }
        });
    }

    onDownloadClick = () => {
        let api = this.gridApi, params = this.getParams();
        api.exportDataAsExcel(params);
    }

    getParams() {
        return {
            // allColumns: true,
            columnKeys: this.gridColumnApi.getAllColumns().filter(c => c.colDef.headerName !== ""),
            fileName: `Compliance_Report_Mapping ${new Date().toDateString()}`
        };
    }

    methodFromParent = (cell, data) => {
        this.setState({ modalAgNode: data, inputAction: CrudAction.UPDATE, showDialog: true });
    };

    handleCreate = () => {
        this.setState({ inputAction: CrudAction.CREATE, modalAgNode: null, showDialog: true });
    }

    handleReload = (_selectedReportId) => {
        this.setState({ selectedReportId: _selectedReportId },
            () => { this.fetchData() }
        );
    }

    render() {
        const { classes } = this.props;
        const pageName = "Report Mapping";
        if (RolePermissionService.COMPLIANCE_REPORT_MAPPING_LIST.cannotView) {
            return RolePermissionService.getAccessDeniedComponent(classes, () => { this.props.history.goBack(); });
        } else {
            switch (this.state.fetchResult) {
                case ResultStatus.NOT_LOADED:
                case ResultStatus.LOADING:
                    return (<PageLoadingComponent classes={classes} label={`Loading ${pageName}`} />);
                case ResultStatus.LOADED:
                case ResultStatus.SUCCESS:
                    return (
                        <div id="MainUsersGrid">
                            <div className="IconBg marginTop10px tableTitleHead">
                                <CompliancePageHeaderComponent back history={this.props.history} noDivider
                                    classes={classes}
                                    label={pageName}

                                    fieldComplianceSelect
                                    selectedFieldId={this.state.selectedFieldId}
                                    fieldSelectData={this.state.fieldList}
                                    onFieldSelectClick={(event) => {
                                        this.onFieldSelectClick(event);
                                    }}

                                    reportComplianceSelect
                                    selectedReportId={this.state.selectedReportId}
                                    reportSelectData={this.state.reportsListWithAll}
                                    onReportSelectClick={(event) => {
                                        this.onReportSelectClick(event);
                                    }}

                                    yearComplianceSelect
                                    selectedYearId={this.state.selectedYearId}
                                    yearList={this.state.yearList}
                                    onYearSelectClick={(event) => {
                                        this.onYearSelectClick(event);
                                    }}
                                    download onDownloadClick={this.onDownloadClick}
                                    addCircle
                                    onAddClick={this.handleCreate}

                                />
                            </div>
                            <div {...LayoutService.getAgGridStyles()}>
                                <AgGridReact
                                    columnDefs={ComplianceReportMappingService.getColumnDefs(this)}
                                    rowData={this.state.data}
                                    pagination={true}
                                    paginationPageSize={50}
                                    frameworkComponents={this.state.frameworkComponents}
                                    gridOptions={{
                                        context: { componentParent: this },
                                    }}
                                    onGridReady={(event) => {
                                        this.onGridReady(event);
                                    }}
                                ></AgGridReact>
                            </div>

                            {this.state.showDialog ?
                                <ComplianceReportDetailsDialogComponent
                                    open={this.state.showDialog || false}
                                    inputAction={this.state.inputAction}
                                    onClose={(_reloadRequired, _selectedReportId) => {
                                        this.setState({ showDialog: false }, () => {
                                            if (_reloadRequired) { this.handleReload(_selectedReportId); }
                                        });
                                    }}
                                    modalAgNode={this.state.modalAgNode}
                                    selectedReportName={this.state.inputAction === CrudAction.CREATE ? '' : this.state.modalAgNode?.reportName}
                                    reportList={this.state.inputAction === CrudAction.UPDATE ? this.state.reportsList
                                        : this.state.reportsListForCreate}
                                />
                                : null
                            }
                        </div>
                    );

                case ResultStatus.ERROR:
                    return (<PageErrorComponent label={`Error Loading ${pageName}`} classes={classes} onRetry={() => { this.fetchData(true); }} />);
                default:
                    return null;
            }
        }
    }
}

class CompliancePageHeaderComponent extends Component {
    render() {
        const { small, classes, history, label, back, menu, search, entityComplianceSelect, fieldComplianceSelect, reportComplianceSelect, yearComplianceSelect, countrySelect, importTemplate, uploadFile, save, add, addCircle, view, reload, ok, cancel, noDivider, noDividerSpacing, download } = this.props;
        const showButtons = back || menu || search || entityComplianceSelect || countrySelect || importTemplate || uploadFile || save || add || addCircle || view || reload || ok || cancel || download;

        return (
            <React.Fragment>
                <Grid className={classes.flexNoShrink} container direction="column">
                    <Grid container direction="row" alignItems="center">
                        {back ? (
                            <Tooltip enterDelay={MatSpecService.tooltipEnterDelay} title="Back" placement="bottom" arrow>
                                <IconButton onClick={() => { history.goBack(); }}>{MatIconService.BACK}</IconButton>
                            </Tooltip>
                        ) : null}

                        <Typography variant={small ? "h6" : "h5"} className={classes.headerTitle}>
                            {label}
                        </Typography>

                        {entityComplianceSelect ? (
                            <>
                                <Typography>Entity:{'\u00A0'}{'\u00A0'}{'\u00A0'}</Typography>
                                <Select
                                    labelId="entityComplianceSelectLabel"
                                    id="entityComplianceSelect"
                                    value={this.props.selectedEntityId}
                                    onChange={this.props.onEntitySelectClick}
                                    style={{ width: 150 }}
                                >
                                    {this.props.entitySelectData.map((el) => (
                                        <MenuItem key={el.entityId} value={el.entityId}>
                                            {el.entityName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </>
                        ) : null}

                        {fieldComplianceSelect ? (
                            <>
                                <Typography>Field:{'\u00A0'}{'\u00A0'}{'\u00A0'}</Typography>
                                <Select
                                    labelId="fieldComplianceSelectLabel"
                                    id="entityComplianceSelect"
                                    value={this.props.selectedFieldId}
                                    onChange={this.props.onFieldSelectClick}
                                    style={{ width: 150 }}
                                >
                                    {this.props.fieldSelectData.map((el) => (
                                        <MenuItem key={el.fieldId} value={el.fieldId}>
                                            {el.fieldName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </>
                        ) : null}

                        {reportComplianceSelect ? (
                            <>
                                <Typography>Report Name:{'\u00A0'}{'\u00A0'}{'\u00A0'}</Typography>
                                <Select
                                    labelId="reportComplianceSelectLabel"
                                    id="reportComplianceSelect"
                                    value={this.props.selectedReportId}
                                    onChange={this.props.onReportSelectClick}
                                    style={{ width: 150 }}
                                >
                                    {this.props.reportSelectData.map((el) => (
                                        <MenuItem key={el.id} value={el.id}>
                                            {el.text}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </>
                        ) : null}

                        {yearComplianceSelect ? (
                            <>
                                <Typography>Year:{'\u00A0'}{'\u00A0'}{'\u00A0'}</Typography>
                                <Select
                                    labelId="yearComplianceSelectLabel"
                                    id="yearComplianceSelect"
                                    value={this.props.selectedYearId}
                                    onChange={this.props.onYearSelectClick}
                                    style={{ width: 150 }}
                                >
                                    {this.props.yearList.map((el) => (
                                        <MenuItem key={el.id} value={el.id}>
                                            {el.text}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </>
                        ) : null}

                        {download && RolePermissionService.COMPLIANCE_REPORT_MAPPING_EXPORT.canView ? (
                            <Tooltip enterDelay={MatSpecService.tooltipEnterDelay} title="Download to Excel" placement="bottom" arrow>
                                <IconButton onClick={this.props.onDownloadClick} color="secondary">
                                    {MatIconService.DOWNLOAD}
                                </IconButton>
                            </Tooltip>
                        ) : null}

                        {(add || addCircle) && RolePermissionService.COMPLIANCE_REPORT_MAPPING_DETAIL.canCreate ? (
                            <Tooltip enterDelay={MatSpecService.tooltipEnterDelay} title="Add" placement="bottom" arrow>
                                <IconButton onClick={this.props.onAddClick} color="secondary">
                                    {addCircle ? MatIconService.ADD_CIRCLE_OUTLINE : MatIconService.ADD}
                                </IconButton>
                            </Tooltip>
                        ) : null}

                        {reload ? (
                            <Tooltip enterDelay={MatSpecService.tooltipEnterDelay} title="Reload" placement="bottom" arrow>
                                <IconButton onClick={this.props.onReloadClick} color="secondary">
                                    {MatIconService.RELOAD}
                                </IconButton>
                            </Tooltip>
                        ) : null}
                    </Grid>
                    {noDivider || noDividerSpacing ? (
                        <span>{!noDivider && MatSpecService.showPageTitleDivider ? <Divider /> : null}</span>
                    ) : (
                        <span className={clsx(showButtons ? classes.pageHeaderDividerWithIcons : classes.pageHeaderDividerWithoutIcons)}>{MatSpecService.showPageTitleDivider ? <Divider /> : null}</span>
                    )}
                </Grid>
            </React.Fragment>
        );
    }
}
export default LayoutService.getHocComponenet(ComplianceReportMappingComponent);