import React, { Component } from "react";
import { combineLatest } from "rxjs";
import { AuthContext } from "../../../shared/store/authProvider";
import { DataService, SubscriptionArray } from "../../../shared/services/dataService";
import { ResultStatus, ReportCategory } from "../../../shared/types/enums";

import PageLoadingComponent from "../../../shared/components/page/pageLoadingComponent";
import PageDynamicHeaderComponent from "../../../shared/components/page/pageDynamicHeaderComponent";
import PageErrorComponent from "../../../shared/components/page/pageErrorComponent";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css";

import LayoutService from "../../../shared/services/layoutService";
import ReportTemplateSubscriptionService from "./reportTemplateSubscriptionsService"
import ToastService from "../../../shared/services/toastService";
import AgGridCheckboxCellRendererComponent from "../../../shared/components/elements/agGridCheckboxCellRendererComponent";
import { MatIconService } from "../../../shared/services/theme/matIconService";
import RolePermissionService from "../../../shared/role-permissions/rolePermissionService";


class ReportTemplateSubscriptionsComponent extends Component {

    static contextType = AuthContext;
    oSubscriptions = new SubscriptionArray();

    constructor(props) {
        super(props);
        this.state = {
            isReadOnly: true,
            fetchResult: ResultStatus.NOT_LOADED,
            complianceTemplateRows: [],
            frameworkComponents: {
                isSubscriptionCellRenderer: AgGridCheckboxCellRendererComponent,
            },
        };
    }

    componentWillUnmount() { this.oSubscriptions.cancelAll(); }
    componentDidMount() { this.fetchData(); }

    /** API Fetch */
    fetchData = () => {
        this.oSubscriptions.cancelAll();
        this.setState({ fetchResult: ResultStatus.LOADING, complianceTemplateRows: [] });

        // save the subscription object
        this.oSubscriptions.add(
            combineLatest([
                ReportTemplateSubscriptionService.fetchTemplates(this.context.user.tenantId, this.props.roleId),
            ]).subscribe(
                ([_complianceTemplateRows]) => {
                    this.setState({
                        complianceTemplateRows: _complianceTemplateRows,
                        fetchResult: ResultStatus.SUCCESS
                    });
                },
                (_error) => {
                    ToastService.showError("Error Occured.");
                    this.setState({ fetchResult: ResultStatus.ERROR });
                }
            )
        );
    };

    render() {
        const { classes } = this.props;
        this.props.compInfo.ref = this;

        switch (this.state.fetchResult) {
            case ResultStatus.NOT_LOADED:
            case ResultStatus.LOADING:
                return (<PageLoadingComponent classes={classes} label="Loading Report Template Subscriptions" />);
            case ResultStatus.SAVING:
                return (<PageLoadingComponent classes={classes} label="Saving Report Template Subscriptions" />);
            case ResultStatus.LOADED:
            case ResultStatus.SUCCESS:
                return (
                    <div id="MainVendorGrid">
                        <div {...LayoutService.getAgGridStyles(160)}>
                            {/* Grid Componenet */}
                            <AgGridReact
                                columnDefs={ReportTemplateSubscriptionService.getColumnDefs(this)}
                                rowData={this.state.complianceTemplateRows}
                                pagination={true}
                                paginationPageSize={50}
                                frameworkComponents={this.state.frameworkComponents}
                                sideBar={true}
                                gridOptions={{
                                    headerHeight: 48,
                                    suppressContextMenu: true,
                                    context: { componentParent: this },
                                }}
                                onGridReady={event => {
                                    this.gridApi = event.api;
                                    event.api.closeToolPanel();
                                }}
                            ></AgGridReact>
                        </div>


                    </div>
                );
            case ResultStatus.ERROR:
            default:
                return (<PageErrorComponent label="Error Loading Report Templates" classes={classes} onRetry={() => { this.fetchData(); }} />);
        }
    }

    gridApi = null;
    postData = () => {
        this.oSubscriptions.cancelAll();
        let selectedIds = [];
        let allIds = [];
        this.gridApi.forEachNode((node) => {
            allIds.push(node.data["reportId"]);
            if (node.data["isSubscription"] === true) {
                selectedIds.push(node.data["reportId"]);
            }
        });

        if (DataService.hasNoElements(allIds)) {
            // || DataService.hasNoElements(selectedIds)) { // excluding selectedId's since the admin may restrict a role not to view any reports
            ToastService.showWarning("No Data to Save");
        } else {
            this.setState({ fetchResult: ResultStatus.SAVING });
            this.oSubscriptions.add(
                ReportTemplateSubscriptionService.saveComplianceTemplatesAsOBS(this.context.user.tenantId, this.context.user.userId,
                    this.props.roleId, selectedIds.join()).subscribe(
                        (success) => {
                            ToastService.showSuccess("Successfully Saved");
                            this.fetchData();
                        },
                        (error) => {
                            ToastService.showError("Save Failed");
                            this.setState({ fetchResult: ResultStatus.LOADED }); // just show the UI
                        }
                    )
            );
        }

    }
}
/** HOC */
export default LayoutService.getHocComponenet(ReportTemplateSubscriptionsComponent);