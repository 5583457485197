import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { Button, DialogContent, DialogContentText, DialogTitle, DialogActions } from "@material-ui/core";
import LayoutService from "../../services/layoutService";

class ActionDialogComponent extends React.Component {
    constructor(props) {
        super(props);

    }
    render() {
        const { showDialog, title, description, content, actions, classes } = this.props;


        return (
            <Dialog open={showDialog || false} fullWidth={true} maxWidth='sm'>
                <DialogTitle style={{ padding: 16 }} id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent style={{ padding: 16 }}>
                    <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
                    {content}
                </DialogContent>
                <DialogActions>
                    {actions.map((_action, index) => {
                        return <Button key={`btn` + index} variant="contained" onClick={_action.callback} color={_action.color} className={classes.button} startIcon={_action.icon}>{_action.text}</Button>
                    })}
                </DialogActions>
            </Dialog>
        );
    }
}
export default LayoutService.getHocComponenet(ActionDialogComponent);

