import React from "react";
import { combineLatest, from, BehaviorSubject } from "rxjs";
import { filter, mergeMap, debounceTime, distinctUntilChanged } from "rxjs/operators";
import { Dialog, DialogTitle, DialogContent, Box, Typography, AppBar, Toolbar, DialogContentText, Grid, LinearProgress, FormControl, InputLabel, Select, MenuItem, Chip, Divider, FormHelperText } from "@material-ui/core";
import { API_ENDPOINT, CrudAction, ENTITY_TYPE, ResultStatus, TaskLauncher, HEALTH_INDICATOR } from "../../../shared/types/enums";
import PageLoadingComponent from "../../../shared/components/page/pageLoadingComponent";
import ChildMessageRendererComponent from "../childMessageRendererComponent";
import { MatIconService } from "../../../shared/services/theme/matIconService";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css";

import { AgGridUtil } from "../../../shared/services/ag-grid/agGridUtil";
import { AuthContext } from "../../../shared/store/authProvider";
import { DataService, SubscriptionArray } from "../../../shared/services/dataService";
import LookupService from "../../../shared/services/lookupService";
import FieldConfigurationComponent from "../../../shared/components/field-configuration/fieldConfigurationComponent";
import TransactionExpandedViewService from "./transactionExpandedViewService";
import LayoutService from "../../../shared/services/layoutService";
import TransactionDetailDialogComponent from "../transaction-detail/transactionDetailDialogComponent";
import { AgGridColumnExt } from "../../../shared/services/ag-grid/agGridColumnExt";
import ToastService from "../../../shared/services/toastService";
import ApiService from "../../../shared/services/apiService";
import TransactionTabService from "../transaction-detail/transaction-tab/transactionTabService";
import AgGridErroredDropdownCellRenderer from "../../../shared/components/ag-grid/error-cell-renderers/agGridErroredDropdownCellRenderer";
import AgGridErroredDatePickerCellRenderer from "../../../shared/components/ag-grid/error-cell-renderers/agGridErroredDatePickerCellRenderer";
import { AgGridBulkEditUtil } from "../../../shared/components/ag-grid/bluk-edit/agGridBulkEditUtil";
import { AgGridErroredTextCellRenderer } from "../../../shared/components/ag-grid/error-cell-renderers/agGridErroredTextCellRenderer";
import AgGridCheckboxCellRendererComponent from "../../../shared/components/elements/agGridCheckboxCellRendererComponent";
import { AgGridErroredCheckboxCellRenderer } from "../../../shared/components/ag-grid/error-cell-renderers/agGridErroredCheckboxCellRenderer";
import PageDynamicHeaderComponent from "../../../shared/components/page/pageDynamicHeaderComponent";
import HealthIndicatorCellRenderer from "../../../shared/components/elements/healthIndicator/healthIndicatorCellRenderer";
import StatusOverrideComponent from "../../../shared/components/profiles-transactions/StatusOverrideComponent";
import TaskDetailsComponent from "../../home/task/task-details/taskDetailsComponent";
import RolePermissionService from "../../../shared/role-permissions/rolePermissionService";
import AgGridRadioButtonCellRendererComponent from "../../../shared/components/elements/agGridRadioButtonCellRendererComponent";
import MatThemeService from "../../../shared/services/theme/matThemeService";
import AgGridSelectMenuComponent from "../../../shared/components/agGridSelectMenuComponent";
import { AgGridService } from "../../../shared/services/ag-grid/agGridService";


let searchSubject = new BehaviorSubject("");
let SearchResultObservable = searchSubject.pipe(
  filter((val) => val.hasOwnProperty("newValue")),
  debounceTime(750),
  distinctUntilChanged(),
  mergeMap((val) => from(fetchTransactionsBySearchBoxText(val)))
);
const fetchTransactionsBySearchBoxText = async (searchBoxParams) => {
  const rowData = await fetch(`${process.env.REACT_APP_CORE_HTTP}/Transactions/GetExpandedViewDetails`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      tenantId: searchBoxParams.user.tenantId,
      fileId: parseInt(searchBoxParams.fileID),
      userId: searchBoxParams.user.userId,
      userType: searchBoxParams.user.userTypeId,
      healthIndicatorId: parseInt(searchBoxParams.healthIndicator),
      sourceId: parseInt(searchBoxParams.sourceID),
      page: 1,
      pageSize: 5000,
      searchBoxText: searchBoxParams.newValue,
    }),
  }).then((result) => result.json());
  return rowData.map(obj => Object.entries(obj).reduce((a, [key, value]) => {
    a[key.toLowerCase()] = value;
    return a;
  }, {}));
};

class TransactionsExpandedViewComponent extends React.Component {
  static contextType = AuthContext;
  scopedSubscriptions = new SubscriptionArray();
  apiSubscriptions = new SubscriptionArray();

  constructor(props) {
    super(props);
    // init state
    this.state = {
      data: [],
      isInit: true, // true for the first time
      isReadOnly: false,
      isGridReady: false,
      fetchResult: ResultStatus.NOT_LOADED,
      porzioSearch: "",
      // transactionModelKeyMap: DataService.getModelKeyMap(new TransactionParameterModel()),
      selectedTransactions: [],
      agGridBulkEditUtil: new AgGridBulkEditUtil(),
      agGridUtils: new AgGridUtil("lastname", {
        // checked or radio button based on permissions
        isCheckedCellRenderer: RolePermissionService.TRANSACTION_EXPANDED_BULK_EDIT.canEdit ? AgGridCheckboxCellRendererComponent : AgGridRadioButtonCellRendererComponent,

        erroredDropdownCellRenderer: AgGridErroredDropdownCellRenderer,
        erroredDatePickerCellRenderer: AgGridErroredDatePickerCellRenderer,
        erroredTextCellRenderer: AgGridErroredTextCellRenderer,
        erroredCheckboxCellRenderer: AgGridErroredCheckboxCellRenderer,

        healthIndicatorCellRenderer: HealthIndicatorCellRenderer,
        childMessageRendererComponent: ChildMessageRendererComponent,
      }),
    };
  }

  componentWillUnmount() {
    this.scopedSubscriptions.cancelAll();
    this.apiSubscriptions.cancelAll();
  }

  componentDidMount() {
    this.setState({ fetchResult: ResultStatus.LOADING });
    LookupService.fetchCommonLookups(this.context);
    this.fetchData();
  }

  fetchData = (_ignoreCache = false) => {
    this.apiSubscriptions.cancelAll();
    this.setState({ fetchResult: ResultStatus.LOADING, data: [] });

    this.apiSubscriptions.add(
      combineLatest([
        LookupService.getSourceSystemsByEntityAsOBS(this.context.user.tenantId, ENTITY_TYPE.PROFILE),
        LookupService.getFormattedCountriesAsOBS(this.context, null),
        TransactionExpandedViewService.postTransactionCenterExpandedViewAsOBS(this.context, this.props.modalAgNode, this.props.healthIndicator, this.props.screenId),
        TransactionExpandedViewService.getTransactionCenterDisplayedFieldsAsOBS(this.context.user.tenantId, this.props.modalAgNode.sourceID, 2, this.context.user.userId, this.props.screenId),
        TransactionExpandedViewService.getTransactionRuleSummaryAsOBS(this.context.user.tenantId, this.props.modalAgNode.fileID)
      ]).subscribe(
        // success
        ([_profileSourceList, _countryList, _data, _agGridColumns, _transactionRuleSummaries]) => {

          // hardocde
          _agGridColumns = TransactionTabService.hardCodeColumns(_agGridColumns);


          // adds the selected prop to each element and converts the all keys to lowecase
          _data = (_data || []).map(obj => { obj[AgGridBulkEditUtil.selectColumnName] = false; return obj; });
          _data = _data.map(obj => Object.entries(obj).reduce((a, [key, value]) => {
            a[key.toLowerCase()] = value;
            return a;
          }, {}));

          if (DataService.hasElements(_data)) {
            this.apiSubscriptions.add(
              combineLatest([
                LookupService.getTemplateBySourceIdAsOBS(this.context.user.tenantId, _data[0].sourceid)
              ]).subscribe(
                ([_templateData]) => {
                  var _displayedColumnConfigs = [];
                  const _dynamicControlFieldsMap = TransactionTabService.getGroupFieldsMap(this, false, _templateData, "grouprenderid", "fieldID");
                  const _displayedFieldIds = _agGridColumns.map(x => { return x.fieldId; });
                  _dynamicControlFieldsMap.forEach(_value => {
                    _value.fieldConfigs.forEach(_fieldConfig => {
                      if (_displayedFieldIds.includes(_fieldConfig.fieldID)) {
                        _displayedColumnConfigs.push(_fieldConfig);
                      }
                    });
                  });

                  this.setState(
                    {
                      data: _data,
                      displayedColumnConfigs: _displayedColumnConfigs,
                      transactionRuleSummaries: _transactionRuleSummaries,
                      agGridColumns: _agGridColumns,
                      countryList: _countryList,
                      profileSourceList: _profileSourceList,
                    },
                    // change the state after all the above are assigned
                    () => { this.setState({ fetchResult: ResultStatus.LOADED }); }
                  );
                },
                (_errorObj) => {
                  ToastService.showError("Error while loading template.");
                  this.setState({ fetchResult: ResultStatus.ERROR });
                }
              )
            );
          } else {
            //ToastService.showWarning("Based on your permissions you do not have access to this data set");
            //ToastService.showError("No Data.");
            this.setState({ fetchResult: ResultStatus.ERROR });
          }
        },
        // onError
        (error) => {
          ToastService.showError("Error while Fetching.");
          this.setState({ fetchResult: ResultStatus.ERROR });
        }
      )
    );
  }

  handleSearchChange = (e) => {
    const newValue = e.target.value;
    this.setState({ porzioSearch: newValue, isProcessing: true });

    if (!this.state.searchSubscriptionAdded) {
      this.setState(
        { searchSubscriptionAdded: true },
        () => {
          this.scopedSubscriptions.add(
            SearchResultObservable.subscribe((searchResultJson) => {
              this.setState({ data: searchResultJson, isProcessing: false });
            })
          );
        }
      );
    } else {
      this.setState({ isProcessing: true });
      searchSubject.next({ newValue, ...this.props.modalAgNode, ...this.context, healthIndicator: this.props.healthIndicator });
    }
  }

  clearSearch = () => {
    searchSubject.next({ newValue: "", ...this.props.modalAgNode, ...this.context, healthIndicator: this.props.healthIndicator });
    this.setState({ isProcessing: false });
  }

  onDownloadClick = () => {
    let api = this.gridApi, params = this.getParams();
    api.exportDataAsExcel(params);
  }

  onProcessCellCallback = (params) => {
    const recordId = params.node.data["recordid"];
    switch (params.column.colId) {
      case 'healthindicator':
        return HEALTH_INDICATOR[params.node.data.healthindicator];
      case 'country':
        return params.node.data.countryname;
      case 'transactionconsentid':
      case 'customersourcesystemid':
      case 'transactiontypeid':
      case 'purposeid':
      case 'secondarypurposeid':
      case 'formid':
      case 'productid':
      case 'indirectpaymentid':
      case 'payeetypeid':
      case 'recipientcategoryid':
      case 'specialityid':
      case 'recipientidentifiertypeid':
      case 'recipientidentifiercountryid':
      case 'engagementtypeid':
      case 'venuecountryid':
      case 'credentialsid':
      case 'currencyid':
      case 'profiletypeid':
      case 'affiliatedcompanyid':
        let colLovName = params.column.colId + '';
        let colName = colLovName.substring(0, colLovName.length - 2);

        return this.getValueOrError(colLovName, recordId, params.node.data[colName]);
      case 'vendorid':
        return this.getValueOrError('vendorid', recordId, params.node.data['vendorname']);
      case 'productid2':
        return this.getValueOrError('productid2', recordId, params.node.data['product2']);
      case 'productid3':
        return this.getValueOrError('productid3', recordId, params.node.data['product3']);
      case 'productid4':
        return this.getValueOrError('productid4', recordId, params.node.data['product4']);
      case 'productid5':
        return this.getValueOrError('productid5', recordId, params.node.data['product5']);
      case 'materialid':
        return this.getValueOrError('materialid', recordId, params.node.data['materialname']);
      default:
        return this.getValueOrError(params.column.colId, recordId, params.value);
    }
  }

  getValueOrError(_colName, _recordId, _value) {
    const fieldRuleSummary = this.state.transactionRuleSummaries.find(x =>
      x.fieldName.toLowerCase() === _colName.toLowerCase()
      && x.recordId === _recordId);

    if (fieldRuleSummary) {
      return DataService.isStringNullOrEmpty(fieldRuleSummary.fieldValue) ? "[null]" : fieldRuleSummary.fieldValue;
    } else {
      return _value;
    }
  }

  getParams() {
    return {
      // allColumns: true,
      columnKeys: this.gridColumnApi.getAllColumns().filter(c => c.colDef.headerName !== "" && c.colDef.headerName !== "Edit"),
      fileName: `Transactions_Expanded_View ${new Date().toDateString()}`,
      processCellCallback: this.onProcessCellCallback
    };
  }

  methodFromParent = (cell, node) => {
    this.setState({ modalAgNode: node });
    if (RolePermissionService.TRANSACTION_DETAILS.cannotView) {
      RolePermissionService.showAccessDeniedToast();
    } else {
      if (this.state.agGridUtils.isNotEditing()) {
        this.setState({ showTransactionDetailDialog: true });
      }
    }
  };

  handleDeleteClick = () => {
    this.setState({
      openDeleteConfirm: true
    });
  }

  handleDeleteConfirm = () => {
    this.apiSubscriptions.cancelAll();

    let selectedTransactionIds = this.state.selectedTransactions.map(t => t.trid);
    this.setState({ openDeleteConfirm: false, selectedTransactions: [] });
    this.state.agGridUtils.gridApi.forEachNode((rowNode) => {
      if (rowNode.data && rowNode.data[AgGridBulkEditUtil.selectColumnName]) {
        this.state.agGridUtils.gridApi.applyTransaction({ remove: [rowNode.data] });
      }
    });
    selectedTransactionIds.map(trid => {
      this.apiSubscriptions.add(TransactionExpandedViewService.getDeleteOBS(this.context.user.tenantId, trid, this.context.user.userId)
        .subscribe(
          (_successResult) => {
            if (_successResult === "validation_error") { this.flashError("Validation Failed"); }
            else if (_successResult === "save_error") { this.flashError("Delete Failed"); }
            else { this.props.onClose(true); } // close this and reload the TransactionCenter
          },
          (_errorResult) => {
            ToastService.showError("Error occurred while deleting");
          }
        ));
    });
  }


  getSelectedGstIds = () => {
    let _selectedGstIds = [];
    this.state.agGridUtils.getSelectedRows(AgGridBulkEditUtil.selectColumnName).forEach((rowNode) => {
      _selectedGstIds.push(rowNode.data["porziogsttransactionid"]);
    });
    return _selectedGstIds;
  }
  getSelectedTrIds = () => {
    let _selectedGstIds = [];
    this.state.agGridUtils.getSelectedRows(AgGridBulkEditUtil.selectColumnName).forEach((rowNode) => {
      _selectedGstIds.push(rowNode.data["trid"]);
    });
    return _selectedGstIds;
  }

  handleBatchProfileClick = () => {
    const _selectedGstIds = this.getSelectedGstIds();
    if (DataService.hasNoElements(_selectedGstIds)) {
      ToastService.showWarning("Please select one or more Transactions");
    }
    else {
      const _selectedTrIds = this.getSelectedTrIds();

      const gstIdsWithMatchedRecipients = (this.state.data || []).filter(x => x.isrecipientmatch && _selectedTrIds.includes(x.trid)).map(x => x.porziogsttransactionid);
      if (DataService.hasElements(gstIdsWithMatchedRecipients)) {
        ToastService.showError(`Recipient already matched for ${gstIdsWithMatchedRecipients.join(', ')}`)
      } else {
        this.setState({
          selectedGstIds: _selectedGstIds,
          selectedTrIds: _selectedTrIds,
          showConfirmCreateBatchProfileDialog: true,
        });
      }
    }
  }

  handleCreateTaskDialogClick = () => {
    const _selectedGstIds = this.getSelectedGstIds();
    if (DataService.hasNoElements(_selectedGstIds)) {
      ToastService.showWarning("Please select one or more Transactions");
    } else {
      this.setState({
        selectedGstIds: _selectedGstIds,
        showCreateTaskDialog: true
      });
    }
  }

  createBatchProfile = () => {
    if (!DataService.isValidNumber(this.state.selProfileSourceId)) {
      ToastService.showWarning("Please select a Profile Source.");
    } else {
      this.setState({ isCreatingBatchProfile: true, });
      TransactionExpandedViewService.createBatchProfiles(this.context, this.state.selProfileSourceId, this.state.selectedTrIds)
        .subscribe(
          (successObj) => {
            if (successObj) {
              ToastService.showSuccess("Successfully Created.");
              this.setState({
                isCreatingBatchProfile: false,
                showConfirmCreateBatchProfileDialog: false,
                selProfileSourceId: ''
              });
            } else {
              ToastService.showError("Please select only the Transactions with Unmatched Recipient.");
              this.setState({ isCreatingBatchProfile: false });
            }
          },
          (errorObj) => {
            ToastService.showError("An Error occured.");
            this.setState({ isCreatingBatchProfile: false });
          }
        );
    }
  }

  customComparator = (number1, number2, nodeA, nodeB, isInverted) => {

    if (number1 === null && number2 === null) {
      return 0;
    }
    if (isNaN(number1)) { return -1 }
    if (isNaN(number2)) { return 1 }
    if (number1 === null) {
      return -1;
    }
    if (number2 === null) {
      return 1;
    }
    return number1 - number2;
  };

  getSelectionIcon = () => {
    return RolePermissionService.TRANSACTION_EXPANDED_BULK_EDIT.cannotEdit ? null
      : { title: "select", icon: MatIconService.MENU, onClick: (e) => { this.setState({ anchorEl: e.currentTarget, openSelectMenu: true }); }, isReadOnly: !this.state.isGridReady };
  }

  render() {
    const { classes } = this.props;

    if (RolePermissionService.TRANSACTION_EXPANDED_VIEW.cannotView) {
      return RolePermissionService.getAccessDeniedComponent(classes, () => { this.props.onClose(false) }); // this is required to prevent Url navigation
    } else {
      let componentType = "";
      switch (this.props.healthIndicator) {
        case 1: componentType = `Transaction Error`; break;
        case 2: componentType = `Transaction Warning`; break;
        case 3: componentType = `Transaction Unmatched`; break;
        case 4: componentType = `Transaction Eligible`; break;
        case 5: componentType = `Transaction NonLicensed`; break;
        case 0:
        default: componentType = `Transaction Count`;
      }

      switch (this.state.fetchResult) {
        case ResultStatus.NOT_LOADED:
        case ResultStatus.LOADING:
          return (
            <React.Fragment>
              <PageDynamicHeaderComponent classes={classes} label={`Go back`} divider
                leftActions={[{ icon: MatIconService.BACK, iconColor: "secondary", onClick: () => { this.props.onClose(false); }, title: "Cancel" }]}
              />
              <PageLoadingComponent classes={classes} label="Loading Transaction Expanded View" />;
            </React.Fragment>
          );
        case ResultStatus.SAVING:
          return <PageLoadingComponent small classes={classes} label={`Saving ${componentType}`} />;
        case ResultStatus.LOADED:
        case ResultStatus.SUCCESS:
          return (
            <React.Fragment>
              {/* Header Componenet */}
              <AgGridSelectMenuComponent anchorEl={this.state.anchorEl} openSelectMenu={this.state.openSelectMenu}
                agGridUtils={this.state.agGridUtils} selectionColumnName={AgGridBulkEditUtil.selectColumnName}
                onClose={() => { this.setState({ anchorEl: null, openSelectMenu: false }); }}
                onSelectionChange={() => { this.toggleSubmitButton(); }}
              />
              <PageDynamicHeaderComponent classes={classes} label={componentType}
                leftActions={[
                  { icon: MatIconService.BACK, iconColor: "secondary", onClick: () => { this.props.onClose(false); }, title: "GoTo Trransactions" },
                  { icon: MatIconService.RELOAD, onClick: () => { this.fetchData(); }, title: "Reload" },
                  { ...this.getSelectionIcon() }
                ]}
                rightActions={[
                  { jsxElement: LayoutService.getSearchText(classes, "search", this.state.porzioSearch, this.handleSearchChange) },
                  {
                    icon: MatIconService.OVERRIDE_28, onClick: () => { this.setState({ showTransactionStatusOverrideDialog: true }); }, title: "Override Transaction Status",
                    isReadOnly: (this.state.isReadOnly || this.state.isInit || RolePermissionService.TRANSACTION_EXPANDED_STATUS_FIELD.cannotEdit),
                  },
                  { icon: MatIconService.COLUMNS_24, onClick: () => { this.setState({ showFieldConfigurationDialog: true }); }, title: "Field Configurations", isReadOnly: RolePermissionService.TRANSACTION_EXPANDED_VIEW.cannotConfigure },
                  { icon: MatIconService.TASK_ADD, onClick: this.handleCreateTaskDialogClick, title: "Create Task", isReadOnly: RolePermissionService.TRANSACTION_EXPANDED_TASK.cannotCreate },
                  { icon: MatIconService.PROFILE_BATCH_ADD, onClick: this.handleBatchProfileClick, title: "Create Batch Profiles", isReadOnly: false },
                  { icon: MatIconService.DOWNLOAD, onClick: () => { this.onDownloadClick(); }, title: "Download", isReadOnly: RolePermissionService.TRANSACTION_EXPANDED_EXPORT.cannotView },
                  { icon: MatIconService.DELETE, onClick: () => { this.handleDeleteClick(); }, title: "Delete", isReadOnly: (this.state.isInit || this.state.isReadOnly || RolePermissionService.TRANSACTION_EXPANDED_VIEW.cannotDelete) },
                  { icon: MatIconService.OK, iconColor: "primary", onClick: () => { this.clearSearch(); this.handleSubmit(); }, title: "Save", isReadOnly: (this.state.isInit || this.state.isReadOnly) },
                ]}
              />
              {/* Content */}
              <Box style={{ padding: 0 }}>
                {/* Processing Progress */}
                {this.state.isProcessing ? <LinearProgress color="secondary" /> : null}

                <div {...LayoutService.getAgGridStyles(168)}>
                  <AgGridReact
                    pagination={true}
                    paginationPageSize={100}
                    rowData={this.state.data}
                    defaultColDef={{ useValueFormatterForExport: true, }}
                    columnDefs={TransactionExpandedViewService.getColumnDefs(this, this.state.isReadOnly)}
                    frameworkComponents={this.state.agGridUtils.frameworkComponents}
                    suppressContextMenu={true}

                    excelStyles={AgGridService.excelStyles}

                    suppressClickEdit={true}
                    suppressRowClickSelection={true}
                    rowSelection="multiple"
                    onGridReady={(params) => {
                      this.setState({ isGridReady: true });
                      this.gridApi = params.api;
                      this.gridColumnApi = params.columnApi;
                      this.state.agGridUtils.setGridParams(params, false);
                      this.state.agGridUtils.pinColumn(AgGridBulkEditUtil.selectColumnName); // pin the edit column to the left
                    }}

                    gridOptions={{
                      context: { componentParent: this },
                      ...AgGridColumnExt.getGridOptions(40),
                      cellFlashDelay: 1000,
                      cellFadeDelay: 750,
                    }}
                  ></AgGridReact>
                </div>
              </Box>

              {/* Delete dialog */}
              {this.state.openDeleteConfirm ?
                <Dialog open={this.state.openDeleteConfirm || false} onClose={() => this.setState({ openDeleteConfirm: false })} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                  <DialogTitle id="alert-dialog-title">
                    <AppBar position="static">
                      <Toolbar>
                        <Typography variant="h6" className={classes.root}>Delete Confirmation</Typography>
                        {LayoutService.getIconButton(false, MatIconService.OK, "Delete", this.handleDeleteConfirm, "inherit", "keyActionDelete")}
                        {LayoutService.getIconButton(false, MatIconService.CANCEL, "Cancel", () => this.setState({ openDeleteConfirm: false }), "secondary", "keyActionCancel")}
                      </Toolbar>
                    </AppBar>
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      <Box style={{ paddingLeft: 16, paddingRight: 32, paddingTop: 8, paddingBottom: 32, }} >
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Typography variant="h6" className={classes.root} align="center">Are you sure you want to delete the selected Transaction(s)?</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="h6" className={classes.root} align="center">{this.state.selectedTransactions.map(t => t.porziogsttransactionid).join(",")}</Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </DialogContentText>
                  </DialogContent>
                </Dialog>
                : null}

              {/* Confirm New Profile dialog */}
              {!this.state.showConfirmCreateBatchProfileDialog ? null :
                <Dialog open={this.state.showConfirmCreateBatchProfileDialog || false} fullWidth={true} maxWidth='md' scroll={false ? "paper" : "body"}>
                  {/* Title */}
                  <DialogTitle style={{ padding: 0 }} id="dialog-title">
                    <AppBar position="static">
                      <Toolbar>
                        <Typography variant="h6" className={classes.root}>Confirm Batch Profile Creation</Typography>
                        {LayoutService.getIconButton(this.state.isCreatingBatchProfile, MatIconService.OK, "Confirm", () => {
                          this.createBatchProfile();
                        }, "inherit", "keyActionSave")}
                        {LayoutService.getIconButton(this.state.isCreatingBatchProfile, MatIconService.CANCEL, "Cancel", () => { this.setState({ showConfirmCreateBatchProfileDialog: false }) }, "secondary", "keyActionCancel1")}
                      </Toolbar>
                      {this.state.isCreatingBatchProfile ? <LinearProgress color="secondary" /> : null}
                    </AppBar>
                  </DialogTitle>
                  {/* Content */}
                  <DialogContent style={{ padding: 0 }}>
                    <Typography style={{ width: "100%", paddingLeft: 8, paddingRight: 8, backgroundColor: "#ecc802", color: "#681501" }} variant="h6" align="center">
                      {this.state.isCreatingBatchProfile ? "Creating Batch Profiles..." : "This Action cannot be reversed."}
                    </Typography>
                    <Divider style={{ width: "100%" }} />
                    <Grid container direction="column" justifyContent="center" alignItems="stretch">
                      {/* <h6>Please select a Profile Source</h6> */}
                      <FormControl required style={{ marginTop: 24, marginInline: 24 }}>
                        <InputLabel >Profile Source</InputLabel>
                        <Select disabled={this.state.isCreatingBatchProfile} value={this.state.selProfileSourceId || ''}
                          MenuProps={{ classes: { paper: classes.menuPaper } }}
                          onChange={(e) => this.setState({ selProfileSourceId: e.target.value })}>
                          {(this.state.profileSourceList || []).map(
                            (el, index) => { return <MenuItem value={el.sourceId}>{el.sourceName}</MenuItem> }
                          )}
                        </Select>
                        {/* <FormHelperText>The selected Profile Source will be used for each Profile.</FormHelperText> */}
                      </FormControl>
                      <Divider style={{ marginTop: 24 }} />
                      <Grid style={{ paddingInline: 24, paddingTop: 24, paddingBottom: 32, backgroundColor: MatThemeService.getAlternatingBG() }}>
                        <h6>New Profile Records will be created, or matched, for the following Unmatched Transactions, when one of the below conditions is true.</h6>
                        <h6>Note: For the Profile to be inserted, the Transaction MUST have a value for the "COUNTRY" field AND a value for at least one of the following fields: "US NPI NUMBER"; or "US LICENSE STATE"; or "US STATE LICENSE NUMBER"; or "US TAX ID NUMBER"; or "RECIPIENT IDENTIFIER VALUE"</h6>
                        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                          {this.state.selectedGstIds.map((x, index) => {
                            return <Chip key={`keyPorzioGstId${index}`} style={{ marginLeft: 8, marginTop: 8 }} variant="outlined" color="secondary" label={x}
                              icon={MatIconService.TRANSACTION_OUTLINED} />
                          })}
                        </Grid>
                      </Grid>
                    </Grid>
                  </DialogContent>
                </Dialog>
              }

              {/* Field configuration Dialog */}
              {this.state.showFieldConfigurationDialog ?
                <FieldConfigurationComponent entityId={ENTITY_TYPE.TRANSACTION} fileId={this.props.modalAgNode.fileID} sourceId={this.props.modalAgNode.sourceID}
                  modalAgNode={this.props.modalAgNode} screenId={this.props.screenId} open={this.state.showFieldConfigurationDialog || false} onClose={() => this.setState({ showFieldConfigurationDialog: false })}
                  refreshList={this.fetchData} />
                : null}

              {/* Transaction Dialog On select */}
              {!this.state.showTransactionDetailDialog ? null :
                <TransactionDetailDialogComponent standAloneProps={LayoutService.getContainedDialogProps(false)}
                  inputAction={CrudAction.UPDATE} modalAgNode={this.state.modalAgNode}
                  open={this.state.showTransactionDetailDialog || false}
                  onClose={(_reload) => {
                    this.setState({ showTransactionDetailDialog: false });
                    if (_reload) { this.componentDidMount(); }
                  }}
                  // clone stuff
                  onClone={this.props.onClone}
                />
              }
              {/* Transaction Status Selector Dialog */}
              {!this.state.showTransactionStatusOverrideDialog ? null : <StatusOverrideComponent currentValue={""}
                selectedTransactions={this.state.selectedTransactions.map(x => x.transactionid)}
                entityId={ENTITY_TYPE.TRANSACTION}
                onClose={(_reload) => {
                  this.setState({ showTransactionStatusOverrideDialog: false });
                }} />}

              {/* Create new Task for selected Profiles */}
              {this.state.showCreateTaskDialog ?
                <TaskDetailsComponent showTaskDialog={this.state.showCreateTaskDialog || false} taskLauncher={TaskLauncher.TRANSACTION_EXPANDED_VIEW}
                  onClose={() => this.setState({ showCreateTaskDialog: false })} inputAction={CrudAction.CREATE} refreshTaskList={() => { }} updateData={null}
                  porzioGstIds={this.state.selectedGstIds}
                />
                : null}


            </React.Fragment>
          );

        case ResultStatus.ERROR:
        default:
          return (
            <PageDynamicHeaderComponent classes={classes} label={componentType}
              leftActions={[
                { icon: MatIconService.BACK, iconColor: "secondary", onClick: () => { this.props.onClose(false); }, title: "GoTo Trransactions" },
                { icon: MatIconService.RELOAD, onClick: () => { this.fetchData(); }, title: "Reload" },
              ]}
            />
            // <Dialog open={this.props.open || false} scroll={true ? "paper" : "body"} maxWidth="lg" >
            //   <PageErrorComponent small label="Error Loading Details" classes={classes}
            //     onRetry={() => { this.props.onClose(true); }}
            //     onClose={() => { this.props.onClose(true); }}
            //   />
            // </Dialog>
          );
      }
    }
  }

  toggleSubmitButton = (_cellRef, _data) => {
    var hasSelected = false;
    let selectedTransactions = [];
    if (this.state.agGridBulkEditUtil.hasUpdates()) {
      hasSelected = true;
    } else {
      this.state.agGridUtils.gridApi.forEachNode((rowNode) => {
        hasSelected = hasSelected || rowNode.data[AgGridBulkEditUtil.selectColumnName] === true;
        if (rowNode.data && rowNode.data[AgGridBulkEditUtil.selectColumnName]) {
          selectedTransactions.push(rowNode.data);
        }
      });
    }
    // set the state
    this.setState({
      selectedTransactions: selectedTransactions,
      isReadOnly: !hasSelected,
      isInit: false // only true for the first time 
    });
  }

  handleSubmit = () => {
    let arrayToPost = [];
    this.state.agGridUtils.gridApi.forEachNode((rowNode, rowIndex) => {
      // TODO: rowIndex will not be correct if sorted, so search this app for sortAndFilterAwareRowNode and see the impl
      if (this.state.agGridBulkEditUtil.updatedRowIdsCoumnNamesMap.has(rowNode.id) && rowNode.data["is_selected"] === true) {
        var modelObj = {};

        const rowColumnNames = this.state.agGridBulkEditUtil.updatedRowIdsCoumnNamesMap.get(rowNode.id);
        rowColumnNames.forEach(rowDataKey => {
          modelObj[rowDataKey] = rowNode.data[rowDataKey];
          const fieldConfig = this.state.displayedColumnConfigs.find(x => x.mappedFieldNameLower === rowDataKey);
          if (fieldConfig) { TransactionTabService.convertForPost(modelObj, fieldConfig, rowDataKey, modelObj[rowDataKey]); }
        });


        //--- ensure mandatory fields
        modelObj.tenantid = this.context.user.tenantId;
        modelObj.userId = this.context.user.userId;
        modelObj.userType = this.context.user.userTypeId;
        modelObj.uid = this.context.user.uid;
        modelObj.transactionid = rowNode.data.transactionid;
        modelObj.sourceid = rowNode.data.sourceid;
        modelObj.recordid = rowNode.data.recordid;
        modelObj.fileid = rowNode.data.fileid;
        modelObj.ismanuallyentered = true; //rowNode.data.ismanuallyentered;
        //---

        arrayToPost.push(modelObj);
      }
    });

    if (arrayToPost.length > 1000) {
      ToastService.showInfo("Select up to 1000 records each occurrence");
    }
    else {
      this.setState({ isProcessing: true });
      ApiService.postOBS(API_ENDPOINT.CORE, "/Transactions/SaveTransactions", JSON.stringify(arrayToPost))
        .subscribe((successObj) => {
          this.state.agGridBulkEditUtil.clearUpdateTrackers();
          ToastService.showSuccess("Successfully saved.");
          this.setState({ isProcessing: false });
          // this.props.onClose(true); as per PP2-871
        },
          (errorObj) => {
            ToastService.showError("Error while saving.");
            this.setState({ isProcessing: false });
          }
        );
    }
  }

  //---
}
/** HOC */
export default LayoutService.getHocComponenet(TransactionsExpandedViewComponent);
