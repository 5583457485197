import { AgGridColumnExt } from "../../../../../shared/services/ag-grid/agGridColumnExt";
import { AgGridService } from "../../../../../shared/services/ag-grid/agGridService";
import ApiService from "../../../../../shared/services/apiService"
import { API_ENDPOINT } from "../../../../../shared/types/enums"

export default class RelatedTransactionsSearchDialogService {

    static searchOBS = (_tenantId, _searchValue) => {
        return ApiService.setOBS("POST", API_ENDPOINT.CORE, `/Transactions/FetchTransactionsSearchValue/${_tenantId}`, JSON.stringify({
            searchValue: _searchValue,
            porzioGSTId: "",
            firstName: "",
            lastName: "",
            organizationName: "",
            companyId: "",
            country: ""
        }));
    }

    static getColumnDefs = (_componentThisRef, _onSelectCallback) => {
        return [
            {
                headerName: "Select",
                field: "isSelected",
                editable: true,
                width: 176,
                cellStyle: { "border-right": "1px solid lightgray" },
                cellRenderer: "isSelectedCellRenderer",
                cellRendererParams: { onChange: (_cellRef, _data) => { _onSelectCallback(_data); } }
            },
            AgGridService.getColumn(false, "Company Transaction ID", "companyTransactionId"),
            AgGridService.getColumn(false, "Porzio GST Transaction ID", "porzioGstTransactionId","childMessageRendererComponent"),
            AgGridService.getTextColumn(false, "Last Name", "lastName"),
            AgGridService.getTextColumn(false, "First Name", "firstName"),
            AgGridService.getColumn(false, "Organization Name", "organizationName"),
            AgGridColumnExt.GET(false, "set", "Country", "country").flexWeight(4).tooltip().headerClass("center-text").SET(),
            AgGridColumnExt.GET(false, "set", "Transaction Status", "transactionStatus").flexWeight(4).tooltip().headerClass("center-text").SET(),
            AgGridColumnExt.GET(false, "number", "totalNumberOfRecipients", "Total Number of Recipients").flexWeight(4).tooltip().headerClass("center-text").SET(),
            AgGridColumnExt.GET(false, "text", "transactionDate", "Transaction Date").flexWeight(4).tooltip().headerClass("center-text").SET(),
            AgGridColumnExt.GET(false, "text", "purpose", "Purpose").flexWeight(4).tooltip().headerClass("center-text").SET(),
            AgGridColumnExt.GET(false, "text", "form", "Form").flexWeight(4).tooltip().headerClass("center-text").SET(),
            AgGridColumnExt.GET(false, "number", "totalAmount", "Total Amount").flexWeight(4).tooltip().headerClass("center-text").SET(),
        ];
    }
}