import React, { Component } from "react";

export default class DataCountCellRendererComponent extends Component {
  constructor(props) {
    super(props);

    this.invokeParentMethod = this.invokeParentMethod.bind(this);
  }

  invokeParentMethod() {
    this.props.context.componentParent.dataCountMethodFromParent(
      `Row: ${this.props.node.rowIndex}, Col: ${this.props.colDef.headerName}`,
      this.props.node.data
    );
  }

  render() {
    return (
      <span
        style={{ textDecoration: "underline", left:"40%", position:"absolute"  }}
        onClick={this.invokeParentMethod}
      >
        {this.props.value}
      </span>
    );
  }
}
