import React, { Component } from "react";
import { Chip, IconButton, ListItemIcon, Menu, MenuItem, Typography, } from "@material-ui/core";
import { DataService } from "../../services/dataService";

export default class AgGridActionCellRendererComponent extends Component {

    constructor(props) {
        super(props);
        this.state = { anchorElement: null };
    }

    handleMenuClose = () => {
        this.setState({ anchorElement: null });
    };
    handleMenuClick = (_event) => {
        this.setState({ anchorElement: _event.currentTarget });
    }

    /**
     * menuOptions: [ 
     *  { text: "text1", icon: MatIconService.EDIT, onClickCallback: ()=>{} },  
     *  { text: "text2", icon: MatIconService.SAVE, onClickCallback: ()=>{} },  
     * ]
     */
    render() {
        const { menuIcon, menuMaxHeight, menuOptions } = this.props;
        const _maxHeight = DataService.isValidNumber(menuMaxHeight) ? menuMaxHeight : 45;
        const _isOpen = Boolean(this.state.anchorElement); // only if exists

        return (
            <>
                <IconButton size="small" onClick={this.handleMenuClick}>{menuIcon}</IconButton>
                <Menu anchorEl={this.state.anchorElement}
                    keepMounted
                    open={_isOpen}
                    onClose={this.handleMenuClose}
                    PaperProps={{
                        style: {
                            maxHeight: _maxHeight * 4.0,
                            //width: '50ch',
                        },
                    }}>

                    {(menuOptions || []).map((_menuOption, index) => (

                        <MenuItem key={"menuOption" + index} onClick={(event) => {
                            if (_menuOption.onClickCallback) {
                                // Note, the this is passed to the caller method
                                _menuOption.onClickCallback(this);
                            }
                            this.handleMenuClose();
                        }}>
                            <ListItemIcon>{_menuOption.icon}</ListItemIcon>
                            <Typography variant="inherit">{_menuOption.text}</Typography>
                        </MenuItem>

                        // <MenuItem key={"menuOption" + index} onClick={() => {
                        //     if (_menuOption.onClickCallback) {
                        //         _menuOption.onClickCallback();
                        //     }
                        //     this.handleMenuClose()
                        // }}>
                        //     {_menuOption.text}
                        // </MenuItem>
                    ))}
                </Menu>
            </>
        );
    }
}
