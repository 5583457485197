import React from "react";
import { from, of, combineLatest, BehaviorSubject, } from "rxjs";
import { filter, mergeMap, debounceTime, distinctUntilChanged } from "rxjs/operators";
import {
    Box,
    DialogTitle,
    DialogContent,
    FormControl,
    Dialog,
    Divider,
    InputLabel,
    Select,
    MenuItem,
    Chip,
    Grid,
    Typography,
    AppBar,
    Toolbar,
    LinearProgress
} from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import { AuthContext } from "../../../shared/store/authProvider";
import { ResultStatus, CrudAction, API_ENDPOINT } from "../../../shared/types/enums";
import { DataService, SubscriptionArray } from "../../../shared/services/dataService";
import PageLoadingComponent from "../../../shared/components/page/pageLoadingComponent";
import PageErrorComponent from "../../../shared/components/page/pageErrorComponent";
import LayoutService from "../../../shared/services/layoutService";
import ApiService from "../../../shared/services/apiService";
import UserMappingDetailService from "./userMappingDetailService";
import LookupService from "../../../shared/services/lookupService";
import RolePermissionService from "../../../shared/role-permissions/rolePermissionService";
import { MatIconService } from "../../../shared/services/theme/matIconService";
import AgGridCheckboxCellRendererComponent from "../../../shared/components/elements/agGridCheckboxCellRendererComponent";
import ToastService from "../../../shared/services/toastService";
import { AgGridUtil } from "../../../shared/services/ag-grid/agGridUtil";
import { AgGridColumnExt } from "../../../shared/services/ag-grid/agGridColumnExt";
import MatThemeService from "../../../shared/services/theme/matThemeService";
import ActionDialog from "../../../shared/components/dialog/actionDialog";

let searchSubject = new BehaviorSubject("");
let SearchResultObservable = searchSubject.pipe(
    filter((val) => val.hasOwnProperty("newValue")),
    debounceTime(200),
    distinctUntilChanged(),
);

class UserMappingDetailsComponent extends React.Component {
    static contextType = AuthContext;
    apiSubscriptions = new SubscriptionArray();
    scopedSubscriptions = new SubscriptionArray();

    constructor(props) {
        super(props);
        // init state
        this.state = {
            isReadOnly: true,
            isEditing: this.props.inputAction === CrudAction.UPDATE,
            data: [],
            openDeleteConfirm: false,
            ndcWarning: " ",
            availableTenantList: [],
            selTenantId: 0,
            selRoleId: 0,
            rolesList: [],
            tenantIdsToUnlink: [],
            agGridUtils: new AgGridUtil("tenantId", {
                isCheckedCellRenderer: AgGridCheckboxCellRendererComponent,
            }),
        };
    }

    componentWillUnmount() {
        this.apiSubscriptions.cancelAll();
    }

    componentDidMount() {

        // search subscriptions
        this.fetchData();
    }

    fetchData = (_ignoreCache = false) => {
        this.apiSubscriptions.cancelAll();
        this.setState({ fetchResult: ResultStatus.LOADING, data: [] });
        /**
         * rxjs <combineLatest> operator takes multiple obs as argument and emits their result in the same order
         * this operator is very handy as we don't have to independently wait for each result
         */
        this.apiSubscriptions.add(
            combineLatest([
                UserMappingDetailService.getUserMappingDetailsAsOBS(this.props.modalAgNode.id),
                UserMappingDetailService.getActiveTenants(),
            ]).subscribe(
                // success
                ([_userMappingDetailsData, _tenantsList]) => {
                    var tenantIds = _userMappingDetailsData.map(x => x.tenantid);
                    //if selected user is porzio user, only then Porzio can be seen on the tenant list
                    _tenantsList = !this.props.modalAgNode.isPorzioUser ? _tenantsList.filter(x => x.tenantid !== 1) : _tenantsList;
                    _tenantsList = _tenantsList.filter(x => !tenantIds.includes(x.tenantid))
                    this.setState(
                        {
                            data: _userMappingDetailsData,
                            availableTenantList: _tenantsList
                        },
                        // change the state after all the above are assigned
                        () => {
                            this.setState({ fetchResult: ResultStatus.LOADED });
                        }
                    );
                },
                // onError
                (error) => {
                    console.log("Error:", error);
                    this.setState({ fetchResult: ResultStatus.ERROR });
                }
            )
        );
    }
    fetchRolesByTenant = () => {
        this.apiSubscriptions.cancelAll();
        this.apiSubscriptions.add(ApiService.getOBS(API_ENDPOINT.TENANT, `/Role/GetAllRoles/${this.state.selTenantId}`).subscribe(
            // success
            (_rolesList) => {
                this.setState({ rolesList: _rolesList });
            },
            // onError
            (error) => {
                console.log("Error:", error);
                this.setState({ fetchResult: ResultStatus.ERROR });
            }
        )
        );
    }
    handleSubmit = () => {
        ToastService.showSuccess("Tenant Linked successfully.");
        this.setState({ fetchResult: ResultStatus.SAVING });
        // the below line has to be done on backend in future
        let userTypeId = this.state.selTenantId === 1 ? 3 : this.state.rolesList.filter(x => x.roleId === this.state.selRoleId)[0].roleName === "Tenant Admin" ? 3 : 2;
        let actionVerb = "POST";
        let targetUrl = `/LinkUserToTenant?tenantId=${this.state.selTenantId}&userId=${this.props.modalAgNode.id}&roleId=${this.state.selRoleId}&linkToTenant=true&loginUserId=${this.context.user.userId}&userTypeId=${userTypeId}`;
        ApiService.setOBS(
            actionVerb,
            API_ENDPOINT.TENANT,
            targetUrl,
        ).subscribe(
            (successResult) => {
                if (successResult) {
                    this.setState({ fetchResult: ResultStatus.SUCCESS });
                    this.setState({ showTenantSelectionDialog: false });
                    this.fetchData();
                    this.setState({ isReadOnly: true });
                } else {
                    console.error("Error: falsey successResult while mapping user to tenant", successResult);
                    this.setState({ fetchResult: ResultStatus.ERROR });
                }
            },
            (errorResult) => {
                console.error("Error while mapping user to tenant", errorResult);
                this.setState({ fetchResult: ResultStatus.ERROR });
            }
        );
    }
    handleUnlinkTenant = () => {
        var ids = this.state.agGridUtils.getUpdatedRowData().filter(x => x.isSelected).map(x => x.tenantid);
        if (ids.length === 0) {
            ToastService.showWarning("Please select atleast one tenant.");
        }
        else {
            this.setState({ fetchResult: ResultStatus.SAVING });
            let actionVerb = "POST";
            let mappedObj = ids.map(x => {
                return {
                    tenantId: x,
                    userId: this.props.modalAgNode.id,
                    linktoTenant: false,
                    loginUserId: this.context.user.userId,
                }
            })
            let targetUrl = `/UnLinkUserFromTenant`;
            ApiService.setOBS(
                actionVerb,
                API_ENDPOINT.TENANT,
                targetUrl,
                JSON.stringify(mappedObj)
            ).subscribe(
                (successResult) => {
                    if (successResult) {
                        this.setState({ fetchResult: ResultStatus.SUCCESS });
                        this.fetchData();
                    } else {
                        console.error("Error: falsey successResult while mapping user to tenant", successResult);
                        this.setState({ fetchResult: ResultStatus.ERROR });
                    }
                },
                (errorResult) => {
                    console.error("Error while mapping user to tenant", errorResult);
                    this.setState({ fetchResult: ResultStatus.ERROR });
                }
            );
            ToastService.showSuccess("Tenants unlinked successfully for " + this.props.modalAgNode.userName);
        }
        this.setState({ isReadOnly: true });
    }

    render() {
        const { classes } = this.props;

        this.state.agGridUtils.setEditingMode(this.state.isReadOnly);
        switch (this.state.fetchResult) {
            case ResultStatus.LOADING:
                return (
                    <PageLoadingComponent small classes={classes} label="Loading User Mapping Details" />
                );
            case ResultStatus.SAVING:
                return (
                    <PageLoadingComponent small classes={classes} label="Saving User Mapping Details" />
                );
            case ResultStatus.SUCCESS:
            case ResultStatus.LOADED:
                return (
                    <React.Fragment>
                        <DialogTitle disableTypography id="dialogTitle">
                            <AppBar position="static">
                                <Toolbar variant="dense">
                                    <Typography variant="h6" className={classes.root}>Tenants List for {this.props.modalAgNode.userName}</Typography>
                                    {LayoutService.getReadOnlyActions(this, !this.state.isEditing, () => { this.props.onClose(false) }, () => { this.handleSubmit() })}
                                </Toolbar>
                            </AppBar>
                        </DialogTitle>
                        {/* Dialog Content */}
                        <DialogContent>
                            <Box style={{
                                paddingLeft: 16, paddingRight: 16,
                                paddingTop: 8, paddingBottom: 32,
                                minHeight: "50vh", minWidth: "80vh"
                            }}>
                                <Grid container direction="row" justify="flex-end" alignItems="center">
                                    {LayoutService.getIconButton(this.state.isReadOnly, MatIconService.LINK, "Link new tenant", () => {
                                        this.setState({ showTenantSelectionDialog: true })
                                    }, "inherit")}
                                    {LayoutService.getIconButton(this.state.isReadOnly, MatIconService.UNLINK, "Unlink tenant", () => {
                                        if (this.state.agGridUtils.getUpdatedRowData().filter(x => x.isSelected).length > 0)
                                            this.setState({ showConfirmDialog: true });
                                        else
                                            ToastService.showWarning("Please select atleast one tenant.");
                                    }, "primary")}
                                </Grid>
                                <div {...LayoutService.getAgGridStyles(320)}>
                                    <AgGridReact
                                        pagination={true}
                                        paginationPageSize={50}
                                        rowData={this.state.data}
                                        columnDefs={UserMappingDetailService.getColumnDefs(this)}
                                        frameworkComponents={this.state.agGridUtils.frameworkComponents}
                                        suppressClickEdit={true}
                                        gridOptions={{
                                            context: { componentParent: this },
                                            ...AgGridColumnExt.getGridOptions(56),
                                            ...this.state.agGridUtils.bindInlineEditEvents(),
                                        }}
                                        onGridReady={(params) => {
                                            this.state.agGridUtils.setGridParams(params, true);
                                            this.state.agGridUtils.setEditingMode(this.state.isReadOnly);
                                            this.state.agGridUtils.sizeColumnsToFit();
                                        }}></AgGridReact>
                                </div>
                            </Box>
                        </DialogContent>
                        {!this.state.showTenantSelectionDialog ? null :
                            <Dialog open={this.state.showTenantSelectionDialog || false} fullWidth={true} maxWidth='md' scroll={false ? "paper" : "body"}>
                                {/* Title */}
                                <DialogTitle style={{ padding: 0 }} id="dialog-title">
                                    <AppBar position="static">
                                        <Toolbar>
                                            <Typography variant="h6" className={classes.root}>Select Tenant and Role</Typography>
                                            {LayoutService.getIconButton(this.state.selTenantId === 0 ? true : this.state.selTenantId === 1 ? false : this.state.selRoleId === 0, MatIconService.OK, "Confirm", () => {
                                                this.handleSubmit();
                                            }, "inherit", "keyActionSave")}
                                            {LayoutService.getIconButton(this.state.isReadOnly, MatIconService.CANCEL, "Cancel", () => { this.setState({ showTenantSelectionDialog: false }) }, "secondary", "keyActionCancel1")}
                                        </Toolbar>
                                        {this.state.isTenantMapping ? <LinearProgress color="secondary" /> : null}
                                    </AppBar>
                                </DialogTitle>
                                {/* Content */}
                                <DialogContent style={{ padding: 0 }}>
                                    <Typography style={{ width: "100%", paddingLeft: 8, paddingRight: 8, backgroundColor: "#ecc802", color: "#681501" }} variant="h6" align="center">
                                        {this.state.isTenantMapping ? "Mapping user and tenant" : "This Action maps user to selected tenant."}
                                    </Typography>
                                    <Divider style={{ width: "100%" }} />
                                    <Grid container direction="column" justifyContent="center" alignItems="stretch">
                                        {/* <h6>Please select a Profile Source</h6> */}
                                        <FormControl required style={{ marginTop: 24, marginInline: 24 }}>
                                            <InputLabel >Tenant</InputLabel>
                                            <Select disabled={this.state.isTenantMapping} value={this.state.selTenantId || ''}
                                                MenuProps={{ classes: { paper: classes.menuPaper } }}
                                                onChange={(e) => {
                                                    this.setState({ selTenantId: e.target.value }, () => {
                                                        this.fetchRolesByTenant();
                                                    });

                                                }}>
                                                {(this.state.availableTenantList || []).map(
                                                    (el, index) => { return <MenuItem value={el.tenantid}>{el.tenantName}</MenuItem> }
                                                )}
                                            </Select>
                                        </FormControl>
                                        <FormControl required style={{ marginTop: 24, marginInline: 24 }}>
                                            <InputLabel >Role Type</InputLabel>
                                            <Select disabled={this.state.selTenantId == 0 || this.state.selTenantId === 1} value={this.state.selRoleId || ''}
                                                MenuProps={{ classes: { paper: classes.menuPaper } }}
                                                onChange={(e) => this.setState({ selRoleId: e.target.value })}>
                                                {(this.state.rolesList || []).map(
                                                    (el, index) => { return <MenuItem value={el.roleId}>{el.roleName}</MenuItem> }
                                                )}
                                            </Select>
                                        </FormControl>
                                        <Divider style={{ marginTop: 24 }} />
                                    </Grid>
                                </DialogContent>
                            </Dialog>
                        }
                        <ActionDialog showDialog={this.state.showConfirmDialog} title="Unlink Confirmation" description="Please confirm user unmapping for tenants"
                            content={this.state.showConfirmDialog ? this.state.agGridUtils.getUpdatedRowData().filter(x => x.isSelected).map(x => x.tenantName).join() : ""}
                            actions={[
                                {
                                    color: "secondary", text: "Continue", icon: null,
                                    callback: () => {
                                        this.setState({ showConfirmDialog: false }, () => {
                                            this.handleUnlinkTenant()
                                        })
                                    }
                                },
                                {
                                    color: "inherit", text: "Cancel", icon: null,
                                    callback: () => { this.setState({ showConfirmDialog: false }, () => { }) }
                                }
                            ]} />
                    </React.Fragment>
                );

            case ResultStatus.ERROR:
                return (
                    <PageErrorComponent
                        small
                        label="Error Loading User Mapping Details"
                        classes={classes}
                        onRetry={() => {
                            this.SERVICE.fetchData(this, true);
                        }}
                        onClose={this.props.onClose}
                    />
                );
            default:
                return (null);
        }
    }
}

/** HOC */
export default LayoutService.getHocComponenet(UserMappingDetailsComponent);