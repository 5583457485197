import React from "react";
import { POSITION } from "../types/enums";

export default class ToastService {

    /**
     * Usage: ToastService.showSuccess("Successfully Saved");
     * @param {*} _message 
     * @param {*} _autoHideDuration 
     * @param {*} _postition 
     */
    static showSuccess = (_message = "Success", _autoHideDuration = 3000, _postition = POSITION.TOP_CENTER) => {
        ToastService._show("success", _message, _autoHideDuration, _postition);
    }

    /**
     * Usage: ToastService.showError("Error Occured while saving");
     * @param {*} _message 
     * @param {*} _autoHideDuration 
     * @param {*} _postition 
     */
    static showError = (_message = "Error", _autoHideDuration = 3000, _postition = POSITION.TOP_CENTER) => {
        ToastService._show("error", _message, _autoHideDuration, _postition);
    }

    /**
     * Usage: ToastService.showWarning("No Changes to Save");
     * @param {*} _message 
     * @param {*} _autoHideDuration 
     * @param {*} _postition 
     */
    static showWarning = (_message = "Warning", _autoHideDuration = 3000, _postition = POSITION.TOP_CENTER) => {
        ToastService._show("warning", _message, _autoHideDuration, _postition);
    }

    /**
     * Usage: ToastService.showInfo("300 Vendors are available");
     * @param {*} _message 
     * @param {*} _autoHideDuration 
     * @param {*} _postition 
     */
    static showInfo = (_message = "Information", _autoHideDuration = 3000, _postition = POSITION.TOP_CENTER) => {
        ToastService._show("info", _message, _autoHideDuration, _postition);
    }


    //#region INTERNALs

    /**
     * Should be called only once in the container component
     * container component should be always loaded and available eg: shell
     * eg: ToastService.INIT(this);
     * @param {*} _containerREF 
     */
    static setComponentREF = (_containerREF) => { ToastService.containerREF = _containerREF; }

    /**
     * for non class based componenets
     * @param {*} _HookREF 
     */
    static setShowToastHookREF = (_HookREF) => { ToastService.setShowToastRef = _HookREF; }

    static setShowToastRef = null;
    static containerREF = null;
    static autoHideDuration = 3000;
    static toastType = "info";
    static message = "Information";
    static variant = "filled"; // filled | outlined
    static elevation = 6;
    static anchorOrigin = { vertical: "top", horizontal: "center" }

    static closeToast = () => {
        if (ToastService.containerREF && ToastService.containerREF.state.showToast === true) {
            ToastService.containerREF.setState({ showToast: false });
        } else if (ToastService.setShowToastRef) {
            ToastService.setShowToastRef(false);
        }
    }

    /**
     * 
     * @param {*} _toastType "info" | "success" | "warning" | "error" <default: "info">
     * @param {*} _message 
     * @param {*} _autoHideDuration <default: 3000>
     * @param {*} _postition eg: POSITION.BOTTOM_RIGHT <Default: POSITION.TOP_CENTER>
     */
    static _show = (_toastType, _message, _autoHideDuration, _postition) => {
        // close previous toasts if any
        ToastService.closeToast();

        _toastType = (_toastType + "").toLowerCase();
        ToastService.toastType = !(["info", "success", "warning", "error"].includes(_toastType)) ? "info" : _toastType;

        ToastService.message = _message;
        ToastService.autoHideDuration = _autoHideDuration;

        switch (_postition) {
            case POSITION.TOP_LEFT: ToastService.anchorOrigin = { vertical: "top", horizontal: "left" }; break;
            case POSITION.TOP_CENTER: ToastService.anchorOrigin = { vertical: "top", horizontal: "center" }; break;
            case POSITION.TOP_RIGHT: ToastService.anchorOrigin = { vertical: "top", horizontal: "right" }; break;
            case POSITION.BOTTOM_LEFT: ToastService.anchorOrigin = { vertical: "bottom", horizontal: "left" }; break;
            case POSITION.BOTTOM_CENTER: ToastService.anchorOrigin = { vertical: "bottom", horizontal: "center" }; break;
            case POSITION.BOTTOM_RIGHT: ToastService.anchorOrigin = { vertical: "bottom", horizontal: "right" }; break;
            default: ToastService.anchorOrigin = { vertical: "top", horizontal: "center" }; break;
        }

        // show the toast
        if (ToastService.containerREF) {
            ToastService.containerREF.setState({ showToast: true });
        } else if (ToastService.setShowToastRef) {
            ToastService.setShowToastRef(true);
        }
    }

    //#endregion



    //---
}