import { ReplaySubject } from 'rxjs';
import ApiService from "../../../../shared/services/apiService";
import { API_ENDPOINT } from "../../../../shared/types/enums";
import { AgGridService } from "../../../../shared/services/ag-grid/agGridService";
import { AgGridColumnExt } from '../../../../shared/services/ag-grid/agGridColumnExt';

export default class EditAffiliateService {
  static getAffiliateAsOBS = (_tenantId, _comapnyAffiliateId) => {
    return ApiService.getOBS(
      API_ENDPOINT.TENANT,
      `/Affiliate/AffiliateById/${_tenantId}/${_comapnyAffiliateId}`
    );
  };

  static getAffiliateByLovIdAsOBS = (_tenantId, _comapnyAffiliateId) => {
    return ApiService.getOBS(
      API_ENDPOINT.TENANT,
      `/Util/GetFieldLOVByIds/${_tenantId}/303`
    );
  };

  //#region Address Grid
  static validateAffiliateRowEditOBS = (_cellRef) => {
    var oSubject = new ReplaySubject(); // 1st

    // static/async validation logic should trigger the below
    oSubject.next({ isSuccess: true }); // true <- success

    // return the subject as observable
    return oSubject.asObservable(); // 3rd
  };
  static getColumnDefs = (_componentThisRef, _isReadOnly, _agGridUtils) => {
    return [
      // edit button column
      _agGridUtils.getInlineEditActionColumn(true, this.validateAffiliateRowEditOBS),
      // other columns
      AgGridColumnExt.GET(false, "text", "reportIdentifierId", "Report Identifier").makeMaterialDropDown(_componentThisRef.state.reportIdentifierList, "lovId", "lovKey").SET(),
      AgGridService.getTextColumn(true, "Report Identifier Value", "reportIdentifierValue")
      
    ];
  };
}
