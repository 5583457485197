import React, { Component, createRef } from "react";
import csv_icon from "../../../../public/images/csv-export-icon.png";
import excel_icon from "../../../../public/images/xls-export-icon.png";
import pdf_icon from "../../../../public/images/pdf-icon.png";
import ms_word_icon from "../../../../public/images/ms-word-icon.png";
import unknown_file from "../../../../public/images/UnknownFile.png";
import { DataService } from "../../../../shared/services/dataService";
import { ReportType } from "../../../../shared/types/enums";

export default class DownloadReportViaImageCellRenderer extends Component {
    //link = createRef();

    constructor(props) {
        super(props);
        this.invokeParentMethod = this.invokeParentMethod.bind(this);
    }

    invokeParentMethod = async (_fileType = "", _fileExtension = "") => {
        //if (this.link.current.href) { return }
        let fileExtension = !DataService.isStringNullOrEmpty(_fileExtension) ? _fileExtension : this.props.value;
        let blob = await this.props.context.componentParent.handleDownloadAg(this.props.node.data, fileExtension, _fileType);
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${this.props.node.data.reportDetailName}${(_fileType == "Companion" ? "PorzioAnalysisReport" : _fileType)}.${fileExtension}`); //or any other extension
        document.body.appendChild(link);
        link.click();
    }

    renderIcons = (category) => {
        let translatedReportLanguageList = !DataService.isStringNullOrEmpty(this.props.data.translatedReportLanguageList) ?
            this.props.data.translatedReportLanguageList.split(',') : null;
        let content;
        let imageSrc;
        let isComplianceSave = this.props.data.isComplianceSave;

        switch (this.props.value) {
            case "csv":
                imageSrc = csv_icon;
                break;
            case "xls":
            case "xlsx":
                imageSrc = excel_icon;
                break;
            case "pdf":
                imageSrc = pdf_icon;
                break;
            case "doc":
            case "docx":
                imageSrc = ms_word_icon;
                break;
            default:
                imageSrc = unknown_file;
        }

        switch (category) {
            case "Compliance": //Compliance(English) + Language reports
                content = (<>
                    {isComplianceSave ?
                        (<span style={{ paddingRight: "5px" }} onClick={(e) => { this.invokeParentMethod("") }}>
                            <img src={imageSrc} title={"English Report - excel version"} alt={"English Report - excel version"} style={{ marginBottom: 25 + 'px', width: 25 + 'px' }} />
                        </span>) : null}
                    {translatedReportLanguageList ?
                        translatedReportLanguageList.map((language, index) => {
                            return (<span key={"span" + index} style={{ paddingRight: "5px" }} onClick={(e) => { this.invokeParentMethod(language.replace('/', '-')) }}>
                                <img src={imageSrc} title={`${language} Report`} alt={`${language} Report`} style={{ marginBottom: 25 + 'px', width: 25 + 'px' }} />
                            </span>);
                        }) : null
                    }
                </>);
                break;
            case "Companion": //Companion + FVM
                content = (<>
                    <span style={{ paddingRight: "5px" }} onClick={() => { this.invokeParentMethod("Companion") }}>
                        <img src={imageSrc} title={"Porzio Analysis Report - excel version"} alt={"Porzio Analysis Report - excel version"} style={{ marginBottom: 25 + 'px', width: 25 + 'px' }} />
                    </span>
                    <span onClick={() => { this.invokeParentMethod("FVM") }}>
                        <img src={imageSrc} title={"FVM Report - excel version"} alt={"FVM Report - excel version"} style={{ marginBottom: 25 + 'px', width: 25 + 'px' }} />
                    </span>
                </>);
                break;
            case "US_General_Report_Companion":
                content = (<>
                    <span style={{ paddingRight: "5px" }} onClick={() => { this.invokeParentMethod("Companion", 'xlsx') }}>
                        <img src={excel_icon} title={"Porzio Analysis Report - excel version"} alt={"Porzio Analysis Report - excel version"} style={{ marginBottom: 25 + 'px', width: 25 + 'px' }} />
                    </span>
                    <span onClick={() => { this.invokeParentMethod("FVM") }}>
                        <img src={excel_icon} title={"FVM Report - excel version"} alt={"FVM Report - excel version"} style={{ marginBottom: 25 + 'px', width: 25 + 'px' }} />
                    </span>
                </>);
                break;
            case "US_General_Report":
                content = (<>
                    {isComplianceSave ?
                        (<>
                            <span style={{ paddingRight: "5px" }} onClick={(e) => { this.invokeParentMethod("") }}>
                                <img src={excel_icon} title={"General Report - excel version"} alt={"General Report - excel version"} style={{ marginBottom: 25 + 'px', width: 25 + 'px' }} />
                            </span>
                            <span style={{ paddingRight: "5px" }} onClick={(e) => { this.invokeParentMethod("", 'csv') }}>
                                <img src={csv_icon} title={"General Report - csv version"} alt={"General Report - csv version"} style={{ marginBottom: 25 + 'px', width: 25 + 'px' }} />
                            </span>
                            <span style={{ paddingRight: "5px" }} onClick={(e) => { this.invokeParentMethod("Research") }}>
                                <img src={excel_icon} title={"Research Report - excel version"} alt={"Research Report - excel version"} style={{ marginBottom: 25 + 'px', width: 25 + 'px' }} />
                            </span>
                            <span onClick={(e) => { this.invokeParentMethod("Research", 'csv') }}>
                                <img src={csv_icon} title={"Research Report - csv version"} alt={"Research Report - csv version"} style={{ marginBottom: 25 + 'px', width: 25 + 'px' }} />
                            </span>
                        </>) : <></>}
                </>);
                break;
            case "US_State_Report_Companion":
                content = (<>
                    <span style={{ paddingRight: "5px" }} onClick={() => { this.invokeParentMethod("Companion",'xlsx') }}>
                        <img src={excel_icon} title={"Porzio Analysis Report - excel version"} alt={"Porzio Analysis Report - excel version"} style={{ marginBottom: 25 + 'px', width: 25 + 'px' }} />
                    </span>
                    <span onClick={() => { this.invokeParentMethod("FVM",'xlsx') }}>
                        <img src={excel_icon} title={"FVM Report - excel version"} alt={"FVM Report - excel version"} style={{ marginBottom: 25 + 'px', width: 25 + 'px' }} />
                    </span>
                </>);
                break;
            case "US_Ownership_Report":
            case "US_State_Report":
                content = (<>
                 {isComplianceSave ?
                        (<>
                    <span style={{ paddingRight: "5px" }} onClick={() => { this.invokeParentMethod("") }}>
                        <img src={imageSrc} title={"English Report - excel version"} alt={"English Report - excel version"} style={{ marginBottom: 25 + 'px', width: 25 + 'px' }} />
                    </span>
                    <span onClick={() => { this.invokeParentMethod("", 'csv') }}>
                        <img src={csv_icon} title={"English Report - csv version"} alt={"English Report - csv version"} style={{ marginBottom: 25 + 'px', width: 25 + 'px' }} />
                    </span>
                    </>) : <></>}
                </>);
                break;
            case "Standard":
                content = (<>
                    {<span style={{ paddingRight: "5px" }} onClick={(e) => { this.invokeParentMethod("") }}>
                            <img src={imageSrc} title={"Standard Report"} alt={"Standard Report"} style={{ marginBottom: 25 + 'px', width: 25 + 'px' }} />
                        </span>}
                </>);
                break;
                default:
                content = <></>;
        }
        return content;
    }

    render() {
        let content = <></>;
        let isIconVisible = true;
        if (this.props.showIcon && !this.props.showIcon(this)) {
            isIconVisible = false;
        }
        let isCompanionCol = this.props.isCompanionCol && this.props.isCompanionCol();

        if (this.props.data['reportType']) {
            switch (this.props.data['reportType']) {
                case ReportType.US_GENERAL:
                    content = (isCompanionCol ? this.renderIcons("US_General_Report_Companion") : this.renderIcons("US_General_Report"));
                    break;
                case ReportType.US_STATE:
                    content = (isCompanionCol ? this.renderIcons("US_State_Report_Companion") : this.renderIcons("US_State_Report"));
                    break;
                case ReportType.US_OWNERSHIP:
                    content = (isCompanionCol ? null : this.renderIcons("US_Ownership_Report"));
                    break;
                case ReportType.OUS:
                    content = (isCompanionCol ? this.renderIcons("Companion") : this.renderIcons("Compliance"));
                    break;
                case ReportType.STANDARD:
                    content = this.renderIcons("Standard");
                    break;
            }
        }
        return (<React.Fragment>
            {isIconVisible ? content : null}
        </React.Fragment>
        );
    }
}
