import React, { Component } from "react";
import { combineLatest, } from "rxjs";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css";

import { AuthContext } from "../../../shared/store/authProvider";
import PageHeaderComponent from "../../../shared/components/page/pageHeaderComponent";
import { Dialog } from "@material-ui/core";
import ChildMessageRendererComponent from "../childMessageRendererComponent";
import ViewFieldDialogComponent from "./view-field/viewFieldDialogComponent";
import PageLoadingComponent from "../../../shared/components/page/pageLoadingComponent";
import PageErrorComponent from "../../../shared/components/page/pageErrorComponent";
import { SubscriptionArray } from "../../../shared/services/dataService";
import { ResultStatus, CrudAction } from "../../../shared/types/enums";
import ActiveRendererComponent from "../../../shared/components/ag-grid/isActiveIconRendererComponent";
import LayoutService from "../../../shared/services/layoutService";
import PorzioFieldListService from "./porzioFieldListService";
import LookupService from "../../../shared/services/lookupService";
import ToastService from "../../../shared/services/toastService";
import RolePermissionService from "../../../shared/role-permissions/rolePermissionService";

class PorzioFieldsListComponent extends Component {
  static contextType = AuthContext;
  apiSubscriptions = new SubscriptionArray();

  constructor(props) {
    super(props);
    this.handleHide = this.handleHide.bind(this);
    this.state = {
      frameworkComponents: {
        childMessageRendererComponent: ChildMessageRendererComponent,
        activeRendererComponent: ActiveRendererComponent,
      },
      modalShow: false,
      modalAgNode: null,
      selectedEntityId: 0,
    };
  }

  componentWillUnmount() {
    this.apiSubscriptions.cancelAll();
  }

  componentDidMount() {
    this.fetchData();
  }

  /** API Fetch */
  fetchData = (_ignoreCache = false) => {
    this.apiSubscriptions.cancelAll();
    this.setState({ fetchResult: ResultStatus.LOADING, data: [] });

    this.apiSubscriptions.add(
      combineLatest([
        // ProfileExpandedViewService.getObs(true, this.props.modalAgNode, this.context),
        PorzioFieldListService.getPorzioFieldListAsOBS(this.context.user.tenantId),
        LookupService.getEntityListAsOBS(this.context.user.tenantId)
      ]).subscribe(
        // success
        ([_data, _entityList]) => {
          this.setState(
            {
              data: _data,
              entityList: [{ entityid: 0, entityname: "-- All --" }, ..._entityList.filter(x => x.isPorzioEntity === true)],
            },
            // change the state after all the above are assigned
            () => { this.setState({ fetchResult: ResultStatus.LOADED }); }
          );
        },
        // onError
        (error) => { this.setState({ fetchResult: ResultStatus.ERROR }); }
      )
    );
  };

  onGridReady = (event) => {
    event.api.closeToolPanel();
    event.api.sizeColumnsToFit();
    this.gridApi = event.api;
    this.gridColumnApi = event.columnApi;
    setTimeout(() => {
      this.gridApi.setFilterModel({
        entityName: {
          filterType: 'text',
          type: 'equals',
          filter: this.state.selectedEntityId === 0 ? null : this.state.entityList.find(x => x.entityid === this.state.selectedEntityId).entityname,
        },
      });
    }, 150);
  };

  onDownloadClick = () => {
    let api = this.gridApi, params = this.getParams();
    api.exportDataAsExcel(params);
  }

  getParams() {
    return {
      // allColumns: true,
      columnKeys: this.gridColumnApi.getAllColumns().filter(c => c.colDef.headerName !== ""),
      fileName: this.getFileName()
    };
  }

  getFileName() {
    return `Porzio_Fields Entity-${(Object.keys(this.gridApi.getFilterModel()).length > 0 ? this.gridApi.getFilterModel().entityName.filter : `All`)} ${new Date().toDateString()}`;
  }

  onEntitySelectClick = (event) => {
    this.setState({ selectedEntityId: event.target.value });
    this.gridApi.setFilterModel({
      entityName: {
        filterType: 'text',
        type: 'equals',
        filter: event.target.value === 0 ? null : this.state.entityList.find(x => x.entityid === event.target.value).entityname,
      },
    });
  }

  methodFromParent = (cell, rowData) => {
    const fieldEntity = this.state.data.find(x => x.fieldId === rowData.fieldId);
    if (!fieldEntity) { ToastService.showError('InvalidField'); }
    else {
      rowData.fieldlength = fieldEntity.fieldlength;
      this.setState({ modalAgNode: rowData, modalShow: true });
    }

  };
  handleHide() {
    this.setState({ modalShow: false });
  }

  validateDownload = () => {
    return (RolePermissionService.PORZIO_FIELD_LIST_EXPORT.cannotView) ? {}
      : { 'download': true, 'onDownloadClick': this.onDownloadClick };
  }
  render() {
    const { classes } = this.props;
    if (RolePermissionService.PORZIO_FIELD_LIST.cannotView) {
      return RolePermissionService.getAccessDeniedComponent(classes, () => { this.props.history.goBack(); });
    } else {
      switch (this.state.fetchResult) {
        case ResultStatus.NOT_LOADED:
        case ResultStatus.LOADING:
          return (<PageLoadingComponent classes={classes} label="Loading Porzio Fields" />);
        case ResultStatus.LOADED:
        case ResultStatus.SUCCESS:
          return (
            <div id="MainUsersGrid">
              <div className="IconBg marginTop10px tableTitleHead">
                <PageHeaderComponent back history={this.props.history} noDivider
                  classes={classes} label="Porzio Fields" entitySelect
                  selectedEntityId={this.state.selectedEntityId}
                  entitySelectData={this.state.entityList}
                  onEntitySelectClick={(event) => { this.onEntitySelectClick(event); }}
                  reload onReloadClick={() => { this.fetchData(true); }}
                  {...this.validateDownload()}
                />
              </div>
              <div {...LayoutService.getAgGridStyles()}>
                <AgGridReact
                  columnDefs={PorzioFieldListService.getColumnDefs(this)}
                  rowData={this.state.data}
                  pagination={true}
                  paginationPageSize={50}
                  frameworkComponents={this.state.frameworkComponents}
                  gridOptions={{
                    context: { componentParent: this },
                  }}
                  onGridReady={(event) => {
                    this.onGridReady(event);
                  }}
                ></AgGridReact>
              </div>
              <Dialog open={this.state.modalShow || false} scroll={true ? "paper" : "body"} maxWidth="md">
                <ViewFieldDialogComponent onClose={this.handleHide} modalAgNode={this.state.modalAgNode}
                  inputAction={CrudAction.UPDATE} isPorzio={true} refreshList={this.fetchData} />
              </Dialog>
            </div>
          );

        case ResultStatus.ERROR:
          return (
            <PageErrorComponent
              label="Error Loading Porzio Fields"
              classes={classes}
              onRetry={() => {
                this.fetchData(true);
              }}
            />
          );
        default:
          return null;
      }
    }
  }
}

export default LayoutService.getHocComponenet(PorzioFieldsListComponent);;
