import ApiService from "../../../shared/services/apiService";
import { API_ENDPOINT } from "../../../shared/types/enums";

export default class WorkflowDetailsService {

  static getWorkflowDetailsAsOBS = (_tenantId, workflowTemplateId) => {
    return ApiService.getOBS(
      API_ENDPOINT.TENANT,
      `/Workflow/FetchWorkflowTemplateDetails/${_tenantId}/${workflowTemplateId}`
    );
  };

  static getAllUsersAsOBS = (_tenantId) => {
    return ApiService.getOBS(
      API_ENDPOINT.TENANT,
      `/User/GetAllUsers/${_tenantId}`
    );
  };

  static GetFieldLOVByIds = (_tenantId, _fieldIds) => {
    return ApiService.getOBS(
      API_ENDPOINT.TENANT,
      `/Util/GetFieldLOVByIds/${_tenantId}/${_fieldIds}`
    );
  };

}
