import { AgGridColumnExt } from "../../../../shared/services/ag-grid/agGridColumnExt";
import ApiService from "../../../../shared/services/apiService";
import { API_ENDPOINT } from "../../../../shared/types/enums";
import { AgGridBulkEditUtil } from "../../../../shared/components/ag-grid/bluk-edit/agGridBulkEditUtil";

export default class UnmatchedProfileService {

  static getProfileByStatus(tenantId, profileStatus) {
    let targetUrl;
    if (profileStatus) {
      targetUrl = `/Profiles/GetReferenceDataGetUnMatchedProfiles?tenantId=${tenantId}&pageNumber=1&rowsPerPage=100000`;
    } else {
      targetUrl = `/Profiles/GetReferenceDataGetReviewedProfiles?tenantId=${tenantId}&pageNumber=1&rowsPerPage=100000`;
    }
    return ApiService.getOBS(API_ENDPOINT.CORE, targetUrl);
  }

  static getColumnDefs = (_parentThis) => {
    return [
      AgGridColumnExt.GET(true, "text", AgGridBulkEditUtil.selectColumnName, "Select").fixedWidth(80).headerClass("center-text")
        .cellRenderer("isCheckedCellRenderer", { onChange: _parentThis.toggleSubmitButton }).SET(),
      AgGridColumnExt.GET(false, "text", "porziogstprofileid", "Porzio GST Profile ID").flexWeight(1).minAndDefaultWidth(140).headerClass("center-text").centerAlignText().cellRenderer("profileDetailCellRendererComponent").SET(),
      AgGridColumnExt.GET(false, "text", "companyprofileid", "Company Profile ID").flexWeight(1).minAndDefaultWidth(120).headerClass("center-text").centerAlignText().SET(),
      AgGridColumnExt.GET(false, "text", "profile_type", "Profile Type").flexWeight(1).minAndDefaultWidth(120).headerClass("center-text").centerAlignText().SET(),
      AgGridColumnExt.GET(false, "text", "usnpinumber", "NPI Number").flexWeight(1).minAndDefaultWidth(120).headerClass("center-text").centerAlignText().SET(),
      AgGridColumnExt.GET(false, "text", "lastname", "Last Name").flexWeight(1).minAndDefaultWidth(120).headerClass("center-text").centerAlignText().SET(),
      AgGridColumnExt.GET(false, "text", "firstname", "First Name").flexWeight(1).minAndDefaultWidth(120).headerClass("center-text").centerAlignText().SET(),
      AgGridColumnExt.GET(false, "text", "uslicensestate", "US License State").flexWeight(1).minAndDefaultWidth(120).headerClass("center-text").centerAlignText().SET(),
      AgGridColumnExt.GET(false, "text", "ustaxidnumber", "US Tax ID Number").flexWeight(1).minAndDefaultWidth(120).headerClass("center-text").centerAlignText().SET(),
      AgGridColumnExt.GET(false, "text", "organizationname", "Organization Name").flexWeight(1).minAndDefaultWidth(120).headerClass("center-text").centerAlignText().SET(),
      AgGridColumnExt.GET(false, "text", "usstatelicensenumber", "US License Number").flexWeight(1).minAndDefaultWidth(120).headerClass("center-text").centerAlignText().SET(),
      AgGridColumnExt.GET(false, "text", "countryname", "Country").flexWeight(1).minAndDefaultWidth(120).headerClass("center-text").centerAlignText().SET(),
      AgGridColumnExt.GET(false, "text", "vpl_or_nppl", "VPL/NPPL").flexWeight(1).minAndDefaultWidth(120).headerClass("center-text").centerAlignText().SET(),
      AgGridColumnExt.GET(false, "text", "nppes", "NPPES").flexWeight(1).minAndDefaultWidth(120).headerClass("center-text").centerAlignText().SET(),
      AgGridColumnExt.GET(false, "text", "teaching_hospital_list", "Teaching Hospital List").flexWeight(1).minAndDefaultWidth(120).headerClass("center-text").centerAlignText().SET(),
    ];
  }

  static saveReviewedProfiles = (tenantId, idsToSave) => {
    return ApiService.postOBS(API_ENDPOINT.CORE,
      `/Profiles/ReferenceDataSaveReviewedProfiles?tenantId=${tenantId}`, JSON.stringify(idsToSave));
  }
  static saveUnmatchedProfiles = (tenantId, porziogstprofileid) => {
    return ApiService.postOBS(API_ENDPOINT.CORE,
      `/Profiles/ReferenceDataSaveUnmatchedProfiles?tenantId=${tenantId}`, JSON.stringify(porziogstprofileid));
  }
}