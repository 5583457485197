import React, { Component } from "react";
import { Chip, } from "@material-ui/core";

export default class AgGridChipCellRendererComponent extends Component {

  render() {
    return (
      <div style={{
        display: "flex",
        "flexDirection": "column",
        "alignItems": "center",
        "justifyContent": "center",
        "gap": "1ch",
      }}>
        {this.props.value === "isWarning" ?
          <Chip size="small" color='primary' style={{ backgroundColor: '#ccbc3f' }} label={"Warning"} align="center" />
          : this.props.value === "isError" ?
            <Chip size="small" color='primary' style={{ backgroundColor: '#da5756' }} label={"Error"} align="center" />
            :
            <Chip size="small" color='primary' style={{ backgroundColor: '#999999' }} label={"n/a"} align="center" />
        }</div>
    )
  }
}
