import { AgGridColumnExt } from "../../../../../shared/services/ag-grid/agGridColumnExt";
import { AgGridService } from "../../../../../shared/services/ag-grid/agGridService";
import ApiService from "../../../../../shared/services/apiService"
import { API_ENDPOINT } from "../../../../../shared/types/enums"

export default class RecipientSearchDialogService {

    static searchOBS = (_tenantId, _searchValue) => {
        return ApiService.setOBS("POST", API_ENDPOINT.CORE, `/Profiles/FetchProfilesBySearch/${_tenantId}`, JSON.stringify({
            searchValue: _searchValue,
            porzioGSTId: "",
            firstName: "",
            lastName: "",
            organizationName: "",
            companyId: "",
            country: ""
        }));
    }

    static getColumnDefs = (_componentThisRef, _onSelectCallback) => {
        return [
            {
                headerName: "Select",
                field: "isSelected",
                editable: true,
                width: 80,
                minWidth: 80,
                cellStyle: { "border-right": "1px solid lightgray" },
                cellRenderer: "isSelectedCellRenderer",
                cellRendererParams: { onChange: (_cellRef, _data) => { _onSelectCallback(_data); } }
            },
            AgGridColumnExt.GET(false, "text", "companyProfileId", "Company Profile ID").minWidth(150).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "porziogstprofileid", "Porzio GST Profile ID").minWidth(150).cellRenderer("childMessageRendererComponent").headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "profileType", "Profile Type").minWidth(150).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "lastName", "Last Name").minWidth(150).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "firstName", "First Name").minWidth(150).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "usnpinumber", "NPI Number").minWidth(150).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "uslicensestate", "US License State").minWidth(150).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "usstatelicensenumber", "US License Nnumber").minWidth(150).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "organizationName", "Organization Name").minWidth(150).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "addresS1", "Address").minWidth(150).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "city", "City").minWidth(104).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "province", "Province").minWidth(104).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "country", "Country").minWidth(104).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "profileStatusKey", "Profile Status").minWidth(104).headerClass("center-text").centerAlignText().SET(),
            // AgGridService.getTextColumn(false, "Company Profile ID", "companyProfileId"),
            // AgGridService.getTextColumn(false, "Porzio GST Profile ID", "porziogstprofileid"),
            // AgGridService.getTextColumn(false, "Last Name", "lastName"),
            // AgGridService.getTextColumn(false, "First Name", "firstName"),
            // AgGridService.getTextColumn(false, "Organization Name", "organizationName"),
            // // AgGridService.getTextColumn(false, "Country", "CountryName"),
            // AgGridService.getTextColumn(false, "Country", "country"),
            // AgGridService.getTextColumn(false, "Profile Status", "profileStatus"),
        ];
    }
}