import { of } from "rxjs";
import { MatIconService } from "../../shared/services/theme/matIconService";
import Cache from "../../shared/store/cache";
import RolePermissionService from "../../shared/role-permissions/rolePermissionService";
import { DataService } from "../../shared/services/dataService";

export default class TenantConfigurationService {
  // cache
  static CACHE = new Cache(this.fetchFromApiObs);

  static getObs(_ignoreCache) {
    if (_ignoreCache || this.CACHE.NOT_EXISTS()) {
      return this.fetchFromApiObs();
    } else {
      return this.CACHE.getOBS();
    }
  }

  /**
   * Replace the static data inside of(...) with real ApiCall
   * @returns observable
   */
  static fetchFromApiObs() {
    // clear the cache before returning the actual subscription
    this.CACHE.CLEAR();
    return of(this.getJsonData()); //.pipe(delay(500));
  }

  static getGridNames(_tenanatConfigs) {
    /** using 'Set' since grid names are present for each control & we need to ignore duplicates */
    return [...new Set(_tenanatConfigs.filter(x => !x.isHidden).map((o) => o.gridName))];
  }
  static getGridGroupNames(_tenanatConfigs, _gridName) {
    /** using 'Set' since group names are present for each control & we need to ignore duplicates */
    return [
      ...new Set(
        _tenanatConfigs
          .filter((o) => o.gridName === _gridName && !o.isHidden)
          .map((o) => o.gridGroupName)
      ),
    ];
  }
  static getGridGroupControls(_tenanatConfigs, _gridName, _groupName) {
    /** not-using 'Set' since controls are unique */
    return [
      ..._tenanatConfigs.filter(
        (o) => !o.isHidden && o.gridName === _gridName && o.gridGroupName === _groupName
      ),
    ];
  }

  static getJsonData = () => {

    const isPorzioUser = RolePermissionService.isTenantReportSubscriptionsAllowed();
    const isTenantUser = !isPorzioUser;

    let oRET = [
      {
        id: 1,
        iconJsx: MatIconService.COMPANY,
        target: "/company-profile",
        label: "Tenant Profile",
        gridGroupName: "Tenant Profile Setup",
        gridName: "Grid1",
        isReadOnly: RolePermissionService.TENANT_DETAIL.cannotView,
        isHidden: isPorzioUser,
      },
      {
        id: 2,
        iconJsx: MatIconService.AFFILIATE,
        target: "/affiliate-companies",
        label: "Affiliate Companies",
        gridGroupName: "Tenant Profile Setup",
        gridName: "Grid1",
        isReadOnly: false,
        isHidden: isPorzioUser,
      },
      {
        id: 3,
        iconJsx: MatIconService.STORE,
        target: "/vendor-setup",
        label: "Vendor Setup",
        gridGroupName: "Tenant Profile Setup",
        gridName: "Grid1",
        isReadOnly: false,
        isHidden: isPorzioUser,
      },
      // GRID-1 : Field Management
      {
        id: 4,
        iconJsx: MatIconService.PORZIO_FILEDS,
        target: "/porzio-fields",
        label: "Porzio Fields",
        gridGroupName: "Field Management",
        gridName: "Grid1",
        isReadOnly: RolePermissionService.PORZIO_FIELD_LIST.cannotView,
        isHidden: isPorzioUser,
      },
      {
        id: 5,
        iconJsx: MatIconService.CUSTOM_FIELDS,
        target: "/custom-fields",
        label: "Custom Fields",
        gridGroupName: "Field Management",
        gridName: "Grid1",
        isReadOnly: RolePermissionService.CUSTOMER_FIELD_LIST.cannotView,
        isHidden: isPorzioUser,
      },
      {
        id: 6,
        iconJsx: MatIconService.NDC_PRODUCT,
        target: "/product-master",
        label: "Product",
        gridGroupName: "Master Data",
        gridName: "Grid1",
        isReadOnly: RolePermissionService.PRODUCT.cannotView,
        isHidden: isPorzioUser,
      },
      {
        id: 7,
        iconJsx: MatIconService.NDC_PRODUCT,
        target: "/sales-rep",
        label: "SalesRep",
        gridGroupName: "Master Data",
        gridName: "Grid1",
        isReadOnly: RolePermissionService.SALESREP.cannotView,
        isHidden: isPorzioUser,
      },
      {
        id: 8,
        iconJsx: MatIconService.NDC_PRODUCT,
        target: "/materials",
        label: "Materials Info",
        gridGroupName: "Master Data",
        gridName: "Grid1",
        isReadOnly: RolePermissionService.MATERIAL.cannotView,
        isHidden: isPorzioUser,
      },
      {
        id: 9,
        iconJsx: MatIconService.NDC_PRODUCT,
        target: "/studies",
        label: "Study Master",
        gridGroupName: "Master Data",
        gridName: "Grid1",
        isReadOnly: RolePermissionService.STUDY.cannotView,
        isHidden: isPorzioUser,
      },
      {
        id: 52, //TODO : id is not in current sequence.For now have put it as 52 to avoid messing up other lists
        iconJsx: MatIconService.NDC_PRODUCT,
        target: "/hcp-master",
        label: "US Miami HCP",
        gridGroupName: "Master Data",
        gridName: "Grid1",
        isReadOnly: RolePermissionService.HCP.cannotView,
        isHidden: isPorzioUser,
      },
      {
        id: 53, //TODO : id is not in current sequence.For now have put it as 53 to avoid messing up other lists
        iconJsx: MatIconService.NDC_PRODUCT,
        target: "/nonentity-master",
        label: "NonEntity",
        gridGroupName: "Master Data",
        gridName: "Grid1",
        isReadOnly: RolePermissionService.HCP.cannotView,
        isHidden: isPorzioUser,
      },

      // GRID-2 : Source Management
      {
        id: 10,
        iconJsx: MatIconService.SOURCE,
        target: "/source-files",
        label: "Source Map",
        gridGroupName: "Source Management",
        gridName: "Grid1",
        isReadOnly: RolePermissionService.SOURCE_FILE_LIST.cannotView,
        isHidden: isPorzioUser,
      },
      // GRID-2 : Business Rules
      {
        id: 11,
        iconJsx: MatIconService.PORZIO_RULES,
        target: "/porzio-rules",
        label: "Porzio Rules",
        gridGroupName: "Business Rules",
        gridName: "Grid1",
        isReadOnly: RolePermissionService.BUSINESS_RULE_LIST.cannotView,
        isHidden: isPorzioUser,
      },
      {
        id: 12,
        iconJsx: MatIconService.DEFAULT_MAPPING,
        target: "/default-mapping",
        label: "Default Mapping",
        gridGroupName: "Compliance Report Mapping",
        gridName: "Grid1",
        isReadOnly: RolePermissionService.DEFAULT_MAPPING_LIST.cannotView,
        isHidden: isPorzioUser,
      },
      {
        id: 13,
        iconJsx: MatIconService.REPORT_MAPPING,
        target: "/report-mapping",
        label: "Report Mapping",
        gridGroupName: "Compliance Report Mapping",
        gridName: "Grid1",
        isReadOnly: RolePermissionService.COMPLIANCE_REPORT_MAPPING_LIST.cannotView,
        isHidden: isPorzioUser,
      },
      {
        id: 19,
        iconJsx: MatIconService.CONSENT_MAPPING,
        target: "/consent-mapping",
        label: "Consent Mapping",
        gridGroupName: "Compliance Report Mapping",
        gridName: "Grid1",
        isReadOnly: RolePermissionService.CONSENT_MAPPING_LIST.cannotView,
        isHidden: isPorzioUser,
      },

      // GRID-2 : User Management
      {
        id: 14,
        iconJsx: MatIconService.USERS,
        target: "/users",
        label: "Users",
        gridGroupName: "User Management",
        gridName: "Grid1",
        isReadOnly: RolePermissionService.USER_LIST.cannotView,
        isHidden: isPorzioUser,
      },
      {
        id: 15,
        iconJsx: MatIconService.ROLE,
        target: "/role-management",
        label: "Role Management",
        gridGroupName: "User Management",
        gridName: "Grid1",
        isReadOnly: RolePermissionService.ROLE_LIST.cannotView,
        isHidden: isPorzioUser,
      },
      {
        id: 16,
        iconJsx: MatIconService.PERMISSION,
        target: "/role-permissions",
        label: "Role Permissions",
        gridGroupName: "User Management",
        gridName: "Grid1",
        isReadOnly: RolePermissionService.ROLE_PERMISSIONS.cannotView,
        isHidden: isPorzioUser,
      },
      // GRID-2 : Workflow Template and Tasks
      {
        id: 17,
        iconJsx: MatIconService.SOURCE,
        target: "/workflow-template",
        label: "Workflow Templates",
        gridGroupName: "Workflows and Tasks",
        gridName: "Grid1",
        isReadOnly: false,
        isHidden: isPorzioUser,
      },

      // GRID-3 : Reports
      {
        id: 50,
        iconJsx: MatIconService.NAV_DEMO,
        target: "/tenant-report-template-subscriptions",
        label: "Report Template Subscriptions",
        gridGroupName: "Reports",
        gridName: "Grid1",
        isReadOnly: false,
        isHidden: isTenantUser,
      },
      {
        id: 56, //TODO : id is not in current sequence.For now have put it as 53 to avoid messing up other lists
        iconJsx: MatIconService.ROLE,
        target: "/admin-report-task-details",
        label: "Report Center Status",
        gridGroupName: "Reports",
        gridName: "Grid1",
        isReadOnly: false,
        isHidden: isTenantUser,
      },

      // Grid-4: Izenda Reporting
      {
        id: 23,
        iconJsx: MatIconService.REPORT_MAPPING,
        target: "/reporting",
        label: "Izenda 7 Reporting",
        gridGroupName: "Reports",
        gridName: "Grid1",
        isReadOnly: RolePermissionService.isIzendaReportsNotAllowed(),
        isHidden: RolePermissionService.isIzendaReportsNotAllowed(),
      },
      {
        id: 18,
        iconJsx: MatIconService.EMAIL,
        target: "/email",
        label: "E-mail Setup",
        gridGroupName: "Security",
        gridName: "Grid1",
        isReadOnly: false,
        isHidden: isPorzioUser,
      },
      {
        id: 22,
        iconJsx: MatIconService.FTP,
        target: "/ftp-folders",
        label: "FTP Folders",
        gridGroupName: "Security",
        gridName: "Grid1",
        isReadOnly: false,
        isHidden: isPorzioUser,
      },
      {
        id: 20,
        iconJsx: MatIconService.ROLE,
        target: "/tenant-status",
        label: "Tenant Creation",
        gridGroupName: "Administration",
        gridName: "Grid1",
        isReadOnly: false,
        isHidden: isTenantUser,
      },
      {
        id: 21,
        iconJsx: MatIconService.ROLE,
        target: "/user-mappings",
        label: "User Mappings",
        gridGroupName: "Administration",
        gridName: "Grid1",
        isReadOnly: false,
        isHidden: isTenantUser,
      },
      {
        id: 55, //TODO : id is not in current sequence.For now have put it as 53 to avoid messing up other lists
        iconJsx: MatIconService.ROLE,
        target: "/admin-task-details",
        label: "File Import Status",
        gridGroupName: "Administration",
        gridName: "Grid1",
        isReadOnly: false,
        isHidden: isTenantUser,
      },
     
    ];

    // //---cleanup-------------------------------------------------------------
    // // - verify access to izenda reports
    // if (RolePermissionService.isIzendaReportsNotAllowed()) {
    //   let indexToRemove = oRET.findIndex(x => x.id === izendaReportingId);
    //   DataService.removeFromArray(oRET, indexToRemove);
    // }

    // // - verify access to report template subscriptions
    // if (RolePermissionService.isTenantReportSubscriptionsNotAllowed()) {
    //   let indexToRemove = oRET.findIndex(x => x.id === reportSubscriptionId);
    //   DataService.removeFromArray(oRET, indexToRemove);
    // }

    // return
    return oRET;
  }
}
