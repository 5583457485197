import React, { Component } from "react";
import { ResultStatus } from "../../shared/types/enums";
import { AuthContext } from "../../shared/store/authProvider";
import { SubscriptionArray } from "../../shared/services/dataService";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css";

import PageHeaderComponent from "../../shared/components/page/pageHeaderComponent";
import PageLoadingComponent from "../../shared/components/page/pageLoadingComponent";
import PageErrorComponent from "../../shared/components/page/pageErrorComponent";
import { withStyles, Dialog } from "@material-ui/core";
import { MatClassService } from "../../shared/services/theme/matClassService";
import { CrudAction } from "../../shared/types/enums";
import AgreementListService from "./agreementListService";
import LayoutService from "../../shared/services/layoutService";
import AgreementDetailCellRendererComponent from "./agreementDetailCellRendererComponent";
import AgreementDetailDialogComponent from "./agreement-detail/agreementDetailDialogComponent";
import ToastService from "../../shared/services/toastService";
import RolePermissionService from "../../shared/role-permissions/rolePermissionService";
import PageDynamicHeaderComponent from "../../shared/components/page/pageDynamicHeaderComponent";
import { MatIconService } from "../../shared/services/theme/matIconService";

class AgreementListComponent extends Component {

  static contextType = AuthContext;
  oSubscriptions = new SubscriptionArray();
  STORE = AgreementListService;

  constructor(props) {
    super(props);
    this.fetchData.bind(this);

    this.state = {
      searchText: "",
      rowData: [],
      frameworkComponents: {
        agreementDetailCellRendererComponent: AgreementDetailCellRendererComponent
      },
      fetchResult: ResultStatus.NOT_LOADED,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = (_ignoreCache = true) => {
    this.oSubscriptions.cancelAll();
    this.setState({ fetchResult: ResultStatus.LOADING, rowData: [] });
    // save the subscription object
    this.oSubscriptions.add(
      this.STORE.getObs(_ignoreCache, this.context).subscribe(
        (o) => {
          // onResultStatus.SUCCESS
          if (!Array.isArray(o)) { o = new Array(o); }
          o.sort(this.compareFn);
          this.STORE.CACHE.SET(o, 0);
          this.setState({ rowData: o, fetchResult: ResultStatus.SUCCESS });
        },
        (o) => {
          ToastService.showError("Error Occured.");
          this.setState({ fetchResult: ResultStatus.ERROR });
        }
      )
    );
  };

  onGridReady = (params) => {
    var defaultSortModel = [{colId: 'reportIdentifierId', sort: 'asc', sortIndex : 0},];
    this.gridApi = params.api;
    params.columnApi.applyColumnState({state: defaultSortModel});
    //this.gridApi.setDomLayout("autoHeight");
    this.gridApi.sizeColumnsToFit();
  };

  methodFromParent = (row_col, node) => {
    this.setState({ modalAgNode: node });
    //if (this.state.agGridUtils.isNotEditing()) {
    this.setState({ showAgreementDetailDialog: true, inputAction: CrudAction.UPDATE });
    //}
  };

  onAddClick = () => { this.setState({ showAgreementDetailDialog: true, inputAction: CrudAction.CREATE }); }

  handleSearch = (e) => {
    const newValue = e.target.value;
    this.setState({ searchText: newValue });
    this.gridApi.setQuickFilter(newValue);
  }


  render() {
    const { classes } = this.props;
    if (RolePermissionService.AGREEMENTS.cannotView) {
      return RolePermissionService.getAccessDeniedComponent(classes);
    } else {
      switch (this.state.fetchResult) {
        case ResultStatus.NOT_LOADED:
        case ResultStatus.LOADING:
          return (<PageLoadingComponent classes={classes} label="Loading Agreements" />);
        case ResultStatus.SUCCESS:
          return (
            <div id="MainRoleGrid">
              <div style={{ backgroundColor: "#ddd", textAlign: "right" }}></div>

              <div className="IconBg marginTop10px tableTitleHead">
                {/* Header Componenet */}
                {<PageDynamicHeaderComponent classes={classes} label="Agreements" rightPadding={16}
                  leftActions={[
                    { icon: MatIconService.RELOAD, title: "Reload", iconColor: "secondary", onClick: () => { this.fetchData(true); }, },
                  ]}
                  rightActions={[
                    { jsxElement: LayoutService.getSearchText(classes, "search", this.state.searchText, this.handleSearch) },
                    { icon: MatIconService.ADD_CIRCLE_OUTLINE, title: "Add New", iconColor: "secondary", onClick: this.onAddClick, isReadOnly: RolePermissionService.AGREEMENT_DETAIL.cannotCreate },
                  ]}
                />}
              </div>

              <div {...LayoutService.getAgGridStyles()}>
                <AgGridReact
                  columnDefs={this.STORE.getColumnDefs(this)}
                  rowData={this.state.rowData}
                  pagination={true}
                  paginationPageSize={100}
                  frameworkComponents={this.state.frameworkComponents}
                  gridOptions={{
                    headerHeight: 70,
                    tooltipShowDelay: 0,
                    context: { componentParent: this },
                    defaultColDef: {sortable: true}
                  }}
                  onGridReady={this.onGridReady}
                ></AgGridReact>
              </div>

              {/* AgreementDetailDialogComponent */}
              {/* <Dialog open={this.state.showAgreementDetailDialog || false} {...LayoutService.getContainedDialogProps()}> */}
              {this.state.showAgreementDetailDialog ?
                <AgreementDetailDialogComponent inputAction={this.state.inputAction} modalAgNode={this.state.modalAgNode}
                  standAloneProps={LayoutService.getContainedDialogProps(false)}
                  open={this.state.showAgreementDetailDialog || false}
                  onClose={(_reloadRequired) => {
                    this.setState({ showAgreementDetailDialog: false });
                    if (_reloadRequired) { this.fetchData(true); }
                  }}
                /> : null}
              {/* </Dialog> */}

            </div>
          );
        case ResultStatus.ERROR:
        default:
          return (
            <PageErrorComponent label="Error Loading Agreements" classes={classes} onRetry={() => { this.fetchData(true); }} />
          );
      }
    }
  }
}
export default withStyles(MatClassService)(AgreementListComponent);