import React, { Component } from "react";
import { Dialog } from "@material-ui/core";
import { ResultStatus, CrudAction, API_ENDPOINT } from "../../../shared/types/enums";
import { AuthContext } from "../../../shared/store/authProvider";
import { SubscriptionArray } from "../../../shared/services/dataService";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css";

import PageHeaderComponent from "../../../shared/components/page/pageHeaderComponent";
import PageLoadingComponent from "../../../shared/components/page/pageLoadingComponent";
import PageErrorComponent from "../../../shared/components/page/pageErrorComponent";
import { withStyles } from "@material-ui/core";
import { MatClassService } from "../../../shared/services/theme/matClassService";
import DocumentListService from "./documentListService";
import ChildMessageRendererComponent from "../../../shared/components/childMessageRendererComponent";
import AgGridDownloadFileViaImageCellRenderer from "../../../shared/components/ag-grid/agGridDownloadFileViaImageCellRenderer";
import ToastService from "../../../shared/services/toastService";
import DocumentDetailsComponent from "../document-details/documentDetailsComponent";
import LayoutService from "../../../shared/services/layoutService";
import RolePermissionService from "../../../shared/role-permissions/rolePermissionService";
import PageDynamicHeaderComponent from "../../../shared/components/page/pageDynamicHeaderComponent";
import { MatIconService } from "../../../shared/services/theme/matIconService";
import OktaAuthService from "../../../public/authentication/oktaAuthService";

class DocumentListComponent extends Component {
  static contextType = AuthContext;
  oSubscriptions = new SubscriptionArray();
  STORE = DocumentListService;
  constructor(props) {
    super(props);
    this.fetchData.bind(this);
    this.handleHide = this.handleHide.bind(this);

    this.state = {
      rowData: [],
      fetchResult: ResultStatus.NOT_LOADED,
      frameworkComponents: {
        childMessageRendererComponent: ChildMessageRendererComponent,
        agGridDownloadFileViaImageCellRenderer: AgGridDownloadFileViaImageCellRenderer,
      },
      modalShow: false,
      modalAgNode: null,
      inputAction: CrudAction.NONE,
    };
  }
  componentDidMount() {
    this.fetchData();
    this.checkPermissionForCreateDocument();
  }
  componentWillUnmount() {
    this.oSubscriptions.cancelAll();
  }

  checkPermissionForCreateDocument = () => {
    if (this.props.documentType === "SystemDocuments") {
      this.setState({
        canNotCreate: RolePermissionService.SYSTEM_DOCUMENTS_DETAILS.cannotCreate,
        canNotDownload: RolePermissionService.DOWNLOAD_SYSTEM_DOCUMENTS.cannotView
      });
    } else if (this.props.documentType === "ComplianceDocuments") {
      this.setState({
        canNotCreate: RolePermissionService.COMPLIANCE_DOCUMENTS_DETAILS.cannotCreate,
        canNotDownload: RolePermissionService.DOWNLOAD_COMPLIANCE_DOCUMENTS.cannotView
      });
    } else {
      this.setState({
        canNotCreate: RolePermissionService.CUSTOMER_DOCUMENTS_DETAILS.cannotCreate,
        canNotDownload: RolePermissionService.DOWNLOAD_CUSTOMER_DOCUMENTS.cannotView
      });
    }
  }
  fetchData = (_ignoreCache = true) => {
    this.oSubscriptions.cancelAll();
    this.setState({ fetchResult: ResultStatus.LOADING, rowData: [] });
    // save the subscription object
    this.oSubscriptions.add(
      this.STORE.getObs(
        _ignoreCache,
        this.context,
        this.props.documentType
      ).subscribe(
        (o) => {
          // onResultStatus.SUCCESS
          if (!Array.isArray(o)) {
            o = new Array(o);
          }
          o.sort(this.compareFn);
          this.STORE.CACHE.SET(o, 0);
          this.setState({
            rowData: o,
            fetchResult: ResultStatus.SUCCESS,
          });
        },
        (o) => {
          console.log("Error:", o);

          this.setState({
            fetchResult: ResultStatus.ERROR,
          });
        }
      )
    );
  };
  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
    console.log("fit");
  };
  methodFromParent = (cell, node) => {
    this.setState({ modalShow: true });
    this.setState({ inputAction: CrudAction.UPDATE });
    this.setState({ modalAgNode: node });
  };

  handleDownloadAg = async (
    cell,
    agNodeData,
    filename,
    linkRefAg,
    windowUrl
  ) => {
    //ToastService.showInfo("Under Construction");
    if (this.state.canNotDownload) {
      this.setState({ accessDenied: this.state.canNotDownload });
      return;
    } else {
      const result = await fetch(
        `${process.env.REACT_APP_CORE_HTTP}/Document/DownloadDocument/${this.context.user.tenantId}?filename=${agNodeData.blobName}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ` + OktaAuthService.getAccessToken(),
          },
        }
      );
      const blob = await result.blob();
      return blob;
    }

  };

  handleCreateModal = () => {
    this.setState({ modalShow: true });
    this.setState({ inputAction: CrudAction.CREATE });
    this.setState({ modalAgNode: { srId: 0 } });
  };

  handleHide() {
    this.setState({ modalShow: false });
  }
  render() {
    const { classes } = this.props;

    switch (this.state.fetchResult) {
      case ResultStatus.NOT_LOADED:
      case ResultStatus.LOADING:
        return <PageLoadingComponent classes={classes} label="Loading" />;
      case ResultStatus.SUCCESS:
        return (
          <div id="MainRoleGrid">
            <div style={{ backgroundColor: "#ddd", textAlign: "right" }}></div>
            <div className="IconBg marginTop10px tableTitleHead">
              {<PageDynamicHeaderComponent classes={classes} label="" rightPadding={16}
                leftActions={[]}
                rightActions={[
                  { icon: MatIconService.ADD_CIRCLE_OUTLINE, title: "Add New", iconColor: "secondary", onClick: this.handleCreateModal, isReadOnly: this.state.canNotCreate },
                  { icon: MatIconService.RELOAD, title: "Reload", iconColor: "secondary", onClick: () => { this.fetchData(true); }, },
                ]}
              />}
            </div>
            <div {...LayoutService.getAgGridStyles()}>
              <AgGridReact
                columnDefs={this.STORE.getColumnDefs(this)}
                rowData={this.state.rowData}
                pagination={true}
                paginationPageSize={50}
                frameworkComponents={this.state.frameworkComponents}
                gridOptions={{
                  tooltipShowDelay: 0,
                  context: { componentParent: this },
                }}
                onGridReady={this.onGridReady}
              ></AgGridReact>
            </div>
            <Dialog
              open={this.state.modalShow || false}
              scroll={true ? "paper" : "body"}
              maxWidth="md"
            >
              <DocumentDetailsComponent
                onClose={this.handleHide}
                modalAgNode={this.state.modalAgNode}
                inputAction={this.state.inputAction}
                refreshGrid={this.fetchData}
                documentType={this.props.documentType}
              />
            </Dialog>
            <Dialog open={this.state.accessDenied}>
              {RolePermissionService.getAccessDeniedComponent(classes, () => { this.setState({ accessDenied: false }) })};
            </Dialog>
          </div>
        );
      case ResultStatus.ERROR:
      default:
        return (
          <PageErrorComponent
            label="Error Loading Document Details"
            classes={classes}
            onRetry={() => {
              this.fetchData(true);
            }}
          />
        );
    }
  }
}

export default withStyles(MatClassService)(DocumentListComponent);
