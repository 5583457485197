import React from "react";
import { of, combineLatest } from "rxjs";
import { Formik } from "formik";
import * as Yup from "yup";
import { Box, DialogTitle, DialogContent, Typography, AppBar, Toolbar, IconButton, TextField, Grid, FormGroup } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { AuthContext } from "../../../shared/store/authProvider";
import { ResultStatus, CrudAction, API_ENDPOINT, } from "../../../shared/types/enums";
import { SubscriptionArray, DataService } from "../../../shared/services/dataService";
import LayoutService from "../../../shared/services/layoutService";
import LookupService from "../../../shared/services/lookupService";
import PageLoadingComponent from "../../../shared/components/page/pageLoadingComponent";
import DialogErrorFragmentComponent from "../../../shared/components/page/dialogErrorFragmentComponent";
import ApiService from "../../../shared/services/apiService";
import WorkflowDetailsService from "./workflowDetailsService";

class WorkflowDetailsComponent extends React.Component {
  static contextType = AuthContext;
  oSubscriptions = new SubscriptionArray();

  // vendor name validations
  lastValidatedVendorName = null;
  lastValidatedVendorResult = false;

  constructor(props) {
    super(props);
    // init state
    this.state = {
      isReadOnly: this.props.inputAction === CrudAction.UPDATE, // default state
      isEditing: this.props.inputAction === CrudAction.UPDATE,
      fetchResult: ResultStatus.NOT_LOADED
    };
  }

  componentWillUnmount() { this.oSubscriptions.cancelAll(); }

  componentDidMount() {
    this.setState({ fetchResult: ResultStatus.LOADING });

    /**
     * rxjs <combineLatest> operator takes multiple obs as argument and emits their result in the same order
     * this operator is very handy as we don't have to independently wait for each result
     */
    this.oSubscriptions.add(
      combineLatest([
        (this.props.inputAction === CrudAction.CREATE ? of(null) : WorkflowDetailsService.getWorkflowDetailsAsOBS(this.context.user.tenantId, this.props.modalAgNode.workflowTemplateId)),
        WorkflowDetailsService.getAllUsersAsOBS(this.context.user.tenantId),
        LookupService.getFormattedCountriesAsOBS(this.context, -1),
        WorkflowDetailsService.GetFieldLOVByIds(this.context.user.tenantId, 283),
      ]).subscribe(([_workflowTemplateData, _userList, _countryList, _approvalOrderList]) => {
        this.setState(
          {
            workflowTemplateData: _workflowTemplateData,
            userList: _userList.map(v => ({ ...v, fullName: `${v.firstName} ${v.lastName}` })).filter(f => f.isActive === true).slice().sort((a,b) => (a.firstName > b.firstName) ? 1 : ((b.firstName > a.firstName) ? -1 : 0)),
            countryList: _countryList,
            approverList: _workflowTemplateData ? _workflowTemplateData.approvers.split(",") : [],
            selApproversList: _workflowTemplateData ? this.getApproversList(_userList, _workflowTemplateData.approvers.split(",")) : [],
            approvalOrderList: _approvalOrderList,
          },
          () => {
            // change the state after all the above are assigned
            this.setState({ fetchResult: ResultStatus.LOADED });
          }
        );
      }
      )
    );
  }

  getApproversList = (allApprovers, selApprovers) => {
    const allFullName = allApprovers.map(v => ({ ...v, fullName: `${v.firstName} ${v.lastName}` }));
    const selFullName = selApprovers.map(s => ({ id: s, fullName: allFullName.find(a => Number(a.id) === Number(s)).fullName }));
    return (selFullName);
  };

  getInitialValues() {
    if (this.props.inputAction === CrudAction.CREATE) {
      return {
        workflowTemplateName: "",
        workflowName: "",
        selApproversList: [],
        approvalOrder: "",
        request: "",
        endOnFirstApproval: false,
        endOnFirstRejection: false,
        jurisdiction: 0,
      };
    } else {
      return {
        workflowTemplateName: this.state.workflowTemplateData.workflowTemplateName,
        workflowName: this.state.workflowTemplateData.workflowName,
        approvalOrder: this.state.workflowTemplateData.approvalOrder,
        selApproversList: this.state.selApproversList,
        request: this.state.workflowTemplateData.request,
        endOnFirstApproval: this.state.workflowTemplateData.endOnFirstApproval,
        endOnFirstRejection: this.state.workflowTemplateData.endOnFirstRejection,
        jurisdiction: this.state.workflowTemplateData.jurisdiction,
      };
    }
  }

  validationSchema = Yup.object().shape({});
  getValidationSchema() {
    this.validationSchema = Yup.object().shape({
      workflowTemplateName: Yup.string().required("Required").min(3, "Must be at least 3 characters long").max(256, "Must be 256 characters or less"),
      workflowName: Yup.string().required("Required").min(3, "Must be at least 3 characters long").max(256, "Must be 256 characters or less"),
      approvalOrder: Yup.number().required("Required").typeError("Required"),
      selApproversList: DataService.hasElements(this.state.userList) ? Yup.array().required("Required") : Yup.array(),
      request: Yup.string().min(3, "Must be at least 3 characters long").max(256, "Must be 256 characters or less"),
      jurisdiction: Yup.number().typeError("Must be a number"),
    });
    return this.validationSchema;
  }

  handleSubmit = async (_formikProps) => {
    if (!_formikProps.isSubmitting && _formikProps.isValid) {
      await this.validationSchema.validate(_formikProps.values, { abortEarly: false })
        .then((x) => {
          // 1) set the status
          this.setState({ fetchResult: ResultStatus.SAVING });

          // 2) extract the formik values into an object
          var mappedObj = {
            workflowTemplateName: _formikProps.values.workflowTemplateName,
            workflowName: _formikProps.values.workflowName,
            approvalOrder: _formikProps.values.approvalOrder,
            approversCommaSeparatedList: _formikProps.values.selApproversList.map(({ id }) => id).join(","),
            daysPerTask: 0,
            dueDate: "2020-10-30T17:19:04.003Z",
            request: _formikProps.values.request,
            endOnFirstApproval: _formikProps.values.endOnFirstApproval,
            endOnFirstRejection: _formikProps.values.endOnFirstRejection,
            jurisdiction: String(_formikProps.values.jurisdiction),
            jurisdictionName: _formikProps.values.jurisdiction === -1 ? "All" : this.state.countryList[_formikProps.values.jurisdiction].value,
            searchText: "",
            tenantId: String(this.context.user.tenantId),
            userId: String(this.context.user.userId),
            sourcePorzioGSTId: "",
            sourceEntityId: 0,
            porzioWorkflowGSTId: "",
            tenantUID: "",
            firstName: "",
            lastName: "",
            middleName: "",
            rowsCount: 0,
          };

          // 3) determine the action and assign the appropriate props
          const actionVerb = "POST";
          if (this.props.inputAction === CrudAction.CREATE) {
            // CREATE
            mappedObj.workflowTemplateId = 0;
            mappedObj.workflowId = 0;
          } else {
            // UPDATE
            mappedObj.workflowTemplateId = this.props.modalAgNode.workflowTemplateId;
            mappedObj.workflowId = this.props.modalAgNode.workflowId;
          }

          // 4) save to Api and subscribe for the result
          ApiService.setOBS(actionVerb, API_ENDPOINT.TENANT, `/Workflow/SaveWorkflowTemplate/${this.context.user.tenantId}`, JSON.stringify(mappedObj)).subscribe(
            (successResult) => {
              if (successResult) {
                this.setState({ isReadOnly: true, fetchResult: ResultStatus.SUCCESS });
                this.props.refreshVendorList(true);
                this.props.onClose(false);
              } else {
                this.setState({ fetchResult: ResultStatus.ERROR });
              }
            },
            (errorResult) => {
              console.error("Error while saving vendor details", errorResult);
              this.setState({ fetchResult: ResultStatus.ERROR });
            }
          );
        })
        .catch((erroObj) => {
          console.log(erroObj);
          if (erroObj.inner) {
            erroObj.inner.forEach(err => { _formikProps.setFieldError(err.path, err.message); });
          }
        });
    }
  }

  render() {
    const { classes } = this.props;
    const pageName = "Workflow Templates Detail";

    switch (this.state.fetchResult) {
      case ResultStatus.NOT_LOADED:
      case ResultStatus.LOADING: return (<PageLoadingComponent small classes={classes} label={`Loading ${pageName}`} />);
      case ResultStatus.SAVING: return (<PageLoadingComponent small classes={classes} label={`Saving ${pageName}`} />);
      case ResultStatus.LOADED:
      case ResultStatus.SUCCESS:
        return (
          <>
            <Formik initialValues={this.getInitialValues()} validationSchema={this.getValidationSchema()} validationSchemaOptions={{ showMultipleFieldErrors: true }}>
              {(fProps) => (
                <form>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    {/* Dialog Title */}
                    <DialogTitle disableTypography id="dialogTitle">
                      <AppBar position="static">
                        <Toolbar>
                          <Typography variant="h6" className={classes.root}>{pageName}</Typography>
                          {LayoutService.getReadOnlyActions(this, !this.state.isEditing, () => { this.props.onClose(false) }, () => { this.handleSubmit(fProps) })}
                        </Toolbar>
                      </AppBar>
                    </DialogTitle>
                    {/* Dialog Content */}
                    <DialogContent>
                      <Box style={{ paddingLeft: 16, paddingRight: 32, paddingTop: 16, paddingBottom: 32, minWidth: "80vh", maxWidth: "80vh", }}>
                        {this.state.fetchResult === ResultStatus.SUCCESS ? (<h1>Your Vendor has been updated!!</h1>) : null}
                        <Grid container spacing={1}>
                          <Grid item xs={12} sm={6}> <TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "workflowTemplateName", "Workflow Template Name")} fullWidth /> </Grid>
                          <Grid item xs={12} sm={6}> <TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "workflowName", "Workflow Name")} fullWidth />{" "}</Grid>
                          <Grid item xs={12} sm={6}>
                            {LayoutService.getChipSelect(this.state.isReadOnly,
                              classes,
                              fProps,
                              "selApproversList",
                              "Approvers",
                              this.state.userList,
                              "id",
                              "fullName",
                              (_formikProps, _newValues) => {
                                this._clearOthersIfAllIsSelected(
                                  _newValues,
                                  _formikProps,
                                  "selApproversList"
                                );
                              },
                              "80%"
                            )}
                          </Grid>
                          {/* <Grid item xs={12} sm={6}> {LayoutService.getSelectControl(this.state.isReadOnly, classes, fProps, "approvalOrder", "Approval Order", [{ id: 0, name: "All At Once (Parallel)" }, { id: 1, name: "One At A Time (Serial)" }], "id", "name", "98%")}</Grid> */}
                          <Grid item xs={12} sm={6}> {LayoutService.getSelectControl(this.state.isReadOnly, classes, fProps, "approvalOrder", "Approval Order", this.state.approvalOrderList, "lovId", "lovKey", "98%")}</Grid>
                          <Grid item xs={12} sm={12}><TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "request", "Request", false)} fullWidth multiline rows={3} /></Grid>
                          <Grid item xs={12} sm={6}> <FormGroup style={{ paddingLeft: "4px", paddingTop: "8px" }}>{LayoutService.getSwitch(this.state.isReadOnly, classes, fProps, "endOnFirstApproval", "End on First Approval")}</FormGroup></Grid>
                          <Grid item xs={12} sm={6}> <FormGroup style={{ paddingLeft: "4px", paddingTop: "8px" }}>{LayoutService.getSwitch(this.state.isReadOnly, classes, fProps, "endOnFirstRejection", "End on First Rejection")}</FormGroup></Grid>
                          <Grid item xs={12} sm={6}> {LayoutService.getSelectControl(this.state.isReadOnly, classes, fProps, "jurisdiction", "Jurisdiction", this.state.countryList, "id", "value", "98%")}</Grid>
                        </Grid>
                      </Box>
                    </DialogContent>
                  </MuiPickersUtilsProvider>
                </form>
              )}
            </Formik>
          </>
        );
      case ResultStatus.ERROR:
      default:
        return (
          <DialogErrorFragmentComponent title="Error" description={`Error in ${pageName}`}
            classes={classes} onClose={() => { this.props.onClose(false); }} onRetry={() => { console.log("Retry Clicked"); }} />
        );
    }
  }

  _clearOthersIfAllIsSelected = async (_newValues, _formikProps, _propName) => {
    // check for <All> Item
    const itemAll = DataService.getFirstOrDefault(
      _newValues.filter((x) => x.id === LookupService.allElement.id)
    );
    // if <all> is selected and is not the only selected item, then clear others
    if (itemAll && _newValues.length !== 1) {
      // then clear the others except All
      await _formikProps.setFieldValue(_propName, [itemAll], true);
      this.setState({ [_propName]: [itemAll] });
    }
  };

}



/** HOC */
export default LayoutService.getHocComponenet(WorkflowDetailsComponent);