import React, { Component } from "react";
import { CircleComponent } from "../circleComponent/circleComponent.jsx"

export default class healthIndicatorCellRendererComponent extends Component {
    STYLES = [
        "circle-error",
        "circle-warning",
        "circle-unmatched",
        "circle-approval"
    ]
    render() {
        return (
            <CircleComponent value={""} circleStyle={this.STYLES[this.props.value - 1 < this.STYLES.length ? this.props.value - 1 : 0]} />
        );
    }
}
