import { AgGridColumnExt } from "../../../../../shared/services/ag-grid/agGridColumnExt";
import ApiService from "../../../../../shared/services/apiService"
import { API_ENDPOINT } from "../../../../../shared/types/enums"

export default class TransactionSearchDialogService {

    static searchTransaction = (_tenantId, _searchValue = null) => {
        return ApiService.setOBS("POST", API_ENDPOINT.CORE, `/Transactions/FetchTransactionsSearchValue/${_tenantId}`, JSON.stringify({
            searchValue: _searchValue,
            porzioGSTId: null,
            firstName: null,
            lastName: null,
            organizationName: null,
            companyId: null,
            country: null
        }));
    }

    static getColumnDefs = (_componentThisRef, _onSelectCallback) => {
        return [
            {
                headerName: "Select",
                field: "isSelected",
                editable: true,
                width: 176,
                cellStyle: { "border-right": "1px solid lightgray" },
                cellRenderer: "isSelectedCellRenderer",
                cellRendererParams: {
                    onChange: (_cellRef, _data) => { _onSelectCallback(_data); }
                }
            },
            AgGridColumnExt.GET(false, "set", "sourceName", "Source").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "porzioGstTransactionId", "Porzio GST Transaction ID").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "transactionDate", "Transaction Date").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "set", "transactionStatus", "Transaction Status").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "lastName", "Last Name").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "firstName", "First Name").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "organizationName", "Organization Name").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "set", "country", "Country").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "number", "totalAmount", "Converted Total Amount").flexWeight(1).headerClass("center-text").centerAlignText().SET()
        ];
    }
}