import ApiService from "../../../shared/services/apiService"
import { API_ENDPOINT } from "../../../shared/types/enums"
import { AgGridColumnExt } from "../../../shared/services/ag-grid/agGridColumnExt";

export default class AgreementSearchService {

    static searchOBS = (_tenantId, _searchValue = "") => {
        return ApiService.setOBS("POST", API_ENDPOINT.CORE, `/Agreement/SearchAgreements/${_tenantId}`, JSON.stringify({
            searchValue: _searchValue,
            porzioGSTId: "",
            firstName: "",
            lastName: "",
            organizationName: "",
            companyId: "",
            country: "",
            isAdvance: false
        }));
    }

    static getColumnDefs = (_componentThisRef, _onSelectCallback) => {
        return [
            AgGridColumnExt.GET(false, "text", "porzioGstProfileId", "Porzio GST Profile ID").flexWeight(1).minWidth(136).headerClass("center-text").centerAlignText().cellRenderer("profileDetailCellRendererComponent").SET(),
            AgGridColumnExt.GET(false, "text", "porzioGstAgreementId", "Porzio GST Agreement ID").flexWeight(1).minWidth(136).headerClass("center-text").centerAlignText().cellRenderer("agreementDetailCellRendererComponent").SET(),
            AgGridColumnExt.GET(false, "text", "companyAgreementId", "Company Agreement ID").flexWeight(1).minWidth(136).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "fileName", "File Name").flexWeight(1).minWidth(136).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "set", "sourceName", "Source").flexWeight(1).minWidth(136).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "lastName", "Last Name").flexWeight(1).minWidth(136).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "firstName", "First Name").flexWeight(1).minWidth(136).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "middleName", "Middle Name").flexWeight(1).minWidth(136).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "organizationName", "Organization Name").flexWeight(1).minWidth(136).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, true, "country", "Country").flexWeight(1).minWidth(136).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, true, "startDate", "Start Date").flexWeight(1).minWidth(136).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, true, "endDate", "End Date").flexWeight(1).minWidth(136).headerClass("center-text").centerAlignText().SET(),
        ];
    }
}