import React, { Component } from "react";
import {
    withStyles,
    DialogTitle,
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    DialogContent,
    Box,
    Grid,
} from "@material-ui/core";
import { AuthContext } from "../../../shared/store/authProvider";
import PageLoadingComponent from "../../../shared/components/page/pageLoadingComponent";
import PageErrorComponent from "../../../shared/components/page/pageErrorComponent";
import { SubscriptionArray } from "../../../shared/services/dataService";
import { ResultStatus } from "../../../shared/types/enums";
import { MatClassService } from "../../../shared/services/theme/matClassService";
import { MatIconService } from "../../../shared/services/theme/matIconService";
import FileSummaryService from "../file-summary/fileSummaryService";
import porzio_gst_icon from "../../../public/images/porzio_global_spend_transparency_logo.png";
import RolePermissionService from "../../../shared/role-permissions/rolePermissionService";

const tableColPadStyle = {
    paddingLeft: "10px",
    paddingRight: "10px"
}
class FileSummaryComponent extends Component {
    static contextType = AuthContext;

    oSubscriptions = new SubscriptionArray();
    STORE = FileSummaryService;
    state = {
        data: {},
        fetchResult: ResultStatus.NOT_LOADED,
    };

    constructor(props) {
        super(props);
        this.fetchData.bind(this);
    }

    componentWillUnmount() {
        this.oSubscriptions.cancelAll();
    }
    componentDidMount() {
        console.log("inside did mount ", this.props.modalAgNode.fileId);
        this.fetchData();
    }

    /** API Fetch */
    fetchData = (_ignoreCache = false) => {
        this.oSubscriptions.cancelAll();
        this.setState({ fetchResult: ResultStatus.LOADING, data: [] });
        // save the subscription object
        this.oSubscriptions.add(
            this.STORE.getObs(
                _ignoreCache,
                this.props.modalAgNode,
                this.context
            ).subscribe(
                (o) => {
                    this.setState({
                        data: o,
                        fetchResult: ResultStatus.SUCCESS,
                    });
                },
                (o) => {
                    console.log("Error:", o);
                    // onResultStatus.ERROR
                    this.setState({
                        fetchResult: ResultStatus.ERROR,
                    });
                }
            )
        );
    };



    renderFileSummary = () => {
        return <>
            <tr>
                <th style={{ paddingLeft: "10px", paddingRight: "10px" }}>Tenant Name</th>
                <td style={{ textAlign: "center" }}>{this.state.data.summary[0]["TENANTNAME"]}</td>
                <th style={{ paddingLeft: "10px", paddingRight: "10px" }}>Number of Records</th>
                <td style={{ textAlign: "center", width: "100px" }}>{this.state.data.summary[0]["Number of Records"]}</td>
            </tr>
            <tr>
                <th style={{ paddingLeft: "10px", paddingRight: "10px" }}>Data Type</th>
                <td style={{ textAlign: "center" }}>{this.state.data.summary[0]["Type"]}</td>
                <th style={{ paddingLeft: "10px", paddingRight: "10px" }}>Records with Errors</th>
                <td style={{ textAlign: "center" }}>{this.state.data.summary[0]["ERROCOUNT"]}</td>
            </tr>
            <tr>
                <th style={{ paddingLeft: "10px", paddingRight: "10px" }}>Import Status</th>
                <td style={{ textAlign: "center" }}>{this.state.data.summary[0]["Import Status"]}</td>
                <th style={{ paddingLeft: "10px", paddingRight: "10px" }}>Records with Warnings</th>
                <td style={{ textAlign: "center" }}>{this.state.data.summary[0]["Warningcount"]}</td>
            </tr>
            <tr>
                <th style={{ paddingLeft: "10px", paddingRight: "10px" }}>Source Name</th>
                <td style={{ textAlign: "center" }}>{this.state.data.summary[0]["Source Name"]}</td>
                <th style={{ paddingLeft: "10px", paddingRight: "10px" }}>Number of Matched Records to Profile Database</th>
                <td style={{ textAlign: "center" }}>{this.state.data.summary[0]["Matchedcount"]}</td>
            </tr>
            <tr>
                <th style={{ paddingLeft: "10px", paddingRight: "10px" }}>Customer Source System</th>
                <td style={{ textAlign: "center" }}>{this.state.data.summary[0]["Customer Source System"]}</td>
                <th style={{ paddingLeft: "10px", paddingRight: "10px" }}>Number of Unmatched Records to Profile Database</th>
                <td style={{ textAlign: "center" }}>{this.state.data.summary[0]["Unmatchedcount"]}</td>
            </tr>
            <tr>
                <th style={{ paddingLeft: "10px", paddingRight: "10px" }}>Received Date</th>
                <td style={{ textAlign: "center" }}>{this.state.data.summary[0]["Received Date"]}</td>
                <th style={{ paddingLeft: "10px", paddingRight: "10px" }}>Expense Count</th>
                <td style={{ textAlign: "center" }}>{this.state.data.summary[0]["Expensecount"]}</td>
            </tr>
            <tr>
                <th style={{ paddingLeft: "10px", paddingRight: "10px" }}>Transaction Date Range</th>
                <td style={{ textAlign: "center" }}>{this.state.data.summary[0]["TransactionDateRange"]}</td>
                <th style={{ paddingLeft: "10px", paddingRight: "10px" }}></th>
                <td style={{ textAlign: "center" }}></td>
            </tr>
        </>
    }

    render() {
        const { classes } = this.props;
        if (RolePermissionService.IMPORT_SUMMARY_REPORT.cannotView) {
            return RolePermissionService.getAccessDeniedComponent(classes, () => { this.props.onClose() }); // this is required to prevent Url navigation
        } else {
            switch (this.state.fetchResult) {
                case ResultStatus.NOT_LOADED:
                case ResultStatus.LOADING:
                    return (
                        <PageLoadingComponent
                            small
                            classes={classes}
                            label="Loading File Status"
                        />
                    );
                case ResultStatus.SUCCESS:
                    return (
                        <React.Fragment>
                            <DialogTitle disableTypography id="dialogTitle">
                                <AppBar position="static">
                                    <Toolbar>
                                        <Typography variant="h6" className={classes.root}>Import Summary Report</Typography>
                                        <IconButton color="secondary"
                                            onClick={() => {
                                                this.props.onClose(false);
                                            }}>
                                            {MatIconService.CANCEL}
                                        </IconButton>
                                    </Toolbar>
                                </AppBar>
                            </DialogTitle>
                            <DialogContent>
                                <Box
                                    style={{
                                        paddingLeft: 16, paddingRight: 32,
                                        paddingTop: 8, paddingBottom: 32,
                                        minHeight: "60vh", maxHeight: "60vh",
                                        minWidth: "130vh", maxWidth: "150vh",
                                    }}
                                >

                                    < div style={{ background: "#ffffff", textAlign: "center" }}>
                                        <img src={porzio_gst_icon} style={{ width: 200, display: "block", marginLeft: "auto", marginRight: "auto" }} alt="Porzio Global Spend Transparency Logo" />
                                        <br /><br />
                                        <Typography variant="h6" className={classes}>{this.state.data.summary[0]["TENANTNAME"]} Import Summary Report</Typography>
                                        <Typography variant="h8" className={classes}>{this.state.data.summary[0]["Received Date"]}</Typography>
                                        <br />
                                        <Typography variant="h8" className={classes}>{this.state.data.summary[0]["File Name"]}</Typography>
                                    </div>
                                    <br />
                                    <Typography variant="h4" className={classes.root} style={{ textAlign: "center" }}>File Summary</Typography>
                                    <br />
                                    <table border="2" style={{ width: "100%" }}>
                                        <tbody>
                                            {this.renderFileSummary()}
                                        </tbody>
                                    </table>
                                    <br /><br />
                                    <Typography variant="h4" classes={classes} style={{ textAlign: "center" }}>Rule Summary</Typography>
                                    <Typography variant="h6" classes={classes} >Errors</Typography>
                                    <table border="2" style={{ width: "100%" }}>
                                        <tbody>
                                            <tr >
                                                <th style={{ paddingLeft: "10px", paddingRight: "10px" }}>Field Name</th>
                                                <th style={{ paddingLeft: "10px", paddingRight: "10px" }}>Error Count</th>
                                                <th style={{ paddingLeft: "10px", paddingRight: "10px" }}>Provided Value</th>
                                                <th style={{ paddingLeft: "10px", paddingRight: "10px" }}>Rule</th>
                                                <th style={{ paddingLeft: "10px", paddingRight: "10px" }}>Error Language</th>
                                            </tr>
                                            {this.state.data.rulesSummaryErrors?.map(x => {
                                                if (!(x["FIELDNAME"] || x["ERRORCOUNT"] || x["PROVIDEDVALUE"] || x["RULE"] || x["ERRORLANGUAGE"])) {
                                                    return <></>
                                                }
                                                else {
                                                    return (
                                                        <tr style={{ textAlign: "center" }}>
                                                            <td style={{ textAlign: "left", paddingLeft: "10px", paddingRight: "10px" }}>{x["FIELDNAME"]}</td>
                                                            <td>{x["ERRORCOUNT"]}</td>
                                                            <td>{x["PROVIDEDVALUE"]}</td>
                                                            <td>{x["RULE"]}</td>
                                                            <td>{x["ERRORLANGUAGE"]}</td>
                                                        </tr>
                                                    )
                                                }
                                            })}
                                        </tbody>
                                    </table>
                                    <br />
                                    <Typography variant="h6" classes={classes} >Warnings</Typography>
                                    <table border="2" style={{ width: "100%" }}>
                                        <tbody>
                                            <tr >
                                                <th style={{ paddingLeft: "10px", paddingRight: "10px" }}>Field Name</th>
                                                <th style={{ paddingLeft: "10px", paddingRight: "10px" }}>Warning Count</th>
                                                <th style={{ paddingLeft: "10px", paddingRight: "10px" }}>Provided Value</th>
                                                <th style={{ paddingLeft: "10px", paddingRight: "10px" }}>Rule</th>
                                                <th style={{ paddingLeft: "10px", paddingRight: "10px" }}>Warning Language</th>
                                            </tr>
                                            {this.state.data.rulesSummaryWarnings?.map(x => {
                                                if (!(x["FIELDNAME"] || x["ERRORCOUNT"] || x["PROVIDEDVALUE"] || x["RULE"] || x["ERRORLANGUAGE"])) {
                                                    return <></>
                                                }
                                                else {
                                                    return (
                                                        <tr style={{ textAlign: "center" }}>
                                                            <td style={{ textAlign: "left", paddingLeft: "10px", paddingRight: "10px" }}>{x["FIELDNAME"]}</td>
                                                            <td>{x["ERRORCOUNT"]}</td>
                                                            <td>{x["PROVIDEDVALUE"]}</td>
                                                            <td>{x["RULE"]}</td>
                                                            <td>{x["ERRORLANGUAGE"]}</td>
                                                        </tr>
                                                    )
                                                }
                                            })}
                                        </tbody>
                                    </table>
                                    <br />
                                    <br />
                                    <Typography variant="h4" classes={classes} style={{ textAlign: "center" }}>Profile Database Matching Summary</Typography>
                                    <Typography variant="h6" classes={classes} >Profiles</Typography>
                                    <table border="2" style={{ width: "100%" }}>
                                        <tbody>
                                            <tr >
                                                <th style={{ paddingLeft: "10px", paddingRight: "10px" }}>Profile Type</th>
                                                <th style={{ paddingLeft: "10px", paddingRight: "10px" }}>Number of Profiles in File</th>
                                                <th style={{ paddingLeft: "10px", paddingRight: "10px" }}>Number of New Profiles Inserted in the Profile Database</th>
                                                <th style={{ paddingLeft: "10px", paddingRight: "10px" }}>Number of Profiles Updated in the Profile Database</th>
                                                <th style={{ paddingLeft: "10px", paddingRight: "10px" }}>Number of Profiles Unmatched to the Profile Database</th>
                                            </tr>
                                            {this.state.data.recipientUnmatchDetailsProfile?.map(x => {
                                                if (!(x["PROFILETYPE"] || x["RECORDSCOUNTINFILE"] || x["INSERTEDPROFILES"] || x["UpdatedPROFILES"] || x["UNMATCHEDProfiles"])) {
                                                    return <></>
                                                }
                                                else {
                                                    return (
                                                        <tr style={{ textAlign: "center" }}>
                                                            <td>{x["PROFILETYPE"]}</td>
                                                            <td>{x["RECORDSCOUNTINFILE"]}</td>
                                                            <td>{x["INSERTEDPROFILES"]}</td>
                                                            <td>{x["UpdatedPROFILES"]}</td>
                                                            <td>{x["UNMATCHEDProfiles"]}</td>
                                                        </tr>
                                                    )
                                                }
                                            })}
                                        </tbody>
                                    </table>
                                    <br />
                                    <Typography variant="h6" classes={classes} >Transactions</Typography>
                                    <table border="2" style={{ width: "100%" }}>
                                        <tbody>
                                            <tr >
                                                <th style={{ paddingLeft: "10px", paddingRight: "10px" }}>Profile Type</th>
                                                <th style={{ paddingLeft: "10px", paddingRight: "10px" }}>Number of Records in File</th>
                                                <th style={{ paddingLeft: "10px", paddingRight: "10px" }}>Number of Records Matched to the Profile Database</th>
                                                <th style={{ paddingLeft: "10px", paddingRight: "10px" }}>Number of Records Unmatched to the Profile Database</th>
                                            </tr>
                                            {this.state.data.recipientUnmatchDetailsTransaction?.map(x => {
                                                if (!(x["PROFILETYPE"] || x["RECORDSCOUNTINFILE"] || x["RULE"] || x["ERRORLANGUAGE"] || x["UNMATCHEDCOUNT"])) {
                                                    return <></>
                                                }
                                                else {
                                                    return (
                                                        <tr style={{ textAlign: "center" }}>
                                                            <td>{x["PROFILETYPE"]}</td>
                                                            <td>{x["RECORDSCOUNTINFILE"]}</td>
                                                            <td>{x["MATCHEDCOUNT"]}</td>
                                                            <td>{x["UNMATCHEDCOUNT"]}</td>
                                                        </tr>
                                                    )
                                                }

                                            })}
                                        </tbody>
                                    </table>
                                    <br /><br />
                                    <Typography variant="h4" classes={classes} style={{ textAlign: "center" }}>Record Type Summary</Typography>
                                    <Typography classes={classes} >This table identifies the records where the action performed during file load (I=Insert, U=Update, D=Delete) does not match Record Type in file.</Typography>
                                    <table border="2" style={{ width: "100%" }}>
                                        <tbody>
                                            <tr >
                                                <th style={{ paddingLeft: "10px", paddingRight: "10px" }}>Row ID</th>
                                                <th style={{ paddingLeft: "10px", paddingRight: "10px" }}>Company Profile ID or Company Transaction ID</th>
                                                <th style={{ paddingLeft: "10px", paddingRight: "10px" }}>Record Type in File</th>
                                                <th style={{ paddingLeft: "10px", paddingRight: "10px" }}>Action Performed</th>
                                            </tr>
                                            {this.state.data.recordTypeSummary?.map(x => {
                                                if (!(x["ROW_ID"] || x["COMPANYTRANSACTIONID"] || x["RECORDTYPEINFILE"] || x["ACTIONPERFORMED"])) {
                                                    return <></>
                                                }
                                                else {
                                                    return (
                                                        <tr style={{ textAlign: "center" }}>
                                                            <td>{x["ROW_ID"]}</td>
                                                            <td>{x["COMPANYTRANSACTIONID"]}</td>
                                                            <td>{x["RECORDTYPEINFILE"]}</td>
                                                            <td>{x["ACTIONPERFORMED"]}</td>
                                                        </tr>
                                                    )
                                                }
                                            })}
                                        </tbody>
                                    </table>
                                    <br />
                                </Box>
                            </DialogContent >
                        </React.Fragment >
                    );

                case ResultStatus.ERROR:
                default:
                    return (
                        <PageErrorComponent
                            small
                            label="Error Loading Data Count"
                            classes={classes}
                            onRetry={() => {
                                this.fetchData(true);
                            }}
                        />
                    );
            }
        }
    }
}

export default withStyles(MatClassService)(FileSummaryComponent);

/* Backup-Reusable code for table rendering
renderDynamicFileSummary = () => {
    let fileSummaryContent;
    let summaryObj = Object.keys(this.state.data.summary[0]);
    let temp = [];
    for (let i = 0; i < summaryObj.length; i += 2) {
        temp.push(
            <tr >
                <th style={{ paddingLeft: "10px", paddingRight: "10px" }}>{summaryObj[i]}</th>
                <td style={{ textAlign: "center" }}>{this.state.data.summary[0][summaryObj[i]]}</td>
                <th style={{ paddingLeft: "10px", paddingRight: "10px" }}>{summaryObj[i + 1]}</th>
                <td style={{ textAlign: "center" }}>{this.state.data.summary[0][summaryObj[i + 1]]}</td>
            </tr>
        );
    }
    fileSummaryContent = (
        <>
            {temp.map(x => {
                return x;
            })}
        </>
    )
    return fileSummaryContent;
}
*/

