import React from "react";
import { combineLatest } from "rxjs";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  Box,
  DialogTitle,
  DialogContent,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  TextField,
  Grid,
  FormGroup,
} from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { MatIconService } from "../../../shared/services/theme/matIconService";
import { AuthContext } from "../../../shared/store/authProvider";
import {
  ResultStatus,
  CrudAction,
  API_ENDPOINT,
} from "../../../shared/types/enums";
import { SubscriptionArray } from "../../../shared/services/dataService";
import LayoutService from "../../../shared/services/layoutService";
import LookupService from "../../../shared/services/lookupService";
import PageLoadingComponent from "../../../shared/components/page/pageLoadingComponent";
import DialogErrorFragmentComponent from "../../../shared/components/page/dialogErrorFragmentComponent";
import ApiService from "../../../shared/services/apiService";
import RolePermissionService from "../../../shared/role-permissions/rolePermissionService";

class MaterialDetailsComponent extends React.Component {
  static contextType = AuthContext;
  oSubscriptions = new SubscriptionArray();
  lastValidatedMaterialName = null;
  lastValidatedMaterialResult = false;

  constructor(props) {
    super(props);
    // init state
    this.state = {
      isReadOnly: this.props.inputAction === CrudAction.UPDATE ? true : false,
      isEditing: this.props.inputAction === CrudAction.UPDATE ? true : false,
      fetchResult: ResultStatus.NOT_LOADED,
    };
  }

  componentWillUnmount() {
    this.oSubscriptions.cancelAll();
  }

  componentDidMount() {
    this.setState({ fetchResult: ResultStatus.LOADING });

    /**
     * rxjs <combineLatest> operator takes multiple obs as argument and emits their result in the same order
     * this operator is very handy as we don't have to independently wait for each result
     */
    this.oSubscriptions.add(
      combineLatest([LookupService.getFormattedCurrenciesAsOBS(this.context, null)]).subscribe(
        ([_currencyList]) => {
          this.setState(
            {
              currencyList: [{ id: 0, value: "Select" }, ..._currencyList],
            },
            () => {
              // change the state after all the above are assigned
              this.setState({ fetchResult: ResultStatus.LOADED });
            }
          );
        }
      )
    );
  }

  getInitialValues() {
    return {
      materialName: this.props.modalAgNode.materialName,
      description: this.props.modalAgNode.description,
      unitCost: this.props.modalAgNode.unitCost,
      currencyId: this.props.inputAction === CrudAction.UPDATE ? this.props.modalAgNode.currencyId : 0,
      isActive:
        this.props.inputAction === CrudAction.UPDATE
          ? this.props.modalAgNode.isActive
          : true,
    };
  }

  validationSchema = Yup.object().shape({});
  getValidationSchema() {
    this.validationSchema = Yup.object().shape({
      materialName: Yup.string()
        .required("Required")
        .min(3, "Must be at least 3 characters long")
        .max(256, "Must be 256 characters or less")
        .test(
          "checkDuplicateMaterialName",
          "Material name already exists",
          async (_formikValue) => {
            if (this.lastValidatedMaterialName === _formikValue) {
              return this.lastValidatedMaterialResult;
            } else {
              this.lastValidatedMaterialName = _formikValue;
              if (
                this.state.isEditing &&
                _formikValue === this.props.modalAgNode.materialName
              ) {
                this.lastValidatedMaterialResult = true;
                return this.lastValidatedMaterialResult; // vendor name edited but okay"
              } else {
                // new vendor name (or) vendorName updated
                const result = await fetch(
                  `${process.env.REACT_APP_TENANT_HTTP}/Materials/MaterialExists/${this.context.user.tenantId}/${_formikValue}`
                );
                const isExists = await result.json();
                console.log("is material name exists: ", isExists);
                this.lastValidatedMaterialResult = !isExists;
                return this.lastValidatedMaterialResult;
              }
            }
          }
        ),
      description: Yup.string()
        .min(3, "Must be at least 3 characters long")
        .max(256, "Must be 256 characters or less"),
      unitCost: Yup.number().required("Required").typeError("Must be a valid number formatted as 0.00"),
      currencyId: Yup.number().required("Required").min(1, "Required"),
    });
    return this.validationSchema;
  }

  handleSubmit = async (_formikProps) => {
    if (!_formikProps.isSubmitting && _formikProps.isValid) {
      await this.validationSchema.validate(_formikProps.values, { abortEarly: false })
        .then((x) => {
          // 0) clear the CACHE,so next time if requried this new object will also be fetched
          LookupService.clearMATERIAL_ITEM_NAMES();

          // 1) set the status
          this.setState({ fetchResult: ResultStatus.SAVING });

          // 2) extract the formik values into an object
          var mappedObj = {
            materialName: _formikProps.values.materialName,
            description: _formikProps.values.description,
            unitCost: parseFloat(_formikProps.values.unitCost),
            currencyId: _formikProps.values.currencyId,
            isActive: _formikProps.values.isActive,
            createdBy: this.props.modalAgNode.createdBy,
            updatedBy: this.props.modalAgNode.updatedBy,
          };

          // 3) determine the action and assign the appropriate props
          let apiUrl = "";
          var actionVerb = "";
          if (this.state.isEditing) {
            // UPDATE
            actionVerb = "PUT";
            apiUrl = `/Materials/EditMaterial/${this.context.user.tenantId}`;
            mappedObj.materialId = this.props.modalAgNode.materialId;
            mappedObj.updatedBy = this.context.user.userId;
          } else {
            // CREATE
            actionVerb = "POST";
            apiUrl = `/Materials/AddMaterial/${this.context.user.tenantId}`;
            mappedObj.materialId = 0;
            mappedObj.createdBy = this.context.user.userId;
            mappedObj.updatedBy = this.context.user.userId;
          }

          // 4) save to Api and subscribe for the result
          ApiService.setOBS(
            actionVerb,
            API_ENDPOINT.TENANT,
            apiUrl,
            JSON.stringify(mappedObj)
          ).subscribe(
            (successResult) => {
              if (successResult) {
                this.setState({ fetchResult: ResultStatus.SUCCESS });
                this.props.refreshGrid(true);
                this.props.onClose(false);
              } else {
                this.setState({ fetchResult: ResultStatus.ERROR });
              }
            },
            (errorResult) => {
              console.error("Error while saving Material Details", errorResult);
              this.setState({ fetchResult: ResultStatus.ERROR });
            }
          );
        })
        .catch((erroObj) => {
          console.log(erroObj);
          if (erroObj.inner) {
            erroObj.inner.forEach(err => { _formikProps.setFieldError(err.path, err.message); });
          }
        });
    }
  }

  render() {
    const { classes } = this.props;
    if (RolePermissionService.MATERIAL_DETAIL.cannotView && this.props.inputAction === CrudAction.UPDATE) {
      return RolePermissionService.getAccessDeniedComponent(classes, () => { this.props.history.goBack() }); // this is required to prevent Url navigation
    } else {
      switch (this.state.fetchResult) {
        case ResultStatus.NOT_LOADED:
        case ResultStatus.LOADING:
          return (
            <PageLoadingComponent
              small
              classes={classes}
              label="Loading Material Details"
            />
          );
        case ResultStatus.SAVING:
          return (
            <PageLoadingComponent
              small
              classes={classes}
              label="Saving Material Details"
            />
          );
        case ResultStatus.LOADED:
        case ResultStatus.SUCCESS:
          return (
            <>
              <Formik initialValues={this.getInitialValues()} validationSchema={this.getValidationSchema()} validationSchemaOptions={{ showMultipleFieldErrors: true }}>
                {(fProps) => (
                  <form>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      {/* Dialog Title */}
                      <DialogTitle disableTypography id="dialogTitle">
                        <AppBar position="static">
                          <Toolbar variant="dense">
                            <Typography variant="h6" className={classes.root}>Material Details</Typography>
                            {this.props.inputAction === CrudAction.UPDATE ? RolePermissionService.MATERIAL_DETAIL.cannotEdit ? LayoutService.getIconButton(false, MatIconService.CLOSE, "close",
                              () => { this.props.onClose(false) }, "secondary")
                              : LayoutService.getReadOnlyActions(this, !this.state.isEditing, () => { this.props.onClose(false) }, () => { this.handleSubmit(fProps) })
                              : LayoutService.getReadOnlyActions(this, !this.state.isEditing, () => { this.props.onClose(false) }, () => { this.handleSubmit(fProps) })
                            }
                          </Toolbar>
                        </AppBar>
                      </DialogTitle>
                      {/* Dialog Content */}
                      <DialogContent>
                        <Box
                          style={{
                            paddingLeft: 16,
                            paddingRight: 32,
                            paddingTop: 16,
                            paddingBottom: 32,
                            minWidth: "80vh",
                            maxWidth: "80vh",
                          }}
                        >
                          {this.state.fetchResult === ResultStatus.SUCCESS ? (
                            <h1>Your Material has been updated!!</h1>
                          ) : null}
                          <Grid container spacing={1}>
                            <Grid item xs={12} sm={12}>
                              <TextField
                                {...LayoutService.getInputProps(this.state.isReadOnly,
                                  classes,
                                  fProps,
                                  "materialName",
                                  "Material Name"
                                )}
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <TextField
                                {...LayoutService.getInputProps(this.state.isReadOnly,
                                  classes,
                                  fProps,
                                  "description",
                                  "Description",
                                  false,
                                )}
                                fullWidth
                              />{" "}
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <TextField
                                {...LayoutService.getInputProps(this.state.isReadOnly,
                                  classes,
                                  fProps,
                                  "unitCost",
                                  "Unit Cost"
                                )}
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              {LayoutService.getSelectControl(this.state.isReadOnly,
                                classes,
                                fProps,
                                "currencyId",
                                "Currency *",
                                this.state.currencyList,
                                "id",
                                "value",
                                "98%"
                              )}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <FormGroup
                                style={{ paddingLeft: "4px", paddingTop: "8px" }}
                              >
                                {LayoutService.getSwitch(this.state.isReadOnly,
                                  classes,
                                  fProps,
                                  "isActive",
                                  "Active"
                                )}
                              </FormGroup>
                            </Grid>
                          </Grid>
                        </Box>
                      </DialogContent>
                    </MuiPickersUtilsProvider>
                  </form>
                )}
              </Formik>
            </>
          );
        case ResultStatus.ERROR:
        default:
          return (
            <DialogErrorFragmentComponent
              title="Error"
              description="Error in Material Details"
              classes={classes}
              onClose={() => {
                this.props.onClose(false);
              }}
              onRetry={() => {
                console.log("Retry Clicked");
              }}
            />
          );
      }
    }
  }
}

/** HOC */
export default LayoutService.getHocComponenet(MaterialDetailsComponent);
