import ApiService from "../../../../../shared/services/apiService";
import { API_ENDPOINT } from "../../../../../shared/types/enums";

export default class StandardReportSettingsService {
    static getReportFilters = (_tenantId, _reportId) => {
        return ApiService.getOBS(API_ENDPOINT.REPORTS, `/Reports/FetchReportFilters/${_tenantId}/${_reportId}`);
    }

    static getReportSaveFileTypes = () => {
        return ApiService.getOBS(API_ENDPOINT.REPORTS, `/Reports/FetchReportSaveFileTypes`);
    };
}