import ApiService from "../../../../shared/services/apiService";
import { API_ENDPOINT, ENTITY_TYPE } from "../../../../shared/types/enums";
import { AgGridColumnExt } from "../../../../shared/services/ag-grid/agGridColumnExt";

export default class ReportReferencesService {

  static getRuleSummaryAsOBS = (_tenantId, _userId, _modalAgNode) => {
    const targetUrl = `/RuleSummary/FetchReportReferncesByEntity/${_tenantId}?entityId=${ENTITY_TYPE.TRANSACTION}&id=${_modalAgNode.transactionid}&pageSize=100&pageNo=1`;
    return ApiService.getOBS(
      API_ENDPOINT.CORE,
      targetUrl,
    );
  };

  /**
   * Refer url for more details: https://www.ag-grid.com/javascript-grid-column-properties/
   * @param _componentThisRef pass {this} keyword from the calling componenet
   */
  static getColumnDefs(_componentThisRef) {
    return [
      AgGridColumnExt.GET(false, "text", "reportDetailID", "Report ID").minAndDefaultWidth(144).cellRenderer("vendorLinkRendererComponent").headerClass("center-text").SET(),
      AgGridColumnExt.GET(false, "text", "reportLibrary", "Report Library").flexWeight(6).tooltip().headerClass("center-text").SET(),
      AgGridColumnExt.GET(false, "text", "reportDetailName", "Report Name").flexWeight(4).tooltip().headerClass("center-text").SET(),
      AgGridColumnExt.GET(false, "text", "finalDispositionName", "Final Disposition").flexWeight(4).tooltip().headerClass("center-text").SET(),
      AgGridColumnExt.GET(false, "text", "reportCreatedDate", "Report Generation Date").flexWeight(4).tooltip().headerClass("center-text").SET(),

    ];
  }
}