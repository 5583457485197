import ApiService from "../../../shared/services/apiService";
import { API_ENDPOINT } from "../../../shared/types/enums";

export default class UploadFileService {

  static getSourceSystemsAsOBS = (_tenantId,) => {
    return ApiService.getOBS(
      API_ENDPOINT.CORE,
      `/SourceSystems?tenantId=${_tenantId}`
    );
  };

}