import React, { Component } from "react";
import {
  withStyles,
  DialogTitle,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  DialogContent,
  Box,
  Grid,
} from "@material-ui/core";
import { AuthContext } from "../../../shared/store/authProvider";
import PageLoadingComponent from "../../../shared/components/page/pageLoadingComponent";
import PageErrorComponent from "../../../shared/components/page/pageErrorComponent";
import { SubscriptionArray } from "../../../shared/services/dataService";
import { ResultStatus, API_ENDPOINT } from "../../../shared/types/enums";
import { MatClassService } from "../../../shared/services/theme/matClassService";
import ApiService from "../../../shared/services/apiService";
import LayoutService from "../../../shared/services/layoutService";
import FileStatusService from "./fileStatusService";
import { MatIconService } from "../../../shared/services/theme/matIconService";
import icon_bad from "../../../public/images/icon_bad.png";
import icon_process from "../../../public/images/icon_process.png";
import icon_verygood from "../../../public/images/icon_verygood.png";
import icon_good from "../../../public/images/icon_good.png";
import icon_blank from "../../../public/images/icon_blank.png";
import icon_delete_black from "../../../public/images/icon_delete_black.gif";
import RecordValidationSummaryComponent from "./record-validation-summary/recordValidationSummaryComponent";
import RolePermissionService from "../../../shared/role-permissions/rolePermissionService";

class FileStatusComponent extends Component {
  static contextType = AuthContext;

  oSubscriptions = new SubscriptionArray();
  STORE = FileStatusService;
  state = {
    data: [],
    fetchResult: ResultStatus.NOT_LOADED,
  };

  constructor(props) {
    super(props);
    this.fetchData.bind(this);
  }

  componentWillUnmount() {
    this.oSubscriptions.cancelAll();
  }
  componentDidMount() {
    console.log("inside did mount ", this.props.modalAgNode.fileId);
    this.fetchData();
  }

  /** API Fetch */
  fetchData = (_ignoreCache = false) => {
    this.oSubscriptions.cancelAll();
    this.setState({ fetchResult: ResultStatus.LOADING, data: [] });
    // save the subscription object
    this.oSubscriptions.add(
      this.STORE.getObs(
        _ignoreCache,
        this.props.modalAgNode,
        this.context
      ).subscribe(
        (o) => {
          // onResultStatus.SUCCESS
          if (!Array.isArray(o)) {
            o = new Array(o);
          }

          this.setState({
            data: o,
            fetchResult: ResultStatus.SUCCESS,
          });
        },
        (o) => {
          console.log("Error:", o);
          // onResultStatus.ERROR
          this.setState({
            fetchResult: ResultStatus.ERROR,
          });
        }
      )
    );
  };

  onDelete = (_fileId) => {
    this.setState({
      fetchResult: ResultStatus.LOADING,
    });
    try {
      console.log("DELETE request started for Delete Role");
      //const roleId = this.props.modalAgNode.roleId;
      //const url = `${process.env.REACT_APP_TENANT_HTTP}/Role/EditRole/${this.context.user.tenantId}/${roleId}`;
      const result = fetch(
        `${process.env.REACT_APP_CORE_HTTP}/DataCenter/DeleteFile/${this.context.user.tenantId}/${_fileId}`,
        {
          method: "DELETE",
          headers: { accept: "*/*" },
        }
      );
      this.setState({ fetchResult: ResultStatus.SUCCESS });
      this.props.onClose(false);
      this.props.refreshGrid(true);
    } catch (error) {
      this.setState({ fetchResult: ResultStatus.ERROR });
    }
  };

  handleUpdateRecordStatus = (_recordIdStatus) => {
    // 4) save to Api and subscribe for the result
    ApiService.setOBS(
      "POST",
      API_ENDPOINT.CORE,
      `/DataCenter/UpdateRecStatus/${this.context.user.tenantId}/${this.props.modalAgNode.fileId}/${this.context.user.uid}/${_recordIdStatus}/${this.context.user.userId}`,
      JSON.stringify({})
    ).subscribe(
      (successResult) => {
        if (successResult) {
          this.setState({ fetchResult: ResultStatus.SUCCESS });
          this.props.onClose(false);
          this.props.refreshGrid(true);
        } else {
          console.error("Error: falsey successResult while updating Record Status", successResult);
          this.setState({ fetchResult: ResultStatus.ERROR });
        }
      },
      (errorResult) => {
        console.error("Error while updating Record Status", errorResult);
        this.setState({ fetchResult: ResultStatus.ERROR });
      }
    );
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.setDomLayout("autoHeight");
  };

  render() {
    const { classes } = this.props;
    //console.log("render", this.state.data);
    let content;
    if (this.state.data.length !== 0) {
      switch (this.props.modalAgNode.url) {
        case "icon_bad.png":
        case "icon_blank.png":
          let errImage =
            this.props.modalAgNode.url === "icon_bad.png"
              ? icon_bad
              : icon_blank;
          content = (
            <div>
              <Grid container spacing={1}>
                <Grid item xs={12} sm="auto">
                  <img
                    src={errImage}
                    alt="Icon Blank"
                    style={{ marginBottom: 20 + "px", width: 30 + "px" }}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography variant="h8" className={classes.root}>
                    File import failed due to the following violations
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  {this.state.data[0].fileViolationSummary ? (
                    this.state.data[0].fileViolationSummary.map((recipient) => {
                      return (
                        <>
                          <Grid item xs={12} sm={12}>
                            <Typography variant="h8" className={classes.root}>
                              {recipient.key}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <Typography variant="h8" className={classes.root}>
                              {recipient.name}
                            </Typography>
                          </Grid>
                        </>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            </div>
          );
          break;
        case "icon_process.png":
          content = (
            <div>
              <Grid container spacing={1}>
                <Grid item xs={12} sm="auto">
                  <img
                    src={icon_process}
                    alt="Icon Process"
                    style={{ marginBottom: 20 + "px", width: 30 + "px" }}
                  />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Typography variant="h8" className={classes.root}>
                    File validation is currently in progress
                  </Typography>
                </Grid>
              </Grid>
            </div>
          );
          break;
        case "icon_verygood.png":
        case "icon_good.png":
          //console.log("in switch.......");
          let image =
            this.props.modalAgNode.url === "icon_verygood.png"
              ? icon_verygood
              : icon_good;
          content = (
            <div>
              <Grid container spacing={1}>
                <Grid item xs={12} sm="auto">
                  <img
                    src={icon_verygood}
                    alt="Very Good"
                    style={{ marginBottom: 20 + "px", width: 30 + "px" }}
                  />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Typography variant="h8" className={classes.root}>File validation was successful</Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography variant="button" className={classes.root}>Record validation</Typography>
                </Grid>
                <Grid item xs={12} sm="auto">
                  <img src={image} alt="Very Good" style={{ marginBottom: 20 + "px", width: 30 + "px" }} />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Typography variant="h8" className={classes.root}>Record validation was {image === icon_good ? "not" : ""}{" "}successful</Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography variant="subtitle2" className={classes.root}>Record metrics:</Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography variant="h8" className={classes.root}>Total Number of Records in File:{" "}{this.state.data[0].totalRecords}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h8" className={classes.root}>Records without Violations:{" "}{this.state.data[0].recordsWithoutViolation}</Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="h8" className={classes.root}>Percent of Total Records:{" "}{this.state.data[0].withoutViolationPercent}%</Typography>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="h8" className={classes.root}></Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h8" className={classes.root}>Records with Rule Violations:{" "}{this.state.data[0].recordsWithViolation}</Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="h8" className={classes.root}>Percent of Total Records:{" "}{this.state.data[0].violationPercent}%</Typography>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="h8" className={classes.root}>Business Rule Threshold(%):{" "}{this.state.data[0].violationsSourceThreshold === null ? "N/A"
                    : this.state.data[0].violationsSourceThreshold + "%"}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h8" className={classes.root} style={{ marginLeft: "25px" }}>Records with Errors: {this.state.data[0].recordsWithErrors}</Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="h8" className={classes.root}>Percent of Total Records:{" "}{this.state.data[0].errorPercent}%</Typography>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="h8" className={classes.root}></Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h8" className={classes.root} style={{ marginLeft: "25px" }}>Records with Warnings: {this.state.data[0].warningRecords}</Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="h8" className={classes.root}>Percent of Total Records:{" "}{this.state.data[0].warningPercent}%</Typography>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="h8" className={classes.root}></Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h8" className={classes.root}>Records with Unmatched Recipients:{" "}{this.state.data[0].unMatchedRecipients}</Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="h8" className={classes.root}>Percent of Total Records:{" "}{this.state.data[0].percentofUnmatched}%</Typography>
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="h8" className={classes.root}>Unmatched Recipient Threshold(%):{" "}{this.state.data[0].unMatchedSourceThreshold === null ? "N/A"
                    : this.state.data[0].unMatchedSourceThreshold + "%"}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" className={classes.root}>Unmatched Recipients by Type</Typography>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="h8" className={classes.root}></Typography>
                </Grid>
                {this.state.data[0].recipientDetails ? (
                  this.state.data[0].recipientDetails.map((recipient) => {
                    return (
                      <>
                        <Grid item xs={12} sm={6}>
                          <Typography variant="h8" className={classes.root} style={{ marginLeft: "40px" }}>{recipient.recipientType} {recipient.recipientCount}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <Typography variant="h8" className={classes.root}>Percent of Unmatched Records:{" "}{recipient.unmatchedRecordsPercentage}%</Typography>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                          <Typography variant="h8" className={classes.root}></Typography>
                        </Grid>
                      </>
                    );
                  })
                ) : (
                  <></>
                )}
                <Grid xs={12} sm={12}>
                  {this.state.data[0].recordValidationSummary?.length !== 0 ? (
                    <RecordValidationSummaryComponent
                      fileStatus={this.state.data[0]}
                    />
                  ) : (
                    <></>
                  )}
                </Grid>
                {this.props.modalAgNode.url === "icon_good.png" ?
                  <Grid item xs={6} sm={6}>
                    <AppBar position="static" elevation={0} style={{ backgroundColor: "primary" }}>
                      <Toolbar variant="dense" style={{ height: "30px", minHeight: "30px" }}>
                        <Typography variant="h6" style={{ color: "#fff" }} className={classes.root}>Record Validation Summary</Typography>
                        {LayoutService.getIconButton(false, MatIconService.NAV_HAND, "Accept File", () => this.handleUpdateRecordStatus(5), "inherit", "keyAcceptFile")}
                        {LayoutService.getIconButton(false, MatIconService.NAV_THUMB_DOWN, "Reject File", () => this.handleUpdateRecordStatus(4), "secondary", "keyRejectFile")}
                      </Toolbar>
                    </AppBar>
                  </Grid>
                  : null}
              </Grid>
            </div>
          );
          break;
        default:
          content = "n/a";
      }
    }
    if (RolePermissionService.FILE_STATUS.cannotView) {
      return RolePermissionService.getAccessDeniedComponent(classes, () => { this.props.onClose() }); // this is required to prevent Url navigation
    } else {
      switch (this.state.fetchResult) {
        case ResultStatus.NOT_LOADED:
        case ResultStatus.LOADING:
          return (
            <PageLoadingComponent
              small
              classes={classes}
              label="Loading File Status"
            />
          );
        case ResultStatus.SUCCESS:
          return (
            <React.Fragment>
              <DialogTitle disableTypography id="dialogTitle">
                <AppBar position="static">
                  <Toolbar>
                    <Typography variant="h6" className={classes.root}>
                      File Status
                    </Typography>
                    {this.props.modalAgNode.url === "icon_bad.png" ? (
                      <IconButton
                        color="secondary"
                        onClick={() => {
                          this.onDelete(this.props.modalAgNode.fileId);
                        }}
                        disabled={!RolePermissionService.FILE_STATUS.canDelete}
                      >
                        {MatIconService.DELETE}
                      </IconButton>
                    ) : (
                      <></>
                    )}

                    <IconButton
                      color="secondary"
                      onClick={() => {
                        this.props.onClose(false);
                      }}
                    >
                      {MatIconService.CANCEL}
                    </IconButton>
                  </Toolbar>
                </AppBar>
              </DialogTitle>
              <DialogContent>
                <Box
                  style={{
                    paddingLeft: 16,
                    paddingRight: 32,
                    paddingTop: 8,
                    paddingBottom: 32,
                    minHeight: "60vh",
                    maxHeight: "60vh",
                    minWidth: "130vh",
                    maxWidth: "150vh",
                  }}
                >
                  <Typography variant="button" className={classes.root}>
                    File validation
                  </Typography>
                  {content}
                </Box>
              </DialogContent>
            </React.Fragment>
          );

        case ResultStatus.ERROR:
        default:
          return (
            <PageErrorComponent
              small
              label="Error Loading Data Count"
              classes={classes}
              onRetry={() => {
                this.fetchData(true);
              }}
            />
          );
      }
    }
  }
}

export default withStyles(MatClassService)(FileStatusComponent);
