import React from "react";
import clsx from "clsx";
import { Typography, Divider, Grid, Tooltip, IconButton, MenuItem, Select } from "@material-ui/core";
import MatSpecService from "../../services/theme/matSpecService";
import { MatIconService } from "../../services/theme/matIconService";

export default class PageDynamicHeaderComponent extends React.Component {
    render() {
        const { small, classes, label, rightPadding, bottomPadding, bgColor } = this.props;
        const leftActions = this.props.leftActions || [];
        const rightActions = this.props.rightActions || [];

        return (
            <React.Fragment>
                <Grid className={classes.flexNoShrink} container direction="column" style={{ backgroundColor: bgColor }}>
                    <Grid container direction="row" alignItems="center" style={{ paddingRight: rightPadding > 0 ? rightPadding : 0, paddingBottom: bottomPadding > 0 ? bottomPadding : 0 }}>

                        {/* LeftSideElements */}
                        {leftActions.map((action, index) => {
                            if (action) {
                                if (action.jsxElement) {
                                    return action.jsxElement;
                                } else {
                                    return (
                                        <Tooltip key={"leftTooltip" + index} enterDelay={MatSpecService.tooltipEnterDelay} title={action.title} placement="bottom" arrow>
                                            <span>
                                                <IconButton disabled={action.isReadOnly} key={"leftIcon" + index} onClick={action.onClick} color={action.iconColor}>{action.icon}</IconButton>
                                            </span>
                                        </Tooltip>
                                    );
                                }
                            }
                        })}

                        {/* Label */}
                        {<Typography style={{ paddingLeft: 8 }} variant={small ? "h6" : "h5"} className={classes.headerTitle}>{label}</Typography>}

                        {/* RightSideElements */}
                        {rightActions.map((action, index) => {
                            if (action) {
                                if (action.jsxElement) {
                                    return action.jsxElement;
                                } else {
                                    return (
                                        <Tooltip key={"rightTooltip" + index} enterDelay={MatSpecService.tooltipEnterDelay} title={action.title} placement="bottom" arrow>
                                            <span>
                                                <IconButton disabled={action.isReadOnly} key={"rightIcon" + index} onClick={action.onClick} color={action.iconColor}>{action.icon}</IconButton>
                                            </span>
                                        </Tooltip>
                                    );
                                }
                            }
                        })}
                    </Grid>
                    {this.props.divider ? <Divider /> : null}
                </Grid>
            </React.Fragment>
        );
    }
}
