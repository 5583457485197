import React from "react";
import { combineLatest, of } from "rxjs";
import {
  ResultStatus,
  TenantSelectList,
  AffiliatesSelectList,
  CrudAction,
  API_ENDPOINT,
} from "../../../../shared/types/enums";
import {
  withStyles,
  Grid,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
  Typography,
  Box,
  Divider,
} from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import { MatClassService } from "../../../../shared/services/theme/matClassService";
import PageLoadingComponent from "../../../../shared/components/page/pageLoadingComponent";
import PageErrorComponent from "../../../../shared/components/page/pageErrorComponent";
import LayoutService from "../../../../shared/services/layoutService";
import { AuthContext } from "../../../../shared/store/authProvider";
import ApiService from "../../../../shared/services/apiService";
import SectionComponent from "../../../../shared/components/sectionComponent";
import EditAffiliateService from "./editAffiliateService";
import { SubscriptionArray } from "../../../../shared/services/dataService";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css";
import { AgGridUtil } from "../../../../shared/services/ag-grid/agGridUtil";
import AgGridEditButtonCellRendererComponent from "../../../../shared/components/elements/agGridEditButtonCellRendererComponent";
import { MatIconService } from "../../../../shared/services/theme/matIconService";
import MatThemeService from "../../../../shared/services/theme/matThemeService";
import LookupService from "../../../../shared/services/lookupService";
import { DataService } from "../../../../shared/services/dataService";
import ToastService from "../../../../shared/services/toastService";
import AgGridDropdownWithStatusCellRenderer from "../../../../shared/components/ag-grid/agGridDropdownWithStatusCellRenderer";

class EditAffiliateModalComponent extends React.Component {
  static contextType = AuthContext;
  oSubscriptions = new SubscriptionArray();
  constructor(props) {
    super(props);

    this.state = {
      fetchResult: ResultStatus.SUCCESS,
      isReadOnly: this.props.inputAction === CrudAction.UPDATE, // default state
      isEditing: this.props.inputAction === CrudAction.UPDATE,

      affiliateNameExists: true,
      showAffiliateNameExistsAlert: false,

      manufacturerData: [],
      manufacturerTypeName: "",
      isLoadingManufacturerData: false,

      federalBusinessTypeData: [],
      federalBusinessTypeName: "",
      isLoadingFederalBusinessData: false,

      affiliate: {},
      reportIdentifierList: [],
      countryList: [],
      agGridComplianceUtils: new AgGridUtil("reportIdentifierId", {
        inlineEditButtonCellRendererComponent: AgGridEditButtonCellRendererComponent,
        dropDownRenderer: AgGridDropdownWithStatusCellRenderer,
      }),
    };
  }

  componentWillUnmount() {
    //this.SERVICE.oSUBSCRIPTIONS.cancelAll();
  }

  componentDidMount() {

    this.setState({ fetchResult: ResultStatus.LOADING, affiliate: {} });
    this.oSubscriptions.add(
      combineLatest([
        (this.props.inputAction === CrudAction.CREATE ? of(null) : EditAffiliateService.getAffiliateAsOBS(this.context.user.tenantId, this.props.affiliate.companyAffiliateId)),
        (this.props.inputAction === CrudAction.CREATE ? of(null) : EditAffiliateService.getAffiliateByLovIdAsOBS(this.context.user.tenantId,)),
        LookupService.getFormattedCountriesAsOBS(this.context, null),
      ]).subscribe(
        ([_affiliate, _reportIdentifiers, _countryList]) => {
          // console.log("_countryList", _countryList);
          this.setState(
            {
              affiliate: _affiliate,
              reportIdentifierList: _reportIdentifiers, //DataService.arrayToObject(_reportIdentifiers, "lovId", "lovKey"),
              countryList: _countryList,
            },
            () => { this.setState({ fetchResult: ResultStatus.SUCCESS }); }
          );
        },
        (error) => {
          ToastService.showError("Error While Fetching the list.");
          this.setState({ fetchResult: ResultStatus.ERROR });
        }
      )
    );

    this.fetchAffiliatesData(AffiliatesSelectList.ManufacturerType, "isLoadingManufacturerData", "manufacturerData");
    this.fetchAffiliatesData(AffiliatesSelectList.FederalBusinessType, "isLoadingFederalBusinessTypeData", "federalBusinessTypeData");
  }

  fetchGetListData = async (tenantSelectList, isLoadingKey, dataKey) => {
    this.setState({ [isLoadingKey]: true });
    try {
      const result = await fetch(`${process.env.REACT_APP_TENANT_HTTP}/Util/GetMasterSubSet/${this.context.user.tenantId}/${tenantSelectList}`);
      const json = await result.json();
      this.setState({ [dataKey]: json, [isLoadingKey]: false });
    } catch (error) {
      ToastService.showError("Error While Fetching the list.");
    }
  };

  getInitialValues() {
    if (this.props.inputAction === CrudAction.CREATE) {
      return {
        customerAffiliateId: "",
        affiliateName: "",
        address1: "",
        address2: null,
        address3: null,
        address4: null,
        city: "",
        province: "",
        country: "",
        countryId: "",
        postalCode: "",
        isActive: true,
        usCmsSubmittingEntity: false,
        manufacturerType: "",
        usCms10PctLimitation: false,
        caAnnualThreshold: null,
        usFederalSmplBusinessType: "",
        registrationName: "",
        complianceOfficerName: "",
        complianceOfficerEmail: "",
        complianceOfficerPhoneNumber: ""
      };
    } else {
      return {
        customerAffiliateId: this.state.affiliate.customerAffiliateId,
        affiliateName: this.state.affiliate.affiliateName,
        address1: this.state.affiliate.address1,
        address2: this.state.affiliate.address2,
        address3: this.state.affiliate.address3,
        address4: this.state.affiliate.address4,
        city: this.state.affiliate.city,
        province: this.state.affiliate.province,
        country: this.state.affiliate.country,
        countryId: this.state.affiliate.countryId,
        postalCode: this.state.affiliate.postalCode,
        isActive: this.state.affiliate.isActive,
        usCmsSubmittingEntity: this.state.affiliate.usCmsSubmittingEntity,
        manufacturerType: this.state.affiliate.manufacturerType ? this.state.affiliate.manufacturerType : "",
        usCms10PctLimitation: this.state.affiliate.usCms10PctLimitation,
        caAnnualThreshold: this.state.affiliate.caAnnualThreshold ? this.state.affiliate.caAnnualThreshold : null,
        usFederalSmplBusinessType: this.state.affiliate.usFederalSmplBusinessType ? this.state.affiliate.usFederalSmplBusinessType : "",
        registrationName: this.state.affiliate.registrationName,
        complianceOfficerName: this.state.affiliate.complianceOfficerName,
        complianceOfficerEmail: this.state.affiliate.complianceOfficerEmail,
        complianceOfficerPhoneNumber: this.state.affiliate.complianceOfficerPhoneNumber
      };
    }
  }

  validationSchema = Yup.object().shape({});
  getValidationSchema() {
    this.validationSchema = Yup.object().shape({
      customerAffiliateId: Yup.number().required("Required").typeError("Must be a number"),
      affiliateName: Yup.string().min(3, "Must be atleast 3 characters long").max(256, "Must be 256 characters or less").required("Required")
        .test("checkDuplicateAffiliateName", "Affiliate Name already exists",
          async (_formikValue) => {
            if (_formikValue) {
              if (this.props.inputAction === CrudAction.UPDATE) {
                if (_formikValue === this.props.affiliate.affiliateName) { return true; }
              }
              const result = await fetch(`${process.env.REACT_APP_TENANT_HTTP}/Affiliate/IsAffiliateNameExist?affiliateName=${_formikValue}&tenantId=${this.context.user.tenantId}`);
              const isExists = await result.json();
              return !isExists;
            } else {
              return true;
            }
          }
        ),
      address1: Yup.string().min(3, "Must be atleast 3 characters long").max(256, "Must be 256 characters or less").required("Required"),
      address2: Yup.string().nullable(),
      address3: Yup.string().nullable(),
      address4: Yup.string().nullable(),
      city: Yup.string().min(3, "Must be atleast 3 characters long").max(256, "Must be 256 characters or less").required("Required"),
      province: Yup.string().min(2, "Must be atleast 2 characters long").max(256, "Must be 256 characters or less").required("Required"),
      countryId: Yup.number().required("Required").typeError("Required"),
      postalCode: Yup.string().min(2, "Must be atleast 2 characters long").max(256, "Must be 256 characters or less").required("Required"),
      caAnnualThreshold: Yup.number().nullable(true).typeError("Must be a number"),
      complianceOfficerName: Yup.string().nullable().max(250, "Must be 250 characters or less"),
      complianceOfficerEmail: Yup.string().nullable().max(250, "Must be 250 characters or less"),
      complianceOfficerPhoneNumber: Yup.string().nullable().max(250, "Must be 250 characters or less"),
      //commented as per ticket PP2-683
      //registrationName: Yup.string().min(3, "Must be atleast 3 characters long").max(500, "Must be 500 characters or less")
    });
    return this.validationSchema;
  }

  handleSubmit = async (_formikProps) => {
    // console.log(_formikProps);
    if (!_formikProps.isSubmitting && _formikProps.isValid) {
      await this.validationSchema
        .validate(_formikProps.values, { abortEarly: false })
        .then((x) => {
          // 0) clear the CACHE,so next time if requried this new object will also be fetched
          LookupService.clearAFFILIATIONS();

          // 1) set the status
          this.setState({ fetchResult: ResultStatus.SAVING });
          // console.log(this.state.agGridComplianceUtils.getUpdatedRowData());

          let reportIdentifiersArray = [];
          if (this.props.inputAction === CrudAction.UPDATE) {
            reportIdentifiersArray = this.state.agGridComplianceUtils.getUpdatedRowData();
          }

          // 2) extract the formik values into an object
          var mappedObj = {
            // companyAffiliateId: this is set conditionally below,
            customerAffiliateId: parseInt(
              _formikProps.values.customerAffiliateId
            ),
            porzioAffiliationId: parseInt(
              _formikProps.values.customerAffiliateId
            ),
            tenantId: this.context.user.tenantId,
            affiliateName: _formikProps.values.affiliateName,
            address1: _formikProps.values.address1,
            address2: _formikProps.values.address2 || null,
            address3: _formikProps.values.address3 || null,
            address4: _formikProps.values.address4 || null,
            city: _formikProps.values.city,
            province: _formikProps.values.province,
            postalCode: _formikProps.values.postalCode,
            isActive: _formikProps.values.isActive,
            caAnnualThreshold: parseFloat(
              _formikProps.values.caAnnualThreshold
            ),
            countryId: _formikProps.values.countryId,
            currencyId: 1,
            timeZoneId: 1,
            languageId: 1,
            updatedBy: 1,
            searchText: "",
            isAllSelected: true,
            usCmsSubmittingEntity: _formikProps.values.usCmsSubmittingEntity,
            usCms10PctLimitation: _formikProps.values.usCms10PctLimitation,
            usCmsBusinessType: _formikProps.values.usCmsBusinessType,
            manufacturerType: _formikProps.values.manufacturerType,
            usFederalSmplBusinessType:
              _formikProps.values.usFederalSmplBusinessType,
            registrationName: _formikProps.values.registrationName,
            reportIdentifiers: reportIdentifiersArray,
            complianceOfficerName: _formikProps.values.complianceOfficerName,
            complianceOfficerEmail: _formikProps.values.complianceOfficerEmail,
            complianceOfficerPhoneNumber: _formikProps.values.complianceOfficerPhoneNumber,
          };

          // 3) determine the action and assign the appropriate props
          let actionVerb = "";
          let targetUrl = "";
          if (this.props.inputAction === CrudAction.UPDATE) {
            // UPDATE
            actionVerb = "PUT";
            targetUrl = `/Affiliate/UpdateAffiliate/${this.context.user.tenantId}`;
            mappedObj.companyAffiliateId = this.props.affiliate.companyAffiliateId;
            mappedObj.updatedBy = this.context.user.userId;
          } else {
            // CREATE
            actionVerb = "POST";
            targetUrl = `/Affiliate/${this.context.user.tenantId}`;
            mappedObj.companyAffiliateId = 0;
            mappedObj.createdBy = this.context.user.userId;
          }

          // 4) save to Api and subscribe for the result
          ApiService.setOBS(actionVerb, API_ENDPOINT.TENANT, targetUrl, JSON.stringify(mappedObj))
            .subscribe(
              (successResult) => {
                if (successResult) {
                  LookupService.fetchCommonLookups(this.context);
                  this.setState({ fetchResult: ResultStatus.SUCCESS });
                  this.props.refreshAfiliatesList(true);
                  this.props.onClose(false);
                } else {
                  this.setState({ fetchResult: ResultStatus.ERROR });
                }
              },
              (errorResult) => {
                ToastService.showError("Error while saving Produc");
                this.setState({ fetchResult: ResultStatus.ERROR });
              }
            );
        })
        .catch((erroObj) => {
          ToastService.showError("Error Occured.");
          (erroObj.inner || []).forEach((err) => {
            _formikProps.setFieldError(err.path, err.message);
          });
        });
    }
  };

  fetchAffiliatesData = async (affiliatesSelectEnum, stateLoadingKey, stateDataKey, stateDataId) => {
    this.setState({ [stateLoadingKey]: true });
    try {
      const result = await fetch(
        `${process.env.REACT_APP_TENANT_HTTP}/Util/GetNonEntityLOVsByID/${this.context.user.tenantId}/${affiliatesSelectEnum}`
      );
      const json = await result.json();
      this.setState({ [stateDataKey]: json, [stateLoadingKey]: false, });
    } catch (error) { ToastService.showError("Error Occured."); }
  };

  handleSwitch = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  /**Render */
  render() {
    const { classes } = this.props;
    let formContent = null;

    this.state.agGridComplianceUtils.setReadOnlyMode(this.state.isReadOnly);

    formContent = (
      <Formik initialValues={this.getInitialValues()} validationSchema={this.getValidationSchema()} validationSchemaOptions={{ showMultipleFieldErrors: true }} >
        {(fProps) => (
          <form>
            <SectionComponent classes={classes} enableEditJsx={LayoutService.getReadOnlyActionsSolo(this, !this.state.isEditing, () => { this.handleSubmit(fProps) })} />

            <Grid container spacing={1} justify="center" alignItems="center" className={classes.root} >
              <Grid item xs={12} sm={12} md={6}><TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "customerAffiliateId", "Affiliate ID")} style={{ minWidth: "90%" }} /></Grid>
              <Grid item xs={12} sm={12} md={6}>
                {this.state.showAffiliateNameExistsAlert ? (<Typography color="secondary"> Please choose another Affiliate Name, the one you have entered is already in use in a different Affiliate entry.</Typography>) : ("")}
                <TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "affiliateName", "Affiliate Name")} style={{ minWidth: "90%" }} />
              </Grid>
              <Grid item xs={12} sm={12} md={6}><TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "address1", "Address 1")} style={{ minWidth: "90%" }} /></Grid>
              <Grid item xs={12} sm={12} md={6}><TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "address2", "Address 2", false)} style={{ minWidth: "90%" }} /></Grid>
              <Grid item xs={12} sm={12} md={6}><TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "address3", "Address 3", false)} style={{ minWidth: "90%" }} /></Grid>
              <Grid item xs={12} sm={12} md={6}><TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "address4", "Address 4", false)} style={{ minWidth: "90%" }} /></Grid>
              <Grid item xs={12} sm={12} md={6}><TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "city", "City")} style={{ minWidth: "90%" }} /></Grid>
              <Grid item xs={12} sm={12} md={6}><TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "province", "Province/State")} style={{ minWidth: "90%" }} /></Grid>
              <Grid item xs={12} sm={12} md={6}><TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "postalCode", "Postal Code")} style={{ minWidth: "90%" }} />
              </Grid><Grid item xs={12} sm={12} md={6}>{LayoutService.getSelectControl(this.state.isReadOnly, classes, fProps, "countryId", "Country*", this.state.countryList, "id", "value", "80%")}</Grid>
              <Grid item xs={12} sm={12} md={6}>
                <FormGroup style={{ paddingLeft: "4px", paddingTop: "8px" }}>
                  {LayoutService.getSwitch(this.state.isReadOnly, classes, fProps, "isActive", "Active?")}
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={6}><TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "caAnnualThreshold", "US CA Annual Threshold", false)} style={{ minWidth: "90%" }} /></Grid>
              <Grid item xs={12} sm={12} md={6}>
                <FormGroup style={{ paddingLeft: "4px", paddingTop: "8px" }}>
                  {LayoutService.getSwitch(this.state.isReadOnly, classes, fProps, "usCmsSubmittingEntity", "US CMS Submitting Entity")}
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <FormGroup style={{ paddingLeft: "4px", paddingTop: "8px" }}>
                  {LayoutService.getSwitch(this.state.isReadOnly, classes, fProps, "usCms10PctLimitation", "US CMS 10% Limitation")}
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>{LayoutService.getSelectControl(this.state.isReadOnly, classes, fProps, "manufacturerType", "Manufacturer Type", this.state.manufacturerData, "lovKey", "lovKey", "98%")}</Grid>
              <Grid item xs={12} sm={12} md={6}>{LayoutService.getSelectControl(this.state.isReadOnly, classes, fProps, "usFederalSmplBusinessType", "US Federal Samples Business Type", this.state.federalBusinessTypeData, "lovKey", "lovKey", "98%")}</Grid>
              <Grid item xs={12} sm={12} md={12}><TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "registrationName", "US CMS Registration Name", false,)} style={{ minWidth: "90%" }} /></Grid>
              <Grid item xs={12} sm={12} md={6}><TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "complianceOfficerName", "Compliance Officer Name", false,)} style={{ minWidth: "90%" }} /></Grid>
              <Grid item xs={12} sm={12} md={6}><TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "complianceOfficerEmail", "Compliance Officer Email", false,)} style={{ minWidth: "90%" }} /></Grid>
              <Grid item xs={12} sm={12} md={12}><TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "complianceOfficerPhoneNumber", "Compliance Officer Phone Number", false,)} style={{ minWidth: "90%" }} /></Grid>
            </Grid>
            {this.props.inputAction === CrudAction.CREATE ? <></> :
              <React.Fragment key={"addressFragment"}>
                <Box key={"complianceBox"} style={{ paddingTop: 8, paddingBottom: 16, backgroundColor: MatThemeService.getReverseAlternatingBG(3), }}>
                  <div id="MainRoleGrid">
                    <Grid container direction="row" justify="space-between" alignItems="center" >
                      <Typography variant="h6" className={classes.sectionHeader} style={{ margin: 8 }}>Compliance Report Identifiers</Typography>
                      {LayoutService.getIconButton(this.state.isReadOnly, MatIconService.ADD_CIRCLE_OUTLINE, "Add New compliance report",
                        () => {
                          if (this.state.agGridComplianceUtils.isNotEditing()) {
                            this.state.agGridComplianceUtils.addNewRow({
                              reportIdentifierId: "",
                              reportIdentifierValue: "",
                              companyProfileId: 0,
                              isActive: true,
                            });
                          }
                        }
                      )}
                    </Grid>
                    <div style={{ height: `186px`, width: `100%` }}  {...LayoutService.getAgGridTheme()}>
                      <AgGridReact
                        rowData={this.state.affiliate.reportIdentifiers}
                        columnDefs={EditAffiliateService.getColumnDefs(this, this.state.isReadOnly, this.state.agGridComplianceUtils)}
                        frameworkComponents={this.state.agGridComplianceUtils.frameworkComponents}
                        suppressClickEdit={true}
                        gridOptions={{
                          context: { componentParent: this },
                          suppressContextMenu: true,
                          rowHeight: 32,
                          ...this.state.agGridComplianceUtils.bindInlineEditEvents(),
                        }}
                        onGridReady={(params) => {
                          this.state.agGridComplianceUtils.setGridParams(params, true);
                          this.state.agGridComplianceUtils.setReadOnlyMode(this.state.isReadOnly);
                        }}
                      />
                    </div>
                  </div>
                </Box>
                <Divider />
              </React.Fragment>
            }

          </form>
        )}
      </Formik>
    );

    switch (this.state.fetchResult) {
      case ResultStatus.NOT_LOADED:
      case ResultStatus.LOADING:
        return (<PageLoadingComponent small classes={classes} label="Loading Affiliated Companies" />);
      case ResultStatus.SAVING:
        return (<PageLoadingComponent small classes={classes} label="Saving Affiliate Details" />);
      case ResultStatus.SUCCESS:
        return formContent;

      case ResultStatus.ERROR:
      default:
        return (<PageErrorComponent small label="Error Loading Affilated Companies" classes={classes} onRetry={() => { this.props.onClose(false); }} />);
    }
  }
}

/** HOC */
export default withStyles(MatClassService)(EditAffiliateModalComponent);