import React from "react";
import { withStyles, Dialog, DialogTitle, DialogContent, Box, Divider, Typography, Grid, AppBar, Toolbar, DialogContentText } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import { combineLatest, of, ReplaySubject } from "rxjs";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css";
import { AgGridUtil } from "../../../../shared/services/ag-grid/agGridUtil";
import { AgGridBulkEditUtil } from "../../../../shared/components/ag-grid/bluk-edit/agGridBulkEditUtil";
import { AuthContext } from "../../../../shared/store/authProvider";
import { CrudAction, ResultStatus } from "../../../../shared/types/enums";
import { MatClassService } from "../../../../shared/services/theme/matClassService";
import PageLoadingComponent from "../../../../shared/components/page/pageLoadingComponent";
import PageErrorComponent from "../../../../shared/components/page/pageErrorComponent";
import { MatIconService } from "../../../../shared/services/theme/matIconService";
import { DataService, SubscriptionArray } from "../../../../shared/services/dataService";
import TransactionsService from "./transactionsService";
import LayoutService from "../../../../shared/services/layoutService";
import { AgGridColumnExt } from "../../../../shared/services/ag-grid/agGridColumnExt";
import TransactionSearchDialogComponent from "./transactions-search-dialog/transactionsSearchDialogComponent";
import AgGridCheckboxCellRendererComponent from "../../../../shared/components/elements/agGridCheckboxCellRendererComponent";
import { AgGridErroredCheckboxCellRenderer } from "../../../../shared/components/ag-grid/error-cell-renderers/agGridErroredCheckboxCellRenderer";
import ToastService from "../../../../shared/services/toastService";
import RolePermissionService from "../../../../shared/role-permissions/rolePermissionService";
import ChildMessageRendererComponent from "../../../transaction-center/childMessageRendererComponent";
import TransactionDetailDialogComponent from "../../../transaction-center/transaction-detail/transactionDetailDialogComponent";

class TransactionsComponent extends React.Component {
  static contextType = AuthContext;
  oSubscriptions = new SubscriptionArray();
  transactionsToDelete = [];// this will store saved/unsaved deleted Transactions

  constructor(props) {
    super(props);
    // init state
    this.state = {
      data: [],
      fetchResult: ResultStatus.NOT_LOADED,
      isReadOnly: this.props.isReadOnly,
      //isEditing: !this.props.isReadOnly,
      agreementId: this.props.inputAction === CrudAction.UPDATE ? this.props.modalAgNode.agreementId : null,
      porzioGSTProfileId: this.props.selectedAgreement.porziogstprofileid,
      selectedTransactions: [],
      showSearchTransactionsDialog: false,
      agGridBulkEditUtil: new AgGridBulkEditUtil(),
      agGridUtils: new AgGridUtil("companytransactionid", {
        isCheckedCellRenderer: AgGridCheckboxCellRendererComponent,
        erroredCheckboxCellRenderer: AgGridErroredCheckboxCellRenderer,
        childMessageRendererComponent: ChildMessageRendererComponent
      }),
    };
    this.fetchData.bind(this);
  }

  componentWillUnmount() {
    this.oSubscriptions.cancelAll();
  }

  componentDidMount() {
    if (this.props.inputAction === CrudAction.UPDATE) {
      this.setState({ fetchResult: ResultStatus.LOADING });
      this.fetchData();
    } else {
      this.setState({ fetchResult: ResultStatus.LOADED });
    }
  }

  /** API Fetch */
  fetchData = (_ignoreCache = true) => {
    this.oSubscriptions.cancelAll();
    this.setState({ fetchResult: ResultStatus.LOADING, data: [] });
    // save the subscription object
    this.oSubscriptions.add(
      TransactionsService.getTransactionsByAgreementId(this.state.agreementId, this.context.user.userId, this.context.user.tenantId)
        .subscribe(
          // success
          (_data) => {
            this.setState({
              data: DataService.hasElements(_data) ? _data : [],
              originalTransactionIds: DataService.hasElements(_data) ? _data.map(d => d['trid']) : [],
              fetchResult: ResultStatus.LOADED
            });
          },
          // onError
          (error) => {
            this.setState({ fetchResult: ResultStatus.ERROR });
          }
        )
    );
  };

  // // called on row-cell click
  methodFromParent = (row_col, node) => {
    this.setState({ modalAgNode: { ...node, porziogsttransactionid: node.porziogsttransactionid, transactionid: node.trid, sourceid: node.sourceId } });
    if (this.state.agGridUtils.isNotEditing()) {
      this.setState({ showTransactionDetailDialog: true });
    }
  };

  updateGrid = (_selectedTransaction) => {
    let newObj = {};
    newObj = {
      is_selected: false,
      trid: _selectedTransaction.trid,
      companytransactionid: _selectedTransaction.companyTransactionId,
      porziogsttransactionid: _selectedTransaction.porzioGstTransactionId,
      form: _selectedTransaction.form,
      purpose: _selectedTransaction.purpose,
      transactiondate: _selectedTransaction.transactionDate,
      convertedtotalamount: _selectedTransaction.totalAmount,
      transactionconsent: _selectedTransaction.transactionConsentId,
      transactionDocumnet: _selectedTransaction.transactionDocumnet
    };
    this.state.agGridUtils.addNewRow(newObj);
  }

  toggleSubmitButton = (_cellRef, _data) => {
    let hasSelected = false;
    let selectedTransactions = [];
    if (this.state.agGridBulkEditUtil.hasUpdates()) {
      hasSelected = true;
    } else {
      this.state.agGridUtils.gridApi.forEachNode((rowNode) => {
        hasSelected = hasSelected || rowNode.data[AgGridBulkEditUtil.selectColumnName] === true;
        if (rowNode.data && rowNode.data[AgGridBulkEditUtil.selectColumnName]) {
          selectedTransactions.push(rowNode.data);
        }
      });
    }

    this.setState({
      selectedTransactions: selectedTransactions,
      isReadOnly: !hasSelected,
    });
  }

  handleSubmitClick = (_selectedTransaction) => {
    this.setState({
      showSearchTransactionsDialog: false,
    }, () => {
      this.updateGrid(_selectedTransaction);
    });
  }

  /** 2/4 Required in Parent */
  isDirtyCallback = () => {
    return false;
    // do any additional checkings if needed
    // if (this.fPropsDynamic) {
    //   return this.fPropsDynamic.dirty;
    // } else {
    //   return false;
    // }
  }

  /** 3/4 Required in Parent */
  resetCallback = () => {
    // if (this.fPropsDynamic) {
    //   this.fPropsDynamic.resetForm();
    // }
    // do any additional resetting if needed
  }

  /** 4/4 Required in Parent */
  postCallbackOBS = () => {
    this.setState({ searchResult: ResultStatus.SAVING });
    var oReturnSubject = new ReplaySubject(); // 1st

    let tridsToDelete = [];
    if (DataService.hasElements(this.transactionsToDelete)) {
      this.transactionsToDelete.forEach(transtn => {
        if (this.state.originalTransactionIds.includes(transtn.trid)) {
          tridsToDelete.push(transtn.trid);
        }
      });
    }

    let tridsToSave = [];
    this.state.agGridUtils.gridApi.forEachNode((rowNode) => {
      if (rowNode.data && rowNode.data.porziogsttransactionid && !this.state.originalTransactionIds.includes(rowNode.data.trid)) {
        tridsToSave.push(rowNode.data.porziogsttransactionid);
      }
    });

    let postObj = {
      tenantId: this.context.user.tenantId,
      porzioGSTAgreementId: this.props.modalAgNode.porzioGstAgreementId,
      companyAgreementId: this.props.modalAgNode.agreementId,
      porzioGSTTransactionIds: tridsToSave.join(",")
    };

    if (DataService.hasElements(tridsToDelete)) {
      TransactionsService.getDeleteOBS(this.context.user.tenantId, tridsToDelete.join(","))
        .subscribe(
          (_successResult) => {
            oReturnSubject.next(true);
          },
          (_errorResult) => {
            ToastService.showError("Error occured while deleting");
            oReturnSubject.next("delete_error");
          }
        );
    }

    if (DataService.hasElements(tridsToSave)) {
      TransactionsService.saveTransactionData(postObj)
        .subscribe(
          (_successResult) => {
            ToastService.showSuccess("Transactions Saved");
            oReturnSubject.next(true);
          },
          (_errorResult) => {
            ToastService.showError("Error occured while saving");
            oReturnSubject.next("save_error");
          }
        );
    }
    return oReturnSubject.asObservable();
  }

  handleDeleteClick = () => {
    this.setState({
      openDeleteConfirm: true
    });
  }

  handleDeleteConfirm = () => {
    this.setState({ openDeleteConfirm: false, selectedTransactions: [] });
    this.state.agGridUtils.gridApi.forEachNode((rowNode) => {
      if (rowNode.data && rowNode.data[AgGridBulkEditUtil.selectColumnName]) {
        this.transactionsToDelete.push(rowNode.data);
        this.state.agGridUtils.gridApi.applyTransaction({ remove: [rowNode.data] });
      }
    });
  }

  // render
  TAB_PERMISSIONS = RolePermissionService.AGREEMENT_TRANSACTIONS;
  render() {
    const { classes } = this.props;
    this.props.tabConfig.ref = this; // 1/4) required by parent component
    const componentType = "Transactions";
    if (RolePermissionService.AGREEMENT_TRANSACTIONS.cannotView) {
      return RolePermissionService.getAccessDeniedComponent(classes);
    } else {
      this.state.agGridUtils.setEditingMode(this.props.isReadOnly);
      switch (this.state.fetchResult) {
        case ResultStatus.NOT_LOADED:
        case ResultStatus.LOADING:
          return <PageLoadingComponent small classes={classes} label={`Loading ${componentType}`} />;
        case ResultStatus.LOADED:
        case ResultStatus.SUCCESS:
          return (
            <React.Fragment>
              <DialogTitle disableTypography id="dialogTitle" />
              <DialogContent>
                <React.Fragment key={"addressFragment"}>
                  <Box style={{ padding: "0px", minHeight: "70vh", maxHeight: "70vh", }}>
                    <div id="MainRoleGrid">
                      <Grid container direction="row" justify="space-between" alignItems="center">
                        <Typography variant="h6" className={classes.sectionHeader} style={{ margin: 8, flexGrow: 1 }}></Typography>
                        {LayoutService.getIconButton(this.props.isReadOnly || this.state.selectedTransactions.length === 0 || RolePermissionService.AGREEMENT_TRANSACTIONS.cannotDelete,
                          MatIconService.UNLINK, "Unlink Transactions",
                          this.handleDeleteClick, "inherit", "keyActionDelete")}
                        {LayoutService.getIconButton(this.props.isReadOnly || RolePermissionService.AGREEMENT_TRANSACTIONS.cannotCreate, MatIconService.ADD_CIRCLE_OUTLINE, "Add", () => {
                          this.setState({ showSearchTransactionsDialog: true })
                        }, "inherit")}
                      </Grid>
                      <div {...LayoutService.getAgGridStyles(320)}>
                        <AgGridReact
                          rowData={this.state.data}
                          columnDefs={TransactionsService.getColumnDefs(this)}
                          suppressClickEdit={true}
                          pagination={true}
                          paginationPageSize={100}
                          suppressRowClickSelection={true}
                          rowSelection="multiple"
                          frameworkComponents={this.state.agGridUtils.frameworkComponents}
                          gridOptions={{
                            context: { componentParent: this },
                            suppressContextMenu: true,
                            ...AgGridColumnExt.getGridOptions(40),
                            cellFlashDelay: 1000,
                            cellFadeDelay: 750,
                          }}
                          onGridReady={(params) => {
                            this.state.agGridUtils.setGridParams(params, true);
                            this.state.agGridUtils.pinColumn(AgGridBulkEditUtil.selectColumnName); // pin the edit column to the left
                          }}
                        />
                      </div>
                    </div>
                  </Box>
                  <Divider />
                </React.Fragment>
                {this.state.showSearchTransactionsDialog ?
                  <Dialog open={this.state.showSearchTransactionsDialog || false} fullWidth={true} scroll={false ? "paper" : "body"} maxWidth={"lg"}
                    style={{ maxHeight: "100vh", }}>
                    <TransactionSearchDialogComponent
                      onClose={(_reloadRequired) => {
                        this.setState({ showSearchTransactionsDialog: false });
                        if (_reloadRequired) {
                          this.fetchData();
                        }
                      }}
                      porzioGSTProfileId={this.state.porzioGSTProfileId}
                      agreementId={this.state.agreementId}
                      onSubmitClick={(_selectedTransaction) => {
                        this.handleSubmitClick(_selectedTransaction);
                      }}
                    />
                  </Dialog>
                  : <></>}
              </DialogContent>

              {/* Unlink dialog */}
              {this.state.openDeleteConfirm ?
                <Dialog open={this.state.openDeleteConfirm || false} onClose={() => this.setState({ openDeleteConfirm: false })}>
                  <DialogTitle id="alert-dialog-title">
                    <AppBar position="static">
                      <Toolbar>
                        <Typography variant="h6" className={classes.root}>Unlink Confirmation</Typography>
                        {LayoutService.getIconButton(false, MatIconService.OK, "Unlink", this.handleDeleteConfirm, "inherit", "keyActionDelete")}
                        {LayoutService.getIconButton(false, MatIconService.CANCEL, "Cancel", () => this.setState({ openDeleteConfirm: false }), "secondary", "keyActionCancel")}
                      </Toolbar>
                    </AppBar>
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      <Box style={{ paddingLeft: 16, paddingRight: 32, paddingTop: 8, paddingBottom: 32, }} >
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Typography variant="h6" className={classes.root} align="center">Are you sure you want to unlink the selected Transaction(s)?</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="h6" className={classes.root} align="center">{this.state.selectedTransactions.map(t => t.porziogsttransactionid).join(",")}</Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </DialogContentText>
                  </DialogContent>
                </Dialog>
                : <></>}

              {this.state.showTransactionDetailDialog ?
                <TransactionDetailDialogComponent inputAction={CrudAction.UPDATE} modalAgNode={this.state.modalAgNode}
                  open={this.state.showTransactionDetailDialog || false} onClose={() => { this.setState({ showTransactionDetailDialog: false }); }} />
                : null}
            </React.Fragment>
          );

        case ResultStatus.ERROR:
        default:
          return (<PageErrorComponent small label="Error Loading Transactions" classes={classes} onRetry={() => { this.fetchData(true); }} />);
      }
    }
  }

}
/** HOC */
export default withStyles(MatClassService)(TransactionsComponent);