import React, { Component } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { Grid } from "@material-ui/core";

import { DataService, SubscriptionArray } from "../../shared/services/dataService";
import { ResultStatus } from "../../shared/types/enums";
import { AuthContext } from "../../shared/store/authProvider";
import LayoutService from "../../shared/services/layoutService";
import PowerBIDemoService from "./powerBIDemoService";
import PageDynamicHeaderComponent from "../../shared/components/page/pageDynamicHeaderComponent";
import PageLoadingComponent from "../../shared/components/page/pageLoadingComponent";
import { MatIconService } from "../../shared/services/theme/matIconService";
import DialogErrorFragmentComponent from "../../shared/components/page/dialogErrorFragmentComponent";


class PowerBIDemoComponent extends Component {
    static contextType = AuthContext;
    oSubscriptions = new SubscriptionArray();
    STORE = PowerBIDemoService;

    constructor(props) {
        super(props);
        // init state
        this.state = {
            reportOrDashboardId: "",
            embedUrl: "",
            accessToken: "",
            reportsList: []
        };
    }

    componentDidMount() {
        this.fetchData(true);
    }

    /** API Fetch */
    fetchData = (_ignoreCache = false) => {
        this.oSubscriptions.cancelAll();
        this.setState({ reportOrDashboardId: "", reportsList: [], fetchResult: ResultStatus.NOT_LOADED }, () => {
            // save the subscription object
            this.oSubscriptions.add(
                this.STORE.fetchFromApiTokenObs(this.context.user.tenantId, this.context.user.workspaceID, "").subscribe(
                    (o) => {
                        if (!Array.isArray(o)) { o = new Array(o); }
                        this.setState({
                            reportsList: o[0].reports,
                            reportOrDashboardId: "",
                        });
                        this.setState({ fetchResult: ResultStatus.LOADED });
                    },
                    (o) => {
                        console.log("Error:", o);
                        this.setState({ fetchResult: ResultStatus.ERROR });
                    }
                )
            );
        });
    };

    /** API Fetch */
    fetchToken = (_reportOrDashboardId) => {
        this.oSubscriptions.cancelAll();
        this.setState({ fetchResult: ResultStatus.LOADING });
        // save the subscription object
        this.oSubscriptions.add(
            this.STORE.fetchFromApiTokenObs(this.context.user.tenantId, this.context.user.workspaceID, _reportOrDashboardId).subscribe(
                (o) => {
                    if (!Array.isArray(o)) { o = new Array(o); }
                    let token = o[0].accessToken.replace(/"([^"]+(?="))"/g, '$1');
                    let embedUrl = o[0].embedURL.replace(/"([^"]+(?="))"/g, '$1');
                    let reportid = o[0].reportID.replace(/"([^"]+(?="))"/g, '$1'); /// <--- WHY ON EARTH ?!?!?!?!
                    this.setState({
                        accessToken: token,
                        embedUrl: embedUrl,
                        reportOrDashboardId: _reportOrDashboardId,
                        // reportsList: o[0].reports, // <--- WHY ON EARTH ?!?!?!?!
                    });
                    this.setState({ fetchResult: ResultStatus.SUCCESS });
                },
                (o) => {
                    console.log("Error:", o);
                    this.setState({ fetchResult: ResultStatus.ERROR });
                }
            )
        );
    };
    validationSchema = Yup.object().shape({});
    getValidationSchema() {
        this.validationSchema = Yup.object().shape({
            reportOrDashboardId: Yup.string().required()
        });
        return this.validationSchema;
    }
    getInitialValues() {
        return {
            reportOrDashboardId: '',
        };
    }

    render() {
        const { classes } = this.props;
        let powerBiEmbedType = "report"
        return (
            <div className="root">
                <Formik initialValues={this.getInitialValues()} validationSchema={this.getValidationSchema()} validationSchemaOptions={{ showMultipleFieldErrors: true }} >
                    {(fProps) => (
                        <form>
                            {/* INIT */}
                            {this.state.fetchResult === ResultStatus.NOT_LOADED ? (<PageLoadingComponent classes={classes} label="Loading..." />) : null}
                            {/* Header Componenet */}
                            {this.state.fetchResult !== ResultStatus.NOT_LOADED && (this.state.fetchResult === ResultStatus.LOADED || this.state.fetchResult === ResultStatus.LOADING ||
                                this.state.fetchResult === ResultStatus.ERROR || this.state.fetchResult === ResultStatus.SUCCESS) ?
                                <PageDynamicHeaderComponent classes={classes} label="Analytics" rightPadding={16} divider
                                    leftActions={[
                                        { icon: MatIconService.RELOAD, title: "Reload", iconColor: "secondary", onClick: () => { this.fetchToken(this.state.reportOrDashboardId); }, },
                                    ]}
                                    rightActions={[
                                        {
                                            jsxElement: LayoutService.getDropDown(false, classes.dialogControl, classes.menuPaper, fProps, this.getValidationSchema(), "reportOrDashboardId", null,
                                                this.state.reportsList, "value", "key", "", false, "40%", (_formikProps, _reportId) => this.fetchToken(_reportId)
                                            )
                                        },
                                    ]}
                                /> : null
                            }
                            {/* Content Error */}
                            {this.state.fetchResult !== ResultStatus.NOT_LOADED && this.state.fetchResult !== ResultStatus.LOADING && DataService.isStringNullOrEmpty(this.state.reportOrDashboardId) ?
                                (<DialogErrorFragmentComponent description="Please select a Report." classes={classes} />) : null}
                            {/* Content Loader */}
                            {this.state.fetchResult === ResultStatus.LOADING ? (<PageLoadingComponent classes={classes} label="Loading Analytics..." />) : null}
                            {/* Content Error */}
                            {this.state.fetchResult === ResultStatus.ERROR ? (<DialogErrorFragmentComponent title="Error" description="Error Loading. Please Retry." classes={classes} />) : null}
                            {/* Content */}
                            {this.state.fetchResult === ResultStatus.SUCCESS ?
                                <Grid item xs={12}>
                                    <PowerBIEmbed
                                        embedConfig={{
                                            type: powerBiEmbedType,        // Supported types: report, dashboard, tile, visual and qna
                                            id: this.state.reportOrDashboardId,             // '<Report Id>',
                                            embedUrl: this.state.embedUrl,       // '<Embed Url>',
                                            accessToken: this.state.accessToken, // '<Access Token>',

                                            tokenType: models.TokenType.Embed, // Use models.TokenType.Aad if you're embedding for your organization.
                                            settings: {
                                                panes: {
                                                    filters: {
                                                        expanded: false,
                                                        visible: true
                                                    }
                                                },
                                                // background: models.BackgroundType.Transparent,
                                                // layoutType: models.LayoutType.Custom,
                                                // customLayout: {
                                                //     displayOption: models.DisplayOption.FitToPage
                                                // }
                                            }
                                        }}
                                        eventHandlers={
                                            new Map([
                                                ['loaded', function () { console.log('Report loaded'); }],
                                                ['rendered', function () { console.log('Report rendered'); }],
                                                ['error', function (event) { console.log(event.detail); }]
                                            ])
                                        }
                                        cssClassName={"report-style-class"}
                                        // style={{ height: "100%", backgroundColor: "green" }}
                                        getEmbeddedComponent={(embeddedReport) => {
                                            // embeddedReport.fullscreen();
                                            window.report = embeddedReport;
                                        }}
                                    />
                                </Grid>
                                : null
                            }
                            <Grid item xs={12}>&nbsp;</Grid>
                        </form>
                    )}</Formik>
            </div>
        )
    }
}
export default LayoutService.getHocComponenet(PowerBIDemoComponent);

