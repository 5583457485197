import ApiService from "../../../shared/services/apiService";
import Cache from "../../../shared/store/cache";
import { GST_API } from "../../../shared/types/enums";

export default class TenantProfileSetupService {
  static CACHE = new Cache(this.fetchFromApiObs);

  static getObs(_ignoreCache, clientAuthContext) {
    if (_ignoreCache || this.CACHE.NOT_EXISTS()) {
      return this.fetchFromApiObs(clientAuthContext);
    } else {
      return this.CACHE.getOBS();
    }
  }

  /**
   * Replace the static data inside of(...) with real ApiCall
   * @returns observable
   */
  static fetchFromApiObs(clientAuthContext) {
    // clear the cache before returning the actual subscription
    this.CACHE.CLEAR();
    // replace this._jsonData with reacl ApiCall
    return ApiService.fetchHTTP_API(GST_API.TenantConfig, `/CompanyProfile/CompanyProfile/${clientAuthContext.user.tenantId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

}
