import React from "react";
import { combineLatest } from "rxjs";
import { Formik } from "formik";
import * as Yup from "yup";
import { TextField, Grid } from "@material-ui/core";

import { ResultStatus, CrudAction, API_ENDPOINT, ENTITY_TYPE } from "../../../shared/types/enums";
import { AuthContext } from "../../../shared/store/authProvider";
import { SubscriptionArray, DataService } from "../../../shared/services/dataService";
import LookupService from "../../../shared/services/lookupService";
import LayoutService from "../../../shared/services/layoutService";
import ApiService from "../../../shared/services/apiService";

import PageLoadingComponent from "../../../shared/components/page/pageLoadingComponent";
import SectionComponent from "../../../shared/components/sectionComponent";
import DialogErrorFragmentComponent from "../../../shared/components/page/dialogErrorFragmentComponent";
import RolePermissionService from "../../../shared/role-permissions/rolePermissionService";
import PageDynamicHeaderComponent from "../../../shared/components/page/pageDynamicHeaderComponent";
import { MatIconService } from "../../../shared/services/theme/matIconService";

class SourceDetailsComponent extends React.Component {

  static contextType = AuthContext;
  oSubscriptions = new SubscriptionArray();

  constructor(props) {
    super(props);
    // init state
    this.state = {
      isReadOnly: this.props.inputAction === CrudAction.UPDATE ? true : false,
      isEditing: this.props.inputAction === CrudAction.UPDATE ? true : false,
      fetchResult: ResultStatus.NOT_LOADED,

      // Lookups
      sourceTypeList: [],

      // source-permission - lookups
      // affiliates
      isAffiliatesLoading: true,
      scopeAffiliationsList: [], // holds all
      selAffiliationsList: [], // holds chip selected
      // affiliate-vendors
      isVendorsLoading: true,
      scopeVendorsList: [], // filtered by affiliates
      selVendorsList: [], // holds chip selected
      // vendor-users
      isUsersLoading: true,
      scopeUsersList: [], // filtered by affiliates & vendors
      selUsersList: [], // holds chip selected
    };
  }

  componentWillUnmount() {
    this.oSubscriptions.cancelAll();
  }

  componentDidMount() {
    this.setState({ fetchResult: ResultStatus.LOADING });

    this.oSubscriptions.add(
      combineLatest([
        LookupService.getSourceTypesAsOBS(this.context),
        LookupService.getAffiliationsAsOBS(this.context),
        LookupService.getAffiliationsBySystemAsOBS(this.context.user.tenantId, this.props.systemId),
        LookupService.getVendorsBySystemAsOBS(this.context.user.tenantId, this.props.systemId),
        LookupService.getUsersBySystemAsOBS(this.context.user.tenantId, this.props.systemId),
      ]).subscribe(([_sourceTypeList, _scopeAffiliationsList, _selAffiliationsList, _selVendorsList, _selUsersList]) => {

        // remove <all> from SCOPE affiliationList & transform its elemts into  {id: 1, text: "affialite 1"}
        _scopeAffiliationsList = DataService.getKeyValueCollection(_scopeAffiliationsList, "companyAffiliateId", "affiliateName", false); // no All

        // remove all from selection
        _selAffiliationsList = DataService.removeElementAll(_selAffiliationsList, "id");
        _selVendorsList = DataService.removeElementAll(_selVendorsList, "id");
        _selUsersList = DataService.removeElementAll(_selUsersList, "id");

        this.oSubscriptions.add(
          LookupService.getVendorsByAffiliationsAsOBS(this.context.user.tenantId, _selAffiliationsList).subscribe((_scopeVendorsList) => {
            // remove <all> from SCOPE vendorList
            _scopeVendorsList = DataService.removeElementAll(_scopeVendorsList, "id"); // remove <all> before passing

            this.oSubscriptions.add(
              LookupService.getUsersByVendorsAsOBS(this.context.user.tenantId, _selAffiliationsList, _selVendorsList).subscribe((_scopeUsersList) => {
                // remove <all> from SCOPE userList
                _scopeUsersList = DataService.removeElementAll(_scopeUsersList, "id"); // remove <all> before passing

                _sourceTypeList = _sourceTypeList.filter(x => {
                  if (x.isPorzioEntity)
                    return x;
                });
                // then set the state
                this.setState(
                  {
                    sourceTypeList: _sourceTypeList,
                    // source-permissions
                    scopeAffiliationsList: _scopeAffiliationsList.length > 0 ? DataService.addElementAll(_scopeAffiliationsList, "id") : [], // adds <all> element
                    scopeVendorsList: _scopeVendorsList.length > 0 ? DataService.addElementAll(_scopeVendorsList, "id") : [], // adds <all> element
                    scopeUsersList: _scopeUsersList.length > 0 ? DataService.addElementAll(_scopeUsersList, "id") : [], // adds <all> element

                    // if both the scope and the selected length are equal then select only All
                    // items that are already selected if any
                    selAffiliationsList: _selAffiliationsList.length > 0 && _scopeAffiliationsList.length === _selAffiliationsList.length ? [LookupService.allElement] : _selAffiliationsList,
                    selVendorsList: _selVendorsList.length > 0 && _scopeVendorsList.length === _selVendorsList.length ? [LookupService.allElement] : _selVendorsList,
                    selUsersList: _selUsersList.length > 0 && _scopeUsersList.length === _selUsersList.length ? [LookupService.allElement] : _selUsersList,

                    isAffiliatesLoading: false,
                    isVendorsLoading: false,
                    isUsersLoading: false,
                  },
                  // change the state after all the above are assigned
                  () => { this.setState({ fetchResult: ResultStatus.LOADED }); }
                );
              })
            );
          })
        );
      })
    );
  }

  handleSubmit = async (_formikProps) => {
    console.log(_formikProps.values);
    if (!_formikProps.isSubmitting && _formikProps.isValid) {
      await this.validationSchema.validate(_formikProps.values, { abortEarly: false })
        .then((x) => {

          // 1) set the status
          this.setState({ fetchResult: ResultStatus.SAVING });

          // 2) extract the formik values into an object
          var mappedObj = {
            sourceLabel: _formikProps.values.sourceLabel,
            toolTip: _formikProps.values.sourceLabel,
            sourceFileDescription: _formikProps.values.sourceFileDescription,
            sourceTypeId: _formikProps.values.sourceTypeId,
            emailTO: _formikProps.values.emailTO,

            isBusinessThreshold: _formikProps.values.isBusinessThreshold,
            businessRuleThresholdPercentage: Number(_formikProps.values.businessRuleThresholdPercentage),
            isBussinessRuleThresholdNotImport: _formikProps.values.isBussinessRuleThresholdNotImport ? _formikProps.values.isBussinessRuleThresholdNotImport : false,

            isUnmatchedRecipientThreshold: _formikProps.values.isUnmatchedRecipientThreshold,
            unmatchedRecipientThresholdPercentage: Number(_formikProps.values.unmatchedRecipientThresholdPercentage),
            isUnmatchedRecipientThresholdNotImport: _formikProps.values.isUnmatchedRecipientThresholdNotImport ? _formikProps.values.isUnmatchedRecipientThresholdNotImport : false,

            isOverwriteRecordStatus: false, // _formikProps.values.isOverwriteRecordStatus,
            isAutoProcessTransaction: _formikProps.values.isAutoProcessTransaction,
            isAutoProcessProfile: _formikProps.values.isAutoProcessProfile,
            isAutoProcessTransactionToNewProfiles: _formikProps.values.isAutoProcessTransactionToNewProfiles,
            isAutoProcess: _formikProps.values.isAutoProcess,
            autoMatchingScore: Number(_formikProps.values.autoMatchingScore),

            createdBy: this.context.user.userId,
            tenantId: this.context.user.tenantId,
            statusId: 1,
            active: _formikProps.values.active
          };

          // 3) determine the action and assign the appropriate props
          const actionVerb = "POST";
          if (this.props.inputAction === CrudAction.CREATE) {
            mappedObj.systemId = 0;
          } else {
            mappedObj.systemId = this.props.systemId;
          }

          // 4) save to Api and subscribe for the result
          this.oSubscriptions.add(
            ApiService.setOBS(actionVerb, API_ENDPOINT.CORE, `/SourceSystems`, JSON.stringify(mappedObj)).subscribe(
              (successResult) => {
                if (successResult) { this._updateSourcePermissions(successResult.value); }
                else {
                  console.error("Error while saving vendor details", successResult);
                  this.setState({ fetchResult: ResultStatus.ERROR });
                }
              },
              (errorResult) => {
                console.error("Error while saving vendor details", errorResult);
                this.setState({ fetchResult: ResultStatus.ERROR });
              }
            )
          );
        })
        .catch((erroObj) => {
          erroObj.inner.forEach(err => { _formikProps.setFieldError(err.path, err.message); });
        });
    }
  }

  _updateSourcePermissions(sourceSystemId) {
    this.oSubscriptions.add(
      combineLatest([
        ApiService.setOBS("POST", API_ENDPOINT.CORE,
          `/SourceSystems/UpdateAffiliations/${this.context.user.tenantId}/${sourceSystemId}`, JSON.stringify(DataService.getScopeKeys(this.state.selAffiliationsList, this.state.scopeAffiliationsList, "id"))),
        ApiService.setOBS("POST", API_ENDPOINT.CORE,
          `/SourceSystems/UpdateVendors/${this.context.user.tenantId}/${sourceSystemId}`, JSON.stringify(DataService.getScopeKeys(this.state.selVendorsList, this.state.scopeVendorsList, "id"))),
        ApiService.setOBS("POST", API_ENDPOINT.CORE,
          `/SourceSystems/UpdateUsers/${this.context.user.tenantId}/${sourceSystemId}`, JSON.stringify(DataService.getScopeKeys(this.state.selUsersList, this.state.scopeUsersList, "id"))),
      ]).subscribe(
        // success
        ([updateAffialitionsResult, updateVendorsResult, updateUsersResult]) => {
          if (updateAffialitionsResult.status === 200) { console.log("Affiliate Chips Updated"); }
          if (updateVendorsResult.status === 200) { console.log("Vendor Chips Updated"); }
          if (updateUsersResult.status === 200) { console.log("User Chips Updated"); }

          this.setState({ fetchResult: ResultStatus.SUCCESS, });
          this.props.refreshSourceGrid(true);
          this.props.onClose(false);
        },
        // error
        () => {
          this.setState({ fetchResult: ResultStatus.ERROR });
          // TODO: update the retry callback as <updateSourcePermissions> in the setState
        })
    );
  }

  getInitialValues() {
    if (this.props.inputAction === CrudAction.CREATE) {
      return {
        sourceLabel: "",
        sourceFileDescription: "",
        sourceTypeId: "",
        emailTO: "",

        isBusinessThreshold: false,
        businessRuleThresholdPercentage: "",
        isBussinessRuleThresholdNotImport: false,

        isUnmatchedRecipientThreshold: false,
        unmatchedRecipientThresholdPercentage: "",
        isUnmatchedRecipientThresholdNotImport: false,
        active: true,

        isOverwriteRecordStatus: false,
        isAutoProcessTransaction: false,
        isAutoProcessProfile: false,
        isAutoProcessTransactionToNewProfiles: false,
        isAutoProcess: false,
        autoMatchingScore: 0,

        // Source Permissions : selected values should only be assigned here
        selAffiliationsList: [],
        selVendorsList: [],
        selUsersList: [],
      };
    } else {
      return {
        sourceLabel: this.props.modalAgNode.sourceLabel,
        sourceFileDescription: this.props.modalAgNode.sourceFileDescription,
        sourceTypeId: Number.isInteger(this.props.modalAgNode.sourceTypeId) ? this.props.modalAgNode.sourceTypeId : 1,
        emailTO: this.props.modalAgNode.emailTO,

        isBusinessThreshold: this.props.modalAgNode.isBusinessThreshold,
        businessRuleThresholdPercentage: this.props.modalAgNode.businessRuleThresholdPercentage,
        isBussinessRuleThresholdNotImport: this.props.modalAgNode.businessThreshold === "Enabled", // string

        isUnmatchedRecipientThreshold: this.props.modalAgNode.isUnmatchedRecipientThreshold,
        unmatchedRecipientThresholdPercentage: this.props.modalAgNode.unmatchedRecipientThresholdPercentage,
        isUnmatchedRecipientThresholdNotImport: this.props.modalAgNode.unmatchedRecipientThreshold === "Enabled", // string
        active: this.props.modalAgNode.active,

        isOverwriteRecordStatus: false, //this.props.modalAgNode.isOverwriteRecordStatus,
        isAutoProcessTransaction: this.props.modalAgNode.isAutoProcessTransaction,
        isAutoProcessProfile: this.props.modalAgNode.isAutoProcessProfile,
        isAutoProcessTransactionToNewProfiles: this.props.modalAgNode.isAutoProcessTransactionToNewProfiles,
        isAutoProcess: this.props.modalAgNode.isAutoProcess,
        autoMatchingScore: this.props.modalAgNode.autoMatchingScore,

        // Source Permissions : selected values should only be assigned here
        selAffiliationsList: this.state.selAffiliationsList,
        selVendorsList: this.state.selVendorsList,
        selUsersList: this.state.selUsersList,
      };
    }
  }

  validationSchema = Yup.object().shape({});
  getValidationSchema() {
    this.validationSchema = Yup.object().shape({

      sourceLabel: Yup.string().required("Required").min(3, "Must be at least 3 characters long").max(256, "Must be 256 characters or less"),
      sourceFileDescription: Yup.string().required("Required").min(3, "Must be at least 3 characters long").max(256, "Must be 256 characters or less"),
      sourceTypeId: Yup.string().required("Required"),
      emailTO: Yup.array()
        .transform(function (value, originalValue) {
          if (this.isType(value) && value !== null) {
            return value;
          }
          return originalValue ? originalValue.split(/[\s,]+/) : [];
        })
        .of(Yup.string().email(({ value }) => `${value} is not a valid email, separate multiple emails by comma`)),
      isBusinessThreshold: Yup.boolean().required("Required"),
      businessRuleThresholdPercentage: Yup.number()
        .when('isBusinessThreshold', {
          is: true,
          then: Yup.number().required("Required").min(0, "Must be above 0").max(100, "Must be below 100"),
          otherwise: Yup.number().min(0, "Must be above 0").max(100, "Must be below 100").transform((_, val) => val === "" ? 0 : Number(val)),
        }),
      isBussinessRuleThresholdNotImport: Yup.boolean(),

      isUnmatchedRecipientThreshold: Yup.boolean().required("Required"),
      unmatchedRecipientThresholdPercentage: Yup.number()
        .when('isUnmatchedRecipientThreshold', {
          is: true,
          then: Yup.number().required("Required").min(0, "Must be above 0").max(100, "Must be below 100"),
          otherwise: Yup.number().min(0, "Must be above 0").max(100, "Must be below 100").transform((_, val) => val === "" ? 0 : Number(val)),
        }),
      isUnmatchedRecipientThresholdNotImport: Yup.boolean(),

      active: Yup.boolean(),

      autoMatchingScore: Yup.number().required("Required").min(0, "Must be above 0").max(100, "Must be below 100"),

      // Source Permissions
      selAffiliationsList: DataService.hasElements(this.state.scopeAffiliationsList) ? Yup.array().required("Required") : Yup.array(),
      selVendorsList: DataService.hasElements(this.state.scopeVendorsList) ? Yup.array().required("Required") : Yup.array(),
      selUsersList: DataService.hasElements(this.state.scopeUsersList) ? Yup.array().required("Required") : Yup.array(),
    });
    return this.validationSchema;
  }


  render() {
    const { classes } = this.props;

    const canAndForCreate = RolePermissionService.SOURCE_FILE_DETAIL.canCreate && this.props.inputAction === CrudAction.CREATE;
    const canAndForEdit = RolePermissionService.SOURCE_FILE_DETAIL.canEdit && this.props.inputAction === CrudAction.UPDATE;

    switch (this.state.fetchResult) {
      case ResultStatus.NOT_LOADED:
      case ResultStatus.LOADING:
        return <PageLoadingComponent small classes={classes} label="Loading Source Details" />;
      case ResultStatus.SAVING:
        return <PageLoadingComponent small classes={classes} label="Saving Source Details" />;
      case ResultStatus.LOADED:
      case ResultStatus.SUCCESS:
        return (
          <Formik initialValues={this.getInitialValues()} validationSchema={this.getValidationSchema()} validationSchemaOptions={{ showMultipleFieldErrors: true }}>
            {(fProps) => (
              <form>
                <SectionComponent classes={classes} enableEditJsx={
                  canAndForCreate ? LayoutService.getReadOnlyActionsSolo(this, true, () => { this.handleSubmit(fProps) }, () => { })
                    : canAndForEdit ? LayoutService.getReadOnlyActionsSolo(this, false, () => { this.handleSubmit(fProps) })
                      : <></>
                } />

                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}> <TextField required {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "sourceLabel", "Source Name")} style={{ minWidth: "90%" }} /> </Grid>
                  <Grid item xs={12} sm={6}> <TextField required {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "sourceFileDescription", "Source File Description")} style={{ minWidth: "90%" }} /> </Grid>
                  <Grid item xs={12} sm={6}> {LayoutService.getSelectControl((this.state.isReadOnly && this.props.inputAction === CrudAction.UPDATE), classes, fProps, "sourceTypeId", "Source Type", this.state.sourceTypeList, "entityid", "entityname", "90%", false)} </Grid>
                  <Grid item xs={12} sm={6}> {LayoutService.getSwitch(this.state.isReadOnly, classes, fProps, "active", "Active")} </Grid>
                  <Grid item xs={12} sm={12}> <TextField required {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "emailTO", "Email To")} style={{ minWidth: "90%" }} /> </Grid>
                </Grid>

                <SectionComponent classes={classes} label={"Thresholds"} />
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={4}> {LayoutService.getSwitch(this.state.isReadOnly, classes, fProps, "isBusinessThreshold", "Business Threshold")} </Grid>
                  <Grid item xs={12} sm={4}> <TextField required {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "businessRuleThresholdPercentage", "Business Rule Threshold(%)", fProps.values.isBusinessThreshold)} style={{ minWidth: "90%" }} /> </Grid>
                  <Grid item xs={12} sm={4}> {LayoutService.getSwitch(this.state.isReadOnly, classes, fProps, "isBussinessRuleThresholdNotImport", "Automatic Not Import File (If Business Rule Threshold Exceeded)", false)} </Grid>
                  <Grid item xs={12} sm={4}> {LayoutService.getSwitch(this.state.isReadOnly, classes, fProps, "isUnmatchedRecipientThreshold", "Unmatched Recipient Threshold", false)} </Grid>
                  <Grid item xs={12} sm={4}> <TextField required {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "unmatchedRecipientThresholdPercentage", "Unmatched Recipient Threshold(%)", fProps.values.isUnmatchedRecipientThreshold)} style={{ minWidth: "90%" }} /> </Grid>
                  <Grid item xs={12} sm={4}> {LayoutService.getSwitch(this.state.isReadOnly, classes, fProps, "isUnmatchedRecipientThresholdNotImport", "Automatic Not Import File (If Unmatched Recipient Threshold Exceeded)")} </Grid>
                </Grid>

                <SectionComponent classes={classes} label={"Automated Processing"} />
                <Grid container spacing={1}>
                  {/* <Grid item xs={12} sm={6}> {LayoutService.getSwitch(this.state.isReadOnly, classes, fProps, "isOverwriteRecordStatus", "Overwrite Record Status During Import Process")} </Grid> */}
                  <Grid item xs={12} sm={4}> {LayoutService.getSwitch(this.state.isReadOnly, classes, fProps, "isAutoProcessTransaction", "Require Eligibility Confirmation for New Transactions")} </Grid>
                  <Grid item xs={12} sm={4}> {LayoutService.getSwitch(this.state.isReadOnly, classes, fProps, "isAutoProcessProfile", "Require Approval for New Profiles")} </Grid>
                  {fProps.values.sourceTypeId == ENTITY_TYPE.TRANSACTION && <Grid item xs={12} sm={4}> {LayoutService.getSwitch(this.state.isReadOnly, classes, fProps, "isAutoProcessTransactionToNewProfiles", "Auto Matching Transactions to New Profiles")} </Grid>}
                  <Grid item xs={12} sm={4}> {LayoutService.getSwitch(this.state.isReadOnly, classes, fProps, "isAutoProcess", "Auto Matching")} </Grid>
                  <Grid item xs={12} sm={4}>  <TextField required {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "autoMatchingScore", "Matching Threshold (%)")} fullWidth /></Grid>
                </Grid>

                <SectionComponent classes={classes} label={"Source Permissions"} />
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>{this.state.isAffiliatesLoading ? null : LayoutService.getChipSelect(this.state.isReadOnly, classes, fProps, "selAffiliationsList", "Affiliated Companies", this.state.scopeAffiliationsList, "id", "text", this.onAffiliatesChange, "80%", "")}</Grid>
                  <Grid item xs={12} sm={6}>{this.state.isVendorsLoading || DataService.hasNoElements(this.state.selAffiliationsList) ? null : LayoutService.getChipSelect(this.state.isReadOnly, classes, fProps, "selVendorsList", "Vendors", this.state.scopeVendorsList, "id", "text", this.onVendorsChange, "80%", "")}</Grid>
                  <Grid item xs={12} sm={6}>{this.state.isUsersLoading || DataService.hasNoElements(this.state.selVendorsList) ? null : LayoutService.getChipSelect(this.state.isReadOnly, classes, fProps, "selUsersList", "Users", this.state.scopeUsersList, "id", "text", this.onUsersChange, "80%", "")}</Grid>
                </Grid>
              </form>
            )}
          </Formik>
        );

      case ResultStatus.ERROR:
      default:
        return (
          <DialogErrorFragmentComponent title="Error" description="Error Loading Source Details"
            classes={classes} onRetry={() => { console.log('Retry not defined!'); }} />
        );
    }
  }

  onAffiliatesChange = async (_formikProps, _newValues) => {
    // 1) clear the selected userList & its datasource
    await this._clearLookupStuff(_formikProps, "isUsersLoading", "selUsersList", "scopeUsersList");
    // 2) clear the selected vendorList & its datasource
    await this._clearLookupStuff(_formikProps, "isVendorsLoading", "selVendorsList", "scopeVendorsList");
    // 3) set the newely selectedAffiliations
    this.setState({ selAffiliationsList: _newValues }); // this is not formik equivalent, but updating just for reference
    // 4) filterFetch the vendorsList and set it as the datasource
    if (DataService.hasElements(_newValues)) {
      // clear others if <All> is selected
      await this._clearOthersIfAllIsSelected(_newValues, _formikProps, "selAffiliationsList");
      // fetch and assign
      LookupService.getVendorsByAffiliationsAsOBS(this.context.user.tenantId, _newValues).subscribe(
        (successResult) => {  // load datasource for vendors-ChipSelect
          this.setState({
            scopeVendorsList: successResult.length > 0 ? DataService.addElementAll(successResult, "id") : [],
            isVendorsLoading: false
          });
        },
        (errorResult) => { console.log("Error loading Affiliate-Vendors", errorResult); }
      );
    }
  }

  onVendorsChange = async (_formikProps, _newValues) => {
    // 1) clear the selected userList & its datasource
    await this._clearLookupStuff(_formikProps, "isUsersLoading", "selUsersList", "scopeUsersList");
    // 2) set the newely selVendorsList
    this.setState({ selVendorsList: _newValues }); // this is not formik equivalent, but updating just for reference
    // 3) filterFetch the vendorsList and set it as the datasource
    if (DataService.hasElements(_newValues)) {
      // clear others if <All> is selected
      await this._clearOthersIfAllIsSelected(_newValues, _formikProps, "selVendorsList");
      // fetch and assign
      LookupService.getUsersByVendorsAsOBS(this.context.user.tenantId, this.state.selAffiliationsList, _newValues).subscribe(
        (successResult) => {  // load datasource for users-ChipSelect
          this.setState({
            scopeUsersList: successResult.length > 0 ? DataService.addElementAll(successResult, "id") : [],
            isUsersLoading: false
          });
        },
        (errorResult) => { console.log("Error loading Vendor-Users", errorResult); }
      );
    }
  }

  onUsersChange = async (_formikProps, _newValues) => {
    // 1) set the newely selUsersList
    this.setState({ selUsersList: _newValues }); // this is not formik equivalent, but updating just for reference
    // 2) clear others if <All> is selected
    if (DataService.hasElements(_newValues)) {
      await this._clearOthersIfAllIsSelected(_newValues, _formikProps, "selUsersList");
    }

  }

  // Utils
  _clearOthersIfAllIsSelected = async (_newValues, _formikProps, _propName) => {
    // check for <All> Item
    const itemAll = DataService.getFirstOrDefault(_newValues.filter((x) => x.id === LookupService.allElement.id));
    // if <all> is selected and is not the only selected item, then clear others
    if (itemAll && _newValues.length !== 1) {
      // then clear the others except All
      await _formikProps.setFieldValue(_propName, [itemAll], true);
      this.setState({ [_propName]: [itemAll] });
    }
  }

  _clearLookupStuff = async (_formikProps, _loadingKey, _selectionKey, _scopeKey) => {
    this.setState({ [_loadingKey]: true }); // set the vendors loading as true
    await _formikProps.setFieldValue(_selectionKey, [], true);
    await _formikProps.setFieldTouched(_selectionKey, true, false);
    this.setState({ [_selectionKey]: [], [_scopeKey]: [] }); // clear the user datasource
  }

}

/** HOC */
export default LayoutService.getHocComponenet(SourceDetailsComponent);