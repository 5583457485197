import React, { Component, createRef } from "react";
import csv_icon from "../../../public/images/csv-export-icon.png";
import excel_icon from "../../../public/images/xls-export-icon.png";
import pdf_icon from "../../../public/images/pdf-icon.png";
import ms_word_icon from "../../../public/images/ms-word-icon.png";
import unknown_file from "../../../public/images/UnknownFile.png";

export default class AgGridDownloadFileViaImageCellRenderer extends Component {
    link = createRef();

    constructor(props) {
        super(props);
        this.invokeParentMethod = this.invokeParentMethod.bind(this);
    }

    invokeParentMethod = async () => {

        console.log("RowCol", `Row: ${this.props.node.rowIndex}, Col: ${this.props.colDef.headerName}`,);
        console.log("this.props.node.data", this.props.node.data,);
        console.log("this.props.value", this.props.value,);
        console.log("this.link", this.link,);
        console.log("window.URL", window.URL);

        if (this.link.current.href) { return }
        let blob = await this.props.context.componentParent.handleDownloadAg(`Row: ${this.props.node.rowIndex}, Col: ${this.props.colDef.headerName}`, this.props.node.data, this.props.value, this.link, window.URL);
        const href = window.URL.createObjectURL(blob);
        this.link.current.download = this.props.node.data.blobName;
        this.link.current.href = href;
        this.link.current.click();
    }

    render() {
        let imageSrc = excel_icon;
        let imageAlt = "Excel Icon";

        switch (this.props.value) {
            case "csv":
                imageSrc = csv_icon;
                imageAlt = "CSV";
                break;
            case "xls":
            case "xlsx":
                imageSrc = excel_icon;
                imageAlt = "Excel";
                break;
            case "pdf":
                imageSrc = pdf_icon;
                imageAlt = "PDF";
                break;
            case "doc":
            case "docx":
                imageSrc = ms_word_icon;
                imageAlt = "MS Word";
                break;
            default:
                imageSrc = unknown_file;
                imageAlt = "Unknown File";
        }

        return (
            // eslint-disable-next-line
            <a role='button'
                style={{ textDecoration: "underline" }}
                onClick={this.invokeParentMethod}
                ref={this.link}            >
                <img src={imageSrc} alt={imageAlt} style={{ marginBottom: 25 + 'px', width: 25 + 'px' }} />
            </a>
        );
    }
}
