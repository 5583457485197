import { AgGridColumnExt } from "../../../../shared/services/ag-grid/agGridColumnExt";
import ApiService from "../../../../shared/services/apiService";
import { API_ENDPOINT } from "../../../../shared/types/enums";

export default class LinkedProfilesService {

    static getLinkedProfilesAsOBS = (_tenantId, _userId, _modalAgNode, _parentProfile, _selectedProfile) => {

        const targetUrl = `/Profiles/GetLinkedProfiles`;
        var mappedObj = {
            searchValues: _selectedProfile.length !== 0 ? "" + _modalAgNode.prid + "," + _selectedProfile : "" + _modalAgNode.prid,
            tenantId: _tenantId,
            userId: _userId,
            parentId: _parentProfile,
            page: 1,
            pageSize: 100
        }

        return ApiService.postOBS(
            API_ENDPOINT.CORE,
            targetUrl,
            JSON.stringify(mappedObj)
        );
    };

    /**
     * Refer url for more details: https://www.ag-grid.com/javascript-grid-column-properties/
     * @param _componentThisRef pass {this} keyword from the calling componenet
     */
    static getColumnDefs(_componentThisRef, _onSelectCallback) {
        return [
            AgGridColumnExt.GET(!_componentThisRef.props.isReadOnly, "text", "isSelected", "Select").fixedWidth(88).headerClass("center-text").cellRenderer("isCheckedCellRenderer").SET(),
            AgGridColumnExt.GET(false, "text", "PORZIOGSTPROFILEID", "Porzio GST Profile ID").fixedWidth(144).cellRenderer("childMessageRendererComponent").headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "RELATIONSHIP", "Relationship").fixedWidth(128).headerClass("center-text").centerAlignText().SET(),
            //AgGridColumnExt.GET(false, "text", "SOURCEFROM", "Source Form").minAndDefaultWidth(128).tooltip().SET(),
            AgGridColumnExt.GET(false, "text", "LASTNAME", "Last Name").minAndDefaultWidth(128).tooltip().SET(),
            AgGridColumnExt.GET(false, "text", "FIRSTNAME", "First Name").minAndDefaultWidth(128).tooltip().SET(),
            AgGridColumnExt.GET(false, "text", "ORGANIZATIONNAME", "Organization Name").flexWeight(1).tooltip().SET(),
            AgGridColumnExt.GET(false, "text", "COUNTRY", "Country").minAndDefaultWidth(128).SET(),
            AgGridColumnExt.GET(false, "text", "NUMBEROFTRANSACTIONS", "Number of Transactions").fixedWidth(128).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "NUMBEROFLINKEDPROFILES", "Number Of Linked Profiles").fixedWidth(128).headerClass("center-text").centerAlignText().SET(),
        ];
    }
}