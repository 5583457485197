import React from "react";
import { ReplaySubject, combineLatest, of } from "rxjs";
import { Formik } from "formik";
import * as Yup from "yup";
import { Box, Divider, Typography, Grid, LinearProgress } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import { AuthContext } from "../../../../shared/store/authProvider";
import { API_ENDPOINT, CrudAction, ENTITY_FIELD_TYPE, ENTITY_TYPE, ResultStatus } from "../../../../shared/types/enums";
import { DataService, SubscriptionArray } from "../../../../shared/services/dataService";
import LookupService from "../../../../shared/services/lookupService";
import LayoutService from "../../../../shared/services/layoutService";
import DynamicControlService from "../../../../shared/services/dynamicControlService";

import PageLoadingComponent from "../../../../shared/components/page/pageLoadingComponent";
import DialogErrorFragmentComponent from "../../../../shared/components/page/dialogErrorFragmentComponent";
import MatThemeService from "../../../../shared/services/theme/matThemeService";
import AgreementTabService from "./agreementTabService";
import ApiService from "../../../../shared/services/apiService";
import ToastService from "../../../../shared/services/toastService";
import { MatIconService } from "../../../../shared/services/theme/matIconService";
import RecipientSearchDialogComponent from "../agreement-tab/recipient-information-dialog/recipientSearchDialogComponent";
import RolePermissionService from "../../../../shared/role-permissions/rolePermissionService";
import RuleSummaryService from "../rule-summary/ruleSummaryService";
import ProfileDetailDialogComponent from "../../../profile-center/profile-detail/profileDetailDialogComponent";

class AgreementTabComponent extends React.Component {

    static contextType = AuthContext;
    oSubscriptions = new SubscriptionArray();

    constructor(props) {
        super(props);
        // init state
        this.state = {
            // isReadOnly: true, not applicable here since it will be sent as a props by the parent Container
            isNew: props.inputAction === CrudAction.CREATE,
            selAgreementId: props.modalAgNode?.agreementId ?? "",
            selPorzioGSTAgreementId: props.modalAgNode?.porzioGstAgreementId ?? "",
            // 1) source-name
            fetchAgreementDataResult: ResultStatus.NOT_LOADED,
            sourceSystemList: [],

            templateFetchResult: ResultStatus.NOT_LOADED,
            allGroupFieldsMap: new Map(),
            dynamicControlsValidationSchema: Yup.object().shape({}),

            showSearchRecipientDialog: false,
            recipientProfileInfo: {},
            recipientIdentifierInfo: {},
            recipientAddressInfo: {},
        };
    }

    componentWillUnmount() { }
    componentDidMount() {
        LookupService.fetchCommonLookups(this.context);
        this.fetchAgreementData();
    }


    //#region SourceSystem DropDown

    // api
    fetchAgreementData = () => {
        this.oSubscriptions.cancelAll();

        // set the loading state
        this.setState({ fetchAgreementDataResult: ResultStatus.LOADING });
        // fetch-source-list
        this.oSubscriptions.add(
            combineLatest([
                LookupService.getFormattedCountriesAsOBS(this.context, null),
                // get source system list
                LookupService.getSourceSystemsByEntityAsOBS(this.context.user.tenantId, ENTITY_TYPE.AGREEMENT),
                // for new don't get the data, set it null (addressList & contactsList are included)
                (this.state.isNew ? of(null) : LookupService.getAgreementTabDetailsWithErrorInfoAsOBS(this.context.user.tenantId, this.state.selAgreementId)),
                (this.state.isNew ? of(null) : RuleSummaryService.getRuleSummaryAsOBS(this.context.user.tenantId, this.state.selAgreementId)),
                LookupService.getUserCurrencyAsOBS(this.context.user.userId),
                LookupService.fetchCurrenciesAsOBS(),
            ]).subscribe(
                // success result
                ([_countryList, _sourceSystemList, _initialValuesOrNull, _rulesSummaryOrNull, _userCurrencyObject, _currencyList,]) => {

                    // converting all keys to lowercase
                    if (!this.state.isNew) {
                        const keys = Object.keys(_initialValuesOrNull);
                        let lowerCaseObj = {};
                        keys.forEach(key => {
                            lowerCaseObj[key.toLowerCase()] = _initialValuesOrNull[key];
                        });
                        _initialValuesOrNull = lowerCaseObj;
                    }

                    if (_initialValuesOrNull) {
                        _initialValuesOrNull['countryid'] = _initialValuesOrNull['country']; // api is returning countryid in country

                        // 1/2) parse error fileds
                        _initialValuesOrNull = DynamicControlService.parseErrorFields(_initialValuesOrNull);
                        _initialValuesOrNull = DynamicControlService.parseCustomFields(_initialValuesOrNull);
                        _initialValuesOrNull["totalamount"] = Number(_initialValuesOrNull?.totalamount).toFixed(2);
                        _initialValuesOrNull["userpersonalcurrency"] = _userCurrencyObject.currencyNameFormatted;
                        _initialValuesOrNull["convertedcurrencytotalamount"] = "n/a - base or conversion currency not found";
                        // append error information
                        _initialValuesOrNull.erroredFields = _rulesSummaryOrNull || [];
                    }

                    if (_currencyList.find(o => o.currencyid === _initialValuesOrNull?.basecurrencyid)) {
                        this.oSubscriptions.add(
                            LookupService.getConvertedTotalAmountAsOBS(this.context.user.tenantId, _currencyList.find(o => o.currencyid === _initialValuesOrNull.basecurrencyid).currencyAcronym, _userCurrencyObject.currencyAcronym, _initialValuesOrNull.totalamount).subscribe((_convertedTotalAmount) => {

                                _initialValuesOrNull["convertedcurrencytotalamount"] = DataService.isStringNullOrEmpty(_convertedTotalAmount) ? "n/a - conversion service not available" : _convertedTotalAmount;

                                this.setState({
                                    prid: _initialValuesOrNull?.prid,
                                    porziogstprofileid: _initialValuesOrNull?.porziogstprofileid,
                                    countryList: _countryList,
                                    countryCellSource: DataService.arrayToObject(_countryList, "id", "value"),

                                    sourceSystemList: DataService.getKeyValueCollection(_sourceSystemList, "sourceId", "sourceName", false),

                                    initialValuesOrNull: _initialValuesOrNull,
                                }, () => {
                                    this.onSourceSystemChange(this.state.isNew ? null : _initialValuesOrNull.sourceid, true);
                                    this.setState({ fetchAgreementDataResult: ResultStatus.LOADED });
                                    this.props.onAgreementTabLoaded(_initialValuesOrNull);
                                });
                            }));
                    } else {
                        this.setState({
                            prid: _initialValuesOrNull?.prid,
                            porziogstprofileid: _initialValuesOrNull?.porziogstprofileid,
                            countryList: _countryList,
                            countryCellSource: DataService.arrayToObject(_countryList, "id", "value"),

                            sourceSystemList: DataService.getKeyValueCollection(_sourceSystemList, "sourceId", "sourceName", false),

                            initialValuesOrNull: _initialValuesOrNull,
                        }, () => {
                            this.onSourceSystemChange(this.state.isNew ? null : _initialValuesOrNull.sourceid, true);
                            this.setState({ fetchAgreementDataResult: ResultStatus.LOADED });
                            this.props.onAgreementTabLoaded(_initialValuesOrNull);
                        });
                    }
                },
                // on error
                (err) => {
                    ToastService.showError(`Error Occured while Loading the Agreement`);
                    this.setState({ fetchAgreementDataResult: ResultStatus.ERROR });
                }
            )
        );

    }


    // formik
    getSourceSystemInitialValues() {
        return { sourceSystemId: this.state.isNew ? "" : this.state.initialValuesOrNull.sourceid };
    }

    sourceSystemValidationSchema = Yup.object().shape({});
    getSourceSystemValidationSchema() {
        this.sourceSystemValidationSchema = Yup.object().shape({
            sourceSystemId: LayoutService.getNullableValidation(Yup.number().required("Required"))
        });
        return this.sourceSystemValidationSchema;
    }


    // render
    TAB_PERMISSIONS = RolePermissionService.AGREEMENT_DETAIL;
    render() {
        const { classes } = this.props;
        // set the props for parent's validation & post logic
        this.fPropsDynamic = null;
        this.props.tabConfig.ref = this; // 1/4) required by parent component

        if (this.props.inputAction !== CrudAction.CREATE && RolePermissionService.AGREEMENT_DETAIL.cannotView) {
            return RolePermissionService.getAccessDeniedComponent(classes);
        } else {
            switch (this.state.fetchAgreementDataResult) {
                case ResultStatus.NOT_LOADED:
                case ResultStatus.LOADING:
                    return <PageLoadingComponent small classes={classes} label="Loading Agreement Details" />;
                case ResultStatus.SAVING:
                    return <PageLoadingComponent small classes={classes} label="Saving Agreement Details" />;
                case ResultStatus.LOADED:
                case ResultStatus.SUCCESS:
                    return (
                        <>
                            <ProfileDetailDialogComponent inputAction={CrudAction.UPDATE}
                                open={this.state.showProfileDetailDialog || false}
                                onClose={() => this.setState({ showProfileDetailDialog: false })}
                                modalAgNode={{
                                    "prid": this.state.profileIdForDetailsDialog,
                                    "porziogstprofileid": this.state.gstProfileIdForDetailsDialog
                                }}
                            />
                            {/* Recipient Search Dialog Component Tab */}
                            <RecipientSearchDialogComponent open={this.state.showSearchRecipientDialog || false}
                                onClose={(selectedProfile) => {
                                    if (DataService.isNullOrUndefined(selectedProfile)) {
                                        this.setState({ showSearchRecipientDialog: false }); // just hide
                                    } else {
                                        // show the progress & hide the search dialog
                                        this.setState({ showSearchRecipientDialog: false, isRecipientInfoInProgress: true });

                                        this.isRecipientMatched = true;
                                        this.fPropsDynamic.setFieldValue("porziogstprofileid", selectedProfile["porziogstprofileid"], true); // only the id,.. leave the other fields with old values
                                        this.oSubscriptions.add(
                                            combineLatest([
                                                LookupService.getProfileTabDetailsWithErrorInfoAsOBS(this.context.user.tenantId, selectedProfile["prid"]),
                                                ApiService.getOBS(API_ENDPOINT.CORE, `/Profiles/FetchProfileIdentifier?tenantId=${this.context.user.tenantId}&prid=${selectedProfile["prid"]}&pageNum=1&rowsPerPage=100`)
                                            ]).subscribe(([_profile, _identifier]) => {
                                                const sortedIdentifiers = _identifier.length === 0 ? null : _identifier.sort((a, b) => b.profileIdentifierId - a.profileIdentifierId)[0];
                                                //const addressInfo = _profile.addresslist.filter(a => a.isprimary === true)[0];
                                                const addressInfo = _profile.addresslist.length === 0 ? null : _profile.addresslist.length === 1 ? _profile.addresslist[0]
                                                    : _profile.addresslist.some(x => x.isprimary === true) ? _profile.addresslist.filter(add => add.isprimary === true)[0] :
                                                        _profile.addresslist.sort((a, b) => { return b.updateddate - a.updateddate })[0];
                                                this.setState({
                                                    prid: selectedProfile["prid"],
                                                    recipientProfileInfo: _profile,
                                                    recipientIdentifierInfo: sortedIdentifiers,
                                                    recipientAddressInfo: addressInfo,
                                                    showSearchRecipientDialog: false,
                                                }, () => {
                                                    this.fPropsDynamic.setFieldValue("companyprofileid", this.state.recipientProfileInfo.companyprofileid || '', false);
                                                    this.fPropsDynamic.setFieldValue("profiletypeid", this.state.recipientProfileInfo.profiletypeid || '', false);
                                                    this.fPropsDynamic.setFieldValue("recipientcategoryid", this.state.recipientProfileInfo.profilecategoryid || '', false);
                                                    this.fPropsDynamic.setFieldValue("organizationname", this.state.recipientProfileInfo.organizationname || '', false);
                                                    this.fPropsDynamic.setFieldValue("suffix", this.state.recipientProfileInfo.suffix || '', false);
                                                    this.fPropsDynamic.setFieldValue("lastname", this.state.recipientProfileInfo.lastname || '', false);
                                                    this.fPropsDynamic.setFieldValue("middlename", this.state.recipientProfileInfo.middlename || '', false);
                                                    this.fPropsDynamic.setFieldValue("firstname", this.state.recipientProfileInfo.firstname || '', false);
                                                    this.fPropsDynamic.setFieldValue("prefix", this.state.recipientProfileInfo.prefix || '', false);
                                                    this.fPropsDynamic.setFieldValue("credentialsid", this.state.recipientProfileInfo.credentialsid || '', false);
                                                    this.fPropsDynamic.setFieldValue("specialtyid", this.state.recipientProfileInfo.specialtyid || '', false);
                                                    if (this.state.recipientAddressInfo !== null) {
                                                        this.fPropsDynamic.setFieldValue("address1", this.state.recipientAddressInfo.addresS1 || '', false);
                                                        this.fPropsDynamic.setFieldValue("address2", this.state.recipientAddressInfo.addresS2 || '', false);
                                                        this.fPropsDynamic.setFieldValue("address3", this.state.recipientAddressInfo.addresS3 || '', false);
                                                        this.fPropsDynamic.setFieldValue("address4", this.state.recipientAddressInfo.addresS4 || '', false);
                                                        this.fPropsDynamic.setFieldValue("city", this.state.recipientAddressInfo.city || '', false);
                                                        this.fPropsDynamic.setFieldValue("province", this.state.recipientAddressInfo.province || '', false);
                                                        this.fPropsDynamic.setFieldValue("countryid", this.state.recipientAddressInfo.countryId || '', false);
                                                        this.fPropsDynamic.setFieldValue("postalcode", this.state.recipientAddressInfo.postalcode || '', false);
                                                    }
                                                    this.fPropsDynamic.setFieldValue("usnpinumber", this.state.recipientProfileInfo.usnpinumber || '', false);
                                                    this.fPropsDynamic.setFieldValue("uslicensestate", this.state.recipientProfileInfo.uslicensestate || '', false);
                                                    this.fPropsDynamic.setFieldValue("usstatelicensenumber", this.state.recipientProfileInfo.usstatelicensenumber || '', false);
                                                    this.fPropsDynamic.setFieldValue("ustaxidnumber", this.state.recipientProfileInfo.ustaxidnumber || '', false);
                                                    if (this.state.recipientIdentifierInfo !== null && this.state.recipientIdentifierInfo !== undefined) {
                                                        this.fPropsDynamic.setFieldValue("recipientidentifiercountryid", this.state.recipientIdentifierInfo.countryId || '', false);
                                                        this.fPropsDynamic.setFieldValue("recipientidentifiertypeid", this.state.recipientIdentifierInfo.porzioGSTIdentifierTypeId || '', false);
                                                    }
                                                });

                                                ToastService.showInfo("Recipient details loaded.");
                                                this.setState({ isRecipientInfoInProgress: false });

                                            })
                                        );
                                    }
                                }}
                            />
                            {/* SourceSystem  Dropdown*/}
                            <Formik initialValues={this.getSourceSystemInitialValues()} validationSchema={this.getSourceSystemValidationSchema()} validationSchemaOptions={{ showMultipleFieldErrors: true }}>
                                {(fPropsSourceSystem) => (
                                    <form>
                                        <Box style={{ paddingLeft: 24, paddingRight: 16, paddingTop: 8, paddingBottom: 8, backgroundColor: MatThemeService.getControllerBG() }}>
                                            {LayoutService.getDropDown((this.props.isReadOnly || !this.state.isNew), classes.dialogControl, classes.menuPaper, fPropsSourceSystem, this.sourceSystemValidationSchema, "sourceSystemId", "Source System",
                                                this.state.sourceSystemList, "id", "text", null, true, "48%",
                                                (_formikProps, _newSourceSystemId) => { this.onSourceSystemChange(_newSourceSystemId, false); })
                                            }
                                        </Box>
                                    </form>
                                )}
                            </Formik >
                            {/* Render Dynamic Controls */}
                            {this.renderDynamicControls(classes)}
                            {<Box style={{ paddingTop: 12, paddingBottom: 12 }} />}
                        </>
                    );
                case ResultStatus.ERROR:
                default:
                    return (<DialogErrorFragmentComponent classes={classes} description="Error Loading Agreement Details" onRetry={() => { this.fetchAgreementData(); }} />);
            }
        }
    }

    //#endregion

    //#region Dynamic controls

    // api
    onSourceSystemChange = (_newSourceSystemId, _force) => {
        this.oSubscriptions.cancelAll();

        if (_force || this.state.selSourceSystemId !== _newSourceSystemId) {
            if (DataService.hasValidValue(_newSourceSystemId)) {
                //CLEAR CAHCE etc,...
                AgreementTabService.INIT();
                DynamicControlService.CLEAR();
                this._setTemplateFetchState(ResultStatus.LOADING, _newSourceSystemId, null, () => {
                    this.oSubscriptions.add(
                        LookupService.getTemplateBySourceIdAsOBS(this.context.user.tenantId, _newSourceSystemId).subscribe(
                            // success result
                            (_templateData) => {
                                // get grouped filed configs
                                const groupFieldsMap = AgreementTabService.getGroupFieldsMap(this, this.state.isNew, _templateData, "grouprenderid", "fieldID");
                                this._setTemplateFetchState(ResultStatus.LOADED, _newSourceSystemId, groupFieldsMap, () => {
                                    // ToastService.showInfo(`${this.state.isNew ? "Template" : "Data"} Loaded`, 1000);
                                });
                            },
                            // on error
                            (err) => {
                                ToastService.showError(`Error Occured while Loading the ${this.state.isNew ? "Template" : "Data"}`);
                                this._setTemplateFetchState(ResultStatus.ERROR, _newSourceSystemId, null, () => { });
                            }
                        )
                    );
                });
            } else { this._setTemplateFetchState(ResultStatus.LOADED, null, null, () => { }); } // clear selectedSourceSystemId
        }
    }

    // render
    fPropsDynamic = null;
    renderDynamicControls = (_classes) => {
        switch (this.state.templateFetchResult) {
            case ResultStatus.NOT_LOADED: return (<></>);
            case ResultStatus.LOADING: return (<PageLoadingComponent classes={_classes} label="Loading Dynamic Controls" />);
            // Good to Render the components
            case ResultStatus.LOADED:
            case ResultStatus.SUCCESS:
                if (!DataService.hasValidValue(this.state.selSourceSystemId)) {
                    return (<DialogErrorFragmentComponent small classes={_classes} description="Select the Source System" />);
                }
                else if (DataService.mapHasNoElements(this.state.allGroupFieldsMap)) {
                    return (<DialogErrorFragmentComponent small classes={_classes} description="Select a valid Source System"
                        onRetry={() => { this.onSourceSystemChange(this.state.selSourceSystemId, true); }} />);
                }
                else {
                    return (
                        <Formik initialValues={this.state.dynamicControlInitialValues} validationSchema={this.state.dynamicControlsValidationSchema}
                            validationSchemaOptions={{ showMultipleFieldErrors: true }} validateOnChange={false} validateOnBlur={false}>
                            {(_fPropsDynamic) => (
                                <form>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <>
                                            {this.renderData(_classes, _fPropsDynamic)}
                                        </>
                                    </MuiPickersUtilsProvider>
                                </form>
                            )}
                        </Formik >
                    );
                }
            // Error
            case ResultStatus.ERROR:
            default:
                return (<DialogErrorFragmentComponent classes={_classes} description="Error loading Dynamic Controls"
                    onRetry={() => { this.onSourceSystemChange(this.state.selSourceSystemId, true); }} />);
        }
    }
    //#endregion


    renderData = (_classes, _fPropsDynamic) => {
        this.fPropsDynamic = _fPropsDynamic;


        // 2/2) Map Errors
        DynamicControlService.setErrors(_fPropsDynamic, this.state.initialValuesOrNull);

        var lastGroupIndex = 0;
        // filter, static & dynamic fields
        const oRET = DataService.getMapKeys(this.state.dynamicControlFieldsMap).filter((_mapKey) =>
            _mapKey !== AgreementTabService.recipientInformationFieldGroupKey &&
            this.state.dynamicControlFieldsMap.get(_mapKey).fieldConfigs.length > 0 // empty check
        ).map((_mapKey, _groupIndex) => {
            lastGroupIndex = _groupIndex;
            const groupInfo = this.state.dynamicControlFieldsMap.get(_mapKey);
            return (
                <React.Fragment key={`group${_groupIndex}`}>
                    <Box key={`section${_groupIndex}`} style={{ paddingLeft: 24, paddingRight: 24, paddingTop: 8, paddingBottom: 16, backgroundColor: MatThemeService.getReverseAlternatingBG(lastGroupIndex) }}>
                        {DataService.isStringNullOrEmpty(groupInfo.name) ? null : <Typography key={`sectionHeader${_groupIndex}`} variant="h6" className={_classes.sectionHeader} style={{ margin: 8 }}>{groupInfo.name}</Typography>}
                        {
                            groupInfo.fieldConfigs.map((_fconfig, _fieldIndex) => {
                                return DynamicControlService.getControl(this.props.isReadOnly, _classes, _fPropsDynamic, this.state.initialValuesOrNull, this.state.dynamicControlsValidationSchema, _fieldIndex, _fconfig);
                            })
                        }
                    </Box>
                    <Divider />
                </React.Fragment>
            );
        });

        //Recipient Information
        oRET.push([
            AgreementTabService.recipientInformationFieldGroupKey,
        ].filter((_mapKey) => this.state.dynamicControlFieldsMap.get(_mapKey).fieldConfigs.length > 0 // empty check
        ).map((_mapKey, _groupIndex) => {
            lastGroupIndex = _groupIndex;
            const groupInfo = this.state.dynamicControlFieldsMap.get(_mapKey);
            return (
                <React.Fragment key={`group${_groupIndex}`}>
                    <Box key={`section${_groupIndex}`} style={{ paddingLeft: 24, paddingRight: 24, paddingTop: 8, paddingBottom: 16, backgroundColor: MatThemeService.getAlternatingBG(lastGroupIndex) }}>
                        {DataService.isStringNullOrEmpty(groupInfo.name) ? null :
                            // Recipient Information - header icons
                            <Grid container direction="row" justify="space-between" alignItems="center">
                                <Typography variant="h6" className={_classes.sectionHeader} style={{ margin: 8 }}>{groupInfo.name}</Typography>
                                {/* {this.isRecipientMatched ? null : <Typography style={{ paddingLeft: 8, paddingRight: 8, backgroundColor: "#FF8A8A", color: "#6E0101" }} variant="h6" align="center">Unmatched Recipient</Typography>} */}
                                <Grid>
                                    {/* {LayoutService.getIconButton(this.props.isReadOnly, MatIconService.IDEA_BULB_32, "Possible Matches", () => {
                                        
                                    }, "inherit", "", "bottom", { color: "#F57C00" })} */}
                                    {LayoutService.getIconButton(this.props.isReadOnly, MatIconService.SEARCH, "Search", () => {
                                        this.setState({ showSearchRecipientDialog: true })
                                    }, "secondary", "", "bottom", { fontSize: 30, marginTop: 4 })}
                                </Grid>
                            </Grid>
                        }
                        {this.state.isRecipientInfoInProgress ? <LinearProgress color="secondary" /> : null}
                        {
                            // Recipient Information - controls
                            groupInfo.fieldConfigs.map((_fconfig, _fieldIndex) => {
                                return DynamicControlService.getControl(
                                    _mapKey === AgreementTabService.recipientInformationFieldGroupKey ? true : this.props.isReadOnly, // recipientcontrols should always be readonly
                                    _classes, _fPropsDynamic, this.state.initialValuesOrNull, this.state.dynamicControlsValidationSchema, _fieldIndex, _fconfig);
                            })
                        }
                    </Box>
                    <Divider />
                </React.Fragment>
            );
        }));

        // RETURN
        return oRET;
    }


    //#region  Utils
    _setTemplateFetchState = (_templateFetchResult, _sourceSystemId, _groupFieldsMap, _callback = () => { }) => {

        const _dynamicControlFieldsMap = DataService.getMapByKeys(_groupFieldsMap,
            [AgreementTabService.filterFieldGroupKey,
            AgreementTabService.staticFieldGroupKey,
            AgreementTabService.eventFieldGroupKey,
            AgreementTabService.recipientInformationFieldGroupKey,
            AgreementTabService.otherFieldGroupKey,
            AgreementTabService.customFieldGroupKey
            ]);

        this.isRecipientMatched = DataService.isNullOrUndefined(this.state.initialValuesOrNull) ? false : this.state.initialValuesOrNull["isrecipientmatch"];
        this.setState({
            selSourceSystemId: _sourceSystemId,
            templateFetchResult: _templateFetchResult,
            allGroupFieldsMap: _groupFieldsMap,
            dynamicControlFieldsMap: _dynamicControlFieldsMap,
            dynamicControlInitialValues: DynamicControlService.getIntitialValues(this.state.isNew, _dynamicControlFieldsMap, this.state.initialValuesOrNull),
            dynamicControlsValidationSchema: DynamicControlService.getValidationSchema(_dynamicControlFieldsMap),
        }, _callback);
    }
    //#endregion

    /** 2/4 Required */
    isDirtyCallback = () => {
        // do any additional checkings if needed
        if (this.fPropsDynamic) {
            return this.fPropsDynamic.dirty;
        } else {
            return false;
        }
    }
    /** 3/4 Required in Parent */
    resetCallback = (_updateFormWithNewValues = false) => {
        // modified on 02-03-2022 as per ticket PP2-1241
        if (this.fPropsDynamic) {
            if (_updateFormWithNewValues) {
                this.fPropsDynamic.resetForm({ values: { ...this.fPropsDynamic.values } });
            }
            else {
                this.fPropsDynamic.resetForm();
            }
        }
        // do any additional resetting if needed
    }

    /** 4/4 Required in Parent */
    postCallbackOBS = () => {
        if (DataService.isNullOrUndefined(this.fPropsDynamic) || this.fPropsDynamic.isSubmitting) {
            return of(null);
        }
        else {
            var oReturnSubject = new ReplaySubject(); // 1st
            var oValidationSubject = new ReplaySubject(); // 2nd

            this._validate(oValidationSubject);
            oValidationSubject.asObservable().subscribe((_dataToPost) => {
                if (_dataToPost) {
                    ApiService.postOBS(API_ENDPOINT.CORE, "/Agreement/SaveAgreement", JSON.stringify(_dataToPost))
                        .subscribe(
                            (_successResult) => {
                                ToastService.showSuccess("Agreement Saved");
                                oReturnSubject.next(true);
                            },
                            (_errorResult) => {
                                ToastService.showError("Error occured while saving");
                                oReturnSubject.next("save_error");
                            }
                        );
                } else {
                    ToastService.showWarning("Please recheck your Input");
                    oReturnSubject.next("validation_error");
                }
            });

            // return the subject as observable
            return oReturnSubject.asObservable(); // 3rd
        }
    }

    _validate = async (_oSubject) => {
        // if (!this.state.isNew) { // no validation for edit
        //     _oSubject.next(this._getDataToPost());
        // } else {
        //     await this.state.dynamicControlsValidationSchema.validate(this.fPropsDynamic.values, { abortEarly: false })
        //         .then((x) => {
        //             _oSubject.next(this._getDataToPost());
        //         })
        //         .catch((erroObj) => {
        //             if (erroObj.inner) { erroObj.inner.forEach(err => { this.fPropsDynamic.setFieldError(err.path, err.message); }); }
        //             _oSubject.next(null); // error
        //         });
        // }
        _oSubject.next(this._getDataToPost());
    }

    isRecipientMatched = false;
    _getDataToPost = () => {
        //---Mandatory-Fields---------------
        var dataToPost = {
            tenantid: this.context.user.tenantId,
            userId: this.context.user.userId,
            userType: this.context.user.userTypeId,
            uid: this.context.user.uid,
            prid: this.state.prid ? this.state.prid : 0,
            agreementid: this.state.isNew ? 0 : this.state.selAgreementId,
            porziogstagreementid:this.state.isNew ? "" : this.state.selPorzioGSTAgreementId,
            sourceid: this.state.selSourceSystemId,
            recordid: this.state.initialValuesOrNull ? this.state.initialValuesOrNull.recordid : null
        };


        //---Static-Fields---------------
        const staticFieldConfigs = [
            ...this.state.dynamicControlFieldsMap.get(AgreementTabService.filterFieldGroupKey).fieldConfigs,
            ...this.state.dynamicControlFieldsMap.get(AgreementTabService.staticFieldGroupKey).fieldConfigs,
            ...this.state.dynamicControlFieldsMap.get(AgreementTabService.recipientInformationFieldGroupKey).fieldConfigs,
            ...this.state.dynamicControlFieldsMap.get(AgreementTabService.otherFieldGroupKey).fieldConfigs,
            ...this.state.dynamicControlFieldsMap.get(AgreementTabService.eventFieldGroupKey).fieldConfigs,
        ];
        staticFieldConfigs.forEach(fieldConfig => {
            const _mappedFieldName = DynamicControlService.getMappedFieldName(fieldConfig) + "";
            var _fieldValue = this.fPropsDynamic.values[_mappedFieldName];
            if (fieldConfig.field_Type_ID === ENTITY_FIELD_TYPE.BOOLEAN) {
                dataToPost[_mappedFieldName] = DataService.parseBoolean(_fieldValue);
            }
            else if (fieldConfig.field_Type_ID === ENTITY_FIELD_TYPE.NUMERIC || fieldConfig.field_Type_ID === ENTITY_FIELD_TYPE.DECIMAL) {
                dataToPost[_mappedFieldName] = DataService.getNumberOrDefault(_fieldValue, null);
            }
            else if (fieldConfig.field_Type_ID === ENTITY_FIELD_TYPE.LOV) {
                // For Lov both agreementstatusId & agreementstatus keys should be set
                _fieldValue = _fieldValue > 0 ? _fieldValue : 0; // if null then use the 0 based lovKey
                dataToPost[_mappedFieldName] = _fieldValue; // <field>Id eg: agreementstatusId
                if (_mappedFieldName === 'countryid') { // since api not impl in a consistent way
                    dataToPost['country'] = _fieldValue;
                }
                else if (_mappedFieldName.endsWith("id")) {
                    const _lovStringFieldName = _mappedFieldName.substring(0, _mappedFieldName.length - 2); // <field> eg: agreementstatus
                    const lovObj = DataService.getFirstOrDefault(fieldConfig.customLOVList.filter((x) => x.lovId === _fieldValue));
                    dataToPost[_lovStringFieldName] = lovObj ? lovObj.lovKey : "";
                }
            } else {
                dataToPost[_mappedFieldName] = _fieldValue;
            }
        });

        // recipientInformation
        const recipientProfileId = this.fPropsDynamic.values["porziogstprofileid"];
        if (DataService.hasValidValue(recipientProfileId)) {
            dataToPost['porziogstprofileid'] = recipientProfileId;
            dataToPost['isrecipientmatch'] = this.isRecipientMatched;
        }


        //---Custom-Fields---------------
        dataToPost.customfields = this.state.dynamicControlFieldsMap.get(AgreementTabService.customFieldGroupKey).fieldConfigs.map(fieldConfig => {
            var _fieldValue = this.fPropsDynamic.values[DynamicControlService.getMappedFieldName(fieldConfig)];
            if (fieldConfig.field_Type_ID === ENTITY_FIELD_TYPE.LOV) {
                _fieldValue = _fieldValue > 0 ? _fieldValue : 0;
                const lovObj = DataService.getFirstOrDefault(fieldConfig.customLOVList.filter((x) => x.lovId === _fieldValue));
                return { 'FIELDID': fieldConfig.fieldID, 'FIELDNAME': fieldConfig.fielD_ALIASNAME, 'FIELDVALUE': _fieldValue, 'FIELDLOVKEY': lovObj ? lovObj.lovKey : "" };
            } else if (fieldConfig.field_Type_ID === ENTITY_FIELD_TYPE.DATE_TIME || fieldConfig.field_Type_ID === ENTITY_FIELD_TYPE.DATE) {
                return { 'FIELDID': fieldConfig.fieldID, 'FIELDNAME': fieldConfig.fielD_ALIASNAME, 'FIELDVALUE': _fieldValue ? DataService.formatDate(new Date(_fieldValue)) : null };
            } else {
                return { 'FIELDID': fieldConfig.fieldID, 'FIELDNAME': fieldConfig.fielD_ALIASNAME, 'FIELDVALUE': _fieldValue };
            }
        });
        dataToPost.customfields = DataService.arrayToSingleQuotedJsonString(dataToPost.customfields, "");

        // return
        return dataToPost;
    }
}
export default LayoutService.getHocComponenet(AgreementTabComponent);