import React, { Component, createRef } from "react";
import { Typography } from "@material-ui/core";
import { MatIconService } from "../../../shared/services/theme/matIconService";
import RolePermissionService from "../../../shared/role-permissions/rolePermissionService";


export default class DownloadFileAgRenderer extends Component {
    link = createRef();

    constructor(props) {
        super(props);
        this.invokeParentMethod = this.invokeParentMethod.bind(this);
    }

    invokeParentMethod = async () => {
        if (this.link.current.href) { return }
        let blob = await this.props.context.componentParent.handleDownloadAg(`Row: ${this.props.node.rowIndex}, Col: ${this.props.colDef.headerName}`, this.props.node.data, this.props.value, this.linkRefAg, window.URL);
        const href = window.URL.createObjectURL(blob);
        this.link.current.download = this.props.value;
        this.link.current.href = href;
        this.link.current.click();
    }
    showCannotDownloadDialog = () => {
        this.props.context.componentParent.canNotDownloadFile(RolePermissionService.DOWNLOAD_FILE_DATACENTER.cannotView);
    }

    render() {
        return (
            <div style={{ display: "flex" }}>
                <Typography style={{
                    fontSize: 14, fontWeight: 400, lineHeight: 1.9,
                    overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis",
                    cursor: "pointer"
                }}><a role='button'
                    style={{ textDecoration: "underline" }} onClick={RolePermissionService.DOWNLOAD_FILE_DATACENTER.cannotView
                        ? this.showCannotDownloadDialog : this.invokeParentMethod}
                    ref={this.link}>{this.props.value}</a></Typography>
                {this.props.node.data?.hasComments ? <div style={{ paddingLeft: 4 }}>{MatIconService.FILE_COMMENT_ON}</div> : null}
            </div>
        );
    }
}
