import React, { Component } from "react";
import { combineLatest, } from "rxjs";
import clsx from "clsx";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css";

import { AuthContext } from "../../../../shared/store/authProvider";
import ApiService from "../../../../shared/services/apiService";
import PageLoadingComponent from "../../../../shared/components/page/pageLoadingComponent";
import PageErrorComponent from "../../../../shared/components/page/pageErrorComponent";
import SectionComponent from "../../../../shared/components/sectionComponent";
import { SubscriptionArray } from "../../../../shared/services/dataService";
import { ResultStatus, API_ENDPOINT, CrudAction } from "../../../../shared/types/enums";
import LayoutService from "../../../../shared/services/layoutService";
import AgGridDropdownWithStatusCellRenderer from "../../../../shared/components/ag-grid/agGridDropdownWithStatusCellRenderer";
import { Typography, Divider, Grid, MenuItem, Select } from "@material-ui/core";
import MatSpecService from "../../../../shared/services/theme/matSpecService";
import CompliancePageHeaderComponent from "../compliance-consent-details/complianceConsentPageHeaderComponent";
import EditConsentMappingService from "./editConsentMappingService";
import { AgGridUtil } from "../../../../shared/services/ag-grid/agGridUtil";
import ActionDialog from "../../../../shared/components/dialog/actionDialog";
import LookupService from "../../../../shared/services/lookupService";
import RolePermissionService from "../../../../shared/role-permissions/rolePermissionService";

class EditConsentMappingComponent extends Component {
    static contextType = AuthContext;
    apiSubscriptions = new SubscriptionArray();

    constructor(props) {
        super(props);
        this.state = {
            isReadOnly: true,
            isEditing: true,
            reportsListWithAll: [],
            yearList: [],
            reportableLovList: [],
            selectedReportId: this.props.modalAgNode.reportId,
            showConfirmDialog: false,
            //selectedYearId: this.props.modalAgNode.templateYearLov,
            selectedYearId: this.props.modalAgNode.reportPathId,
            selectedVerifiedYearId: this.props.modalAgNode.templateYearLov,
            selectedReportName: this.props.modalAgNode.reportName,
            agGridUtils: new AgGridUtil("gstLovID", {
                dropDownRenderer: AgGridDropdownWithStatusCellRenderer,
            }),
        };
    }

    componentWillUnmount() {
        this.apiSubscriptions.cancelAll();
    }

    componentDidMount() {
        this.fetchData();
    }

    /** API Fetch */
    fetchData = (_ignoreCache = false) => {
        this.apiSubscriptions.cancelAll();
        this.setState({ fetchResult: ResultStatus.LOADING, data: [] });
        /**
         * rxjs <combineLatest> operator takes multiple obs as argument and emits their result in the same order
         * this operator is very handy as we don't have to independently wait for each result
         */
        this.apiSubscriptions.add(
            combineLatest([
                EditConsentMappingService.fetchConsentMappingsAsOBS(this.context.user.tenantId, this.state.selectedReportName, this.state.selectedVerifiedYearId),
                EditConsentMappingService.fetchReportMappings(this.context.user.tenantId,),
                EditConsentMappingService.getYearsByReportId(this.context.user.tenantId, this.state.selectedReportId),
                ApiService.getOBS(API_ENDPOINT.TENANT, `/Util/GetFieldLOVByName/${this.context.user.tenantId}/reportable`)
            ]).subscribe(
                // success
                ([_data, _reportList, _yearList, _reportableLovList]) => {
                    _data.forEach(x => {
                        x.recievedReportableId = x.reportableId;
                        x.initialCustomMappingValue = x.isCustomMapping;
                    });
                    const _arrReportsObjsDupes = _reportList.map(r => ({ id: r.reportId, text: r.reportName }));
                    _reportableLovList = _reportableLovList.map(x => ({ reportableId: x.lovId, reportableName: x.lovKey }));
                    let _arrReportsObjs = _arrReportsObjsDupes.filter((dupeReport, index, self) =>
                        index === self.findIndex((dedupedReport) => (
                            dedupedReport.text === dupeReport.text)));
                    _arrReportsObjs = _arrReportsObjs.slice().sort((a, b) => (a.text > b.text) ? 1 : ((b.text > a.text) ? -1 : 0));
                    _arrReportsObjs = _arrReportsObjs.filter(x => !LookupService.REPORTS_TO_BE_REMOVED_CONSENT.includes(x.text));

                    _yearList = _yearList.map(r => ({ id: r.reportPathId, text: r.reportYear }));
                    _yearList = _yearList.filter((dupeReport, index, self) =>
                        index === self.findIndex((dedupedReport) => (
                            dedupedReport.text === dupeReport.text)));
                    _yearList = _yearList.slice().sort((a, b) => (a.text > b.text) ? 1 : ((b.text > a.text) ? -1 : 0))
                    this.setState(
                        {
                            data: _data,
                            reportsListWithAll: [..._arrReportsObjs],
                            reportableLovList: _reportableLovList,
                            yearList: [..._yearList],
                        },
                        // change the state after all the above are assigned
                        () => {
                            this.setState({ fetchResult: ResultStatus.LOADED });
                        }
                    );
                },
                // onError
                (error) => {
                    console.log("Error:", error);
                    this.setState({ fetchResult: ResultStatus.ERROR });
                }
            )
        );
    }

    onReportSelectClick = (event) => {
        this.setState(
            {
                selectedReportName: event.target.value,
                selectedReportId: this.state.reportsListWithAll.find(x => x.text === event.target.value).id,
                selectedYearId: 0,
            },
            () => this.fetchYear(this.state.selectedReportId, this.setVerifiedYear)
        );
    }

    fetchYear = (_reportId, _callbackOnSuccess = null) => {
        let url = `/ComplianceMapping/FetchReportTemplateYears/${this.context.user.tenantId}`
        if (_reportId !== 0) {
            url = `/ComplianceMapping/FetchReportTemplateYears/${this.context.user.tenantId}?reportId=${_reportId}`
        }
        ApiService.getOBS(
            API_ENDPOINT.TENANT,
            url
        ).subscribe(
            (successResult) => {
                let _yearList = successResult;
                if (_yearList) {
                    _yearList = _yearList.map(r => ({ id: r.reportPathId, text: r.reportYear }));
                    _yearList = _yearList.filter((dupeReport, index, self) =>
                        index === self.findIndex((dedupedReport) => (
                            dedupedReport.text === dupeReport.text)));
                    _yearList = _yearList.slice().sort((a, b) => (a.text > b.text) ? 1 : ((b.text > a.text) ? -1 : 0))
                    this.setState({
                        fetchResult: ResultStatus.SUCCESS,
                        yearList: [..._yearList],
                    }, () => {
                        if (this.state.selectedYearId === 0) {
                            this.setState({ selectedYearId: this.state.yearList[0].id }, () => {
                                if (_callbackOnSuccess) { _callbackOnSuccess(this.state.yearList[0].text, this.fetchData); }
                            })
                        }
                        else {
                            if (_callbackOnSuccess) {
                                _callbackOnSuccess(this.state.yearList.find(x => x.id === this.state.selectedYearId).text,
                                    this.fetchData);
                            }
                        }
                    });
                } else {
                    this.setState({ fetchResult: ResultStatus.ERROR });
                }
            },
            (errorResult) => {
                console.error("Error while saving Product", errorResult);
                this.setState({ fetchResult: ResultStatus.ERROR });
            }
        );
    }
    setVerifiedYear = (_templateYear, _callbackOnSuccess = null) => {
        ApiService.postOBS(
            API_ENDPOINT.TENANT,
            `/ComplianceMapping/VerifyTemplateYear/${this.context.user.tenantId}/${_templateYear}`
        ).subscribe(
            (successResult) => {
                this.setState({ selectedVerifiedYearId: successResult }, () => {
                    if (_callbackOnSuccess) { _callbackOnSuccess(); }
                });
            },
            (errorResult) => {
                this.setState({ fetchResult: ResultStatus.ERROR });
            }
        )
    }

    onGridReady = (event) => {
        event.api.closeToolPanel();
        event.api.sizeColumnsToFit();
        this.gridApi = event.api;
        this.gridColumnApi = event.columnApi;
        //this.gridApi.setDomLayout("autoHeight");
    };

    handleSubmit = async (_formikProps) => {
        var arrNodes = [];
        this.gridApi.forEachNode((rowNode) => {
            arrNodes.push({
                profileConsentValue: rowNode.data.profileConsentValue,
                agreementConsentValue: rowNode.data.agreementConsentValue,
                transactionConsentValue: rowNode.data.transactionConsentValue,
                reportName: this.state.selectedReportName,
                templateYear: this.props.modalAgNode.templateYear,
                reportable: rowNode.data.reportable,
                rowsCount: 27,
                profileConsentId: rowNode.data.profileConsentId,
                agreementConsentId: rowNode.data.agreementConsentId,
                transactionConsentId: rowNode.data.transactionConsentId,
                reportableId: rowNode.data.reportableId,
                templateYearLov: this.state.selectedVerifiedYearId,
                // isCustomMapping: rowNode.data.isCustomMapping,
                isCustomMapping: (rowNode.data.isCustomMapping && rowNode.data.recievedReportableId !== rowNode.data.reportableId) ?
                    false :
                    (!rowNode.data.isCustomMapping && rowNode.data.recievedReportableId !== rowNode.data.reportableId) ?
                        true : rowNode.data.initialCustomMappingValue,
                reportPathId: this.props.modalAgNode.reportPathId,
                isModified: rowNode.data.isModified,
            });
        });

        // 2) extract the formik values into an object
        var mappedObj = arrNodes;

        // 4) save to Api and subscribe for the result
        ApiService.setOBS(
            "POST",
            API_ENDPOINT.TENANT,
            `/ComplianceMapping/SaveConsentMapping/${this.context.user.tenantId}/${this.context.user.userId}?reportName=${this.state.selectedReportName}&year=${this.state.selectedVerifiedYearId}`,
            JSON.stringify(mappedObj)
        ).subscribe(
            (successResult) => {
                if (successResult) {
                    this.setState({ fetchResult: ResultStatus.SUCCESS });
                    this.props.refreshList(true);
                    this.props.onClose(false);
                } else {
                    this.setState({ fetchResult: ResultStatus.ERROR });
                }
            },
            (errorResult) => {
                console.error("Error while saving Product", errorResult);
                this.setState({ fetchResult: ResultStatus.ERROR });
            }
        );
    }
    onYearSelectClick = (event) => {
        this.setState(
            {
                selectedYearId: event.target.value,
            },
            () => {
                this.setVerifiedYear(this.state.yearList.find(x => x.id === this.state.selectedYearId).text, this.fetchData);
                //this.fetchProcessingObj(this.state.selectedVerifiedYearId);
            }
        );
    }
    handleConfirmDialog = () => {
        this.gridApi.forEachNode((rowNode) => {
            if (!rowNode.data.isModified) {
                this.setState({ showConfirmDialog: true });
            }
        })
    }

    render() {
        const { classes } = this.props;
        const pageName = "Consent Mapping";
        this.state.agGridUtils.disableEditability(this.state.isReadOnly);

        switch (this.state.fetchResult) {
            case ResultStatus.NOT_LOADED:
            case ResultStatus.LOADING:
                return (<PageLoadingComponent classes={classes} label={`Loading ${pageName}`} />);
            case ResultStatus.LOADED:
            case ResultStatus.SUCCESS:
                return (
                    <div id="MainUsersGrid">
                        <ActionDialog showDialog={this.state.showConfirmDialog} title="Custom Consent Mapping Confirmation" description="You have chosen to update a consent mapping to a non-standard mapping. Please confirm this custom report mapping." actions={[
                            {
                                color: "secondary", text: "Submit", icon: null,
                                callback: () => {
                                    this.handleSubmit();
                                    this.setState({ showConfirmDialog: false });
                                }
                            },
                            {
                                color: "inherit", text: "Cancel", icon: null,
                                callback: () => { this.setState({ showConfirmDialog: false }, () => { }) }
                            }
                        ]} />
                        <div className="IconBg marginTop10px tableTitleHead">
                            <CompliancePageHeaderComponent back={false} history={this.props.history}
                                classes={classes} label={""}

                                reportComplianceSelect
                                selectedReportId={this.state.selectedReportName}
                                reportSelectData={this.state.reportsListWithAll}
                                onReportSelectClick={(event) => { this.onReportSelectClick(event); }}


                                yearComplianceSelect
                                selectedYearId={this.state.selectedYearId}
                                yearList={this.state.yearList}
                                onYearSelectClick={(event) => { this.onYearSelectClick(event); }}
                            />
                        </div>
                        <div>
                            <SectionComponent classes={classes} enableEditJsx={
                                RolePermissionService.CONSENT_MAPPING_DETAIL.cannotEdit ? null :
                                    LayoutService.getReadOnlyActionsSolo(this, false, () => { this.handleConfirmDialog() })
                            } />
                        </div>
                        <div {...LayoutService.getAgGridStyles()}>
                            <AgGridReact
                                columnDefs={EditConsentMappingService.getColumnDefs(this)}
                                rowData={this.state.data}
                                pagination={true}
                                paginationPageSize={50}
                                frameworkComponents={this.state.agGridUtils.frameworkComponents}
                                gridOptions={{
                                    context: { componentParent: this },
                                }}
                                onGridReady={(event) => {
                                    this.onGridReady(event);
                                    this.state.agGridUtils.setGridParams(event, true);
                                    this.state.agGridUtils.disableEditability(this.state.isReadOnly);
                                }}
                            ></AgGridReact>
                        </div>
                        <br /><br />
                        <Typography variant="subtitle2" className={classes.root} >
                            1. Blank consent values will be treated as "Unknown" by the system during reporting.
                            <br />
                            2. Profile Date and Agreement Date consents and revocations will be considered by Transaction Date.  If no dates exist, system will consider as blanks and thus treated as "Unknown".
                            <br />
                            3. If Consent Evaluation for an entity is not enabled in the Processing tab, then the system will assume the consent for the non-enabled entity(s) is "Yes."
                            <br />
                            4. If a Transaction is not linked to an Agreement or Profile (Unmatched), then the Consent value will be treated as a "Yes" value per the above table.
                        </Typography>
                    </div>
                );

            case ResultStatus.ERROR:
                return (<PageErrorComponent label={`Error Loading ${pageName}`} classes={classes} onRetry={() => { this.fetchData(true); }} />);
            default:
                return null;
        }
    }
}
export default LayoutService.getHocComponenet(EditConsentMappingComponent);
