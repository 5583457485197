import ApiService from "../../../shared/services/apiService";
import { API_ENDPOINT } from "../../../shared/types/enums";

export default class ComplianceConsentMappingService {

  static fetchConsentMappingsAsOBS = (_tenantId,) => {
    return ApiService.postOBS(
      API_ENDPOINT.TENANT,
      `/ComplianceMapping/FetchConsentMappings/${_tenantId}`,
      JSON.stringify({
        pageNumber: 1,
        pageSize: 5000,
        searchText: "",
        entityId: "",
        fieldId: "",
        reportId: "",
        reportName: "0",
        year: "0"
      }),
    );
  };

  static fetchReportMappings = (_tenantId,) => {
    return ApiService.postOBS(
      API_ENDPOINT.TENANT,
      `/ComplianceMapping/FetchReportMappings/${_tenantId}`,
      JSON.stringify({
        pageNumber: 1,
        pageSize: 50000,
        searchText: "",
        entityId: "",
        fieldId: ""
      }),
    );
  };

  static getYearsByReportId = (_tenantId, _reportId) => {
    let $url = `/ComplianceMapping/FetchReportTemplateYears/${_tenantId}`
    if (_reportId !== 0) {
      $url = `/ComplianceMapping/FetchReportTemplateYears/${_tenantId}?reportId=${_reportId}`
    }
    return ApiService.getOBS(API_ENDPOINT.TENANT, $url);
  };

  static getEntityListAsOBS = (_tenantId) => {
    return ApiService.getOBS(
      API_ENDPOINT.TENANT,
      `/ComplianceMapping/FetchEntitiesForDefaultMapping/${_tenantId}`
    );
  };

  static getComplianceEntityFieldsAsOBS = (_tenantId, _entityId) => {
    return ApiService.getOBS(
      API_ENDPOINT.TENANT,
      `/ComplianceMapping/FetchEntityFields/${_tenantId}/${_entityId}?entityId=${_entityId}`
    );
  };

  static getRows() {
    return [];
  }

  static getColumnDefs() {
    return [
      {
        headerName: "Report Name",
        field: "reportName",
        sortable: true,
        filter: "text",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
      },
      {
        headerName: "Template Year",
        field: "templateYear",
        sortable: true,
        filter: "text",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        cellRenderer: "reportYearAgGridRendererComponent",
      },
      {
        headerName: "Profile Consent Value",
        field: "profileConsentValue",
        sortable: true,
        filter: "agTextColumnFilter",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
      },
      {
        headerName: "Agreement Consent Value",
        field: "agreementConsentValue",
        sortable: true,
        filter: "agTextColumnFilter",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
      },
      {
        headerName: "Transaction Consent Value",
        field: "transactionConsentValue",
        sortable: true,
        filter: "agTextColumnFilter",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
      },
      {
        headerName: "Reportable",
        field: "reportable",
        sortable: true,
        filter: "agTextColumnFilter",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
      },
    ];
  }
}
