import React, { Component } from "react";
import { CircleComponent} from  "../../../shared/components/elements/circleComponent/circleComponent.jsx"

export default class ProfileErrorCellRendererComponent extends Component {
    constructor(props) {
        super(props);

        this.invokeParentMethod = this.invokeParentMethod.bind(this);
    }

    invokeParentMethod() {
        this.props.context.componentParent.profileErrorMethodFromParent(
            `Row: ${this.props.node.rowIndex}, Col: ${this.props.colDef.headerName}`, this.props.node.data
        );
    }

    render() {
        return (
            <span onClick={this.invokeParentMethod}>
               <CircleComponent value={this.props.value} circleStyle={"circle-error"}/>    
            </span>
        );
    }
}
