import React from "react";
import { combineLatest } from "rxjs";
import { AppBar, Box, Dialog, DialogContent, DialogTitle, Divider, IconButton, LinearProgress, Tab, Tabs, TextField, Toolbar, Typography } from "@material-ui/core";
import LayoutService from "../../../../shared/services/layoutService";
import { CrudAction } from "../../../../shared/types/enums";
import { DataService, SubscriptionArray } from "../../../../shared/services/dataService";
import { MatIconService } from "../../../../shared/services/theme/matIconService";
import ComplianceDefaultEditComponent from "./complianceDefaultEditComponent";
// import AuditMappingComponent from "./auditMappingComponent";

class ComplianceDefaultDialogComponent extends React.Component {
    oSubscriptions = new SubscriptionArray();
    tabConfigMap = new Map();

    constructor(props) {
        super(props);

        this.tabIndexMap = new Map();
        this.tabIndexMap.set(0, { name: "editTabPanel", validationCallback: null, postCallback: null });

        this.state = {
            isReadOnly: this.props.inputAction === CrudAction.UPDATE, // default state
            isEditing: this.props.inputAction === CrudAction.UPDATE,
            selectedTabIndex: 0,
        };

    }

    onTabChange = (event, newValue) => {
        this.setState({ selectedTabIndex: newValue });
    };


    onClose = (_reloadRequired) => {
        this.setState({ isReadOnly: true }, () => {
            this.props.onClose(_reloadRequired);
        });
    }

    onSave = (_dataToPost) => {
        this.oSubscriptions.cancelAll();
        this.setState({ isSaving: true });

        let validationOBSs = [];
        this.tabIndexMap.forEach(tabConfig => { if (tabConfig.validationCallback) { validationOBSs.push(tabConfig.validationCallback()); } });

        this.oSubscriptions.add(combineLatest(validationOBSs).subscribe(
            (_validationResults) => {
                if (DataService.hasAnyFalse(_validationResults)) {  // error :  validation of all tabs
                    // TODO: display the error
                }
                else { // success :  validation of all tabs
                    let postOBSs = [];
                    this.tabIndexMap.forEach(tabConfig => { if (tabConfig.postCallback) { postOBSs.push(tabConfig.postCallback()); } });

                    // post the data
                    this.oSubscriptions.add(combineLatest(postOBSs).subscribe(
                        // success : Post
                        (_tabResults) => {
                            this.setState({ isSaving: false, saveErrorInfo: null });
                            this.onClose(true); // close this and reload the ProfileCenter
                        },
                        // error : Post
                        (_error) => {
                            this.setState({ isSaving: false, saveErrorInfo: _error }, () => {
                                setTimeout(() => { this.setState({ saveErrorInfo: null }); }, 5000)
                            });
                        }
                    ));
                }
            }
        ));
    }

    render() {
        const { classes } = this.props;
        return (
            <Dialog open={this.props.open || false} scroll={true ? "paper" : "body"} fullWidth={true} maxWidth={"lg"} onClose={() => { this.onClose(false) }} >
                {/* DialogTitle */}
                <DialogTitle disableTypography id="dialogTitle">
                    <AppBar position="static">
                        <Toolbar>
                            <Typography variant="h6" className={classes.root}>Mapping Detail</Typography>
                            <IconButton color="secondary" onClick={() => { this.onClose(false); }}>{MatIconService.CANCEL}</IconButton>
                        </Toolbar>
                        {this.state.isSaving ? <LinearProgress color="secondary" /> : null}
                        {this.state.saveErrorInfo ? <Typography style={{ backgroundColor: "#FF8A8A", color: "#6E0101" }} variant="h6" align="center">Error Occured while Saving</Typography> : null}
                    </AppBar>
                </DialogTitle>
                {/* DialogContent */}
                <DialogContent>
                    <Tabs value={this.state.selectedTabIndex} onChange={this.onTabChange} indicatorColor="secondary" textColor="inherit" variant="scrollable" scrollButtons="auto">
                        <Tab label="Default Mapping" id="editTab" />
                    </Tabs>
                    <div role="tabpanel" hidden={this.state.selectedTabIndex !== 0} id={"editTabPanel"} >
                        <Divider />
                        <Box style={{ padding: "0px", minHeight: "70vh", maxHeight: "70vh", }} >
                            <ComplianceDefaultEditComponent tabConfig={this.tabIndexMap.get(0)} isReadOnly={this.state.isReadOnly} inputAction={this.props.inputAction}
                                modalAgNode={this.props.modalAgNode} reportsList={this.props.reportsList} onClose={this.props.onClose} refreshList={this.props.refreshList} />
                        </Box>
                    </div>

                </DialogContent>
            </Dialog>
        );
    }
}
export default LayoutService.getHocComponenet(ComplianceDefaultDialogComponent);