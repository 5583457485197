import { ALIGNMENT } from "../../types/enums";
import { DataService } from "../dataService";

export class AgGridService {

    /**
     * toLowerCase should go away.  It is needed now because the Data come as LowerCase, and the Columns come as UpperCase.  
     * They APIs should change in the future to use the same case for both 
     * fieldAliasName -> header name, the same will be auto lowercased for fieldname
     * isExpViewDefault -> editable if true
     * 
     * @param {*} _agGridColumns Pass the Api Data
     * @param {*} _columnsToExclude an array of coulmn names to exclude
     * @returns  Returns Ag grid columns by Api Data
     */
    static getDynamicColumns(_agGridColumns, _lowerCase = true, _columnsToExclude, _agCellRenderers, _mixedCaseData = false) {

        _columnsToExclude = DataService.getLowerCaseElements(_columnsToExclude);

        return (_agGridColumns || []).filter((x) => !_columnsToExclude.includes(x.fieldAliasName.toLowerCase())).map((x) => ({
            headerName: x.fieldAliasName,
            field: _mixedCaseData && x.fieldAliasName === "ADDRESS1" ?
                "addresS1"
                : _lowerCase ? x.fieldAliasName.toLowerCase() : x.fieldAliasName,
            editable: x.isExpViewDefault,
            valueSetter: (params) => {
                params.data.ruleviolations = params.newValue;
                return true;
            },
            filter: "text",
            ...(_agCellRenderers.find(cr => cr.crId.toLowerCase() === x.fieldAliasName.toLowerCase()) && { cellRenderer: _agCellRenderers.find(cr => cr.crId.toLowerCase() === x.fieldAliasName.toLowerCase()).crVal })
            , resizable: true,
        }));
    }

    static getFixedWidth = (_width) => {
        return {
            resizable: false,
            width: _width,
            minWidth: _width,
            maxWidth: _width,
        }
    }

    static excelStyles = [
        {
            id: 'excelErrorClassName',
            font: {
                color: "#FF0000",
                strikeThrough: true,
            },
        },
        {
            id: 'excelWarningClassName',
            font: {
                color: "#ffa500",
                strikeThrough: true,
            },
        },
    ]

    static getBusinessRuleSeverity = (_ruleSummaries, _fieldName, _recordId) => {
        const fieldRuleSummary = _ruleSummaries.find(x =>
            x.fieldName.toLowerCase() === _fieldName.toLowerCase()
            && x.recordId === _recordId);

        if (fieldRuleSummary) {
            return (fieldRuleSummary.businessRuleSeverity + '').toLowerCase();
        } else {
            return null;
        }
    }

    /**
     * returns a dropdown column for the AgGrid's ColumnDef
     * @param {*} _fieldName field name in the row data (eg: countryId, languageId)
     * @param {*} _mappings parsed values eg: [{ 1: "Afganistan", 2 : "Argentina" }] | UsageTip: DataService.arrayToObject(_componentThisRef.state.countryList, "id", "value");
     * @param {*} _isEditable optional : {false} to disable editing the column
     * @param {*} _cellEditor optional : pass the cell-editor
     * @param {*} _cellHeight optional : height of the cell
     */
    static getDropdownColumn(_headerName, _fieldName, _mappings, _isEditable = true, _cellEditor = "agSelectCellEditor", _cellHeight = 20, _cellStyle = { "border-right": "1px solid lightgray" }) {
        const temp = {
            headerName: _headerName,
            field: _fieldName,
            editable: _isEditable,
            cellStyle: _cellStyle,
            cellEditor: _cellEditor,
            refData: _mappings, // dropdown-dataSource
            cellEditorParams: {
                cellHeight: _cellHeight,
                values: DataService.extractKeysAsNumbers(_mappings), // extract the keys as numbers
            },
        };
        return temp;
    }


    static getColumn(_isEditable, _headerText, _dataField, _cellRenderer, _width, _cellStyle = { "border-right": "1px solid lightgray" }, _filter = "text", _resizable = true) {
        return {
            headerName: _headerText,
            field: _dataField,
            editable: _isEditable,
            width: _width,
            cellStyle: _cellStyle,
            cellRenderer: _cellRenderer,
            filter: _filter,
            resizable: _resizable
        };
    }
    static getTextColumn(_isEditable, _headerText, _dataField, _cellStyle = { "border-right": "1px solid lightgray" }, _filter = "text") {
        return {
            headerName: _headerText,
            headerTooltip: _headerText,
            field: _dataField,
            editable: _isEditable,
            cellStyle: _cellStyle,
            filter: _filter,
            valueSetter: params => {
                params.data[_dataField] = (params.newValue + "").trim();
                return true;
            }
        };
    }

    static getSizedColumn(_isEditable, _dataField, _cellStyles = {},
        _headerText, _headerTextAlign = ALIGNMENT.LEFT,
        _filter = null, _sortable = null,
        _minWidth = null, _maxWidth = null, _flex = null, _resizable = true,
    ) {

        // corrections
        if (_minWidth > 0 && _maxWidth > 0) { _resizable = false; }
        if (DataService.isNullOrUndefined(_cellStyles)) { _cellStyles = {}; }

        // mandatory fields
        let oRET = {
            editable: _isEditable,
            headerName: _headerText,
            field: _dataField,
            resizable: _resizable,
            cellStyle: { "border-right": "1px solid lightgray", ..._cellStyles }
        };

        // add conditionally
        if (!DataService.isNullOrUndefined(_filter)) { oRET["filter"] = _filter; }
        if (!DataService.isNullOrUndefined(_sortable)) { oRET["sortable"] = _sortable; }
        if (!DataService.isNullOrUndefined(_flex)) { oRET["flex"] = _flex; }
        if (_minWidth > 0) { oRET["minWidth"] = _minWidth; }
        if (_maxWidth > 0) { oRET["maxWidth"] = _maxWidth; }
        switch (_headerTextAlign) {
            case ALIGNMENT.CENTER: oRET["headerClass"] = "center-text"; break;
            case ALIGNMENT.JUSTIFY: oRET["headerClass"] = "justify-text"; break;
            case ALIGNMENT.RIGHT: oRET["headerClass"] = "right-text"; break;
            //---
            case ALIGNMENT.LEFT:
            default:
                oRET["headerClass"] = "left-text";
                break;
        }

        // RETURN
        return oRET;

    }

}