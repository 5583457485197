import { DataService } from "../services/dataService";

export class AddressModel {

    addressid = "";
    addresS1 = "";
    addresS2 = "";
    addresS3 = "";
    addresS4 = "";
    city = "";
    province = "";
    postalcode = "";
    countryId = "";
    countryName = "";
    countrylist = "";
    porziogstaddressid = "";
    isprimary = "";
    isactive = "";
    companyaddressid = "";

    constructor(_isprimary = false, _isactive = true,
        _addressid = "", _addresS1 = "", _addresS2 = "", _addresS3 = "", _addresS4 = "",
        _city = "", _province = "", _postalcode = "", _porziogstaddressid = "", _companyaddressid = "",
        _countrylist = { "countryId": "", "countryName": "" }
    ) {
        this.addressid = _addressid;
        this.addresS1 = _addresS1;
        this.addresS2 = _addresS2;
        this.addresS3 = _addresS3;
        this.addresS4 = _addresS4;
        this.city = _city;
        this.province = _province;
        this.postalcode = _postalcode;
        this.countryId = _countrylist.countryId;
        this.countryName = _countrylist.countryName;
        this.countrylist = _countrylist;
        this.porziogstaddressid = _porziogstaddressid;
        this.isprimary = _isprimary;
        this.isactive = _isactive;
        this.companyaddressid = _companyaddressid;
    }

    //---
}

export class ProfileAddressModel {

    AddressId = null;
    Address1 = "";
    Address2 = "";
    Address3 = "";
    Address4 = "";
    City = "";
    Province = "";
    PostalCode = "";
    CountryId = null;
    CountryName = "";
    CountryList = "";

    ISPRIMARY = false;
    IsActive = false;
    PorzioGSTAddressId = "";
    CompanyAddressId = "";
    AddressList = null;

    constructor(_isprimary = false, _isactive = true,
        _addressid = "", _addresS1 = "", _addresS2 = "", _addresS3 = "", _addresS4 = "",
        _city = "", _province = "", _postalcode = "", _porziogstaddressid = "", _companyaddressid = "",
        _countrylist = { "CountryId": null, "CountryName": "", "CountryCode": "" }
    ) {
        this.AddressId = DataService.isStringNullOrEmpty(_addressid) ? null : _addressid;
        this.Address1 = _addresS1;
        this.Address2 = _addresS2;
        this.Address3 = _addresS3;
        this.Address4 = _addresS4;
        this.City = _city;
        this.Province = _province;
        this.PostalCode = _postalcode;
        this.CountryId = _countrylist.CountryId > 0 ? _countrylist.CountryId : null;
        this.CountryName = _countrylist.CountryName;

        this.IsActive = _isactive;
        this.CountryList = _countrylist;
        this.AddressList = null;
        this.CompanyAddressId = _companyaddressid;
        this.PorzioGSTAddressId = _porziogstaddressid;
        this.ISPRIMARY = _isprimary;
    }

    //---
}