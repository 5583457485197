import React from "react";
import { combineLatest, from, BehaviorSubject } from "rxjs";
import { Formik } from "formik";
import * as Yup from "yup";

import { Typography, Dialog, DialogTitle, DialogContent, AppBar, Toolbar, Box, Grid, LinearProgress } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";

import LayoutService from "../../../../shared/services/layoutService";
import { SubscriptionArray } from "../../../../shared/services/dataService";
import PageLoadingComponent from "../../../../shared/components/page/pageLoadingComponent";
import { API_ENDPOINT, ResultStatus } from "../../../../shared/types/enums";
import { MatIconService } from "../../../../shared/services/theme/matIconService";
import DialogErrorFragmentComponent from "../../../../shared/components/page/dialogErrorFragmentComponent";
import LookupService from "../../../../shared/services/lookupService";
import ToastService from "../../../../shared/services/toastService";
import ApiService from "../../../../shared/services/apiService";
import { AuthContext } from "../../../../shared/store/authProvider";


class ProfileStatusOverrideComponenet extends React.Component {

    static contextType = AuthContext;
    apiSubscriptions = new SubscriptionArray();

    constructor(props) {
        super(props);

        // init state
        this.state = {
            apiResult: ResultStatus.LOADED,
            currentProfileStatusId: this.props.currentValue,
            newProfileStausId: this.props.currentValue,
        };
    }


    componentWillUnmount() {
        this.apiSubscriptions.cancelAll();
    }

    componentDidMount() {
        this.setState({ apiResult: ResultStatus.LOADING });

        this.apiSubscriptions.cancelAll();
        this.apiSubscriptions.add(
            combineLatest([
                LookupService.getProfileStatusesAsOBS(this.context)
            ]).subscribe(
                // success
                ([_profileStatuses]) => {
                    this.setState({
                        profileStatuses: _profileStatuses.filter(x => x.id === 2 || x.id === 3),
                        apiResult: ResultStatus.LOADED
                    });
                },
                // error
                (errorObj) => {
                    ToastService.showError("Error while Loading");
                    this.props.onClose(false);
                }
            )
        );
    }

    saveProfileStatus = () => {
        this.setState({ apiResult: ResultStatus.SAVING });

        this.apiSubscriptions.cancelAll();
        let ids = this.props.selectedProfiles !== undefined ? [...this.props.selectedProfiles] : [this.props.id];
        let targetUrl = "";
        ids.forEach((x, idx) => {
            targetUrl = `/Profiles/UpdateProfileStatus/${this.context.user.tenantId}/${x}?userId=${this.context.user.userId}&manualEdit=${true}&profileStatusId=${this.state.newProfileStausId}`
            ApiService.postOBS(API_ENDPOINT.CORE, targetUrl)
                .subscribe(
                    (successResult) => {
                        if (successResult) {
                            if (idx === ids.length - 1) {
                                ToastService.showSuccess("Successfully Saved.");
                                this.setState({ apiResult: ResultStatus.SUCCESS });
                            }
                            this.props.onClose(false);
                            this.props.refreshTab();
                        }
                        else {
                            this.setState({ apiResult: ResultStatus.ERROR });
                        }
                    },
                    (errorResult) => {
                        ToastService.showError("Error while saving Transaction status");
                        this.setState({ apiResult: ResultStatus.ERROR });
                    }
                );
        })
    }


    // formik
    getInitialValues = () => {
        return { profileStatusId: this.state.currentProfileStatusId ? this.state.currentProfileStatusId : "" };
    }

    validationSchemaObj = Yup.object().shape({});
    getValidationSchema = () => {
        this.validationSchemaObj = Yup.object().shape({
            profileStatusId: LayoutService.getNullableValidation(Yup.number().required("Required"))
        });
        return this.validationSchemaObj;
    }


    render() {
        const { classes } = this.props;
        return (
            <Dialog open={true} scroll={"paper"} fullWidth={true} maxWidth="sm">
                {/* Title */}
                <DialogTitle style={{ padding: 0 }} id="dialog-title">
                    <AppBar position="static">
                        <Toolbar>
                            <Typography variant="h6" className={classes.root}>Override Profile Status</Typography>
                            {LayoutService.getIconButton((this.state.currentProfileStatusId === this.state.newProfileStausId || this.state.apiResult !== ResultStatus.LOADED),
                                MatIconService.OK, "Save", () => { this.saveProfileStatus() }, "inherit", "keyActionSave")}
                            {LayoutService.getIconButton(this.state.apiResult === ResultStatus.SAVING, MatIconService.CANCEL, "Cancel", () => { this.props.onClose(false); }, "secondary", "keyActionCancel1")}
                        </Toolbar>
                    </AppBar>
                </DialogTitle>
                {/* Content */}
                <DialogContent>
                    {this.renderContent(classes)}
                </DialogContent>
            </Dialog>
        );

    }

    renderContent = (classes) => {
        switch (this.state.apiResult) {
            case ResultStatus.LOADING:
            case ResultStatus.NOT_LOADED:
                return <PageLoadingComponent small classes={classes} label="Loading..." />;
            case ResultStatus.SAVING:
            case ResultStatus.LOADED:
            case ResultStatus.SUCCESS:
                return (<>
                    {this.state.apiResult === ResultStatus.SAVING ? <LinearProgress color="secondary" style={{ height: 6 }} /> : null}
                    {/* Form */}
                    <Formik initialValues={this.getInitialValues()} validationSchema={this.getValidationSchema()} validationSchemaOptions={{ showMultipleFieldErrors: true }}>
                        {(fProps) => (
                            <form>
                                <Box style={{ paddingLeft: 24, paddingRight: 24, paddingTop: 24, paddingBottom: 16 }}>
                                    {LayoutService.getDropDown(this.state.apiResult !== ResultStatus.LOADED, classes.dialogControl, classes.menuPaper, fProps, this.validationSchemaObj, "profileStatusId", "Profile Status",
                                        this.state.profileStatuses, "id", "value", "", false, "98%",
                                        (_fProps, _newProfileStatusId) => {
                                            this.setState({ newProfileStausId: _newProfileStatusId })
                                        })
                                    }
                                </Box>
                            </form>
                        )}
                    </Formik >
                    {/* Alert */}
                    {/* <Grid container direction="column" justify="flex-start" alignItems="stretch" style={{ padding: 0 }}>
                        <Alert severity="warning">
                            <AlertTitle>Note: This is only a temporary override of the Profile Status.</AlertTitle>
                            Any <strong>changes</strong> to the profile will <strong>Reset this Override</strong> and the profile status will be <strong>newley set</strong> based on the <strong>Profile Status matching logic.</strong>
                        </Alert>
                    </Grid> */}
                </>);
            case ResultStatus.ERROR:
            default:
                return (<DialogErrorFragmentComponent classes={classes} description="An error occured while Saving" onRetry={() => { this.saveProfileStatus(); }} />);
        }

    }

    //---
}
export default LayoutService.getHocComponenet(ProfileStatusOverrideComponenet);