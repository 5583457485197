import React from "react";
import { ResultStatus, CrudAction, ReportType } from "../../../../shared/types/enums";
import { of, combineLatest } from "rxjs";
import { withStyles, TextField, Grid, InputLabel, FormControl, Backdrop, colors } from "@material-ui/core";
import { DataService, SubscriptionArray } from "../../../../shared/services/dataService";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Formik } from "formik";
import * as Yup from "yup";
import DateFnsUtils from "@date-io/date-fns";
import { MatClassService } from "../../../../shared/services/theme/matClassService";
import PageLoadingComponent from "../../../../shared/components/page/pageLoadingComponent";
import PageErrorComponent from "../../../../shared/components/page/pageErrorComponent";
import LayoutService from "../../../../shared/services/layoutService";
import { Box, DialogTitle, DialogContent, Typography, AppBar, Toolbar, IconButton, MenuItem } from "@material-ui/core";
import { AuthContext } from "../../../../shared/store/authProvider";
import { MatIconService } from "../../../../shared/services/theme/matIconService";
import ToastService from "../../../../shared/services/toastService";
import EditReportDetailDialogService from "./editReportDetailDialogService";
import LookupService from "../../../../shared/services/lookupService";
import ReportSaveService from "../../report-save/reportSaveService";

let izendaTrue = false;
class EditReportDetailDialogComponent extends React.Component {
  static contextType = AuthContext;
  apiSubscriptions = new SubscriptionArray();

  constructor(props) {
    super(props);

    // init state
    this.state = {
      isReadOnly: this.props.inputAction === CrudAction.UPDATE ? true : false,
      isEditing: this.props.inputAction === CrudAction.UPDATE ? true : false,
      data: [],
      fetchResult: ResultStatus.LOADING,
      OUS: false
      //reportType : this.reportDetail.reportType
    };
  }

  componentWillUnmount() {
    this.apiSubscriptions.cancelAll();
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = (_ignoreCache = false) => {
    this.apiSubscriptions.cancelAll();
    this.setState({ fetchResult: ResultStatus.LOADING, data: [] });
    this.apiSubscriptions.add(
      combineLatest([
        EditReportDetailDialogService.getReportDetailsById(this.context.user.tenantId, this.props.modalAgNode.reportDetailID),
        LookupService.getFieldLOVByIdsAsOBS(this.context.user.tenantId, 304),
        LookupService.getFieldLOVByIdsAsOBS(this.context.user.tenantId, 305)
      ]).subscribe(
        // success
        ([_reportDetail, _reportLibraryLov, _finalDispositionLov]) => {
          this.setState(
            {
              reportDetail: _reportDetail,
              reportLibraryLov: _reportLibraryLov,
              finalDispositionLov: _finalDispositionLov,
            },
            // change the state after all the above are assigned
            () => {
              if (this.state.reportDetail.reportType.toLowerCase() !== ReportType.OUS.toLowerCase()) {
                this.setSelectedFilters();
                this.setState({ OUS: false });
              }
              else {
                this.setSelectedFiltersOUS();
                this.setState({ OUS: true });
              }

              this.setState({ fetchResult: ResultStatus.SUCCESS });

            }
          );
        },
        // onError
        (error) => {
          console.log("Error:", error);
          this.setState({ fetchResult: ResultStatus.ERROR });
        }));
  }

  setSelectedFilters = () => {
    let deserializedata = JSON.parse(this.state.reportDetail.filterData);
    let deserializedfilters = deserializedata ? deserializedata.Filters : [];
    this.setState({ selectedFilters: deserializedfilters.length > 0 ? deserializedfilters : [] }, () => {
      console.log(this.state.selectedFilters);
    })
    return this.selectedFilters;
  }

  setSelectedFiltersOUS = () => {
    let deserializedata = JSON.parse(this.state.reportDetail.filterData);
    let deserializedfilters = deserializedata ? deserializedata.IzendaReportFilters : [];
    if (deserializedfilters === null) {
      izendaTrue = false;
      this.setState({ OUS: true });
      this.setSelectedFilters();
      return this.selectedFilters;
    }
    else {
      izendaTrue = true;
      let izendaFilters = deserializedfilters ? deserializedfilters.ReportFilter : [];
      let filterFields = izendaFilters ? izendaFilters.FilterFields : [];
      let extraFilters = filterFields && filterFields.length > 0 ? filterFields.filter(o => o.QuerySourceFieldName.toLowerCase() !== '@Izendadisplayname'.toLowerCase() && o.QuerySourceFieldName.toLowerCase() !== '@reportspname'.toLowerCase()) : [];
      this.setState({ selectedFilters: extraFilters && extraFilters.length > 0 ? extraFilters : [] })
      return this.selectedFilters;
    }
  }


  getInitialValues = () => {
    let obj = {
      reportDetailName: this.state.reportDetail.reportDetailName,
      reportDetailDescription: this.state.reportDetail.reportDetailDescription,
      reportLibraryId: this.state.reportDetail.reportLibraryId,
      finalDisposition: this.state.reportDetail.finalDisposition,
      finalDispositionDate: DataService.isNullOrUndefined(this.state.reportDetail.finalDispositionDate) ? this.state.reportDetail.finalDispositionDate : DataService.getDateStringForDisplay(this.state.reportDetail.finalDispositionDate),
      dispositionComments: this.state.reportDetail.dispositionComments


    };
    return obj;
  }

  validationSchema = Yup.object().shape({});
  getValidationSchema = () => {
    this.validationSchema = Yup.object({
      reportDetailName: Yup.string().required("Enter a Report Name").max(256, "Must be 256 characters or less"),
      reportDetailDescription: Yup.string().max(256, "Must be 256 characters or less"),
      reportLibraryId: Yup.number().min(1, "Select a Report Library"),
      finalDisposition: Yup.number().nullable(true).min(0, "Select a Final Disposition"),
      finalDispositionDate: Yup.date().nullable(true).min("1/1/2000", "On or after 1/1/2000").max("1/1/2099", "On or before 1/1/2099"),
      dispositionComments: Yup.string().nullable(true).max(256, "Must be 256 characters or less"),
    });
    return this.validationSchema;
  }

  handleSubmit = async (_formikProps) => {
    if (!_formikProps.isSubmitting && _formikProps.isValid) {
      await this.validationSchema.validate(_formikProps.values, { abortEarly: false })
        .then((x) => {
          this.updateReportDetails(_formikProps);
        })
        .catch((errorObj) => {
          console.log(errorObj.message);
          ToastService.showError("Please recheck your input.");
          errorObj.inner && errorObj.inner.forEach(err => { _formikProps.setFieldError(err.path, err.message); });
        });
    }
  }

  updateReportDetails = async (_formikProps) => {
    this.setState({ fetchResult: ResultStatus.SAVING, });
    try {
      const obj = JSON.stringify({
        reportDetailID: this.state.reportDetail.reportDetailID,
        reportDetailName: _formikProps.values.reportDetailName,
        reportDetailDescription: _formikProps.values.reportDetailDescription,
        reportLibraryId: _formikProps.values.reportLibraryId,
        finalDisposition: _formikProps.values.finalDisposition,
        finalDispositionDate: DataService.getDateFromDateTimeStamp(_formikProps.values.finalDispositionDate, null),
        dispositionComments: _formikProps.values.dispositionComments,
        //Passing additional values below as they are mandatory
        fileType: this.props.modalAgNode.fileType,
        isActive: true, //this.state.reportDetail.isActive,: TODO
        genStatus: this.state.reportDetail.genStatus,
        isComplianceSave: this.state.reportDetail.isComplianceSave,
        reportType: this.state.reportDetail.reportType
      });

      this.apiSubscriptions.add(
        EditReportDetailDialogService.updateReportDetails(this.context.user.tenantId, obj, this.context.user.userId)
          .subscribe((successResult) => {
            ToastService.showSuccess("Report details updated successfully");
            ReportSaveService.reportSavedSubject.next('true');
            this.props.refreshReportLibrary(true);
            this.props.onClose(false);
          },
            (errors) => {
              console.error("Error while saving report", errors);
              this.setState({ fetchResult: ResultStatus.ERROR });
            }
          ));
    } catch (error) {
      console.log(error);
      this.setState({ fetchResult: ResultStatus.ERROR, });
    }
  }

  /**Render */
  render() {
    const { classes } = this.props;
    const componentType = "Report Details"
    switch (this.state.fetchResult) {
      case ResultStatus.NOT_LOADED:
      case ResultStatus.LOADING:
        return <PageLoadingComponent small classes={classes} label={`Loading ${componentType}`} />;
      case ResultStatus.SAVING:
        return <PageLoadingComponent small classes={classes} label={`Saving ${componentType}`} />;
      case ResultStatus.SUCCESS:
        return (
          <Formik initialValues={this.getInitialValues()} validationSchema={this.getValidationSchema()}>
            {(fProps) => (
              <form >
                {/* Dialog Title */}
                <DialogTitle disableTypography id="dialogTitle">
                  <AppBar position="static">
                    <Toolbar variant="dense">
                      <Typography variant="h6" className={classes.root}>Edit {componentType}</Typography>

                      {LayoutService.getReadOnlyActions(this, !this.state.isEditing, () => { this.props.onClose(false) }, () => { this.handleSubmit(fProps) })}
                    </Toolbar>
                  </AppBar>
                </DialogTitle>

                {/* Dialog Content */}
                <DialogContent>
                  <Box style={{ paddingLeft: 16, paddingRight: 32, paddingTop: 8, paddingBottom: 32, }}  >

                    <div title="User Selected  Filters" hidden={this.state.selectedFilters ? this.state.selectedFilters.length == 0 : true}> User Selected Filters: </div>

                    <Grid container spacing={1} style={{ minHeight: "10vh" }}>
                      {!izendaTrue ? (
                        <table border="2" style={{ width: "100%" }} hidden={this.state.selectedFilters ? this.state.selectedFilters.length == 0 : true}>
                          {this.state.selectedFilters ? this.state.selectedFilters.map((item) => (
                            <tr style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                              <td>
                              {item.FilterName === "IZENDADISPLAYNAME" ? "DISPLAYNAME" : item.FilterName}
                              </td>
                              <td>{item.DisplayValue}</td>
                            </tr>
                          )) : <tr></tr>}
                        </table>
                      ) : (
                        <table border="2" style={{ width: "100%" }} hidden={this.state.selectedFilters ? this.state.selectedFilters.length == 0 : true}>
                          {this.state.selectedFilters ? this.state.selectedFilters.map((item) => (
                            <tr style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                              <td>
                                {this.state.OUS ? (item.QuerySourceFieldName.includes('@') ? item.QuerySourceFieldName.replace('@', '') : item.QuerySourceFieldName) : (item.FilterDisplayName ? item.FilterDisplayName : item.FilterName)}
                              </td>
                              <td>{this.state.OUS ? item.Value : item.DisplayValue}</td>
                            </tr>
                          )) : <tr></tr>}
                        </table>
                      )}



                    </Grid>

                    <br></br>
                    <table border="2" style={{ width: "100%" }}>
                      <tr style={{ paddingLeft: "10px", paddingRight: "10px" }} hidden={this.state.OUS ? this.state.reportDetail.languageList ? this.state.reportDetail.languageList.length == 0 : true : true}><td>Selected Reports</td><td>{this.state.reportDetail.languageList ? this.state.reportDetail.languageList : '-'}</td></tr>
                      <tr><td>Compliance Report</td><td>{this.state.reportDetail.isComplianceSave ? "Yes" : "No"}</td></tr>
                      <tr><td>Porzio Analysis Report</td><td>{this.state.reportDetail.isCompanionSave ? "Yes" : "No"}</td></tr>
                      <tr><td>FileType</td><td>{this.state.reportDetail.fileType}</td></tr></table>

                    <Grid item xs={12} sm={12}> <TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "reportDetailName", "Report Name")} fullWidth /></Grid>
                    <Grid item xs={12} sm={12}> <TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "reportDetailDescription", "Report Description", false)} fullWidth rows={3}
                      multiline /></Grid>
                    <Grid item xs={12} sm={6}>{LayoutService.getSelectControl(this.state.isReadOnly, classes, fProps, "reportLibraryId", "Report Library",
                      this.state.reportLibraryLov, "lovId", "lovKey", "95%")}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {LayoutService.getSelectControl(this.state.isReadOnly, classes, fProps, "finalDisposition", "Final Disposition",
                        [{ lovId: 0, lovKey: "Select" }, ...this.state.finalDispositionLov], "lovId", "lovKey", "95%")}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker {...LayoutService.getDateProps(this.state.isReadOnly, classes, fProps, "finalDispositionDate", "Final Disposition Date", false)}
                          format="yyyy-MM-dd" style={{ minWidth: "47%" }} />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "dispositionComments", "Final Disposition Comments", false
                      )} style={{ minWidth: "80%" }} rows={3} multiline />
                    </Grid>
                    {/* </Grid> */}
                  </Box>
                </DialogContent>
              </form>
            )}
          </Formik>
        );
      case ResultStatus.ERROR:
      default:
        return (<PageErrorComponent small label="Error Loading Report Details" classes={classes} onRetry={() => { this.fetchData(true); }} />);
    }
  }

  //---
}
/** HOC */
export default withStyles(MatClassService)(EditReportDetailDialogComponent);
