import React from "react";
import { AppBar, Box, Dialog, Tooltip, IconButton, DialogContent, DialogTitle, Divider, LinearProgress, Tab, Tabs, TextField, Toolbar, Typography, Accordion, AccordionSummary, AccordionDetails, Select, Grid, Chip, InputBase, makeStyles, Button, Menu, MenuItem, Paper, FormControl, FormHelperText } from "@material-ui/core";
import { MatIconService } from "../../../shared/services/theme/matIconService";
import LayoutService from "../../../shared/services/layoutService";
import { DataService, SubscriptionArray } from "../../../shared/services/dataService";
import { FILTER_TYPE, ResultStatus } from "../../../shared/types/enums";
import { textFilterCriteria, lovFilterCriteria, valueFilterCriteria, dateFilterCriteria, usMiamiHcpValues } from "../../../shared/types/constants";
import { Formik } from "formik";
import * as Yup from "yup";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import MatSpecService from "../../../shared/services/theme/matSpecService";
import HCPSearchDialogService from "./hcpSearchDialogService";
import { AuthContext } from "../../../shared/store/authProvider";
import DialogErrorFragmentComponent from "../../../shared/components/page/dialogErrorFragmentComponent";
import PageLoadingComponent from "../../../shared/components/page/pageLoadingComponent";
import { AgGridReact } from "ag-grid-react";
import { AgGridColumnExt } from "../../../shared/services/ag-grid/agGridColumnExt";
import { AgGridUtil } from "../../../shared/services/ag-grid/agGridUtil";
import { combineLatest } from "rxjs";
import LookupService from "../../../shared/services/lookupService";
import { AgGridBulkEditUtil } from "../../../shared/components/ag-grid/bluk-edit/agGridBulkEditUtil";
import AgGridCheckboxCellRendererComponent from "../../../shared/components/elements/agGridCheckboxCellRendererComponent";
import PageDynamicHeaderComponent from "../../../shared/components/page/pageDynamicHeaderComponent";
import RolePermissionService from "../../../shared/role-permissions/rolePermissionService";
import ToastService from "../../../shared/services/toastService";

class HCPSearchDialogComponent extends React.Component {
  static contextType = AuthContext;
  oSubscriptions = new SubscriptionArray();

  constructor(props) {
    super(props);
    this.filterWithErrorValue = [5, 11, 118, 35, 36];

    this.state = {
      selectedTabIndex: 0,
      filterCriteria: [],
      savedFilterData: [],
      searchValue: "",
      hcpSearchResults: [],
      selectedProfiles: [],
      errorInfo: null,
      isUsMiamiHcp: null,
      //fetchResult: ResultStatus.NOT_LOADED,
      agGridBulkEditUtil: new AgGridBulkEditUtil(),
      agGridUtils: new AgGridUtil("lastname", {
        isCheckedCellRenderer: AgGridCheckboxCellRendererComponent,
        //inlineEditButtonCellRendererComponent: AgGridEditButtonCellRendererComponent,
        //profileDetailCellRendererComponent: ProfileDetailCellRendererComponent,
      }),
    };
  }

  componentWillUnmount() {
  }

  componentDidMount() {
    this.fetchFilterData();
  }

  /*  Filter section Start */
  fetchFilterData = (_ignoreCache = false) => {
    this.oSubscriptions.cancelAll();
    this.setState({ fetchResult: ResultStatus.LOADING });
    // save the subscription object
    this.oSubscriptions.add(
      HCPSearchDialogService.fetchFilterData(this.context.user.tenantId)
        .subscribe(
          (data) => {
            if (data) {
              data["filterFields"].forEach(x => { x.isSelected = false; });
              let filterFields = data["filterFields"];

              let operators = data["operators"];

              let lovFilters = data["lovFilters"];
              this.setState({
                fetchResult: ResultStatus.LOADED,
                filterFields: filterFields,
                operators: operators,
                lovFilters: lovFilters,
              });
              //TODO : add isSelected flag for hiding already selected filters
            }
            else {
              this.setState({
                fetchResult: ResultStatus.ERROR,
                errorInfo: "Error occurred while fetching filters"
              });
            }
          },
          (error) => {
            this.setState({
              fetchResult: ResultStatus.ERROR,
              errorInfo: "Error occurred while fetching filters. " + error.message
            });
          }
        )
    );
  }

  handleDeleteChip = (chipToDelete) => () => {
    this.setState({
      savedFilterData: this.state.savedFilterData.filter((filter) => filter.fieldAliasName !== chipToDelete.fieldAliasName),
      hcpSearchResults: []
    });
  }

  clearAllChips = () => {
    this.setState({ savedFilterData: [], hcpSearchResults: [] });
  }

  async handleFieldSelectChange(value) {
    let filterType = this.state.filterFields.find(f => f.fieldName == value)?.fieldTypeID;
    let filterCriteria;
    switch (filterType) {
      case FILTER_TYPE.TEXT:
      default:
        filterCriteria = textFilterCriteria;
        break;
      case FILTER_TYPE.LOV:
        filterCriteria = lovFilterCriteria;
        break;
      case FILTER_TYPE.NUMERIC:
        filterCriteria = valueFilterCriteria;
        break;
      case FILTER_TYPE.DATE:
        filterCriteria = dateFilterCriteria;
        break;
    }
    await this.formikProps.setFieldValue('filterCriteria', filterCriteria[0].lovId, false);

    this.setState({
      filterCriteria: filterCriteria
    });
  }

  async handleCriteriaSelectChange(value) {
    // await this.formikProps.setFieldValue('filterCriteria', value, false);
    // await this.formikProps.setFieldTouched('filterCriteria', true, false);
    // this.formikProps.validateField('filterCriteria');
  }

  handleLOVValueChange(newValues) {
  }

  addFilter = async (fieldName, criteria, value) => {//TODO
    if (!this.formikProps.isSubmitting && this.formikProps.isValid) {
      await this.validationSchema.validate(this.formikProps.values, { abortEarly: false })
        .then((x) => {
          let selectedFilter = this.state.filterFields.find(f => f.fieldName == fieldName);

          let savedFilterData = {
            fieldId: selectedFilter?.fieldID,
            fieldText: fieldName,
            fieldAliasName: selectedFilter?.fieldAliasName,
            fieldTypeID: selectedFilter?.fieldTypeID,
            criteria: criteria,
            value: value,
          };

          this.setState({
            savedFilterData: this.state.savedFilterData.concat(savedFilterData)
          }, () => this.formikProps.resetForm());
        })
        .catch((errorObj) => {
          console.log(errorObj.message);
          if (errorObj.inner) {
            errorObj.inner.forEach(err => {
              this.formikProps.setFieldError(err.path, err.message);
            });
          }
        });
    }
  }

  constructSearchString = () => {
    let searchValue = "";

    searchValue = this.state.savedFilterData.map(data => {
      let operator;
      switch (data.criteria) {
        case "equals":
        case "notequals":

          //Handle Null and Error filter values
          if (data.fieldTypeID == FILTER_TYPE.LOV) {//sample string: @SerachString='Country IN ('Belgium', 'United States') OR Country is NULL' 
            let selectedValues = data.value.split(",");
            const nullStr = "'Null'";
            const errorStr = "'Error/Warning'";//sample string: if 'PURPOSE_FLAG='1'

            const hasNullValue = selectedValues.some(x => x == nullStr);
            const hasErrorValue = selectedValues.some(x => x == errorStr);

            let clause = '';
            if (selectedValues.some(x => x != nullStr && x != errorStr)) {
              operator = data.criteria == "equals" ? " IN " : " NOT IN ";
              clause = `${data.fieldAliasName}${operator}(${selectedValues.filter(x => x != nullStr && x != errorStr).join()})`;
            }
            if (hasNullValue && hasErrorValue) {
              let addClause = `${data.fieldAliasName}_FLAG${(data.criteria == "equals") ? "=" : "<>"}1 OR ${data.fieldAliasName} ${(data.criteria == "equals") ? "IS NULL" : "IS NOT NULL"}`
              clause += selectedValues.some(x => x != errorStr && x != nullStr) ? ` OR ${addClause}` : `${addClause}`;
            } else if (hasNullValue) {
              let addClause = `${data.fieldAliasName} ${(data.criteria == "equals") ? "IS NULL" : "IS NOT NULL"}`;
              clause += selectedValues.some(x => x != nullStr) ? ` OR ${addClause}` : `${addClause}`;
            } else if (hasErrorValue) {
              let addClause = `${data.fieldAliasName}_FLAG${(data.criteria == "equals") ? "=" : "<>"}1`;
              clause += selectedValues.some(x => x != errorStr) ? ` OR ${addClause}` : `${addClause}`;
            }
            return clause;
          }


          if (data.fieldTypeID == FILTER_TYPE.TEXT) {
            if (data.value.indexOf(",") !== -1) { //Handle comma separated values
              operator = data.criteria == "equals" ? " IN " : " NOT IN ";
              let clause = `${data.fieldAliasName}${operator}(${data.value})`;
              return clause;
            }
            operator = data.criteria == "equals" ? "=" : "<>";
            return `${data.fieldAliasName}${operator}N'${data.value}'`;
          }

          operator = data.criteria == "equals" ? "=" : "<>";
          break;
        case "lessthan":
          operator = "<";
          break;
        case "greaterthan":
          operator = ">";
          break;
        case "between":
          operator = " BETWEEN ";
          return `${data.fieldAliasName}${operator}${data.value}`;
        //break;
        case "contains":
          operator = " LIKE ";
          return `${data.fieldAliasName}${operator}N'%${data.value}%'`;
        //break;
        default:
          operator = "=";
      }
      return `${data.fieldAliasName}${operator}'${data.value}'`;
    }).join(" AND ");
    return searchValue;
  }

  applyFilter = () => {
    let searchedValue = this.constructSearchString();
    this.setState({
      searchValue: searchedValue
    }, () => {
      this.fetchData();
    });
  }

  getFilterValueContent(_formikProps, _classes) {
    this.formikProps = _formikProps;
    let filterType = this.state.filterFields.some(f => f.fieldName == _formikProps.values['filterFieldId']) ?
      this.state.filterFields.find(f => f.fieldName == _formikProps.values['filterFieldId']).fieldTypeID : FILTER_TYPE.TEXT;
    let selectedCriteriaValue = _formikProps.values['filterCriteria'] ? _formikProps.values['filterCriteria'] :
      this.state.filterCriteria[0];

    if (filterType === FILTER_TYPE.DATE) {
      return (
        <>
          <Grid item xs={12} sm={2}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker {...LayoutService.getDateProps(false, _classes, _formikProps, "startDate", "Start Date", true)} format="yyyy-MM-dd" style={{ minWidth: "90%" }} />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={2}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker {...LayoutService.getDateProps(false, _classes, _formikProps, "endDate", "End Date", true)} format="yyyy-MM-dd" style={{ minWidth: "90%" }} />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={2} style={{ marginTop: "10px" }}>
            <Tooltip enterDelay={MatSpecService.tooltipEnterDelay} title="Add Filter" placement="bottom" arrow>
              <IconButton onClick={() => {
                this.addFilter(_formikProps.values['filterFieldId'], _formikProps.values['filterCriteria'], `'${DataService.getDateFromDateTimeStamp(_formikProps.values['startDate']) + "' and '" + DataService.getDateFromDateTimeStamp(_formikProps.values['endDate'])}'`)
              }} color="primary">{MatIconService.ADD_CIRCLE_OUTLINE}</IconButton>
            </Tooltip>
          </Grid>
        </>
      );
    } else if (filterType === FILTER_TYPE.LOV) {
      let selectedFilterFieldID = this.state.filterFields.find(f => f.fieldName == _formikProps.values['filterFieldId'])?.fieldID;
      return (<>
        {this.filterWithErrorValue.includes(selectedFilterFieldID) ?
          <Grid item xs={12} sm={4} style={{ marginTop: "-10px" }}>
            {LayoutService.getChipSelect(false, _classes, _formikProps, "filterLOVValue", "Filter Value", [{ fieldID: selectedFilterFieldID, lovKey: "Error/Warning", lovid: null }, { fieldID: selectedFilterFieldID, lovKey: "Null", lovid: 0 }, ...[this.state.lovFilters.filter(f => f.fieldID == selectedFilterFieldID)][0]].sort((a, b) => a.lovKey.localeCompare(b.lovKey)), "lovid", "lovKey", this.handleLOVValueChange, "98%")}
          </Grid>
          :
          <Grid item xs={12} sm={4} style={{ marginTop: "-10px" }}>
            {LayoutService.getChipSelect(false, _classes, _formikProps, "filterLOVValue", "Filter Value", [{ fieldID: selectedFilterFieldID, lovKey: "Null", lovid: 0 }, ...[this.state.lovFilters.filter(f => f.fieldID == selectedFilterFieldID)][0]].sort((a, b) => a.lovKey.localeCompare(b.lovKey)), "lovid", "lovKey", this.handleLOVValueChange, "98%")}
          </Grid>}
        <Grid item xs={12} sm={2} style={{ marginTop: "10px" }}>
          <Tooltip enterDelay={MatSpecService.tooltipEnterDelay} title="Add Filter" placement="bottom" arrow>
            <IconButton onClick={() => {
              this.addFilter(_formikProps.values['filterFieldId'], _formikProps.values['filterCriteria'], "'" + _formikProps.values["filterLOVValue"].map(lov => lov.lovKey).join("','") + "'")
            }} color="primary">{MatIconService.ADD_CIRCLE_OUTLINE}</IconButton>
          </Tooltip>
        </Grid>
      </>);
    } else {
      if (selectedCriteriaValue == "between") {
        //value type with range selected
        return (
          <>
            <Grid item xs={12} sm={2}>
              <TextField type="number" {...LayoutService.getInputProps(false, _classes, _formikProps, "filterRangeValue1", "From", true)} style={{ minWidth: "80%" }} />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField type="number" {...LayoutService.getInputProps(false, _classes, _formikProps, "filterRangeValue2", "To", true)} style={{ minWidth: "80%" }} />
            </Grid>
            <Grid item xs={12} sm={2} style={{ marginTop: "10px" }}>
              <Tooltip enterDelay={MatSpecService.tooltipEnterDelay} title="Add Filter" placement="bottom" arrow>
                <IconButton onClick={() => {
                  this.addFilter(_formikProps.values['filterFieldId'], _formikProps.values['filterCriteria'], `'${_formikProps.values['filterRangeValue1'] + "' and '" + _formikProps.values['filterRangeValue2']}'`)
                }} color="primary">{MatIconService.ADD_CIRCLE_OUTLINE}</IconButton>
              </Tooltip>
            </Grid>
          </>
        );
      }
      return (
        <React.Fragment>
          <Grid item xs={12} sm={4}>
            <TextField error={_formikProps.values["filterValue"] == ''} helperText={_formikProps.values["filterValue"] == '' ? 'Enter a Filter Value!' : ' '} {...LayoutService.getInputProps(false, _classes, _formikProps, "filterValue", "Filter Value", true)} style={{ minWidth: "80%" }} />
          </Grid>
          <Grid item xs={12} sm={2} style={{ marginTop: "10px" }}>
            <Tooltip enterDelay={MatSpecService.tooltipEnterDelay} title="Add Filter" placement="bottom" arrow>
              <IconButton onClick={() => {
                let modValues = _formikProps.values['filterValue'];
                let selectedValues = _formikProps.values['filterValue'].split(",");
                if (selectedValues && selectedValues.length > 1) {
                  modValues = "'" + selectedValues.join("','") + "'";
                }
                this.addFilter(_formikProps.values['filterFieldId'], _formikProps.values['filterCriteria'], modValues);
              }} color="primary">{MatIconService.ADD_CIRCLE_OUTLINE}</IconButton>
            </Tooltip>
          </Grid>
        </React.Fragment>
      );
    }
  }
  /*  Filter section End */

  onClose = (_reloadRequired = false) => {
    this.setState({
      savedFilterData: [],
      searchValue: ""
    }, () => this.props.close());
  }

  handleHcpChange = (_hcpValue) => {
    if (_hcpValue === "yes") {
      this.setState({ isUsMiamiHcp: true });
    } else {
      this.setState({ isUsMiamiHcp: false });
    }
  }

  /** API Fetch */
  fetchData = () => {
    this.oSubscriptions.cancelAll();
    this.setState({ data: [], fetchResult: ResultStatus.LOADING });
    this.oSubscriptions.add(
      combineLatest([
        HCPSearchDialogService.searchOBS(this.context.user.tenantId, this.state.searchValue),
        LookupService.getFormattedCountriesAsOBS(this.context, null),
      ]).subscribe(
        // success
        ([_data, _countryList]) => {
          _data.forEach(x => x.is_USMiamiHCP === "Y" ? x.is_MiamiHcp = "Yes" : x.is_MiamiHcp = "No");
          this.setState(
            {
              hcpSearchResults: DataService.hasElements(_data) ? _data.map(r => ({ ...r, countryName: _countryList.find(c => c.id === r.country)?.value, })) : [],
            },
            // change the state after all the above are assigned
            () => {
              //this.state.agGridUtils.sizeColumnsToFit();
              this.setState({ fetchResult: ResultStatus.SUCCESS });
            }
          );
        },
        // onError
        (error) => {
          console.log("Error:", error);
          this.setState({ fetchResult: ResultStatus.ERROR });
        }
      )
    );
  }

  toggleSubmitButton = (_cellRef, _data) => {
    var hasSelected = false;
    let selectedProfiles = [];
    if (this.state.agGridBulkEditUtil.hasUpdates()) {
      hasSelected = true;
    } else {
      this.state.agGridUtils.gridApi.forEachNode((rowNode) => {
        hasSelected = hasSelected || rowNode.data[AgGridBulkEditUtil.selectColumnName] === true;
        if (rowNode.data && rowNode.data[AgGridBulkEditUtil.selectColumnName]) {
          selectedProfiles.push(rowNode.data);
        }
      });
    }
    // set the state
    this.setState({
      selectedProfiles: selectedProfiles,
      isReadOnly: !hasSelected,
      //isInit: false // only true for the first time 
    });
  }

  handleSubmit = () => {
    let selectedProfileIds = this.state.selectedProfiles.map(p => p.prid).join(",");
    HCPSearchDialogService.saveProfiles(this.context.user.tenantId, selectedProfileIds, this.state.isUsMiamiHcp).subscribe(
      (_successResult) => {
        if (_successResult) {
          this.setState({ selectedProfiles: [] })
          ToastService.showSuccess("Profile(s) updated successfully");
          //this.fetchData(false);
        }

      },
      (_errorResult) => {
        ToastService.showError("Error occured while updating Profile(s)");
        console.log(_errorResult);
      }
    );

    // this.setState({
    //   savedFilterData: [],
    //   //searchValue: ""
    // });
  }

  // formik
  getInitialValues() {
    return {
      filterFieldId: null,
      filterCriteria: '',
      startDate: new Date(),
      endDate: new Date(),
      //filterLOVValue: '',
      filterValue: '',
      filterRangeValue1: '',
      filterRangeValue2: '',
      //isUsMiamiHcp: ''
    };
  }

  validationSchema = Yup.object().shape({});
  getValidationSchema() {
    let filterSchema = Yup.object().shape({
      filterFieldId: Yup.string().required("Select a Filter Name").nullable(true).min(1, "Select a Filter Name"), //Yup.number().nullable(true)
      filterCriteria: Yup.string().required("Select a Filter Criteria").nullable(true).min(1, "Select a Filter Criteria"),
      isUsMiamiHcp: Yup.string()
      // //filterLOVValue: Yup.string().nullable(true).min(1, "Select a Filter Value"),
    });

    if (this.formikProps && this.formikProps.values['filterFieldId']) {
      let filterType = this.state.filterFields.find(f => f.fieldName == this.formikProps.values['filterFieldId']).fieldTypeID;
      let filterDynamicSchema = Yup.object().shape({});
      if (filterType === FILTER_TYPE.DATE) {
        filterDynamicSchema = Yup.object().shape({
          startDate: Yup.date().required("Select a Start Date").nullable(true).min("1/1/2000", "On or after 1/1/2000").max("1/1/2099", "On or before 1/1/2099"),
          endDate: Yup.date().required("Select an End Date").nullable(true).min("1/1/2000", "On or after 1/1/2000").max("1/1/2099", "On or before 1/1/2099"),
        });
      } else if (filterType === FILTER_TYPE.LOV) {
        filterDynamicSchema = Yup.object().shape({
          filterLOVValue: Yup.string().required("Select a Filter Value").nullable(true).min(1, "Select a Filter Value"),
        });
      } else {
      }

      this.validationSchema = filterSchema.concat(filterDynamicSchema);
    } else this.validationSchema = filterSchema;
    return this.validationSchema;
  }

  render() {
    const { classes } = this.props;
    switch (this.state.fetchResult) {
      case ResultStatus.NOT_LOADED:
      case ResultStatus.LOADING:
        return <PageLoadingComponent small classes={classes} label="Loading" />;
      case ResultStatus.LOADED:
      case ResultStatus.SUCCESS:
        return (
          <>
            <React.Fragment>
              <div id="MainRoleGrid">
                <div style={{ backgroundColor: "#ddd", textAlign: "right" }}></div>
                <div className="IconBg marginTop10px tableTitleHead">
                  {<PageDynamicHeaderComponent classes={classes} label="US Miami HCP" rightPadding={16}
                    leftActions={[{ icon: MatIconService.BACK, title: "Back", iconColor: "secondary", onClick: this.props.history.goBack }]}
                    rightActions={[
                      { icon: MatIconService.OK, title: "Update", iconColor: "secondary", onClick: this.handleSubmit, isReadOnly: this.state.selectedProfiles?.length === 0 },
                      { icon: MatIconService.RELOAD, title: "Reload", iconColor: "secondary", onClick: () => { this.fetchData(true); }, },
                    ]}
                  />}
                </div>
                <Accordion expanded={true} square={true}>
                  <AccordionDetails>
                    <Formik initialValues={this.getInitialValues()} validationSchema={this.getValidationSchema()}
                      validationSchemaOptions={{ showMultipleFieldErrors: true }}>
                      {(fProps) => (
                        <form style={{ width: "100%" }}>
                          <Grid container direction="row" alignItems="center" style={{ paddingBottom: "10px", }}>
                            <Grid container spacing={1} style={{ minHeight: "10vh" }}>
                              <Grid item xs={12} sm={3} >
                                {LayoutService.getDropDown(false, classes.dialogControl, classes.menuPaper, fProps, this.validationSchema, "filterFieldId", "Filter Name",
                                  this.state.filterFields, "fieldName", "fieldName", null, false, "98%", (_formikProps, _newValue) => {
                                    this.handleFieldSelectChange(_newValue);
                                  }
                                )}
                              </Grid>
                              <Grid item xs={12} sm={3}>
                                {LayoutService.getDropDown(false, classes.dialogControl, classes.menuPaper, fProps, this.validationSchema, "filterCriteria", "Criteria",
                                  this.state.filterCriteria, "lovId", "lovKey", null, false, "98%", (_formikProps, _newValue) => { this.handleCriteriaSelectChange(_newValue); }
                                )}
                              </Grid>
                              {this.getFilterValueContent(fProps, classes)}
                            </Grid>
                            <Grid container spacing={1}>
                              <Paper component="ul" square="true" elevation="0" style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', listStyle: 'none', padding: '4px', boxShadow: 'none', alignItems: 'center' }}>
                                {this.state.savedFilterData.map((data) => {
                                  return (<li key={data.key}>
                                    <Chip variant="outlined" label={
                                      <span>
                                        <span style={{ color: 'black' }}>{data.fieldText}</span>
                                        <span style={{ color: 'gray' }}> {data.criteria}</span>
                                        <span style={{ color: 'black' }}> {data.value}</span>
                                      </span>} onDelete={this.handleDeleteChip(data)} style={{ margin: '4px' }} />
                                  </li>
                                  );
                                })}
                                {this.state.savedFilterData.length > 0 ?
                                  <Tooltip enterDelay={MatSpecService.tooltipEnterDelay} title="Clear All" placement="bottom" arrow>
                                    <IconButton onClick={() => { this.clearAllChips() }} color="inherit">{MatIconService.CLOSE}</IconButton>
                                  </Tooltip> : null}
                              </Paper>
                            </Grid>
                            <Grid container xs={12} justify="center">
                              <Button variant="outlined" color="secondary" style={{ marginRight: "10px" }} onClick={() => { this.applyFilter() }} className={classes.button} disabled={this.state.savedFilterData.length === 0} >Apply Filter</Button>
                            </Grid>
                            {this.state.hcpSearchResults.length !== 0 ? <Grid container xs={12} sm={3} >
                              {LayoutService.getDropDown(false, classes.dialogControl, classes.menuPaper, fProps, this.validationSchema, "isUsMiamiHcp", "US Miami HCP",
                                usMiamiHcpValues, "lovId", "lovKey", null, false, "98%", (_formikProps, _newValue) => {
                                  this.handleHcpChange(_newValue);
                                }, null, {}, {}, {}
                              )}
                            </Grid> : <></>}
                          </Grid>
                        </form>
                      )}
                    </Formik>
                  </AccordionDetails>
                </Accordion>
                <Box style={{ padding: "0px", minHeight: "70vh", maxHeight: "70vh", }} >
                  <div {...LayoutService.getAgGridStyles(320)}>
                    <AgGridReact
                      pagination={true}
                      paginationPageSize={50}
                      rowData={this.state.hcpSearchResults}
                      columnDefs={HCPSearchDialogService.getColumnDefs(this)}
                      frameworkComponents={this.state.agGridUtils.frameworkComponents}
                      suppressClickEdit={true}

                      gridOptions={{
                        context: { componentParent: this },
                        ...AgGridColumnExt.getGridOptions(40),
                        ...this.state.agGridUtils.bindInlineEditEvents(),
                      }}
                      onGridReady={(params) => {
                        this.gridApi = params.api;
                        this.gridColumnApi = params.columnApi;
                        this.state.agGridUtils.setGridParams(params, false);
                        this.state.agGridUtils.pinColumn(AgGridBulkEditUtil.selectColumnName);
                      }}>
                    </AgGridReact>
                  </div>
                </Box>
              </div>
            </React.Fragment>
          </>
        );
      case ResultStatus.ERROR:
        return (
          <React.Fragment>
            <DialogContent>
              <DialogErrorFragmentComponent small classes={classes} description={this.state.errorInfo}
                onRetry={() => { this.fetchFilterData(); }} />
            </DialogContent>
          </React.Fragment>
        );
      default:
        return <></>;
    }
  }
}

export default LayoutService.getHocComponenet(HCPSearchDialogComponent);