import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  IconButton,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { MatIconService } from "../../../shared/services/theme/matIconService";
import { AuthContext } from "../../../shared/store/authProvider";
import { TenantSelectList } from "../../../shared/types/enums";
import { DataService } from "../../../shared/services/dataService";
import ToastService from "../../../shared/services/toastService";


const useStyles = makeStyles({
  root: {
    marginLeft: "auto",
  },
});

export default function LanguageComponent(props) {
  const classes = useStyles();
  const userContext = React.useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [sourceData, setSourceData] = useState([]);

  const changeLanguage = code => {
    let lcode = "en";
    console.log(code);
    switch (code.value) {
      case "Deutsch":
        lcode = "de";
        break;
      case "English":
        lcode = "en";
        break;
      case "français":
        lcode = "fr";
        break;
      default:
        lcode = "en";
    }
  };

  const changeCurrency = async code => {
    // alert("code: " + JSON.stringify(code), null, 2);
    try {
      const result = await fetch(
        `${process.env.REACT_APP_TENANT_HTTP}/User/SaveCurrencyByUser/${userContext.user.userId}/${code.id}/${userContext.user.tenantId}`,
        {
          method: 'post',
          body: JSON.stringify({})
        }
      );
      const json = await result.json();
      ToastService.showSuccess("Currency Updated");
      props.handleDialogClose();
    } catch (error) {
      ToastService.showError("An Error occured while changing the Currency");
      console.log("/User/SaveCurrencyByUser Error:", error);
    }

  };

  const handleClose = () => {
    props.handleDialogClose();
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const result = await fetch(
          `${process.env.REACT_APP_TENANT_HTTP}/Util/GetMasterSubSet/${userContext.user.tenantId}/${props.internationalList}`
        );
        const json = await result.json();
        setSourceData(props.internationalList === TenantSelectList.LANGUAGE ? json : DataService.formatCurrencyList(json, null));
      } catch (error) {
        console.log("/Util/GetMasterSubSet Error:", error);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [userContext.user.tenantId, props.internationalList]);

  let pageTitle = "Default";
  switch (props.internationalList) {
    case TenantSelectList.LANGUAGE:
      pageTitle = "Language";
      break;
    case TenantSelectList.CURRENCY:
      pageTitle = "Currency";
      break;
    default:
      pageTitle = "Default";
  }

  return (
    <Dialog
      open={props.openDialog || false}
      scroll={true ? "paper" : "body"}
      maxWidth="md"
    >
      <DialogTitle disableTypography id="dialogTitle">
        <AppBar position="static">
          <Toolbar variant="dense">
            <Typography variant="h6">{pageTitle}</Typography>
            <IconButton
              color="secondary"
              onClick={handleClose}
              className={classes.root}
            >
              {MatIconService.CANCEL}
            </IconButton>
          </Toolbar>
        </AppBar>
      </DialogTitle>
      <DialogContent>
        <Box
          style={{
            paddingLeft: 16,
            paddingRight: 32,
            paddingTop: 8,
            paddingBottom: 32,
            minHeight: "80vh",
            maxHeight: "80vh",
            minWidth: "90vh",
            maxWidth: "90vh",
          }}
        >
          <Grid container spacing={1}>
            {!isLoading
              ? sourceData.map((el, iDex) => {
                const nSlots = Math.ceil(sourceData.length / 3) * 3;
                const nCol2 = sourceData.length % 3 === 1 ? 2 * nSlots / 3 - 1 : 2 * nSlots / 3;

                const natDex = iDex + 1;
                let iOrder = 0;
                if (natDex <= nSlots / 3) {
                  iOrder = 3 * natDex - 2;
                } else if (natDex <= nCol2) {
                  iOrder = 3 * natDex - 1 - nSlots;
                } else if (natDex <= nSlots) {
                  iOrder = 3 * natDex - 2 * nSlots + 3;
                }

                return (<Box key={"box" + iDex} item xs={12} sm={6} md={4} order={/*sourceData.length-el.id*/iOrder} width={"33%"}>
                  {props.internationalList === TenantSelectList.LANGUAGE ?
                    <Button onClick={() => changeLanguage(el)}>{el.value}</Button>
                    :
                    <Button onClick={() => changeCurrency(el)}>{el.value}</Button>
                  }
                </Box>)
              })
              : ""}
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
