import React, { Component } from "react";
import RecordValidationSummaryService from "./recordValidationSummaryService";
import { AgGridReact } from "ag-grid-react";
import { ResultStatus } from "../../../../shared/types/enums";
import SectionComponent from "../../../../shared/components/sectionComponent";
import { MatClassService } from "../../../../shared/services/theme/matClassService";
import { withStyles } from "@material-ui/core";
import LayoutService from "../../../../shared/services/layoutService";
import { AgGridUtil } from "../../../../shared/services/ag-grid/agGridUtil";
import { AgGridColumnExt } from "../../../../shared/services/ag-grid/agGridColumnExt";

class RecordValidationSummaryComponent extends Component {
  SERVICE = RecordValidationSummaryService;
  state = {
    addressData: [],
    fetchResult: ResultStatus.NOT_LOADED,
    agGridUtils: new AgGridUtil(),
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
  };

  fetchData = (_ignoreCache = false) => {
    this.setState({
      fetchResult: ResultStatus.LOADING,
      recordValidationSummary: this.props.fileStatus.recordValidationSummary,
    });
  };

  componentDidMount() {
    this.fetchData(this);
  }
  render() {
    console.log("Data", this.state.addressData);
    const { classes } = this.props;
    return (
      <div>
        <SectionComponent
          classes={classes}
          label={"Record Validation Summary"}
        />

        <div
          {
            ...LayoutService.getAgGridTheme()}
          //className={"ag-theme-balham"}
          style={{
            height: "250px",
            width: "100%",
            marginTop: "10px",
          }
          //...LayoutService.getAgGridStyles(220)
          }
        >
          <AgGridReact
            columnDefs={this.SERVICE.getColumnDefs(this)}
            rowData={this.state.recordValidationSummary}
            pagination={true}
            paginationPageSize={4}
            suppressClickEdit={true}
            gridOptions={{
              context: { componentParent: this },
              ...AgGridColumnExt.getGridOptions(40),
              ...this.state.agGridUtils.bindInlineEditEvents(),
            }}
            onGridReady={(params) => {
              this.state.agGridUtils.setGridParams(params, true);
            }}
          ></AgGridReact>
        </div>
      </div>
    );
  }
}
export default withStyles(MatClassService)(RecordValidationSummaryComponent);
