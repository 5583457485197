import React, { useState } from "react";
import "fontsource-roboto";
import "./App.css";
import "./AgGridStyles.css";
import { BrowserRouter, Route, useHistory, Switch } from 'react-router-dom';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import config from './config'
import OktaSingleSignOnComponent from "../src/public/authentication/oktaSingleSignOnComponent";

const oktaAuth = new OktaAuth(config.oidc);
const App = (props) => {

  const history = useHistory(); // example from react-router

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '', window.location.origin));
  };
  const customAuthHandler = () => {
    // Redirect to the /login page that has a CustomLoginComponent
    history.push('/login');
  };

  return (
    <BrowserRouter>
      <Security oktaAuth={oktaAuth}  onAuthRequired={customAuthHandler}
      restoreOriginalUri={restoreOriginalUri}>
        <Switch>
          <Route path='/'  component={OktaSingleSignOnComponent} />
          <Route path="/login" component={OktaSingleSignOnComponent} />
          <Route path='/login/callback' component={LoginCallback} />
        </Switch>
                 
        {/* <Switch>
          <Route path="/" component={OktaSingleSignOnComponent} />
          <Route path="/login" component={OktaSingleSignOnComponent} />
        </Switch> */}
      </Security>

    </BrowserRouter>
  );
};
export default App;
