import React, { Component } from "react";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css";

import { Box, Divider } from "@material-ui/core";
import LayoutService from "../../../../../shared/services/layoutService";

class AuditComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnDefs: [
        {
          headerName: "Field",
          field: "field",
          sortable: true,
          filter: "text",
          cellStyle: { "border-right": "1px solid lightgray" },
          resizable: true,
          width: 225,
        },
        {
          headerName: "Beginning Value",
          field: "beginningValue",
          sortable: true,
          filter: "text",
          cellStyle: { "border-right": "1px solid lightgray" },
          resizable: true,
          width: 225,
        },
        {
          headerName: "Ending Value",
          field: "endingValue",
          sortable: true,
          filter: "text",
          cellStyle: { "border-right": "1px solid lightgray" },
          resizable: true,
          width: 225,
        },
        {
          headerName: "Modified On",
          field: "modifiedOn",
          sortable: true,
          filter: "text",
          cellStyle: { "border-right": "1px solid lightgray" },
          resizable: true,
          width: 225,
        },
        {
          headerName: "Modified By",
          field: "modifiedBy",
          sortable: true,
          filter: "text",
          cellStyle: { "border-right": "1px solid lightgray" },
          resizable: true,
          width: 225,
        },
      ],
      rowData: [
        {
          field: "FirstName",
          beginningValue: "Satyendra",
          endingValue: "Mustafa",
          modifiedOn: "2020-06-11 12:15:01",
          modifiedBy: "mustafa_alure@epam.com",
        },
        {
          field: "IsPorzioUser",
          beginningValue: "True",
          endingValue: "False",
          modifiedOn: "2020-06-11 12:15:01",
          modifiedBy: "mustafa_alure@epam.com",
        },
      ],
    };
  }
  //   componentDidMount() {
  //     fetch(
  //       "https://gst2coreapi.azurewebsites.net/SourceSystems/GetRuleDetailsBySource/25",
  //       {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     )
  //       .then((result) => result.json())
  //       .then((rowData) => this.setState({ rowData }))
  //       .then((a) => console.log(a))
  //       .catch((err) => console.log(err));
  //   }
  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.setDomLayout("autoHeight");
  };
  render() {
    return (
      <div id="BusinessRulesGrid">
        <Divider />
        <Box
          style={{
            paddingLeft: 16,
            paddingRight: 32,
            paddingTop: 8,
            paddingBottom: 32,
          }}
        >
          <div {...LayoutService.getAgGridStyles()} >
            <AgGridReact
              columnDefs={this.state.columnDefs}
              rowData={this.state.rowData}
              pagination={true}
              paginationPageSize={50}
              gridOptions={{
                context: { componentParent: this },
              }}
              onGridReady={this.onGridReady}
            ></AgGridReact>
          </div>
        </Box>
      </div>
    );
  }
}

export default AuditComponent;
