import ApiService from "../../../../shared/services/apiService";
import { API_ENDPOINT } from "../../../../shared/types/enums";
import { DataService } from "../../../../shared/services/dataService";
import LookupService from "../../../../shared/services/lookupService";
import { AgGridColumnExt } from "../../../../shared/services/ag-grid/agGridColumnExt";
import { MatIconService } from "../../../../shared/services/theme/matIconService";

export default class EditConsentMappingService {

  static fetchConsentMappingsAsOBS = (_tenantId, _reportName = "0", _year = "0") => {
    if (_reportName !== 0) {
      // prepend "Compliance\\" for dialog only, not for the home screen
      _reportName = `Compliance\\${_reportName}`
    }

    return ApiService.postOBS(
      API_ENDPOINT.TENANT,
      `/ComplianceMapping/FetchConsentMappings/${_tenantId}`,
      JSON.stringify({
        pageNumber: 1,
        pageSize: 5000,
        searchText: "",
        entityId: "",
        fieldId: "",
        reportId: "",
        reportName: _reportName,
        year: String(_year)
      }),
    );
  };

  static fetchReportMappings = (_tenantId,) => {
    return ApiService.postOBS(
      API_ENDPOINT.TENANT,
      `/ComplianceMapping/FetchReportMappings/${_tenantId}`,
      JSON.stringify({
        pageNumber: 1,
        pageSize: 5000,
        searchText: "",
        entityId: "",
        fieldId: ""
      }),
    );
  };

  static getYearsByReportId = (_tenantId, _reportId) => {
    let $url = `/ComplianceMapping/FetchReportTemplateYears/${_tenantId}`
    if (_reportId !== 0) {
      $url = `/ComplianceMapping/FetchReportTemplateYears/${_tenantId}?reportId=${_reportId}`
    }
    return ApiService.getOBS(API_ENDPOINT.TENANT, $url);
  };

  static getComplianceEntityFieldsAsOBS = (_tenantId, _entityId) => {
    return ApiService.getOBS(
      API_ENDPOINT.TENANT,
      `/ComplianceMapping/FetchEntityFields/${_tenantId}/${_entityId}`
    );
  };

  static getColumnDefs(_componentRef) {
    return [
      {
        headerName: "Profile Consent Value",
        field: "profileConsentValue",
        sortable: true,
        filter: "agTextColumnFilter",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
      },
      {
        headerName: "Agreement Consent Value",
        field: "agreementConsentValue",
        sortable: true,
        filter: "agTextColumnFilter",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
      },
      {
        headerName: "Transaction Consent Value",
        field: "transactionConsentValue",
        sortable: true,
        filter: "agTextColumnFilter",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
      },

      // {
      //   headerName: "Reportable Id",
      //   field: "reportableId",
      //   sortable: true,
      //   filter: "agTextColumnFilter",
      //   cellStyle: { "border-right": "1px solid lightgray" },
      //   resizable: true,
      //   width: 75,
      // },
      // {
      //   headerName: "Reportable Name",
      //   field: "reportable",
      //   sortable: true,
      //   filter: "agTextColumnFilter",
      //   cellStyle: { "border-right": "1px solid lightgray" },
      //   resizable: true,
      // },

      // {
      //   headerName: "Reportable",
      //   field: "reportableId",
      //   editable: true,
      //   singleClickEdit: true,
      //   cellStyle: { "border-right": "1px solid lightgray" },
      //   cellEditor: "agSelectCellEditor",
      //   // refData: { 257: "Reportable - Direct", 258: "Reportable - Aggregate" },

      //   refData: LookupService.COMPLIANCE_CONSENT_REPORTABLE.reduce((obj, item) => Object.assign(obj, { [item.reportableId]: item.reportableName }), {}),

      //   cellEditorParams: {
      //     cellHeight: 20,
      //     values: DataService.extractKeysAsNumbers(
      //       LookupService.COMPLIANCE_CONSENT_REPORTABLE.reduce((obj, item) => Object.assign(obj, { [item.reportableId]: item.reportableName }), {})
      //     ), // extract the keys as numbers
      //   },
      // },
      AgGridColumnExt.GET(false, "text", "reportableId", "Reportable").cellRenderer("dropDownRenderer", {
        options: _componentRef.state.reportableLovList,
        optionKey: "reportableId", optionLabel: "reportableName",
        statusIcon: MatIconService.REPORT,
        showStatusCallBack: (_cellRef) => {
          if (_cellRef.props.data["reportableId"] !== 0 && _cellRef.props.data["isCustomMapping"] !== undefined) { return _cellRef.props.data["isCustomMapping"]; }
          else { return false; }
        },
        showEmpty: true, emptyValue: 0,
        onChangeCallback: (_cellRef, params) => {
          _cellRef.props.data.isModified = true;

          //_componentThisRef.handleReportValueChanged(_cellRef, params);
        },
      }).SET(),
    ];
  }
}
