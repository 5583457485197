import { ReplaySubject } from "rxjs";
import { AgGridColumnExt } from "../../shared/services/ag-grid/agGridColumnExt";
import ApiService from "../../shared/services/apiService";
import Cache from "../../shared/store/cache";
import { GST_API } from "../../shared/types/enums";

export default class UserMappingsService {
  static CACHE = new Cache(this.fetchFromApiObs);

  static getObs(_ignoreCache, clientAuthContext) {
    if (_ignoreCache || this.CACHE.NOT_EXISTS()) {
      return this.fetchFromApiObs(clientAuthContext);
    } else {
      return this.CACHE.getOBS();
    }
  }

  /**
   * Replace the static data inside of(...) with real ApiCall
   * @returns observable
   */
  static fetchFromApiObs(clientAuthContext) {
    // clear the cache before returning the actual subscription
    this.CACHE.CLEAR();
    // replace this._jsonData with reacl ApiCall
    return ApiService.fetchHTTP_API(GST_API.Identity, `/User/GetUsers`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  static getRows() {
    return [];
  }

  static getColumnDefs() {
    return [
      AgGridColumnExt.GET(false, "text", "userName", "User Name").flexWeight(1).minWidth(250).cellRenderer("childMessageRendererComponent").headerClass("center-text").SET(),
      AgGridColumnExt.GET(false, "text", "firstName", "First Name").flexWeight(2).headerClass("center-text").SET(),
      AgGridColumnExt.GET(false, "text", "lastName", "Last Name").flexWeight(2).headerClass("center-text").SET(),
      AgGridColumnExt.GET(false, "text", "isPorzioUser", "Is Porzio User").flexWeight(1).cellRenderer("isActiveCellRenderer").headerClass("center-text").centerAlignText().SET(),
    ];
  }
}
