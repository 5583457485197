import Cache from "../../shared/store/cache";
import { ResultStatus } from "../../shared/types/enums";
import { SubscriptionArray } from "../../shared/services/dataService";
import ApiService from "../../shared/services/apiService";
import { AgGridColumnExt } from "../../shared/services/ag-grid/agGridColumnExt";

export default class TenantStatusService {
  // subscriptions
  static oSUBSCRIPTIONS = new SubscriptionArray();

  // cache
  static CACHE = new Cache(this.fetchFromApiObs);

  static getObs(_ignoreCache, context) {
    if (_ignoreCache || this.CACHE.NOT_EXISTS()) {
      return this.fetchFromApiObs(context);
    } else {
      return this.CACHE.getOBS();
    }
  }

  /**
   * Replace the static data inside of(...) with real ApiCall
   * @returns observable
   */
  static fetchFromNewTenantStatusApiObs(context) {
    // clear the cache before returning the actual subscription
    this.CACHE.CLEAR();
    return ApiService.fetchObsidentity(`/NewTenant/GetTenantStatus?tenantStatus=Create Tenant`);
  }


  static getColumnDefs() {
    return [
      AgGridColumnExt.GET(false, "text", "timeStamp", "Date and Time").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
      AgGridColumnExt.GET(false, "text", "activity", "Activity").flexWeight(1).headerClass("left-text").leftAlignText().SET(),
      AgGridColumnExt.GET(false, "text", "status", "Status").flexWeight(1).headerClass("left-text").leftAlignText().SET(),
    ]
  }
}
