import React from "react";
import { combineLatest, BehaviorSubject } from "rxjs";
import { filter, mergeMap, debounceTime, distinctUntilChanged } from "rxjs/operators";
import { Box, LinearProgress } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css";

import { AgGridUtil } from "../../../shared/services/ag-grid/agGridUtil";
import { AuthContext } from "../../../shared/store/authProvider";
import { DataService, SubscriptionArray } from "../../../shared/services/dataService";
import { API_ENDPOINT, CrudAction, ENTITY_TYPE, HEALTH_INDICATOR, ResultStatus, SELECT_MODE, TaskLauncher } from "../../../shared/types/enums";
import { MatIconService } from "../../../shared/services/theme/matIconService";
import LayoutService from "../../../shared/services/layoutService";
import PageLoadingComponent from "../../../shared/components/page/pageLoadingComponent";
import DialogErrorFragmentComponent from "../../../shared/components/page/dialogErrorFragmentComponent";
import LookupService from "../../../shared/services/lookupService";
import FieldConfigurationComponent from "../../../shared/components/field-configuration/fieldConfigurationComponent";
import TaskDetailsComponent from "../../home/task/task-details/taskDetailsComponent";
import ProfileCountCellRendererComponent from "./profileCountCellRendererComponent";
import ProfileDetailCellRendererComponent from "../profile-detail/profileDetailCellRendererComponent";
import ProfileDetailDialogComponent from "../profile-detail/profileDetailDialogComponent";
import ProfileExpandedViewService from "./profileExpandedViewService";
import { AgGridBulkEditUtil } from "../../../shared/components/ag-grid/bluk-edit/agGridBulkEditUtil";
import ToastService from "../../../shared/services/toastService";
import ProfileTabService from "../profile-detail/tabs/profileTabService";
import { AgGridColumnExt } from "../../../shared/services/ag-grid/agGridColumnExt";
import ApiService from "../../../shared/services/apiService";
import AgGridCheckboxCellRendererComponent from "../../../shared/components/elements/agGridCheckboxCellRendererComponent";
import AgGridRadioButtonCellRendererComponent from "../../../shared/components/elements/agGridRadioButtonCellRendererComponent";

import AgGridErroredDropdownCellRenderer from "../../../shared/components/ag-grid/error-cell-renderers/agGridErroredDropdownCellRenderer";
import AgGridErroredDatePickerCellRenderer from "../../../shared/components/ag-grid/error-cell-renderers/agGridErroredDatePickerCellRenderer";
import { AgGridErroredTextCellRenderer } from "../../../shared/components/ag-grid/error-cell-renderers/agGridErroredTextCellRenderer";
import { AgGridErroredCheckboxCellRenderer } from "../../../shared/components/ag-grid/error-cell-renderers/agGridErroredCheckboxCellRenderer";
import PageDynamicHeaderComponent from "../../../shared/components/page/pageDynamicHeaderComponent";
import HealthIndicatorCellRenderer from "../../../shared/components/elements/healthIndicator/healthIndicatorCellRenderer";
import ProfileStatusOverrideComponenet from "../profile-detail/tabs/profileStatusOverrideComponenet";
import RolePermissionService from "../../../shared/role-permissions/rolePermissionService";
import AgGridSelectMenuComponent from "../../../shared/components/agGridSelectMenuComponent";

let searchSubject = new BehaviorSubject("");
let SearchResultObservable = searchSubject.pipe(
  filter((val) => val.hasOwnProperty("newValue")),
  debounceTime(750),
  distinctUntilChanged(),
  mergeMap((val) =>
    ProfileExpandedViewService.postProfileCenterExpandedViewAsOBS(val.user.tenantId, val.user.userId,
      { sourceId: val.sourceId, fileId: val.fileId }, val.healthIndicator, val.newValue))
);

class ProfileExpandedViewComponent extends React.Component {
  static contextType = AuthContext;

  // cancelled during component's unMount
  scopedSubscriptions = new SubscriptionArray();
  // apiSubscriptions are canclled before retry also
  apiSubscriptions = new SubscriptionArray();

  constructor(props) {
    super(props);
    // init state
    this.state = {
      // - others
      data: [],
      isInit: true, // true for the first time
      isReadOnly: false,
      selectedProfiles: [],

      profileStatusList: [],
      fetchResult: ResultStatus.NOT_LOADED,
      porzioSearch: "",
      showCreateTaskDialog: false,
      agGridBulkEditUtil: new AgGridBulkEditUtil(),
      agGridUtils: new AgGridUtil("lastname", {
        // checked or radio button based on permissions
        isCheckedCellRenderer: RolePermissionService.PROFILE_EXPANDED_BULK_EDIT.canEdit ? AgGridCheckboxCellRendererComponent : AgGridRadioButtonCellRendererComponent,

        erroredDropdownCellRenderer: AgGridErroredDropdownCellRenderer,
        erroredDatePickerCellRenderer: AgGridErroredDatePickerCellRenderer,
        erroredTextCellRenderer: AgGridErroredTextCellRenderer,
        erroredCheckboxCellRenderer: AgGridErroredCheckboxCellRenderer,

        profileCountCellRendererComponent: ProfileCountCellRendererComponent,
        profileDetailCellRendererComponent: ProfileDetailCellRendererComponent,
        healthIndicatorCellRenderer: HealthIndicatorCellRenderer,
      }),
    };
  }

  componentWillUnmount() {
    this.scopedSubscriptions.cancelAll();
    this.apiSubscriptions.cancelAll();
  }

  componentDidMount() {
    this.setState({ fetchResult: ResultStatus.LOADING });
    LookupService.fetchCommonLookups(this.context);
    this.fetchData();
  }

  /** API Fetch */
  fetchData = () => {
    this.apiSubscriptions.cancelAll();
    this.setState({ isGridReady: false, fetchResult: ResultStatus.LOADING, data: [], isInit: true });

    this.apiSubscriptions.add(
      combineLatest([
        LookupService.getFormattedCountriesAsOBS(this.context, null),
        ProfileExpandedViewService.postProfileCenterExpandedViewAsOBS(this.context.user.tenantId, this.context.user.userId, this.props.modalAgNode, this.props.healthIndicator),
        ProfileExpandedViewService.getDataCenterDisplayedFieldsAsOBS(this.context.user.tenantId, this.props.modalAgNode.sourceId, 1, this.context.user.userId, this.props.screenId),
        ProfileExpandedViewService.getProfileRuleSummaryAsOBS(this.context.user.tenantId, this.props.modalAgNode.fileId)
      ]).subscribe(
        // success
        ([_countryList, _data, _agGridColumns, _profileRuleSummaries]) => {
          // hardocde
          _agGridColumns = ProfileTabService.hardCodeColumns(_agGridColumns);

          // adds the selected prop to each element and converts the all keys to lowecase
          _data = (_data || []).map(obj => { obj[AgGridBulkEditUtil.selectColumnName] = false; return obj; });
          _data = _data.map(obj => Object.entries(obj).reduce((a, [key, value]) => {
            a[key.toLowerCase()] = value;
            return a;
          }, {}));

          if (DataService.hasElements(_data)) {
            this.apiSubscriptions.add(
              combineLatest([
                LookupService.getTemplateBySourceIdAsOBS(this.context.user.tenantId, _data[0].sourceid)
              ]).subscribe(
                ([_templateData]) => {
                  var _displayedColumnConfigs = [];
                  const _dynamicControlFieldsMap = ProfileTabService.getGroupFieldsMap(this, false, _templateData, "grouprenderid", "ordinal", true);
                  const _displayedFieldIds = _agGridColumns.map(x => { return x.fieldId; });

                  // adding in same order as _agGridColumns
                  _displayedFieldIds.forEach(_displayedFieldId => {
                    _dynamicControlFieldsMap.forEach(_value => {
                      _value.fieldConfigs.forEach(_fieldConfig => {
                        if (_displayedFieldId === _fieldConfig.fieldID) {
                          _displayedColumnConfigs.push(_fieldConfig);
                        }
                      });
                    });
                  });

                  this.setState(
                    {
                      data: _data,
                      displayedColumnConfigs: _displayedColumnConfigs,
                      profileRuleSummaries: _profileRuleSummaries,
                      agGridColumns: _agGridColumns,
                      countryList: _countryList,
                    },
                    // change the state after all the above are assigned
                    () => { this.setState({ fetchResult: ResultStatus.LOADED }); }
                  );
                },
                (_errorObj) => {
                  ToastService.showError("Error while loading template.");
                  this.setState({ fetchResult: ResultStatus.ERROR });
                }
              )
            );
          } else {
            ToastService.showError("No Data.");
            this.setState({ fetchResult: ResultStatus.ERROR });
          }
        },
        // onError
        (error) => {
          ToastService.showError("Error while Fetching.");
          this.setState({ fetchResult: ResultStatus.ERROR });
        }
      )
    );
  };

  handleSearchChange = (e) => {
    const newValue = e.target.value;
    this.setState({ porzioSearch: newValue, isProcessing: true });

    if (!this.state.searchSubscriptionAdded) {
      this.setState(
        { searchSubscriptionAdded: true },
        () => {
          this.scopedSubscriptions.add(
            SearchResultObservable.subscribe((searchResultJson) => {
              this.setState({ data: searchResultJson, isProcessing: false });
            })
          );
        }
      );
    } else {
      this.setState({ isProcessing: true });
      searchSubject.next({ newValue, ...this.props.modalAgNode, ...this.context, healthIndicator: this.props.healthIndicator });
    }
  }

  clearSearch = () => {
    searchSubject.next({ newValue: "", ...this.props.modalAgNode, ...this.context, healthIndicator: this.props.healthIndicator });
    this.setState({ isProcessing: false });
  }

  onDownloadClick = () => {
    let api = this.gridApi, params = this.getParams();
    api.exportDataAsExcel(params);
  }

  onProcessCellCallback = (params) => {
    switch (params.column.colId) {
      case 'healthindicator':
        return HEALTH_INDICATOR[params.node.data.healthindicator];
      case 'country':
        return params.node.data.countryname;
      case 'profiletypeid':
      case 'profilecategoryid':
      case 'credentialsid':
      case 'specialtyid':
      case 'affiliatedcompanyid':
        let colName = params.column.colId;
        return params.node.data[colName.substring(0, colName.length - 2)];
      default:
        return params.value;
    }
  }

  getParams() {
    return {
      // allColumns: true,
      columnKeys: this.gridColumnApi.getAllColumns().filter(c => c.colDef.headerName !== "" && c.colDef.headerName !== "Edit"),
      fileName: `Profile_Expanded_View ${new Date().toDateString()}`,
      processCellCallback: this.onProcessCellCallback
    };
  }

  // called on row-cell click
  methodFromParent = (row_col, node) => {
    this.setState({ modalAgNode: node });
    if (RolePermissionService.PROFILE_DETAILS.cannotView) {
      RolePermissionService.showAccessDeniedToast();
    } else {
      if (this.state.agGridUtils.isNotEditing()) {
        this.setState({ showProfileDetailDialog: true });
      }
    }
  };

  handleCreateTaskDialogClick = () => {
    let _selectedGstIds = [];
    this.state.agGridUtils.getSelectedRows(AgGridBulkEditUtil.selectColumnName).forEach((rowNode) => {
      _selectedGstIds.push(rowNode.data["porziogstprofileid"]);
    })

    if (DataService.hasNoElements(_selectedGstIds)) {
      ToastService.showWarning("Please select one or more Profiles");
    } else {
      this.setState({
        selectedGstIds: _selectedGstIds,
        showCreateTaskDialog: true
      });
    }
  }


  getSelectionIcon = () => {
    return RolePermissionService.PROFILE_EXPANDED_BULK_EDIT.cannotEdit ? null
      : { title: "select", icon: MatIconService.MENU, onClick: (e) => { this.setState({ anchorEl: e.currentTarget, openSelectMenu: true }); }, isReadOnly: !this.state.isGridReady };
  }

  render() {
    const { classes } = this.props;

    if (RolePermissionService.PROFILE_EXPANDED_VIEW.cannotView) {
      return RolePermissionService.getAccessDeniedComponent(); // this is required to prevent Url navigation
    } else {

      let componentType = "";
      switch (this.props.healthIndicator) {
        case "1": componentType = `Profile Error`; break;
        case "2": componentType = `Profile Warning`; break;
        case "3": componentType = `Profile Unmatched`; break;
        case "":
        default: componentType = `Profile Count`; break;
      }
      switch (this.state.fetchResult) {
        case ResultStatus.NOT_LOADED:
        case ResultStatus.LOADING:
          return (
            <React.Fragment>
              <PageDynamicHeaderComponent classes={classes} label={`Go back`} divider
                leftActions={[{ icon: MatIconService.BACK, iconColor: "secondary", onClick: () => { this.props.onClose(false); }, title: "Cancel" }]}
              />
              <PageLoadingComponent classes={classes} label="Loading Profile Expanded View" />;
            </React.Fragment>
          );
        case ResultStatus.SAVING:
          return <PageLoadingComponent classes={classes} label={`Saving Profiles`} />;
        case ResultStatus.LOADED:
        case ResultStatus.SUCCESS:
          return (
            <React.Fragment>
              {/* Header Componenet */}
              <AgGridSelectMenuComponent anchorEl={this.state.anchorEl} openSelectMenu={this.state.openSelectMenu}
                agGridUtils={this.state.agGridUtils} selectionColumnName={AgGridBulkEditUtil.selectColumnName}
                onClose={() => { this.setState({ anchorEl: null, openSelectMenu: false }); }}
                onSelectionChange={() => { this.toggleSubmitButton(); }}
              />
              <PageDynamicHeaderComponent classes={classes} label={componentType}
                leftActions={[
                  { icon: MatIconService.BACK, onClick: () => { this.props.onClose(false); }, title: "GoTo Profiles" },
                  { icon: MatIconService.RELOAD, iconColor: "secondary", onClick: () => { this.fetchData(); }, title: "Reload" },
                  { ...this.getSelectionIcon() }
                ]}
                rightActions={[
                  { jsxElement: LayoutService.getSearchText(classes, "search", this.state.porzioSearch, this.handleSearchChange) },
                  {
                    icon: MatIconService.OVERRIDE_28, onClick: () => { this.setState({ showProfileStatusOverrideDialog: true }); }, title: "Override Profile Status",
                    isReadOnly: this.state.isReadOnly || this.state.isInit || RolePermissionService.PROFILE_EXPANDED_BULK_STATUS_FIELD.cannotEdit,
                  },
                  { icon: MatIconService.COLUMNS_24, onClick: () => { this.setState({ showFieldConfigurationDialog: true }); }, title: "Field Configurations", isReadOnly: RolePermissionService.PROFILE_EXPANDED_VIEW.cannotConfigure },
                  { icon: MatIconService.TASK_ADD, onClick: this.handleCreateTaskDialogClick, title: "Create Task", isReadOnly: RolePermissionService.PROFILE_EXPANDED_VIEW_TASK.cannotCreate },
                  { icon: MatIconService.DOWNLOAD, onClick: () => { this.onDownloadClick(); }, title: "Export", isReadOnly: RolePermissionService.PROFILE_EXPANDED_VIEW_EXPORT.cannotView },
                  { icon: MatIconService.OK, iconColor: "primary", onClick: () => { this.clearSearch(); this.handleSubmit(); }, isReadOnly: (this.state.isInit || this.state.isReadOnly), title: "Save" },
                ]}
              />

              {/* Content */}
              <Box style={{ padding: 0 }}>
                {/* Processing Progress */}
                {this.state.isProcessing ? <LinearProgress color="secondary" /> : null}

                {/* AgGrid */}
                <div {...LayoutService.getAgGridStyles(168)}>
                  <AgGridReact
                    pagination={true}
                    paginationPageSize={100}
                    rowData={this.state.data}
                    columnDefs={ProfileExpandedViewService.getColumnDefs(this, this.state.isReadOnly)}
                    frameworkComponents={this.state.agGridUtils.frameworkComponents}
                    suppressContextMenu={true}
                    suppressClickEdit={true}

                    suppressRowClickSelection={true}
                    rowSelection="multiple"

                    onGridReady={(params) => {
                      this.setState({ isGridReady: true });
                      this.gridApi = params.api;
                      this.gridColumnApi = params.columnApi;
                      this.state.agGridUtils.setGridParams(params, false);
                      this.state.agGridUtils.pinColumn(AgGridBulkEditUtil.selectColumnName); // pin the edit column to the left
                    }}

                    gridOptions={{
                      context: { componentParent: this },
                      ...AgGridColumnExt.getGridOptions(40),
                      cellFlashDelay: 1000,
                      cellFadeDelay: 750,
                    }}
                  ></AgGridReact>
                </div>
              </Box>

              {/* Show Field-Configuration Dialog */}
              {this.state.showFieldConfigurationDialog ?
                <FieldConfigurationComponent entityId={ENTITY_TYPE.PROFILE} fileId={this.props.modalAgNode.fileId} sourceId={this.props.modalAgNode.sourceId} screenId={this.props.screenId}
                  modalAgNode={this.props.modalAgNode} open={this.state.showFieldConfigurationDialog || false} onClose={() => this.setState({ showFieldConfigurationDialog: false })}
                  refreshList={this.fetchData} />
                : null}

              {/* Show Profile-Detail Dialog as a Page */}
              <ProfileDetailDialogComponent standAloneProps={LayoutService.getContainedDialogProps(false)}
                inputAction={CrudAction.UPDATE} modalAgNode={this.state.modalAgNode}
                open={this.state.showProfileDetailDialog || false}
                onClose={(_params) => this.setState({ showProfileDetailDialog: false }, () => {
                  if (_params !== false) {
                    this.props.onClose(true);
                  }
                })}
                closeParentCallback={() => {
                  this.props.onClose(true);
                }}
              />

              {/* Create new Task for selected Profiles */}
              {this.state.showCreateTaskDialog ?
                <TaskDetailsComponent showTaskDialog={this.state.showCreateTaskDialog} taskLauncher={TaskLauncher.PROFILE_EXPANDED_VIEW}
                  onClose={() => this.setState({ showCreateTaskDialog: false })} inputAction={CrudAction.CREATE} refreshTaskList={() => { }} updateData={null}
                  porzioGstIds={this.state.selectedGstIds}
                />
                : null}

              {/* Profile Status Selector Dialog */}
              {!this.state.showProfileStatusOverrideDialog ? null : <ProfileStatusOverrideComponenet currentValue={""}
                selectedProfiles={this.state.selectedProfiles.map(x => x.prid)}
                onClose={(_reload) => {
                  this.setState({ showProfileStatusOverrideDialog: false });
                }} />}
            </React.Fragment>
          );

        case ResultStatus.ERROR:
        default:
          return (
            <DialogErrorFragmentComponent title={`Error Loading ${componentType}`} description={`Error Loading ${componentType}`} classes={classes}
              onClose={() => { this.props.onClose(false); }}
              onRetry={() => { this.fetchData(); }}
            />
          );
      }
    }

  }


  toggleSubmitButton = (_cellRef, _data) => {
    var hasUpdates = false;
    var hasSelected = false;
    let selectedProfiles = [];
    if (this.state.agGridBulkEditUtil.hasUpdates()) {
      hasUpdates = true;
    } else {
      this.state.agGridUtils.gridApi.forEachNode((rowNode) => {
        if (!hasSelected) { hasSelected = rowNode.data[AgGridBulkEditUtil.selectColumnName] === true; }
        hasUpdates = hasUpdates || hasSelected;
        if (rowNode.data && rowNode.data[AgGridBulkEditUtil.selectColumnName]) {
          selectedProfiles.push(rowNode.data);
        }
      });
    }
    // set the state
    this.setState({
      isReadOnly: !hasUpdates,
      hasSelected: hasSelected,
      selectedProfiles: selectedProfiles,
      isInit: false // only true for the first time 
    });
  }

  handleSubmit = () => {
    this.setState({ isProcessing: true });
    var arrayToPost = [];
    // var rowDataKeys = [];
    this.state.agGridUtils.gridApi.forEachNode((rowNode, rowIndex) => {
      // TODO: rowIndex will not be correct if sorted, so search this app for sortAndFilterAwareRowNode and see the impl
      if (this.state.agGridBulkEditUtil.updatedRowIdsCoumnNamesMap.has(rowNode.id)) {
        var modelObj = {};

        // update the modelobject with poulated values
        const rowColumnNames = this.state.agGridBulkEditUtil.updatedRowIdsCoumnNamesMap.get(rowNode.id);
        rowColumnNames.forEach(rowDataKey => {
          modelObj[rowDataKey] = rowNode.data[rowDataKey];
        });

        //--- ensure mandatory fields
        modelObj.tenantid = this.context.user.tenantId;
        modelObj.userId = this.context.user.userId;
        modelObj.userType = this.context.user.userTypeId;
        modelObj.uid = this.context.user.uid;
        modelObj.profileid = rowNode.data.prid;
        modelObj.prid = rowNode.data.prid;
        modelObj.sourceid = rowNode.data.sourceid;
        modelObj.recordid = rowNode.data.recordid;
        modelObj.fileid = rowNode.data.fileid;
        modelObj.ismanuallyentered = rowNode.data.ismanuallyentered;
        //---

        arrayToPost.push(modelObj);
      }
    });

    ApiService.postOBS(API_ENDPOINT.CORE, "/Profiles/SaveProfiles", JSON.stringify(arrayToPost))
      .subscribe(
        (successObj) => {
          this.state.agGridBulkEditUtil.clearUpdateTrackers();
          ToastService.showSuccess("Successfully saved.");
          this.setState({ isProcessing: false });
          // this.props.onClose(true); as per PP2-871
        },
        (errorObj) => {
          ToastService.showError("Error while saving.");
          this.setState({ isProcessing: false });
        }
      );
  }


}
/** HOC */
export default LayoutService.getHocComponenet(ProfileExpandedViewComponent);
