import React, { Component } from "react";
import { combineLatest, } from "rxjs";
import clsx from "clsx";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css";

import { AuthContext } from "../../shared/store/authProvider";
import PageLoadingComponent from "../../shared/components/page/pageLoadingComponent";
import PageErrorComponent from "../../shared/components/page/pageErrorComponent";
import { SubscriptionArray } from "../../shared/services/dataService";
import { ResultStatus, CrudAction } from "../../shared/types/enums";
import LayoutService from "../../shared/services/layoutService";
import { Typography, Divider, Grid, Tooltip, IconButton, MenuItem, Select } from "@material-ui/core";
import MatSpecService from "../../shared/services/theme/matSpecService";
import { MatIconService } from "../../shared/services/theme/matIconService";

//import ChildMessageRendererComponent from "./compliance-default-details/childMessageRendererComponent";
import ReportStatusDetailsService from "./reportStatusDetailsService"
import FileStatusImageRenderer from "../admin-task-details/fileStatusImageRenderer";

class ReportStatusDetailsComponent extends Component {
    static contextType = AuthContext;
    apiSubscriptions = new SubscriptionArray();

    constructor(props) {
        super(props);
        this.state = {
            frameworkComponents: {
                FileStatusImageRenderer: FileStatusImageRenderer,
            },
           
            selectedTenantId: 0,
           
            //selectedOption: '',
        };
    }

    componentWillUnmount() {
        this.apiSubscriptions.cancelAll();
    }

    componentDidMount(){
          this.fetchData();
        }
      

    componentDidUpdate(prevProps,prevState) {
        // Check if the selected option has changed
        if (prevState.selectedTenantId !== this.state.selectedTenantId ) {
          // Make API call when the selected option changes
          this.fetchData();
        }
      }

    /** API Fetch */
    fetchData = (_ignoreCache = false) => {
        this.apiSubscriptions.cancelAll();
        this.setState({ fetchResult: ResultStatus.LOADING, tenantList: [], reportDetails: [] });

        this.apiSubscriptions.add(
            combineLatest([
                ReportStatusDetailsService.getActiveTenants(),
                ReportStatusDetailsService.fetchReportDetails(this.state.selectedTenantId),
            ]).subscribe(
                // success
                ([_tenantList, _reportDetails]) => {

                    
                    this.setState(
                        {
                            
                            tenantList: _tenantList,
                            reportDetails: _reportDetails,
                            
                        },
                      
                      
                      
                        // change the state after all the above are assigned
                        () => {
                            this.setState({ fetchResult: ResultStatus.LOADED });
                        }
                    );
                },
                // onError
                (error) => {
                    console.log("Error:", error);
                    this.setState({ fetchResult: ResultStatus.ERROR });
                }
            )
        );
    };

    onGridReady = (event) => {
        event.api.closeToolPanel();
        event.api.sizeColumnsToFit();
        this.gridApi = event.api;
        this.gridColumnApi = event.columnApi;
    };

    onTenantSelectClick = (event) => {
        this.setState({ selectedTenantId: event.target.value });
        this.gridApi.setFilterModel({
            fieldName: {
                filterType: 'text',
                type: 'equals',
                filter: this.state.tenantList.find(x => x.tenantid === event.target.value).tenantName,
            },
        });
    }

    // onDownloadClick = () => {
    //     let api = this.gridApi, params = this.getParams();
    //     api.exportDataAsExcel(params);
    // }

    // getParams() {
    //     return {
    //         // allColumns: true,
    //         columnKeys: this.gridColumnApi.getAllColumns().filter(c => c.colDef.headerName !== ""),
    //         fileName: `Compliance_Default_Mapping ${new Date().toDateString()}`
    //     };
    // }

    methodFromParent = (cell, data) => {
        this.setState({
            showDialog: true,
            modalAgNode: data,
            inputAction: CrudAction.UPDATE,
        });
    };

    // handleCreate = () => {
    //     this.setState({
    //         showDialog: true,
    //         inputAction: CrudAction.CREATE,
    //     });
    // }

    render() {
        const { classes } = this.props;
        const pageName = " Report Queue Status";
       
            switch (this.state.fetchResult) {
                case ResultStatus.NOT_LOADED:
                case ResultStatus.LOADING:
                    return (<PageLoadingComponent classes={classes} label={`Loading ${pageName}`} />);
                case ResultStatus.LOADED:
                case ResultStatus.SUCCESS:
                    return (
                        <div id="MainUsersGrid">
                            <div className="IconBg marginTop10px tableTitleHead">
                                <ReportDetailsPageHeaderComponent back reload onReloadClick={() => { this.fetchData(true); }} history={this.props.history} noDivider 
                                    classes={classes} label={pageName} tenantSelect
                                    selectedTenantId={this.state.selectedTenantId}
                                    tenantSelectData={this.state.tenantList}
                                    onTenantSelectClick={(event) => { this.onTenantSelectClick(event); }}
                                  
                                    // download onDownloadClick={this.onDownloadClick}
                                    // addCircle onAddClick={this.handleCreate}
                                />
                            </div>
                            {/* <div {...LayoutService.getAgGridStyles()}>
                                <AgGridReact
                                    columnDefs={TenantFtpDetailsService.getColumnDefs(this)}
                                    rowData={this.state.ftpPathRows}
                                    // pagination={true}
                                    // paginationPageSize={50}
                                    // frameworkComponents={this.state.frameworkComponents}
                                    gridOptions={{
                                        context: { componentParent: this },
                                    }}
                                    onGridReady={(event) => {
                                        this.onGridReady(event);
                                    }}
                                ></AgGridReact>
                            </div> */}
                            <div {...LayoutService.getAgGridStyles()}>
                                <AgGridReact
                                    columnDefs={ReportStatusDetailsService.getReportColumnDefs(this)}
                                    rowData={this.state.reportDetails}
                                    pagination={true}
                                    paginationPageSize={50}
                                    frameworkComponents={this.state.frameworkComponents}
                                    gridOptions={{
                                        context: { componentParent: this },
                                    }}
                                    onGridReady={(event) => {
                                        this.onGridReady(event);
                                    }}
                                ></AgGridReact>
                            </div>

                            {/* {this.state.showDialog ? <ComplianceDefaultDialogComponent inputAction={this.state.inputAction} modalAgNode={this.state.modalAgNode}
                                open={this.state.showDialog || false} onClose={() => this.setState({ showDialog: false })} refreshList={this.fetchData} />
                                : <></>} */}

                        </div>
                    );

                case ResultStatus.ERROR:
                    return (<PageErrorComponent label={`Error Loading ${pageName}`} classes={classes} onRetry={() => { this.fetchData(true); }} />);
                default:
                    return null;
            }
        }
    }


class ReportDetailsPageHeaderComponent extends Component {
    render() {
        const { small, classes, history, label, back, menu, search, tenantSelect, sourceSystemSelect, countrySelect, importTemplate, uploadFile, save, add, addCircle, view, reload, ok, cancel, noDivider, noDividerSpacing, download } = this.props;
        const showButtons = back || menu || search || tenantSelect || sourceSystemSelect || countrySelect || importTemplate || uploadFile || save || add || addCircle || view || reload || ok || cancel || download;

        return (
            <React.Fragment>
                <Grid className={classes.flexNoShrink} container direction="column">
                    <Grid container direction="row" alignItems="center">
                        {back ? (
                            <Tooltip enterDelay={MatSpecService.tooltipEnterDelay} title="Back" placement="bottom" arrow>
                                <IconButton onClick={() => { history.goBack(); }}>{MatIconService.BACK}</IconButton>
                            </Tooltip>
                        ) : null}

                        {reload ? (
                            <Tooltip enterDelay={MatSpecService.tooltipEnterDelay} title="Reload" placement="bottom" arrow>
                                <IconButton onClick={this.props.onReloadClick} color="secondary">
                                    {MatIconService.RELOAD}
                                </IconButton>
                            </Tooltip>
                        ) : null}

                        <Typography variant={small ? "h6" : "h5"} className={classes.headerTitle}>
                            {label}
                        </Typography>

                        {tenantSelect ? (
                            <>
                                <Typography>Tenant:{'\u00A0'}{'\u00A0'}{'\u00A0'}</Typography>
                                <Select
                                    labelId="tenantSelectLabel"
                                    id="tenantSelect"
                                    value={this.props.selectedTenantId}
                                    onChange={this.props.onTenantSelectClick}
                                    style={{ width: 150 }}
                                >
                                    {this.props.tenantSelectData.map((el) => (
                                        <MenuItem key={el.tenantid} value={el.tenantid}>
                                            {el.tenantName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </>
                        ) : null}


                     
                    
                    </Grid>
                    {noDivider || noDividerSpacing ? (
                        <span>{!noDivider && MatSpecService.showPageTitleDivider ? <Divider /> : null}</span>
                    ) : (
                        <span className={clsx(showButtons ? classes.pageHeaderDividerWithIcons : classes.pageHeaderDividerWithoutIcons)}>{MatSpecService.showPageTitleDivider ? <Divider /> : null}</span>
                    )}
                </Grid>
            </React.Fragment>

        );
    }
}


export default LayoutService.getHocComponenet(ReportStatusDetailsComponent);
