import React, { Component } from 'react';
import IzendaIntegrate from './config/izenda.integrate';
import IzendaAuthService from './services/izendaAuthService';
import ReportingRoot from './reportingRoot';

class ReportingHome extends Component {
    constructor(props) {
        super(props);
        this.izIntegrate = new IzendaIntegrate();
        this.izAuthService = new IzendaAuthService();
        this.state = {
            isRegistered: false,
            isIzendaRendering: false,
            izDom: document.getElementById('izenda-root')
        }
    }
    componentDidMount() {
        this.registerPorzioUser().then((result) => {
            if (result.success) {
                const izDom = this.izIntegrate.RenderIzenda();
                this.setState(() => ({ isIzendaRendering: true, izDom: izDom }));
            }
        });
    }
    componentWillUnmount() {
        this.izIntegrate.DestroyDom(this.state.izDom);
    }
    /*
        Register Porzio User
        After a Porzio user logs into the SPA, they will need to have all their Porzio Data translated into Izenda data. 
        Will call authapi/Account/RegisterPorzioUser
    */
    registerPorzioUser = async () => {
        const result = await this.izAuthService.registerUser();
        this.setState({ isRegistered: result.success });
        return result;
    }

    render() {
        return (<ReportingRoot izState={this.state} />);
    }
}

export default ReportingHome