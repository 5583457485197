import React, { Component } from "react";

export default class DataCountCellRendererComponent extends Component {
  constructor(props) {
    super(props);

    this.invokeParentMethod = this.invokeParentMethod.bind(this);
  }

  invokeParentMethod() {
    this.props.context.componentParent.dataCountMethodFromParent(
      `Row: ${this.props.node.rowIndex}, Col: ${this.props.colDef.headerName}`,
      this.props.node.data
    );
  }

  render() {
    let isCellDisabled = false;
    if (this.props.isCellEnabledCallback && !this.props.isCellEnabledCallback(this)) {
      isCellDisabled = true;
    }
    return (
      <span style={isCellDisabled ? { pointerEvents: "none", opacity: "0.4", textDecoration: "none", left: "40%", position: "absolute", textAlign: "center" } : { textDecoration: "underline", left: "40%", position: "absolute", textAlign: "center" }}
        // style={{ textDecoration: "underline", left: "40%", position: "absolute", textAlign: "center" }}
        // onClick={this.invokeParentMethod}
        onClick={!isCellDisabled && this.invokeParentMethod}>
        {this.props.value}
      </span>
    );
  }
}
