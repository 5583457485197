import React, { Component } from "react";
import { TextField } from "@material-ui/core";
import MatThemeService from "../../../services/theme/matThemeService";
import { AgGridBulkEditUtil } from "../bluk-edit/agGridBulkEditUtil";
import { DataService } from "../../../services/dataService";

export class AgGridErroredTextCellRenderer extends Component {

    constructor(props) {
        super(props);

        if (DataService.isNullOrUndefined(this.props.node.data[this.props.column.colDef.field])) {
            // if null or undefined is passed then the textcontrol becomes uncontrolled,
            // to avoid that we are updating the underlying datasource's value to empty string 
            this.props.node.data[this.props.column.colDef.field] = "";
        }

        const _errorOrWarningConfig = this.props.getErrorOrWarningInfoCallback(this);
        this.state = {
            //|---> Mandatory Fields
            isEditing: false,
            isParentReadOnly: this.props.isParentReadOnly,
            isColumnReadOnly: this.props.isColumnReadOnly,
            value: this.props.node.data[this.props.column.colDef.field],
            parentRef: this.props.componentThisRef,
            errorOrWarningConfig: _errorOrWarningConfig,
            hasErrorOrWarning: AgGridBulkEditUtil.hasErrorOrWarning(this.props.componentThisRef.state.agGridBulkEditUtil, this.props, _errorOrWarningConfig),
            //<---|
            onBlurHandled: true // onblur is handled for text fields

        };
    }


    isValidValue = () => {
        return this.state.value !== this.props.node.data[this.props.column.colDef.field];
    }

    render() {

        return AgGridBulkEditUtil.getRenderer(this,
            // formattedValue callback
            () => { return this.state.value; },
            // editor callback
            () => {
                return (
                    <TextField value={this.state.value} autoFocus={true}
                        type={this.props.inputType} size="small" variant={MatThemeService.inputVariant}
                        InputProps={{ style: { padding: 0, fontSize: 14, fontWeight: 400, lineHeight: 1.9 } }}
                        // applies the typed values
                        onChange={(e) => { this.setState({ value: e.target.value }); }}
                        // handles tab/Enter key and other navigations
                        onBlur={(e) => { // onNavigation out,
                            // if the newValue is different than the orignalValue, then apply the changes
                            if (this.isValidValue()) {
                                AgGridBulkEditUtil.applyChange(this, e.target.value); // commit value
                            } else { // else if same, the hide the editor
                                AgGridBulkEditUtil.handleClose(this);
                            }
                        }}
                        onKeyDown={(e) => {
                            // Enter || Tab key
                            if (e.which === 13 || e.which === 9) {
                                // If the newValue is different than the orignalValue, then apply the changes
                                if (this.isValidValue()) {
                                    AgGridBulkEditUtil.applyChange(this, e.target.value); // commit value
                                } else { // same, then hide the editor
                                    AgGridBulkEditUtil.handleClose(this);
                                }
                                e.stopPropagation();
                            } else if (e.which === 27) { // Escape Key
                                // revert back last committed value
                                this.setState({ value: this.props.node.data[this.props.column.colDef.field] });
                                // hide the editor
                                AgGridBulkEditUtil.handleClose(this);
                            } else {

                            }
                        }}
                    />
                );
            }

        );
    }

    //---
}