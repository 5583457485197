import React, { Component } from "react";
import { CircleComponent } from "../../shared/components/elements/circleComponent/circleComponent.jsx"

export default class ChildMessageRendererComponent extends Component {
  constructor(props) {
    super(props);
    this.invokeParentMethod = this.invokeParentMethod.bind(this);
    this.getComponent = this.getComponent(this);
    this.getHComp = this.getHComp(this);
  }

  invokeParentMethod() {
    this.props.context.componentParent.methodFromParent(this.props.colDef.field, this.props.node.data);
  }

  tranTypes = {
    error: <CircleComponent value={this.props.value} circleStyle={"circle-error"} />,
    warn: <CircleComponent value={this.props.value} circleStyle={"circle-warning"} />,
    unmatch: <CircleComponent value={this.props.value} circleStyle={"circle-unmatched"} />,
    nonLicensed: <CircleComponent value={this.props.value} circleStyle={"circle-unmatched"} />,
    eligible: <CircleComponent value={this.props.value} circleStyle={"circle-eligible"} />,
    approve: <CircleComponent value={this.props.value} circleStyle={"circle-approval"} />
  }

  getComponent() {
    let dynamic = null;
    switch (this.props.colDef.field) {
      case "transactionCount":
        dynamic = <span style={{ textDecoration: "underline", cursor: "pointer", left: "40%" }}>{this.props.value}</span>
        break;
      case "porziogsttransactionid":
        dynamic = <span style={{ textDecoration: "underline", cursor: "pointer", left: "40%" }}>{this.props.value}</span>
        break;
      case "porzioGstTransactionId":
        dynamic = <span style={{ textDecoration: "underline", cursor: "pointer", left: "40%" }}>{this.props.value}</span>
        break;
      case "PorzioTransactionId":
        dynamic = <span style={{ textDecoration: "underline", cursor: "pointer", left: "40%" }}>{this.props.value}</span>
        break;
      case "porziogstprofileid":
        dynamic = <span style={{ textDecoration: "underline", cursor: "pointer", left: "40%" }}>{this.props.value}</span>
        break;
      case "error":
        dynamic = this.tranTypes.error
        break;
      case "warning":
        dynamic = this.tranTypes.warn
        break;
      case "unmatched":
        dynamic = this.tranTypes.unmatch
        break;
      case "nonLicensed":
        dynamic = this.tranTypes.nonLicensed
        break;
      case "eligible":
        dynamic = this.tranTypes.eligible
        break;
      case "approval":
        dynamic = this.tranTypes.approve
        break;
      case "healthindicator":
        dynamic = this.getHComp(this.props.value);
        break;
      default:
        break;
    }
    return dynamic;
  }

  getHComp(val) {
    let dynamic = null;
    switch (val) {
      case 1:
        dynamic = this.tranTypes.error
        break;
      case 2:
        dynamic = this.tranTypes.warn
        break;
      case 3:
        dynamic = this.tranTypes.unmatch
        break;
      case 4:
        dynamic = this.tranTypes.approve
        break;
      default:
        break;
    }
    return dynamic;
  }

  render() {
    return (
      <span onClick={this.invokeParentMethod}>
        {this.getComponent}
      </span>
    );
  }
}
