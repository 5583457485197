import { AgGridColumnExt } from "../../../../shared/services/ag-grid/agGridColumnExt";
import ApiService from "../../../../shared/services/apiService";
import { API_ENDPOINT, ENTITY_TYPE } from "../../../../shared/types/enums";
import { AgGridBulkEditUtil } from "../../../../shared/components/ag-grid/bluk-edit/agGridBulkEditUtil";

export default class ReportLibraryViewService {

  static getReportsByLibraryId = (_tenantId, _userId, _reportLibId) => {
    const targetUrl = `/Reports/FetchReportLibraryView?tenantId=${_tenantId}&userId=${_userId}&reportLibId=${_reportLibId}&pageNum=1&pageSize=1000&active=1`
    return ApiService.getOBS(API_ENDPOINT.REPORTS, targetUrl);
  };

  static deleteReportLibrary = (tenantId, userId, reportDetailIds) => {
    return ApiService.deleteOBS(API_ENDPOINT.REPORTS,
      `/Reports/DeleteReportLibrary/${tenantId}?userId=${userId}&reportDetailIds=${reportDetailIds},`);
  }

  static getColumnDefs(_componentThisRef) {
    return [
      AgGridColumnExt.GET(true, "text", AgGridBulkEditUtil.selectColumnName, "Select").fixedWidth(80).headerClass("center-text")
        .cellRenderer("isCheckedCellRenderer", { onChange: _componentThisRef.toggleSubmitButton }).SET(),
      AgGridColumnExt.GET(false, "set", "genStatus", "Generation Status").flexWeight(1).minWidth(144).headerClass("center-text").pivot().SET(),
      AgGridColumnExt.GET(false, true, "fileType", "File").flexWeight(1).minWidth(144).headerClass("center-text").pivot().cellRenderer("downloadReportViaImageCellRenderer", {
        showIcon: (_cellRef) => {
          return _cellRef.props.data['genStatus'] === "Completed";
        },
        reportType: (_cellRef) => {
          return _cellRef.props.data['reportType'];
        }
      }).noCellHighlight().SET(),
      AgGridColumnExt.GET(false, "text", "reportDetailName", "Report Name").flexWeight(1).minWidth(144).headerClass("center-text").pivot().cellRenderer("childMessageRendererComponent").SET(),
      AgGridColumnExt.GET(false, "text", "reportDetailDescription", "Report Description").flexWeight(1).minWidth(144).headerClass("center-text").pivot().SET(),
      AgGridColumnExt.GET(false, true, "fileType", "Porzio Analysis Report").flexWeight(1).minWidth(144).headerClass("center-text").pivot().cellRenderer("downloadReportViaImageCellRenderer", {
        showIcon: (_cellRef) => {
          return _cellRef.props.data['isCompanionSave'] && _cellRef.props.data['genStatus'] === "Completed";;
        },
        isCompanionCol: () => true,
        reportType: (_cellRef) => {
          return _cellRef.props.data['reportType']
        }
      }).noCellHighlight().SET(),
      AgGridColumnExt.GET(false, "set", "workflowStatus", "WorkFlow Status").flexWeight(1).minWidth(144).headerClass("center-text").pivot().SET(),
      AgGridColumnExt.GET(false, "text", "startDate", "Report Run Date").flexWeight(1).minWidth(144).headerClass("center-text").pivot().SET(),
      AgGridColumnExt.GET(false, "set", "finalDispositionName", "Final Disposition").flexWeight(1).minWidth(144).headerClass("center-text").pivot().SET(),
      AgGridColumnExt.GET(false, "text", "finalDispositionDate", "Final Disposition Date").flexWeight(1).minWidth(144).headerClass("center-text").pivot().SET()];
  }
}