import { of } from "rxjs";
import { AgGridColumnExt } from "../../../shared/services/ag-grid/agGridColumnExt";
import ApiService from "../../../shared/services/apiService";
import { API_ENDPOINT, ReportCategory } from "../../../shared/types/enums";

export default class TenantReportTemplateSubscriptionService {

    // static fetchTenants(_userId) {
    //     return ApiService.getOBS(API_ENDPOINT.IDENTITY, `/User/GetUserTenants?userId=${_userId}`);
    // }

    static getActiveTenants = () => {
        const targetUrl = `/GetActiveTenants/true`;
        return ApiService.getOBS(
            API_ENDPOINT.TENANT,
            targetUrl,
        );
    }

    static fetchTemplates(_selTenantId, _roleId) {
        /**
         * As per the discussion with the DB Team
         *  --#1---Report subscription Link
                EXEC USP_RPT_REPORTBYCATEGORYIDGET @CATEGORYID = 1, @PageNumber = 1, @RowsperPage = 1000, @IsViewable = 0, @IsSusbcribe = 0, @RoleID = NULL
         */
        const jsonObj = {
            "tenantId": _selTenantId,
            "categoryId": ReportCategory.COMPLIANCE_TEMPLATES,
            "roleId": _roleId, // no need to pass null,... its handled at the api
            "isViewable": false,
            "isSubscribe": false,
            "pageNo": 1,
            "pageSize": 1000,
        };
        // TODO: ensure that this  is fetching from ReportMaster Table for the selected tenant
        return ApiService.postOBS(API_ENDPOINT.REPORTS, `/Reports/FetchReportPermissions`, JSON.stringify(jsonObj));
    }

    // save-api
    static saveComplianceTemplatesAsOBS(_selTenantId, _userId, _allIdsAsString, _selectedIdsAsString) {
        const jsonBody = JSON.stringify({
            "allIds": _allIdsAsString,
            "selectedIds": _selectedIdsAsString,
        });
        // TODO: ensure that this save is affecting ReportMaster Table for the selected tenant
        return ApiService.postOBS(API_ENDPOINT.REPORTS, `/Reports/SaveReportPermissions?tenantId=${_selTenantId}&userId=${_userId}&categoryId=1`, jsonBody);
    }

    // column-definitions
    static getColumnDefs(_parentThisRef) {
        return [
            AgGridColumnExt.GET(!_parentThisRef.state.isReadOnly, "text", "isViewable", "").cellRenderer("isViewableCellRenderer").fixedWidth(56).SET(),
            AgGridColumnExt.GET(false, "text", "reportName", "Template Name").flexWeight(3).tooltip().SET(),
            AgGridColumnExt.GET(false, "text", "templateYear", "Template Year").flexWeight(2).tooltip().SET(),
            AgGridColumnExt.GET(false, "text", "jurisdiction", "Jurisdiction").flexWeight(1).tooltip().SET(),
            AgGridColumnExt.GET(false, "text", "izendaDisplayName", "Template Description").flexWeight(3).tooltip().SET(),

        ];
    }

    //---
}
