import ApiService from "../../../shared/services/apiService";
import { API_ENDPOINT } from "../../../shared/types/enums";

export default class EmailService {
    static getEmailSetupAsOBS = (_tenantId, _emailId) => {
        return ApiService.getOBS(
            API_ENDPOINT.TENANT,
            `/Email/GetEmailSetUp/${_tenantId}/${_emailId}`
        );
    };

    static getEmailSetupTypes = (_tenantId) => {
        return ApiService.getOBS(
            API_ENDPOINT.TENANT,
            `/Email/GetEmailSetUpTypes/${_tenantId}`
        );
    };
}
