import React from "react";
import Checkbox from '@material-ui/core/Checkbox';
import { CHECK_STATE, ROLE_CELL_TYPE } from "../../types/enums";
import { MatIconService } from "../../services/theme/matIconService";
import { Typography } from "@material-ui/core";

export default class AgGridHierarchicalTextboxRendererComponent extends React.Component {

    // indeterminate is always triggered by the childrens
    // if most of the child are true, then this is true & indeterminate
    // else if most of the child are false, then this is false & indeterminate
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { classes, data } = this.props;
        const _leftPadding = data.hierarcyLevel <= 1 ? 0 : (data.hierarcyLevel * data.hierarcyLevel * 6);
        const _variant = data.hierarcyLevel > 1 ? 'body2' : 'subtitle2';

        return (
            <Typography variant={_variant} style={{ paddingLeft: _leftPadding }}>
                {/* {data.permissionName} */}
                {`${data.permissionName} - (${data.permissionId})`}
            </Typography>
        );

    }
}
