import ApiService from "../../../../shared/services/apiService";
import { API_ENDPOINT } from "../../../../shared/types/enums";

export default class SalesRepDetailsService {

    static getReportValueDropdownAsOBS = (_tenantId, _srId, _companySalesRepId) => {
        let queryString = `srId=${_srId}`;
        if(_srId === undefined){
            queryString = `companySalesRepId=${_companySalesRepId}`;
        }
        return ApiService.getOBS(
            API_ENDPOINT.TENANT,
            `/SalesRep/FetchSalesRepBySRIdOrCompanySalesRepId/${_tenantId}?${queryString}`
        );
    };

}    