import React, { Component } from "react";
import { Typography } from "@material-ui/core";

export default class AgGridIconTextRendererComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {
        const _icon = this.props.getIcon(this);
        return (
            <div style={{ display: "flex" }}>
                <Typography style={{
                    fontSize: 14, fontWeight: 400, lineHeight: 1.9,
                    overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis",
                    cursor: "pointer"
                }}>{this.props.value}</Typography>
                {_icon ? <div style={{ paddingLeft: 4 }}>{_icon}</div> : null}
            </div>

        );
    }

    //---
}