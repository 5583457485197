import { AgGridColumnExt } from "../../../shared/services/ag-grid/agGridColumnExt";
import ApiService from "../../../shared/services/apiService";
import { API_ENDPOINT, ReportCategory } from "../../../shared/types/enums";
import RolePermissionService from "../../../shared/role-permissions/rolePermissionService";

export default class ReportTemplateSubscriptionService {

 

    //---
}
