import { AgGridColumnExt } from "../../../shared/services/ag-grid/agGridColumnExt";
import ApiService from "../../../shared/services/apiService";
import { API_ENDPOINT } from "../../../shared/types/enums";
import { ReplaySubject } from "rxjs";

export default class UserMappingDetailService {

    static getUserMappingDetailsAsOBS = (_userId) => {
        const targetUrl = `/User/GetUserTenants?userId=${_userId}`;
        return ApiService.getOBS(
            API_ENDPOINT.IDENTITY,
            targetUrl,
        );
    };
    static getActiveTenants = () => {
        const targetUrl = `/GetActiveTenants/true`;
        return ApiService.getOBS(
            API_ENDPOINT.TENANT,
            targetUrl,
        );
    }

    /**
     * Refer url for more details: https://www.ag-grid.com/javascript-grid-column-properties/
     * @param _componentThisRef pass {this} keyword from the calling componenet
     */
    static getColumnDefs(_componentThisRef) {
        return [
            AgGridColumnExt.GET(false, "text", "isSelected", "Select").fixedWidth(88).flexWeight(1).headerClass("center-text").cellRenderer("isCheckedCellRenderer").SET(),
            AgGridColumnExt.GET(false, "text", "uid", "UID").flexWeight(1).headerClass("center-text").SET(),
            AgGridColumnExt.GET(false, "text", "tenantName", "Tenant Name").flexWeight(1).headerClass("center-text").SET(),
        ];
    }
}