import React, { useEffect } from 'react';
import IzendaIntegrate from './config/izenda.integrate';
import CustomButton from './customButton';
import './reportingStyle.css';

export default function ReportingRoot(props) {
    const callConfig = () => {
        const izIntegrate = new IzendaIntegrate();
        izIntegrate.DoIzendaConfig();
    }

    useEffect(() => {
        callConfig();
    });

    return (
        <>
            <div className="loader" id="progressLoader"> </div>
            <div className="izenda-container" id="izenda-root">LOADING IZENDA REPORTING.....
                <CustomButton izState={props.izState} />
            </div>
        </>
    );
}
