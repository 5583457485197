import ApiService from "../../../../shared/services/apiService";
import { API_ENDPOINT } from "../../../../shared/types/enums";
import { AgGridColumnExt } from "../../../../shared/services/ag-grid/agGridColumnExt";

export default class RelatedTransactionsService {

  static getRelatedTransactionsAsOBS = (_tenantId, _userId, _modalAgNode,) => {
    const targetUrl = `/Transactions/RelatedTransactions/${_tenantId}/${_userId}/${_modalAgNode.transactionid}/1/1000`;
    return ApiService.getOBS(
      API_ENDPOINT.CORE,
      targetUrl,
    );
  };

  /**
   * Refer url for more details: https://www.ag-grid.com/javascript-grid-column-properties/
   * @param _componentThisRef pass {this} keyword from the calling componenet
   */
  static getColumnDefs(_componentThisRef) {
    return [
      AgGridColumnExt.GET(!_componentThisRef.props.isReadOnly, "text", "isSelected", "Select").fixedWidth(88).headerClass("center-text").cellRenderer("isCheckedCellRenderer").SET(),
      AgGridColumnExt.GET(false, "text", "CompanyTransactionId", "Company Transaction Id").minAndDefaultWidth(144).headerClass("center-text").SET(),
      AgGridColumnExt.GET(false, "text", "PorzioTransactionId", "GST Transaction Id").cellRenderer("childMessageRendererComponent").flexWeight(6).tooltip().headerClass("center-text").SET(),
      AgGridColumnExt.GET(false, "text", "LastName", "Last Name").flexWeight(4).tooltip().headerClass("center-text").SET(),
      AgGridColumnExt.GET(false, "text", "FirstName", "First Name").flexWeight(4).tooltip().headerClass("center-text").SET(),
      AgGridColumnExt.GET(false, "text", "OrganizationName", "Organization").flexWeight(4).tooltip().headerClass("center-text").SET(),
      AgGridColumnExt.GET(false, "text", "CountryName", "Country").flexWeight(4).tooltip().headerClass("center-text").SET(),
      AgGridColumnExt.GET(false, "text", "Total Attendees", "Total Number of Recipients").flexWeight(4).tooltip().headerClass("center-text").SET(),
      AgGridColumnExt.GET(false, "text", "Transaction Date", "Transaction Date").flexWeight(4).tooltip().headerClass("center-text").SET(),
      AgGridColumnExt.GET(false, "text", "Purpose", "Purpose").flexWeight(4).tooltip().headerClass("center-text").SET(),
      AgGridColumnExt.GET(false, "text", "Form", "Form").flexWeight(4).tooltip().headerClass("center-text").SET(),
      AgGridColumnExt.GET(false, "text", "Total Amount", "Total Amount").flexWeight(4).tooltip().headerClass("center-text").SET(),
    ];
  }

  //---
}