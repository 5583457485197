import React from "react";
import { DialogContent, Tabs, Tab, Box, TextField, Divider } from "@material-ui/core";

import SourceDetailsComponent from "../source-details/sourceDetailsComponent";
import BusinessRulesListComponent from "../business-rules/businessRulesListComponent";
import FieldConfigurationComponent from "../field-configuration/fieldConfigurationComponent";

import { CrudAction, ResultStatus } from "../../../shared/types/enums";
import PageLoadingComponent from "../../../shared/components/page/pageLoadingComponent";
import PageErrorComponent from "../../../shared/components/page/pageErrorComponent";
import { MatIconService } from "../../../shared/services/theme/matIconService";
import LayoutService from "../../../shared/services/layoutService";
import SectionComponent from "../../../shared/components/sectionComponent";
import PageDynamicHeaderComponent from "../../../shared/components/page/pageDynamicHeaderComponent";
import MatThemeService from "../../../shared/services/theme/matThemeService";
import RolePermissionService from "../../../shared/role-permissions/rolePermissionService";

class SourceDialogComponent extends React.Component {
  constructor(props) {
    super(props);

    // bind
    this.onValueChange = this.onValueChange.bind(this);

    // init state
    this.state = {
      data: [],
      fetchResult: ResultStatus.NOT_LOADED,
      selectedTabIndex: 0,
      // build the state with the error model
      model: LayoutService.buildWithErrorModel({
        taskName: "",
        taskPriority: "",
        taskAssignedTo: "",
        startDate: "",
        dueDate: "",
        status: "",
      }),
    };
  }
  componentWillUnmount() { }
  componentDidMount() { this.setState({ fetchResult: ResultStatus.SUCCESS }); }

  onTabChange = (event, newValue) => {
    this.setState({ selectedTabIndex: newValue });
  };

  onValueChange(_event) {
    const key = _event.target.id;
    const value = _event.target.value;

    // set value
    this.setState({
      model: {
        [key]: value,
      }
    });

    // set validation message
    switch (key) {
      case "taskName": this.setModelError(key, value === "", "Task Name is required"); break;
      case "taskPriority": this.setModelError(key, value === "", "Task Priority is required"); break;
      case "taskAssignedTo": this.setModelError(key, value === "", "Assigned To is required"); break;
      case "startDate": this.setModelError(key, value === "", "Start Date is required"); break;
      case "dueDate": this.setModelError(key, value === "", "Due Date is required"); break;
      case "status": this.setModelError(key, value === "", "Status is required"); break;
      default: console.log("Source Dialog Component Default");
    }
  }

  setModelError(_key, _hasError, _errorMsg) {
    this.setState({
      model: {
        [_key + "_error"]: _hasError ? _errorMsg : null
      }
    });
  }

  buildProps(_key, _label) {
    return {
      label: _label,
      id: _key,
      name: _key,
      defaultValue: this.state.model[_key],
      error: this.state.model[_key + "_error"] !== null,
      helperText: this.state.model[_key + "_error"],
      onChange: this.onValueChange,
    };
  }

  /**Render */
  render() {
    const { classes } = this.props;
    if ((this.props.inputAction === CrudAction.CREATE && RolePermissionService.SOURCE_FILE_DETAIL.cannotCreate) // applies for create
      || (this.props.inputAction === CrudAction.UPDATE && RolePermissionService.SOURCE_FILE_DETAIL.cannotView)) { // applies for both view and edit
      return RolePermissionService.getAccessDeniedComponent(classes, () => { this.props.onClose(false) });
    } else {
      switch (this.state.fetchResult) {
        case ResultStatus.NOT_LOADED:
        case ResultStatus.LOADING:
          return (<PageLoadingComponent small classes={classes} label="Loading Source Details" />);
        case ResultStatus.SUCCESS:
          return (
            <React.Fragment>

              {/* Header */}
              <PageDynamicHeaderComponent classes={classes} label="Source Detail" divider rightPadding={16}
                leftActions={[
                  { icon: MatIconService.BACK, onClick: () => { this.props.onClose(false); }, title: "Go back", iconColor: "secondary" },
                ]}
              />

              {/* constent */}
              <DialogContent>

                {this.props.inputAction === CrudAction.UPDATE ?
                  <Tabs value={this.state.selectedTabIndex} onChange={this.onTabChange} indicatorColor="secondary" textColor="inherit" variant="fullWidth" scrollButtons="auto"
                    style={{ backgroundColor: MatThemeService.getTabBG() }}
                  >
                    <Tab label="Edit Source Details" id="taskTab" />
                    <Tab label="Business Rules" id="tab-1" />
                    <Tab label="Field Configuration" id="fieldConfigurationTab" />
                  </Tabs>
                  :
                  <Tabs value={this.state.selectedTabIndex} onChange={this.onTabChange} indicatorColor="secondary" textColor="inherit" variant="fullWidth" scrollButtons="auto"
                    style={{ backgroundColor: MatThemeService.getTabBG() }}
                  >
                    <Tab label="Create Source" id="taskTab" />
                  </Tabs>}


                <div role="tabpanel" hidden={this.state.selectedTabIndex !== 0} id="taskTabPanel">
                  <Divider />
                  <Box style={{ padding: "8px 16px 16px 16px" }}>
                    <SourceDetailsComponent
                      systemId={this.props.systemId} modalAgNode={this.props.modalAgNode} inputAction={this.props.inputAction}
                      refreshSourceGrid={this.props.refreshSourceGrid} onClose={this.props.onClose} />
                  </Box>
                </div>

                <div role="tabpanel" hidden={this.state.selectedTabIndex !== 1} id={"businessRuleTabPanel"} >
                  <Divider />
                  <Box style={{ padding: "8px 16px 16px 16px" }}>
                    <BusinessRulesListComponent systemId={this.props.systemId} onClose={this.props.onClose} />
                  </Box>
                </div>

                {this.props.inputAction === CrudAction.UPDATE ?
                  <div role="tabpanel" hidden={this.state.selectedTabIndex !== 2} id={"fieldConfigurationTabPanel"}>
                    <Divider />
                    <Box style={{ padding: "8px 16px 16px 16px" }}>
                      <FieldConfigurationComponent isReadOnly={RolePermissionService.SOURCE_FILE_DETAIL.cannotEdit} modalAgNode={this.props.modalAgNode} onClose={this.props.onClose} />
                    </Box>
                  </div>
                  : null}
              </DialogContent>

            </React.Fragment>
          );

        case ResultStatus.ERROR:
        default:
          return (<PageErrorComponent small label="Error Loading Task Details" classes={classes} onRetry={() => { }} />);
      }
    }

  }

}

/** HOC */
export default LayoutService.getHocComponenet(SourceDialogComponent);