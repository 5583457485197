import React from "react";
import { of, combineLatest } from "rxjs";
import { Dialog, DialogTitle, DialogContent, DialogContentText, Box, Typography, Grid, AppBar, Toolbar } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css";
import ChildMessageRendererComponent from "../../../../shared/components/childMessageRendererComponent";
import DownloadFileAgRenderer from "../../../data-center/download-file/downloadFileAgRenderer";
import { AgGridColumnExt } from "../../../../shared/services/ag-grid/agGridColumnExt";
import { AgGridUtil } from "../../../../shared/services/ag-grid/agGridUtil";
import AgGridCheckboxCellRendererComponent from "../../../../shared/components/elements/agGridCheckboxCellRendererComponent";
import { AgGridBulkEditUtil } from "../../../../shared/components/ag-grid/bluk-edit/agGridBulkEditUtil";
import { AuthContext } from "../../../../shared/store/authProvider";
import { SubscriptionArray } from "../../../../shared/services/dataService";
import { ResultStatus, CrudAction, API_ENDPOINT } from "../../../../shared/types/enums";
import LayoutService from "../../../../shared/services/layoutService";
import LookupService from "../../../../shared/services/lookupService";
import SectionComponent from "../../../../shared/components/sectionComponent";
import PageLoadingComponent from "../../../../shared/components/page/pageLoadingComponent";
import DialogErrorFragmentComponent from "../../../../shared/components/page/dialogErrorFragmentComponent";
import DocumentDetailsComponent from "../../../../shared/components/document-details/documentDetailsComponent"
import DocumentsService from "./documentsService";
import { MatIconService } from "../../../../shared/services/theme/matIconService";
import RolePermissionService from "../../../../shared/role-permissions/rolePermissionService";
import ToastService from "../../../../shared/services/toastService";
import ApiService from "../../../../shared/services/apiService";
import OktaAuthService from "../../../../public/authentication/oktaAuthService";

class DocumentsComponent extends React.Component {
  static contextType = AuthContext;
  apiSubscriptions = new SubscriptionArray();

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      fetchResult: ResultStatus.NOT_LOADED,
      isEditing: this.props.inputAction === CrudAction.UPDATE,
      porzioSearch: "",
      selectedDocuments:[],
      agGridBulkEditUtil: new AgGridBulkEditUtil(),
      agGridUtils: new AgGridUtil("lastname", {
        isCheckedCellRenderer: AgGridCheckboxCellRendererComponent,
        childMessageRendererComponent: ChildMessageRendererComponent,
        downloadFileAgRenderer: DownloadFileAgRenderer,
      }),
    };
  }
  componentWillUnmount() {
    this.apiSubscriptions.cancelAll();
  }
  componentDidMount() {
    this.fetchData();
  }

  handleDownloadAg = async (
    cell,
    agNodeData,
    filename,
    linkRefAg,
    windowUrl
  ) => {
    //ToastService.showInfo("Under Construction");
    if (this.state.canNotDownload) {
      this.setState({ accessDenied: this.state.canNotDownload });
      return;
    } else {
      const result = await fetch(
        `${process.env.REACT_APP_CORE_HTTP}/Document/DownloadDocument/${this.context.user.tenantId}?filename=${agNodeData.filePath}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ` + OktaAuthService.getAccessToken(),
          },
        }
      );
      const blob = await result.blob();
      return blob;
    }

  };

  /** API Fetch */
  fetchData = () => {
    this.apiSubscriptions.cancelAll();
    this.setState({ fetchResult: ResultStatus.LOADING, data: [] });

    /**
     * rxjs <combineLatest> operator takes multiple obs as argument and emits their result in the same order
     * this operator is very handy as we don't have to independently wait for each result
     */
    this.apiSubscriptions.add(
      combineLatest([
        DocumentsService.getDocumentsAsOBS(this.context.user.tenantId, this.props.modalAgNode,),
        LookupService.getFieldLOVByIdsAsOBS(this.context.user.tenantId, 268),
      ]).subscribe(
        // success
        ([_documents, _documentCategoryLov]) => {
          this.setState(
            {
              //data: [..._systemDocuments, ..._complianceDocuments, ..._customDocuments].map(r => (parseInt(r.documentCategoryId) ? { ...r, documentCategoryName: _documentCategoryLov.find(f => f.lovId === r.documentCategoryId).lovKey } : "")),
              data: _documents || []
            },
            // change the state after all the above are assigned
            () => {
              this.setState({ fetchResult: ResultStatus.LOADED });
            }
          );
        },
        // onError
        (error) => {
          console.log("Error:", error);
          this.setState({ fetchResult: ResultStatus.ERROR });
        }
      )
    );
  };

  // called on row-cell click
  methodFromParent = (row_col, node) => {
    this.setState({
      modalShow: true,
      modalAgNode: node,
      inputAction: CrudAction.UPDATE,
    });
  };

  // render
  TAB_PERMISSIONS = RolePermissionService.TRANSACTION_RELATED_DOCUMENTS;
  render() {
    const { classes } = this.props;
    this.props.tabConfig.ref = this; // 1/4) required by parent component
    const cannotDelete = this.props.isReadOnly || this.state.selectedDocuments.length === 0;
    if (this.TAB_PERMISSIONS.cannotView) {
      return RolePermissionService.getAccessDeniedComponent(); // this is required to prevent Url navigation
    }
    else {
      const componentType = "Documents";
      switch (this.state.fetchResult) {
        case ResultStatus.NOT_LOADED:
        case ResultStatus.LOADING:
          return <PageLoadingComponent small classes={classes} label={`Loading ${componentType}`} />;
        case ResultStatus.SAVING:
          return <PageLoadingComponent small classes={classes} label={`Saving ${componentType}`} />;
        case ResultStatus.LOADED:
        case ResultStatus.SUCCESS:
          return (
            <React.Fragment>
              <DialogTitle disableTypography id="dialogTitle" />
              <DialogContent>
                <Box style={{ padding: "0px", minHeight: "70vh", maxHeight: "70vh", }}>
                  <Grid container direction="row" justify="flex-end" alignItems="center">
                    <Typography variant="h6" className={classes.sectionHeader} style={{ margin: 8,flexGrow: 1  }}></Typography>
                    {LayoutService.getIconButton(this.props.isReadOnly || this.TAB_PERMISSIONS.cannotCreate, MatIconService.ADD_CIRCLE_OUTLINE, "Add New Document",
                      () => {
                        this.setState({ modalShow: true, modalAgNode: {}, inputAction: CrudAction.CREATE, })
                      }
                    )}
                    {LayoutService.getIconButton(cannotDelete, cannotDelete ? MatIconService.DELETE : MatIconService.DELETE_RED, "Delete", () => { this.handleDeleteClick()},"inherit")}
                    {/* {LayoutService.getIconButton(this.props.isReadOnly || this.TAB_PERMISSIONS.cannotDelete, MatIconService.DELETE, "Delete Document",
                      () => {
                        ToastService.showWarning("Please Select a document to delete.")
                      }
                    )} */}
                  </Grid>
                  {/* <SectionComponent classes={classes} add onAddClick={() => this.setState({ modalShow: true, modalAgNode: {}, inputAction: CrudAction.CREATE, })} /> */}
                  <div {...LayoutService.getAgGridStyles(320)}>
                    <AgGridReact
                      pagination={true}
                      paginationPageSize={50}
                      rowData={this.state.data}
                      columnDefs={DocumentsService.getColumnDefs(this)}
                      frameworkComponents={this.state.agGridUtils.frameworkComponents}
                      suppressClickEdit={true}

                      gridOptions={{
                        context: { componentParent: this },
                        ...AgGridColumnExt.getGridOptions(40),
                        ...this.state.agGridUtils.bindInlineEditEvents(),
                      }}
                      onGridReady={(params) => {
                        this.state.agGridUtils.setGridParams(params, true);
                      }}></AgGridReact>
                  </div>
                </Box>
              </DialogContent>
              {this.state.openDeleteConfirm ?
              <Dialog open={this.state.openDeleteConfirm || false} onClose={() => this.setState({ openDeleteConfirm: false })} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                  <AppBar position="static">
                    <Toolbar>
                      <Typography variant="h6" className={classes.root}>Delete Confirmation</Typography>
                      {LayoutService.getIconButton(false, MatIconService.OK, "Delete", this.handleDeleteConfirm, "inherit", "keyActionDelete")}
                      {LayoutService.getIconButton(false, MatIconService.CANCEL, "Cancel", () => this.setState({ openDeleteConfirm: false }), "secondary", "keyActionCancel")}
                    </Toolbar>
                  </AppBar>
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <Box style={{ paddingLeft: 16, paddingRight: 32, paddingTop: 8, paddingBottom: 32, }} >
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Typography variant="h6" className={classes.root} align="center">Are you sure you want to delete the selected document? This action cannot be reversed.</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="h6" className={classes.root} align="center">{this.state.selectedDocuments.map(r => (<React.Fragment>{r.filePath}<br /> </React.Fragment> ))}</Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </DialogContentText>
                </DialogContent>
                </Dialog>
                :<></>
      }
              
              <Dialog open={this.state.modalShow || false} scroll={true ? "paper" : "body"} maxWidth="md">
                <DocumentDetailsComponent
                  onClose={() => this.setState({ modalShow: false })}
                  modalAgNode={this.state.modalAgNode}
                  inputAction={this.state.inputAction}
                  refreshGrid={this.fetchData}
                  entityType={this.props.entityType}
                  transactionId={this.props.modalAgNode.transactionid}
                />
              </Dialog>
            </React.Fragment>
          );

        case ResultStatus.ERROR:
        default:
          return (
            <DialogErrorFragmentComponent title={`Error Loading ${componentType}`} description={`Error Loading ${componentType}`} classes={classes}
              onClose={() => { this.props.onClose(false); }} onRetry={() => { this.fetchData(); }} />
          );
      }
    }
  }
  handleDeleteClick = () => {
    this.setState({
      openDeleteConfirm: true
    });
  }

 handleDeleteConfirm = () => {
    this.setState({ openDeleteConfirm: false });
    let idsToDelete = this.state.selectedDocuments.map(r => r.porzioGSTDocumentID)?.join(",");
    var pathParams = `/Document/DeleteDocument/${this.context.user.tenantId}/${this.context.user.userId}/${idsToDelete}`;
    ApiService.setOBS("DELETE", API_ENDPOINT.CORE, pathParams, JSON.stringify({})).subscribe(
      (successResult) => {
        if (successResult) {
          ToastService.showSuccess("Delete Document Successful");
          //refreshGrid= false;
          this.fetchData();
          this.props.onClose();
        } else {
          this.setState({ fetchResult: ResultStatus.ERROR });
        }
      },
       
      (errorResult) => {
        this.setState({ fetchResult: ResultStatus.ERROR });
      }
    );
    this.setState({ selectedDocuments: [] });
  }

  toggleSubmitButton = (_cellRef, _data) => {
    let hasSelected = false;
    let selectedDocuments = [];
    if (this.state.agGridBulkEditUtil.hasUpdates()) {
      hasSelected = true;
    } else {
      this.state.agGridUtils.gridApi.forEachNode((rowNode) => {
        hasSelected = hasSelected || rowNode.data[AgGridBulkEditUtil.selectColumnName] === true;
        if (rowNode.data && rowNode.data[AgGridBulkEditUtil.selectColumnName]) {
          selectedDocuments.push(rowNode.data);
        }
      });
    }

    this.setState({
      selectedDocuments: selectedDocuments,
      isReadOnly: !hasSelected,
    });
  }

  /** 2/4 Required in Parent */
  isDirtyCallback = () => {
    return false;
    // do any additional checkings if needed
    // if (this.fPropsDynamic) {
    //   return this.fPropsDynamic.dirty;
    // } else {
    //   return false;
    // }
  }

  /** 3/4 Required in Parent */
  resetCallback = () => {
    // if (this.fPropsDynamic) {
    //   this.fPropsDynamic.resetForm();
    // }
    // do any additional resetting if needed
  }

  /** 4/4 Required in Parent */
  postCallbackOBS = () => {
    return of(null);
    // if (DataService.isNullOrUndefined(this.fPropsDynamic)) { return of(null); }
    // else {

    // }
  }

}

/** HOC */
export default LayoutService.getHocComponenet(DocumentsComponent);
