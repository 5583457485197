import React from "react";
import { AppBar, Typography, Button, DialogTitle, Grid, Toolbar, IconButton } from "@material-ui/core";
import LayoutService from "../services/layoutService";
import { MatIconService } from "../services/theme/matIconService";

class AccessDeniedComponent extends React.Component {
    render() {
        const { classes } = this.props;

        return (
            <>
                <Toolbar>
                    <IconButton color="secondary">{MatIconService.DENIED}</IconButton>
                    <Typography variant="h6" className={classes.root}>{this.props.title}</Typography>
                    {this.props.showCancel ? <IconButton color="secondary" onClick={this.props.onClose}>{MatIconService.CANCEL}</IconButton> : <></>}
                </Toolbar>
                <Grid direction="column" container justify="center" alignItems="center" style={{ padding: 24 }}>
                    <Typography variant="h6" className={classes.pageErrorHeader}>{this.props.message}</Typography>
                </Grid>
            </>
        );
    }
    //---
}
export default LayoutService.getHocComponenet(AccessDeniedComponent);