import ApiService from "../../../../../shared/services/apiService";
import { API_ENDPOINT } from "../../../../../shared/types/enums";

export default class PersonalSettingsService {

  static getFullUserAsOBS = (_tenantId, _userId) => {
    return ApiService.getOBS(
      API_ENDPOINT.TENANT,
      `/User/GetFullUser?userid=${_userId}&tenantId=${_tenantId}`
    );
  };

}
