import SessionService from '../../../shared/services/sessionService';
import ApiEndpointConfig from '../config/apiEndpointConfig';

export default class IzendaAuthService {
    constructor() {
        const authUserObj = SessionService.getUserModelFromStorage();
        this.token = authUserObj.sessionToken;
        this.id = authUserObj.userId;
    }

    async registerUser() {
        if (!this.hasToken) {
            console.log('IZENDA ERROR | Could not get gstToken...');
            return false;
        }
        const body = {
            accessToken: this.token,
            userTenants: await this.getPorzioTenants(),
        }
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        };
        const url = ApiEndpointConfig.getPath('registerPorzioUser');
        let response = {
            success: false,
            message: ''
        };
        return fetch(url, requestOptions)
            .then(response => response.json())
            .then(data => {
                response.success = data['success'];
                response.message = data['message'];
                return response;
            })
            .catch((error) => {
                console.log(error);
                response.success = false;
                response.message = "User Registration Failed.";
                return response;
            });

        ;
    }

    async getPorzioTenants() {
        const getUserTenantsResult = await fetch(`${process.env.REACT_APP_IDENTITY_HTTP}/User/GetUserTenants?userId=${this.id}`);
        const getUserTenantsJson = await getUserTenantsResult.json();
        return getUserTenantsJson;
    }

    hasToken() {
        return this.token !== undefined && this.token !== null;
    }
}