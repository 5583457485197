import ApiService from "../../../../shared/services/apiService";
import { API_ENDPOINT } from "../../../../shared/types/enums";

export default class ComplianceReportMappingService {

  static fetchTemplateTypeDataAsOBS = (_tenantId) => {
    return ApiService.getOBS(API_ENDPOINT.CORE, `/Entity/${_tenantId}`);
  };

}
