import React from 'react';
import { AppBar, Box, CircularProgress, TextField, Dialog, DialogContent, DialogContentText, DialogTitle, Grid, Toolbar, Typography } from "@material-ui/core";
import { AuthContext } from "../../store/authProvider";
import LayoutService from "../../services/layoutService";
import ApiService from "../../services/apiService";
import ToastService from "../../services/toastService";
import PageErrorComponent from "../page/pageErrorComponent";
import PageLoadingComponent from "../page/pageLoadingComponent";
import { ResultStatus, API_ENDPOINT, ENTITY_TYPE, CrudAction } from "../../types/enums";
import { Formik } from "formik";
import { SubscriptionArray } from "../../services/dataService";
import OktaAuthService from '../../../public/authentication/oktaAuthService';

class AddEditCommentComponent extends React.Component {
  static contextType = AuthContext;
  oSubscriptions = new SubscriptionArray();

  state = {
    fetchResult: ResultStatus.NOT_LOADED,
    comment: "",
    isReadOnly : true,
    isEditing: false,
  };

  constructor(props) {
    super(props);
    this.fetchComments = this.fetchComments.bind(this);
  }

  componentDidMount() {
    this.fetchComments();
  }

  componentWillUnmount() {
    this.oSubscriptions.cancelAll();
  }

  fetchComments = (_ignoreCache = false) => {
    this.oSubscriptions.cancelAll();
    this.setState({ fetchResult: ResultStatus.LOADING });
    // save the subscription object
    let targetUrl = `/DataCenter/FileDetailsById/${this.context.user.tenantId}/`;

    switch (this.props.entityType) {
      case ENTITY_TYPE.PROFILE:
        targetUrl += `${this.props.agCellData.fileId}`;
        break;
      case ENTITY_TYPE.TRANSACTION:
        targetUrl += `${this.props.agCellData.fileID}`;
        break;
      case ENTITY_TYPE.DATA:
        targetUrl += `${this.props.agCellData.fileId}`;
        break;
      default:
        this.setState({ fetchResult: ResultStatus.ERROR });
        break;
    }

    this.oSubscriptions.add(ApiService.getOBS(API_ENDPOINT.CORE, targetUrl)
      .subscribe((successResult) => {
        this.setState({
          comment: successResult && successResult.comment ? successResult.comment : ""
        },
          () => {
            // change the state after all the above are assigned
            this.setState({ fetchResult: ResultStatus.LOADED });
          });
      },
        (error) => {
          this.setState({
            fetchResult: ResultStatus.ERROR
          });
        }
      )
    );
  };

  handleAddEditComment = async (fProps) => {
    this.setState({ fetchResult: ResultStatus.SAVING });

    let actionVerb = "POST";
    let fileId = this.props.agCellData.fileId ?? this.props.agCellData.fileID;
    let pathParams = `/DataCenter/SaveComments`;

    const headers = {
      Authorization: `Bearer ` + OktaAuthService.getAccessToken(),
      'Content-Type': 'application/json; charset=utf-8'
    };

    ApiService.setOBS(actionVerb, API_ENDPOINT.CORE, pathParams, JSON.stringify({
      tenantId: this.context.user.tenantId,
      fileId: fileId,
      comments: fProps.values.comment
    }), headers)
      .subscribe((successResult) => {
        if (successResult) {
          ToastService.showSuccess("Comments saved successfully");
          this.props.handleClose(true);
        } else { this.setState({ fetchResult: ResultStatus.ERROR }); }
      },
        (errorResult) => { this.setState({ fetchResult: ResultStatus.ERROR }); }
      );
  }

  getInitialValues = () => {
    return {
      comment: this.state.comment,
    };
  }

  render() {
    const { classes } = this.props;

    switch (this.state.fetchResult) {
      case ResultStatus.NOT_LOADED:
      case ResultStatus.LOADING:
        return (
          <PageLoadingComponent small classes={classes} label="Loading Comments" />
        );
      case ResultStatus.LOADED:
      case ResultStatus.SUCCESS:
      case ResultStatus.SAVING:
        return (
          <Dialog
            open={this.props.open || false}
            onClose={this.props.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <Formik
              initialValues={this.getInitialValues()}
              //validationSchema={this.getValidationSchema()}
              validationSchemaOptions={{ showMultipleFieldErrors: true }}
            >
              {(fProps) => (
                <form>
                  <DialogTitle disableTypography id="dialogTitle">
                    <AppBar position="static">
                      <Toolbar variant="dense">
                        <Typography variant="h6" className={classes.root}>Add/Edit Comment</Typography>
                        {LayoutService.getReadOnlyActions(this, this.state.isEditing,
                          () => { this.props.handleClose(false) },
                          () => { this.handleAddEditComment(fProps) })}
                      </Toolbar>
                    </AppBar>
                  </DialogTitle>
                  {/* Dialog Content */}
                  <DialogContent>
                    <Box
                      style={{
                        paddingLeft: 16,
                        paddingRight: 16,
                        paddingTop: 16,
                        paddingBottom: 32,
                        minWidth: "40vh",
                        maxWidth: "60vh",
                      }}
                    >
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <TextField
                            {...LayoutService.getInputProps(this.state.isReadOnly,
                              classes,
                              fProps,
                              "comment",
                              "Comment"
                            )}
                            style={{ minWidth: "95%" }}
                            multiline
                          />
                        </Grid>


                      </Grid>
                    </Box>
                  </DialogContent>
                </form>
              )}
            </Formik>
          </Dialog>
        );
      case ResultStatus.ERROR:
      default:
        return (<PageErrorComponent label="Error occurred while saving comments" onClose={this.props.handleClose} classes={classes} onRetry={this.handleAddEditComment} />);
    }
  }
}

export default LayoutService.getHocComponenet(AddEditCommentComponent);