import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { ResultStatus } from "../../../../../shared/types/enums";
import PageLoadingComponent from "../../../../../shared/components/page/pageLoadingComponent";
import PageErrorComponent from "../../../../../shared/components/page/pageErrorComponent";

import LayoutService from "../../../../../shared/services/layoutService";
import { TextField } from "@material-ui/core";

class AuthenticationModalComponent extends React.Component {
  constructor(props) {
    super(props);
    // init state
    this.state = {
      data: [],
      fetchResult: ResultStatus.NOT_LOADED,
    };
  }
  componentWillUnmount() {}
  componentDidMount() {
    //this.SERVICE.fetchData(this);
    this.setState({
      fetchResult: ResultStatus.SUCCESS,
    });
  }

  getInitialValues() {
    return {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    };
  }

  getValidationSchema() {
    return Yup.object({
      oldPassword: Yup.string()
        .min(3, "Must be atleast 3 characters long")
        .max(256, "Must be 256 characters or less")
        .required("Required"),
      newPassword: Yup.string()
        .min(3, "Must be atleast 3 characters long")
        .max(256, "Must be 256 characters or less")
        .required("Required"),
      confirmPassword: Yup.number()
        .min(0, "Must be above 0")
        .max(100, "Must be below 100")
        .required("Required"),
    });
  }

  handleSubmit(_formikProps) {
    if (_formikProps.isSubmitting) {
      return;
    } else {
      if (_formikProps.isValid) {
        // TODO
        alert(JSON.stringify(_formikProps.values));
        this.setState({
          fetchResult: ResultStatus.LOADING,
        });
        _formikProps.setSubmitting(true);
        setTimeout(() => {
          this.setState({
            fetchResult: ResultStatus.SUCCESS,
          });
          _formikProps.setSubmitting(false);
        }, 3000);
      } else {
        alert("Invalid values");
      }
    }
  }

  /**Render */
  render() {
    const { classes } = this.props;

    switch (this.state.fetchResult) {
      case ResultStatus.NOT_LOADED:
      case ResultStatus.LOADING:
        return (
          <PageLoadingComponent
            small
            classes={classes}
            label="Loading Authentication"
          />
        );
      case ResultStatus.SUCCESS:
        return (
          <Formik
            initialValues={this.getInitialValues()}
            validationSchema={this.getValidationSchema()}
          >
            {(fProps) => (
              <form>
                <TextField
                  {...LayoutService.getInputProps(this.state.isReadOnly, 
                    classes,
                    fProps,
                    "oldPassword",
                    "Old Password"
                  )}
                />
                <br />
                <TextField
                  {...LayoutService.getInputProps(this.state.isReadOnly, 
                    classes,
                    fProps,
                    "newPassword",
                    "New Password"
                  )}
                />
                <br />
                <TextField
                  {...LayoutService.getInputProps(this.state.isReadOnly, 
                    classes,
                    fProps,
                    "confirmPassword",
                    "Confirm Password"
                  )}
                />
              </form>
            )}
          </Formik>
        );

      case ResultStatus.ERROR:
      default:
        return (
          <PageErrorComponent
            small
            label="Error Loading Authentication"
            classes={classes}
            onRetry={() => {
              //this.SERVICE.fetchData(this, true);
            }}
          />
        );
    }
  }
}

/** HOC */
export default LayoutService.getHocComponenet(AuthenticationModalComponent);
