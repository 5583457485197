import React from "react";
import { combineLatest, ReplaySubject, of } from "rxjs";
import { Formik } from "formik";
import * as Yup from "yup";
import { DialogTitle, DialogContent, Grid, Box, Typography, AppBar, Toolbar, IconButton, TextField, FormHelperText, Input, Divider } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { AuthContext } from "../../../../shared/store/authProvider";
import { DataService, SubscriptionArray } from "../../../../shared/services/dataService";
import { ResultStatus, CrudAction, API_ENDPOINT, } from "../../../../shared/types/enums";
import PageLoadingComponent from "../../../../shared/components/page/pageLoadingComponent";
import PageErrorComponent from "../../../../shared/components/page/pageErrorComponent";
import { MatIconService } from "../../../../shared/services/theme/matIconService";
import LayoutService from "../../../../shared/services/layoutService";
import LookupService from "../../../../shared/services/lookupService";
import ApiService from "../../../../shared/services/apiService";
import MatThemeService from "../../../../shared/services/theme/matThemeService";
import ToastService from "../../../../shared/services/toastService";
import RolePermissionService from "../../../../shared/role-permissions/rolePermissionService";

class ConsentComponent extends React.Component {
    static contextType = AuthContext;
    oSubscriptions = new SubscriptionArray();

    constructor(props) {
        super(props);
        // init state
        this.state = {
            isNew: this.props.inputAction === CrudAction.CREATE,
            isEditing: this.props.inputAction === CrudAction.UPDATE,
            fetchResult: ResultStatus.NOT_LOADED,
            agreementId: this.props.modalAgNode?.agreementId ?? null,
            agreementConsent: {}
        };
    }

    componentWillUnmount() {
        this.oSubscriptions.cancelAll();
    }

    componentDidMount() {
        if (this.props.inputAction === CrudAction.UPDATE) {
            this.setState({ fetchResult: ResultStatus.LOADING });
            /**
             * rxjs <combineLatest> operator takes multiple obs as argument and emits their reusult in the same order
             * this operator is very handy as we don't have to independently wait for each result
             */
            this.oSubscriptions.add(
                combineLatest([
                    ApiService.getOBS(API_ENDPOINT.CORE, `/Agreement/GetAgreementConsent/${this.context.user.tenantId}/${this.state.agreementId}`),
                    ApiService.getOBS(API_ENDPOINT.TENANT, `/Util/GetFieldLOVByIds/${this.context.user.tenantId}/97`)
                ]).subscribe(
                    ([_agreementConsent, _consentLOVValues]) => {
                        this.setState(
                            {
                                agreementConsent: _agreementConsent,
                                consentLOVValues: _consentLOVValues
                            },
                            () => {
                                // change the state after all the above are assigned
                                this.setState({ fetchResult: ResultStatus.LOADED });
                            }
                        );
                    }
                )
            );
        } else {
            this.setState({ fetchResult: ResultStatus.LOADED });
        }
    }

    getInitialValues() {
        return {
            agreementId: !this.state.agreementConsent ? "" : this.state.agreementId,
            consentValues: !this.state.agreementConsent ? "" : this.state.agreementConsent.consentValues,
            disclosureConsent: !this.state.agreementConsent ? "" : this.state.agreementConsent.disclosureConsent,
            consentStartDate: !this.state.agreementConsent ? "" : this.state.agreementConsent.consentStartDate,
            consentEndDate: !this.state.agreementConsent ? "" : this.state.agreementConsent.consentEndDate,
            consentExecutedDate: !this.state.agreementConsent ? "" : this.state.agreementConsent.consentExecutedDate,
            consentRevoked: !this.state.agreementConsent ? "" : this.state.agreementConsent.consentRevoked,
            consentRevocationStartDate: !this.state.agreementConsent ? "" : this.state.agreementConsent.consentRevocationStartDate,
            consentRevocationEndDate: !this.state.agreementConsent ? "" : this.state.agreementConsent.consentRevocationEndDate,
            consentRevocationExecutedDate: !this.state.agreementConsent ? "" : this.state.agreementConsent.consentRevocationExecutedDate,
            consentRevocationReason: !this.state.agreementConsent ? "" : DataService.getStringOrDefault(this.state.agreementConsent.consentRevocationReason),
        };
    }

    validationSchema = Yup.object().shape({});
    getValidationSchema() {
        this.validationSchema = Yup.object().shape({
            consentStartDate: Yup.date().min("1/1/2018", "On or after 1/1/2018").max("1/1/2051", "On or before 1/1/2051"),
            consentEndDate: Yup.date().min("1/1/2018", "On or after 1/1/2018").max("1/1/2051", "On or before 1/1/2051"),
            consentRevocationReason: Yup.string(),
        });
        return this.validationSchema;
    }



    /**Render */
    TAB_PERMISSIONS = RolePermissionService.AGREEMENT_CONSENT;
    render() {
        const { classes } = this.props;
        this.fPropsDynamic = null;
        this.props.tabConfig.ref = this; // 1/4) required by parent component

        if (RolePermissionService.AGREEMENT_CONSENT.cannotView) {
            return RolePermissionService.getAccessDeniedComponent(classes);
        } else {
            switch (this.state.fetchResult) {
                case ResultStatus.NOT_LOADED:
                case ResultStatus.LOADING:
                    return <PageLoadingComponent small classes={classes} label="Loading Consent Details" />;
                case ResultStatus.SAVING:
                    return <PageLoadingComponent small classes={classes} label="Saving Consent Details" />;
                case ResultStatus.LOADED:
                case ResultStatus.SUCCESS:
                    return (
                        <Formik initialValues={this.getInitialValues()} validationSchema={this.getValidationSchema()} validationSchemaOptions={{ showMultipleFieldErrors: true }} >
                            {(fProps) => (
                                <form>
                                    {this.renderData(classes, fProps)}
                                </form>
                            )}
                        </Formik>
                    );

                case ResultStatus.ERROR:
                default:
                    return (
                        <PageErrorComponent small label="Error Loading Consent Details" classes={classes} onRetry={() => {
                            //this.SERVICE.fetchData(this, true);
                        }}
                        />
                    );
            }
        }
    }
    renderData = (_classes, _fProps) => {
        this.fProps = _fProps;

        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Box style={{ paddingLeft: 24, paddingRight: 24, paddingTop: 8, paddingBottom: 16, backgroundColor: MatThemeService.getAlternatingBG(1) }}>
                    <Typography variant="h6" className={_classes.sectionHeader} style={{ margin: 8 }}>Disclosure Consent</Typography>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>{LayoutService.getSelectControl(this.props.isReadOnly, _classes, _fProps, "disclosureConsent", "Disclosure Consent", this.state.consentLOVValues, "lovId", "lovKey", "90%", false)}</Grid>
                        <Grid item xs={12} sm={6}><DatePicker {...LayoutService.getDateProps(this.props.isReadOnly, _classes, _fProps, "consentStartDate", "Consent Start Date", true)} format="yyyy-MM-dd" style={{ minWidth: "90%" }} /></Grid>
                        <Grid item xs={12} sm={6}><DatePicker {...LayoutService.getDateProps(this.props.isReadOnly, _classes, _fProps, "consentEndDate", "Consent End Date", true)} format="yyyy-MM-dd" style={{ minWidth: "90%" }} /></Grid>
                        <Grid item xs={12} sm={6}><DatePicker {...LayoutService.getDateProps(this.props.isReadOnly, _classes, _fProps, "consentExecutedDate", "Consent Executed Date", true)} format="yyyy-MM-dd" style={{ minWidth: "90%" }} /></Grid>
                    </Grid>
                </Box>
                <Divider />
                <Box style={{ paddingLeft: 24, paddingRight: 24, paddingTop: 8, paddingBottom: 16, backgroundColor: MatThemeService.getAlternatingBG(2) }}>
                    <Typography variant="h6" className={_classes.sectionHeader} style={{ margin: 8 }}>Consent Revocations</Typography>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>{LayoutService.getSelectControl(this.props.isReadOnly, _classes, _fProps, "consentRevoked", "Consent Revoked", [{ "id": 1, "text": "YES" }, { "id": 0, "text": "NO" }], "id", "text", "90%", true)}</Grid>
                        <Grid item xs={12} sm={6}><DatePicker {...LayoutService.getDateProps(this.props.isReadOnly, _classes, _fProps, "consentRevocationStartDate", "Consent Revocation Start Date", true)} format="yyyy-MM-dd" style={{ minWidth: "90%" }} /></Grid>
                        <Grid item xs={12} sm={6}><DatePicker {...LayoutService.getDateProps(this.props.isReadOnly, _classes, _fProps, "consentRevocationEndDate", "Consent Revocation End Date", true)} format="yyyy-MM-dd" style={{ minWidth: "90%" }} /></Grid>
                        <Grid item xs={12} sm={6}><DatePicker {...LayoutService.getDateProps(this.props.isReadOnly, _classes, _fProps, "consentRevocationExecutedDate", "Consent Revocation Executed Date", true)} format="yyyy-MM-dd" style={{ minWidth: "90%" }} /></Grid>
                        <Grid item xs={12}>{LayoutService.getTextBox(this.props.isReadOnly, _classes.dialogControl, _fProps, this.validationSchema, "consentRevocationReason", "Consent Revocation Reason")}</Grid>
                    </Grid>
                </Box>
            </MuiPickersUtilsProvider>
        );
    }

    /** 2/4 Required */
    isDirtyCallback = () => {
        // do any additional checkings if needed
        if (this.fProps) {
            return this.fProps.dirty;
        } else {
            return false;
        }
    }

    /** 3/4 Required in Parent */
    resetCallback = () => {
        if (this.fProps) {
            this.fProps.resetForm();
        }
        // do any additional resetting if needed
    }


    _validate = async (_oSubject) => {

        await this.validationSchema.validate(this.fProps.values, { abortEarly: false })
            .then((x) => {
                _oSubject.next(this._getDataToPost());
            })
            .catch((erroObj) => {
                if (erroObj.inner) { erroObj.inner.forEach(err => { this.fProps.setFieldError(err.path, err.message); }); }
                _oSubject.next(null); // error
            });

    }

    _getDataToPost = () => {
        return {
            agreementId: this.fProps.values["agreementId"],
            consentValues: this.fProps.values["consentValues"],
            disclosureConsent: this.fProps.values["disclosureConsent"],
            consentStartDate: this.fProps.values["consentStartDate"],
            consentEndDate: this.fProps.values["consentEndDate"],
            consentExecutedDate: this.fProps.values["consentExecutedDate"],
            consentRevoked: this.fProps.values["consentRevoked"],
            consentRevocationStartDate: this.fProps.values["consentRevocationStartDate"],
            consentRevocationEndDate: this.fProps.values["consentRevocationEndDate"],
            consentRevocationExecutedDate: this.fProps.values["consentRevocationExecutedDate"],
            consentRevocationReason: this.fProps.values["consentRevocationReason"],
        };
    }

    /** 4/4 Required in Parent */
    postCallbackOBS = () => {
        if (DataService.isNullOrUndefined(this.fProps) || this.fProps.isSubmitting) {
            return of(null);
        }
        else {
            var oReturnSubject = new ReplaySubject(); // 1st
            var oValidationSubject = new ReplaySubject(); // 2nd

            this._validate(oValidationSubject);
            oValidationSubject.asObservable().subscribe((_dataToPost) => {
                if (_dataToPost) {
                    ApiService.postOBS(API_ENDPOINT.CORE, `/Agreement/SaveAgreementConsent/${this.context.user.tenantId}`, JSON.stringify(_dataToPost))
                        .subscribe(
                            (_successResult) => {
                                ToastService.showSuccess("Agreement Saved");
                                oReturnSubject.next(true);
                            },
                            (_errorResult) => {
                                ToastService.showError("Error occured while saving");
                                oReturnSubject.next("save_error");
                            }
                        );
                } else {
                    ToastService.showWarning("Please recheck your Input");
                    oReturnSubject.next("validation_error");
                }
            });

            // return the subject as observable
            return oReturnSubject.asObservable(); // 3rd
        }
    }

}

/** HOC */
export default LayoutService.getHocComponenet(ConsentComponent);