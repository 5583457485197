import React, { Component } from 'react';
import IzendaIntegrate from './config/izenda.integrate';
import IzendaAuthService from './services/izendaAuthService';
import queryString from 'query-string';

import ReportingRoot from './reportingRoot';

export default class ReportingViewer extends Component {
    constructor(props) {
        super(props);
        this.izIntegrate = new IzendaIntegrate();
        this.izAuthService = new IzendaAuthService();
        this.state = {
            isRegistered: false,
            isIzendaRendering: false,
            izDom: document.getElementById('izenda-root')
        }
    }
    componentDidMount() {
        this.registerPorzioUser().then((result) => {
            if (result.success) {
                const reportId = this.props.match.params.id;
                const filterQuery = queryString.parse(this.props.location.search);
                const overridingFilterValue = {};
                Object.keys(filterQuery).forEach(function (key) {
                    overridingFilterValue[key] = decodeURIComponent(filterQuery[key]);
                });
                const filters = { overridingFilterValue: overridingFilterValue };
                const izDom = this.izIntegrate.RenderReportViewer(reportId, filters);
                this.setState(() => ({ isIzendaRendering: true, izDom: izDom }));
            }
        });
    }
    componentWillUnmount() {
        this.izIntegrate.DestroyDom(this.state.izDom);
    }
    /**
     * Will call service functions that return API results for verifying a Porzio user's status in Izenda
     */
    registerPorzioUser = async () => {
        const result = await this.izAuthService.registerUser();
        this.setState(() => ({ isRegistered: result.success }));
        return result;
    }

    render() {
        return (
            <ReportingRoot izState={this.state} />
        );
    }
}
