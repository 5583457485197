import React from "react";
import { combineLatest } from "rxjs";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  Box,
  FormControlLabel,
  TextField,
  Grid,
  Switch,
} from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { AuthContext } from "../../../shared/store/authProvider";
import {
  ResultStatus,
  CrudAction,
  API_ENDPOINT,
} from "../../../shared/types/enums";
import { SubscriptionArray } from "../../../shared/services/dataService";
import LayoutService from "../../../shared/services/layoutService";
import EditRulesService from "./editRulesService";
import PageLoadingComponent from "../../../shared/components/page/pageLoadingComponent";
import DialogErrorFragmentComponent from "../../../shared/components/page/dialogErrorFragmentComponent";
import ApiService from "../../../shared/services/apiService";
import SectionComponent from "../../../shared/components/sectionComponent";
import LookupService from "../../../shared/services/lookupService";

class EditRulesComponent extends React.Component {
  static contextType = AuthContext;
  oSubscriptions = new SubscriptionArray();

  constructor(props) {
    super(props);
    // init state
    this.state = {
      isReadOnly: this.props.inputAction === CrudAction.UPDATE ? true : false,
      isEditing: this.props.inputAction === CrudAction.UPDATE ? true : false,
      fetchResult: ResultStatus.NOT_LOADED,
      tempRuleCategoryList: [
        { id: 1, text: "Data Processing" },
        { id: 2, text: "Field Values" },
        { id: 3, text: "Notifications" },
        { id: 4, text: "Required Fields" },
        { id: 5, text: "Internal" },
        { id: 6, text: "File Validation" },
      ],
      switchPLSRequired: this.props.modalAgNode.plsRequired,
      switchActive: this.props.modalAgNode.active,
    };
    this.handleSwitch = this.handleSwitch.bind(this);
  }

  changeSelectedJurisdictionsToAll = (selJur, allJur) => {
    if (selJur.length === allJur.length) {
      return [{ id: 0, text: "All" }];
    } else {
      return selJur;
    }
  };

  getSelectedSources = (selSource, allSource) => {
    let selected =
      selSource.length !== 0
        ? allSource.filter((sr) => selSource.includes(sr.sourceId))
        : [];
    this.setState({
      selectedSourceNames: selected.map((x) => {
        return x.sourceName;
      }),
    });
    console.log(selected);
    return selected;
  };
  combineRuleFieldLists = (porzioList, customList) => {
    return [...porzioList, ...customList].sort((a, b) =>
      a.fielD_ALIASNAME > b.fielD_ALIASNAME
        ? 1
        : b.fielD_ALIASNAME > a.fielD_ALIASNAME
          ? -1
          : 0
    );
  };

  componentWillUnmount() {
    this.oSubscriptions.cancelAll();
  }

  componentDidMount() {
    this.setState({ fetchResult: ResultStatus.LOADING });

    /**
     * rxjs <combineLatest> operator takes multiple obs as argument and emits their reusult in the same order
     * this operator is very handy as we don't have to independently wait for each result
     */
    this.oSubscriptions.add(
      combineLatest([
        EditRulesService.getBusinessRuleAsOBS(this.context, this.props.modalAgNode.businessruleid),
        LookupService.getEntityListAsOBS(this.context.user.tenantId),
        EditRulesService.getJurisdictionAsOBS(this.context),
        EditRulesService.getRuleCategoryAsOBS(this.context),
        EditRulesService.getRuleFieldPorzioAsOBS(this.context, this.props.modalAgNode.entityId),
        EditRulesService.getRuleFieldCustomAsOBS(this.context, this.props.modalAgNode.entityId),
        EditRulesService.getAvailableSourcesAsOBS(this.context, this.props.modalAgNode.entityId)
      ]).subscribe(
        ([_businessRuleList, _entityList, _jurisdictionList, _ruleCategoryList, _ruleFieldPorzioList, _ruleFieldCustomList, _availableSourcesList]) => {
          this.setState(
            {
              businessRuleList: _businessRuleList,
              selectedSourceIds:
                _businessRuleList.selectedSourceIds.length !== 0
                  ? _businessRuleList.selectedSourceIds
                    .split(",")
                    .map((item) => {
                      return parseInt(item);
                    })
                  : [],
              entityList: _entityList,
              jurisdictionList: [
                ...[{ id: 0, text: "All" }],
                ..._jurisdictionList.map((el) => ({
                  id: el.id,
                  text: el.value,
                })),
              ],
              //selectedJurisdictionList: this.changeSelectedJurisdictionsToAll(_businessRuleList.selectedCountryList.map(el => ({ id: el, text: _jurisdictionList.find(x => x.id === el).value })), _jurisdictionList.map(el => ({ id: el.id, text: el.value }))),
              selectedJurisdictionList: this.changeSelectedJurisdictionsToAll(
                _businessRuleList.selectedCountryList.map((el) => ({
                  id: el,
                  text: [
                    ...[{ id: 0, value: "All" }],
                    ..._jurisdictionList,
                  ].find((x) => x.id === el).value,
                })),
                _jurisdictionList.map((el) => ({ id: el.id, text: el.value }))
              ),
              ruleCategoryList: this.state.tempRuleCategoryList,
              selectedRuleCategoryList: _businessRuleList.ruleCategory
                .split(",")
                .map((el) => Number(el))
                .map((el) => ({
                  id: el,
                  text: this.state.tempRuleCategoryList.find(
                    (x) => Number(x.id) === el
                  ).text,
                })),
              ruleFieldList: this.combineRuleFieldLists(
                _ruleFieldPorzioList,
                _ruleFieldCustomList
              ),
              availableSourcesList: _availableSourcesList,
            },
            () => {
              // change the state after all the above are assigned
              this.setState({ fetchResult: ResultStatus.LOADED });
              console.log(this.state.selectedSourceIds);
              this.setState({
                selectedSourcesList: this.getSelectedSources(
                  this.state.selectedSourceIds,
                  _availableSourcesList
                ),
              });
            }
          );
        }
      )
    );
  }

  handleSubmit = (_formikProps) => {
    if (_formikProps.isSubmitting || !_formikProps.isValid) {
      return;
    } else {
      // 1) set the status
      this.setState({ fetchResult: ResultStatus.SAVING });

      // 2) extract the formik values into an object
      var mappedObj = {
        tenantId: this.context.user.tenantId,
        active: this.state.switchActive,
        ruleName: _formikProps.values.ruleName,
        description: _formikProps.values.description,
        entityName: _formikProps.values.description,
        entityId: _formikProps.values.entityId,
        jurisdiction: this.state.selectedJurisdictionList
          .map((el) => el.id)
          .toString(),
        plsRequired: this.state.switchPLSRequired,
        rulesLastModified: new Date().toISOString(),
        searchText: "",
        entities: "",
        guid: _formikProps.values.guid,
        startDate: _formikProps.values.startDate,
        endDate: _formikProps.values.endDate,
        createdBy: this.context.user.userId,

        publicRevisionID: this.state.businessRuleList.publicRevisionID,
        countryIds: this.state.selectedJurisdictionList
          .map((el) => el.id)
          .toString(),
        ruleOrder: Number(_formikProps.values.ruleOrder),
        wasbPath: "",
        selectedCountryList: this.state.selectedJurisdictionList.some(
          (el) => el.id === 0
        )
          ? this.state.jurisdictionList
            .map((el) => el.id)
            .filter((e) => e !== 0)
          : this.state.selectedJurisdictionList.map((el) => el.id),
        isDeleted: false,
        isCustomField: false,
        ruleCategory: this.state.selectedRuleCategoryList
          .map((el) => el.id)
          .toString(),
        ruleFieldId: this.state.businessRuleList.ruleFieldId,
        activeText: this.state.businessRuleList.activeText,
        version: this.state.businessRuleList.version,
        selectedSourceIds: this.state.selectedSourcesList
          .map((x) => x.sourceId)
          .toString(),
        selectedSourceNames: this.state.selectedSourcesList
          .map((x) => x.sourceName)
          .toString(),
      };

      // 3) determine the action and assign the appropriate props
      var actionVerb = "";
      if (this.state.isEditing) {
        // UPDATE
        actionVerb = "POST";
        mappedObj.businessruleid = this.props.modalAgNode.businessruleid;
        mappedObj.updatedBy = this.context.user.userId;
      } else {
        // CREATE
        actionVerb = "POST";
        mappedObj.businessruleid = 0;
        mappedObj.createdBy = this.context.user.userId;
      }

      // 4) save to Api and subscribe for the result
      ApiService.setOBS(
        actionVerb,
        API_ENDPOINT.TENANT,
        `/BusinessRules/UpdateBusinessRule?tenantId=${this.context.user.tenantId}`,
        JSON.stringify(mappedObj)
      ).subscribe(
        (successResult) => {
          if (successResult) {
            this.setState({ fetchResult: ResultStatus.SUCCESS });
            this.props.refreshParentGrid(true);
            this.props.onClose(false);
          } else {
            this.setState({ fetchResult: ResultStatus.ERROR });
          }
        },
        (errorResult) => {
          console.error("Error while saving Porzio Rule details", errorResult);
          this.setState({ fetchResult: ResultStatus.ERROR });
        }
      );
    }
  };
  handleSwitch(event) {
    console.log(event.target.checked);
    if (!this.state.isReadOnly)
      this.setState({ [event.target.name]: event.target.checked });
  }
  getInitialValues() {
    return {
      ruleName: this.props.modalAgNode.ruleName,
      guid: this.props.modalAgNode.guid,
      description: this.props.modalAgNode.description,

      entityId: this.props.modalAgNode.entityId,
      selectedJurisdictionList: this.state.businessRuleList.selectedCountryList,
      selectedRuleCategoryList: this.props.modalAgNode.ruleCategory.split(","),
      ruleFieldId: this.props.modalAgNode.ruleFieldId,

      ruleOrder: this.props.modalAgNode.ruleOrder,
      startDate: this.props.modalAgNode.startDate,
      endDate: this.props.modalAgNode.endDate,
    };
  }

  getValidationSchema() {
    return Yup.object({
      ruleName: Yup.string()
        .required("Required")
        .min(3, "Must be at least 3 characters long")
        .max(256, "Must be 256 characters or less"),
      guid: Yup.string()
        .required("Required")
        .min(32, "Must be at least 32 characters long")
        .max(36, "Must be 36 characters or less"),
      description: Yup.string()
        .min(3, "Must be at least 3 characters long")
        .max(256, "Must be 256 characters or less"),

      ruleOrder: Yup.number()
        .required("Required")
        .min(0, "Must be above 0")
        .max(100, "Must be below 100"),
      // startDate: Yup.date()
      //   .required("Required")
      //   .min("1/1/2011", "On or after 1/1/2011")
      //   .max("1/1/2050", "On or before 1/1/2050"),
      // endDate: Yup.date()
      //   .required("Required")
      //   .min("1/1/2011", "On or after 1/1/2011")
      //   .max("1/1/2050", "On or before 1/1/2050"),
    });
  }

  render() {
    const { classes } = this.props;

    switch (this.state.fetchResult) {
      case ResultStatus.NOT_LOADED:
      case ResultStatus.LOADING:
        return (
          <PageLoadingComponent
            small
            classes={classes}
            label="Loading Business Rule Details"
          />
        );
      case ResultStatus.SAVING:
        return (
          <PageLoadingComponent
            small
            classes={classes}
            label="Saving Business Rule Details"
          />
        );
      case ResultStatus.LOADED:
      case ResultStatus.SUCCESS:
        return (
          <>
            <Formik
              initialValues={this.getInitialValues()}
              validationSchema={this.getValidationSchema()}
            >
              {(fProps) => (
                <form>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Box
                      style={{
                        paddingLeft: 16,
                        paddingRight: 32,
                        paddingTop: 0,
                        paddingBottom: 32,
                        minWidth: "80vh",
                        maxWidth: "80vh",
                      }}
                    >
                      {this.state.fetchResult === ResultStatus.SUCCESS ? (
                        <h1>Your Business Rule has been updated!!</h1>
                      ) : null}
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={6}>
                          <TextField
                            disabled
                            {...LayoutService.getInputProps(this.state.isReadOnly,
                              classes,
                              fProps,
                              "ruleName",
                              "Rule Name"
                            )}
                            fullWidth
                          />{" "}
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          {LayoutService.getSelectControl(this.state.isReadOnly,
                            classes,
                            fProps,
                            "entityId",
                            "Entity",
                            this.state.entityList,
                            "entityid",
                            "entityname",
                            "98%"
                          )}
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <TextField
                            disabled
                            rows={2}
                            multiline
                            {...LayoutService.getInputProps(this.state.isReadOnly,
                              classes,
                              fProps,
                              "description",
                              "Description"
                            )}
                            fullWidth
                          />{" "}
                        </Grid>
                        {this.props.modalAgNode.ruleFieldId !== 0 ? (
                          <Grid item xs={12} sm={6}>
                            {LayoutService.getSelectControl(this.state.isReadOnly,
                              classes,
                              fProps,
                              "ruleFieldId",
                              "Rule Field",
                              this.state.ruleFieldList,
                              "fieldId",
                              "fielD_ALIASNAME",
                              "98%"
                            )}
                          </Grid>
                        ) : (
                          <></>
                        )}
                        <Grid item xs={12} sm={12} md={4}>
                          <FormControlLabel
                            control={
                              <Switch
                                name="switchActive"
                                checked={this.state.switchActive}
                                onChange={this.handleSwitch}
                                inputProps={{
                                  "aria-label": "primary checkbox",
                                }}
                              />
                            }
                            label="Active"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {LayoutService.getChipSelect2(this.state.isReadOnly,
                            classes,
                            fProps,
                            "ruleCategoryLayoutId",
                            "Rule Category",
                            this.state.ruleCategoryList,
                            "id",
                            "text",
                            this.state.selectedRuleCategoryList,
                            (newVal) =>
                              this.setState({
                                selectedRuleCategoryList: newVal,
                              }),
                            "98%"
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {LayoutService.getChipSelect2(this.state.isReadOnly,
                            classes,
                            fProps,
                            "sourceId",
                            "Available Sources",
                            this.state.availableSourcesList,
                            "sourceId",
                            "sourceName",
                            this.state.selectedSourcesList,
                            (newVal) =>
                              this.setState({ selectedSourcesList: newVal }),
                            "98%"
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  </MuiPickersUtilsProvider>
                </form>
              )}
            </Formik>
          </>
        );
      case ResultStatus.ERROR:
      default:
        return (
          <DialogErrorFragmentComponent
            title="Error"
            description="Error in Business Rule details"
            classes={classes}
            onClose={() => {
              this.props.onClose(false);
            }}
            onRetry={() => {
              console.log("Retry Clicked");
            }}
          />
        );
    }
  }
}

/** HOC */
export default LayoutService.getHocComponenet(EditRulesComponent);
