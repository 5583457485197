import React, { Component } from "react";
import { ResultStatus } from "../../shared/types/enums";
import { AuthContext } from "../../shared/store/authProvider";
import { SubscriptionArray } from "../../shared/services/dataService";
import PageLoadingComponent from "../../shared/components/page/pageLoadingComponent";
import PageErrorComponent from "../../shared/components/page/pageErrorComponent";
import { withStyles } from "@material-ui/core";
import { MatClassService } from "../../shared/services/theme/matClassService";
import { Tabs, Tab, Divider } from "@material-ui/core";
import DocumentListComponent from "./document-list/documentListComponent";
import RolePermissionService from "../../shared/role-permissions/rolePermissionService";

class DocumentComponent extends Component {
  static contextType = AuthContext;
  oSubscriptions = new SubscriptionArray();
  //STORE = StudiessListService;
  constructor(props) {
    super(props);
    this.state = {
      fetchResult: ResultStatus.NOT_LOADED,
      selectedTabIndex: 0,
    };
  }
  componentDidMount() {
    this.setState({ fetchResult: ResultStatus.SUCCESS });
  }
  componentWillUnmount() {
    this.oSubscriptions.cancelAll();
  }
  onTabChange = (event, newValue) => {
    this.setState({
      selectedTabIndex: newValue,
    });
  };
  render() {
    const { classes } = this.props;
    switch (this.state.fetchResult) {
      case ResultStatus.NOT_LOADED:
      case ResultStatus.LOADING:
        return (
          <PageLoadingComponent
            classes={classes}
            label="Loading Documents"
          />
        );
      case ResultStatus.SUCCESS:
        return (
          <div>
            <Tabs
              value={this.state.selectedTabIndex}
              onChange={this.onTabChange}
              indicatorColor="primary"
              textColor="inherit"
              variant="fullWidth"
              scrollButtons="auto"
            >
              <Tab label="System Documents" id="taskTab" />
              <Tab label="Compliance Documents" id="taskTab" />
              <Tab label="Customer Documents" id="taskTab" />
            </Tabs>
            <div
              role="tabpanel"
              id="taskTabPanel"
            >
              <Divider />
              {this.state.selectedTabIndex === 0 ?
                RolePermissionService.SYSTEM_DOCUMENTS.cannotView ? RolePermissionService.getAccessDeniedComponent(classes, () => { }, false)
                  : <DocumentListComponent documentType={"SystemDocuments"} />
                :
                null
              }

              {this.state.selectedTabIndex === 1 ?
                RolePermissionService.COMPLIANCE_DOCUMENTS.cannotView ? RolePermissionService.getAccessDeniedComponent(classes, () => { }, false)
                  : <DocumentListComponent documentType={"ComplianceDocuments"} />
                :
                null
              }
              {this.state.selectedTabIndex === 2 ?
                RolePermissionService.CUSTOMER_DOCUMENTS.cannotView ? RolePermissionService.getAccessDeniedComponent(classes, () => { }, false)
                  : <DocumentListComponent documentType={"CustomDocuments"} />
                :
                null
              }
            </div>
          </div>
        );
      case ResultStatus.ERROR:
      default:
        return (
          <PageErrorComponent
            label="Error Loading Documents"
            classes={classes}
            onRetry={() => {
              this.fetchData(true);
            }}
          />
        );
    }
  }
}

export default withStyles(MatClassService)(DocumentComponent);
