import React, { Component } from "react";
import { AllCommunityModules } from "ag-grid-community";
import { AuthContext } from "../../../shared/store/authProvider";
import VendorLinkRendererComponent from "./vendorLinkRendererComponent";
import PageHeaderComponent from "../../../shared/components/page/pageHeaderComponent";
import PageLoadingComponent from "../../../shared/components/page/pageLoadingComponent";
import PageErrorComponent from "../../../shared/components/page/pageErrorComponent";
import IsActiveIconRendererComponent from "../../../shared/components/ag-grid/isActiveIconRendererComponent";
import { SubscriptionArray } from "../../../shared/services/dataService";
import { ResultStatus, CrudAction } from "../../../shared/types/enums";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";

import VendorDetailsComponent from "./vendor-details/vendorDetailsComponent.jsx";
import VendorSetupService from "./vendorSetupService";
import LayoutService from "../../../shared/services/layoutService";

import { Dialog } from "@material-ui/core";

class VendorSetupComponent extends Component {
  static contextType = AuthContext;
  oSubscriptions = new SubscriptionArray();
  STORE = VendorSetupService;
  constructor(props) {
    super(props);
    this.handleHide = this.handleHide.bind(this);
    this.state = {
      modules: AllCommunityModules,
      data: [],
      fetchResult: ResultStatus.NOT_LOADED,
      frameworkComponents: {
        vendorLinkRendererComponent: VendorLinkRendererComponent,
        isActiveIconRendererComponent: IsActiveIconRendererComponent,
      },
      modalShow: false,
      modalAgNode: null,
      inputAction: CrudAction.NONE,
    };
    this.fetchData.bind(this);
  }

  componentWillUnmount() {
    this.oSubscriptions.cancelAll();
  }
  componentDidMount() {
    this.fetchData();
  }

  /** API Fetch */
  fetchData = (_ignoreCache = false) => {
    this.oSubscriptions.cancelAll();
    this.setState({ fetchResult: ResultStatus.LOADING, data: [] });
    // save the subscription object
    this.oSubscriptions.add(
      this.STORE.getObs(_ignoreCache, this.context).subscribe(
        (o) => {
          // onResultStatus.SUCCESS
          if (!Array.isArray(o)) {
            o = new Array(o);
          }
          this.STORE.CACHE.SET(o, 0);
          this.setState({
            data: o,
            fetchResult: ResultStatus.SUCCESS,
          });
        },
        (o) => {
          console.log("Error:", o);
          // onResultStatus.ERROR
          this.setState({
            fetchResult: ResultStatus.ERROR,
          });
        }
      )
    );
  };

  methodFromParent = (cell, node) => {
    this.setState({ modalShow: true });
    this.setState({ inputAction: CrudAction.UPDATE });
    this.setState({ modalAgNode: node });
  };

  handleCreateModal = () => {
    this.setState({ modalShow: true });
    this.setState({ inputAction: CrudAction.CREATE });
    this.setState({
      modalAgNode: {
        id: 100,
        vendorName: "",
        vendorDescription: "",
        address1: "",
        address2: "",
        address3: "",
        address4: "",
        city: "",
        province: "",
        postalCode: "",
        countryID: "",
        languageID: "",
        currencyID: "",
        timeZoneID: "",
        affiliateID: "",
      },
    });
  };

  handleHide() {
    this.setState({ modalShow: false });
  }

  render() {
    const { classes } = this.props;
    switch (this.state.fetchResult) {
      case ResultStatus.NOT_LOADED:
      case ResultStatus.LOADING:
        return (<PageLoadingComponent classes={classes} label="Loading Vendor Setup" />);
      case ResultStatus.SUCCESS:
        return (
          <div id="MainVendorGrid">
            <div style={{ backgroundColor: "#ddd", textAlign: "right" }}></div>
            <div className="IconBg marginTop10px tableTitleHead">
              <PageHeaderComponent back history={this.props.history} noDivider
                classes={classes} label="Vendor Setup" addCircle onAddClick={this.handleCreateModal}
                reload onReloadClick={() => { this.fetchData(true); }}
              />
            </div>
            <div {...LayoutService.getAgGridStyles()}>
              <AgGridReact
                columnDefs={this.STORE.getColumnDefs(this)}
                rowData={this.state.data}
                pagination={true}
                paginationPageSize={50}
                frameworkComponents={this.state.frameworkComponents}
                gridOptions={{
                  context: {
                    headerHeight: 48,
                    componentParent: this
                  },
                }}
                onGridReady={(event) => { event.api.sizeColumnsToFit(); }}
              ></AgGridReact>
            </div>
            <Dialog open={this.state.modalShow || false} scroll={true ? "paper" : "body"} maxWidth="md">
              <VendorDetailsComponent onClose={this.handleHide} modalAgNode={this.state.modalAgNode} inputAction={this.state.inputAction} refreshVendorList={this.fetchData} />
            </Dialog>
          </div>
        );

      case ResultStatus.ERROR:
      default:
        return (<PageErrorComponent label="Error Loading Vendor" classes={classes} onRetry={() => { this.fetchData(true); }} />);
    }
  }
}

/** HOC */
export default LayoutService.getHocComponenet(VendorSetupComponent);
