import React from "react";
import { AgGridReact } from "ag-grid-react";
import { combineLatest } from "rxjs";
import { Typography, Dialog, DialogTitle, DialogContent, AppBar, Toolbar,  Box } from "@material-ui/core";
import { AuthContext } from "../../../../../shared/store/authProvider";
import { ResultStatus, CrudAction } from "../../../../../shared/types/enums";
import { DataService, SubscriptionArray } from "../../../../../shared/services/dataService";
import LayoutService from "../../../../../shared/services/layoutService";
import PageLoadingComponent from "../../../../../shared/components/page/pageLoadingComponent";
import DialogErrorFragmentComponent from "../../../../../shared/components/page/dialogErrorFragmentComponent";
import { MatIconService } from "../../../../../shared/services/theme/matIconService";
import AgGridRadioButtonCellRendererComponent from "../../../../../shared/components/elements/agGridRadioButtonCellRendererComponent";
import { AgGridUtil } from "../../../../../shared/services/ag-grid/agGridUtil";
import { AgGridColumnExt } from "../../../../../shared/services/ag-grid/agGridColumnExt";
import LookupService from "../../../../../shared/services/lookupService";
import TransactionSearchDialogService from "./transactionsSearchDialogService";

class TransactionSearchDialogComponent extends React.Component {
    static contextType = AuthContext;
    apiSubscriptions = new SubscriptionArray();

    constructor(props) {
        super(props);
        this.state = {
            searchResult: ResultStatus.NOT_LOADED,
            errorMessage: null,
            isEditing: true,
            transactionList: [],
            selectedTransaction: null,
            agGridUtils: new AgGridUtil("porzioGstTransactionId", {
                isSelectedCellRenderer: AgGridRadioButtonCellRendererComponent,
            }),
        };
    }

    componentWillUnmount() {
        this.apiSubscriptions.cancelAll();
    }

    componentDidMount() {
        if (this.state.isEditing) {
            this.setState({ fetchResult: ResultStatus.LOADING });
            this.fetchData();
        }
    }

    /** API Fetch */
    fetchData = () => {
        this.apiSubscriptions.cancelAll();
        this.setState({ fetchResult: ResultStatus.LOADING, transactionList: [] });

        this.apiSubscriptions.add(
            combineLatest([
                TransactionSearchDialogService.searchTransaction(this.context.user.tenantId, this.props.porzioGSTProfileId),
                LookupService.getSourceSystemsByEntityAsOBS(this.context.user.tenantId, 2),
                LookupService.getFieldLOVByIdsAsOBS(this.context.user.tenantId, 35),//form
                LookupService.getFieldLOVByIdsAsOBS(this.context.user.tenantId, 36),//purpose
                //LookupService.getFormattedCountriesAsOBS(this.context, null),
            ]).subscribe(
                // success
                ([_data, _sourceList, _formList, _purposeList]) => {
                    this.setState(
                        {
                            transactionList: DataService.hasElements(_data) ? _data.map(r => ({
                                ...r,
                                sourceName: _sourceList.find(s => s.sourceId === r.sourceId)?.sourceName,
                                form: _formList.find(f => f.lovId === r.formId)?.lovKey,
                                purpose: _purposeList.find(p => p.lovId === r.purposeId)?.lovKey
                                //countryName: _countryList.find(c => c.id === r.country)?.value,
                            })) : [],
                        },
                        // change the state after all the above are assigned
                        () => {
                            this.state.agGridUtils.sizeColumnsToFit();
                            this.setState({ fetchResult: ResultStatus.LOADED });
                        }
                    );
                },
                // onError
                (error) => {
                    console.log("Error:", error);
                    this.setState({ fetchResult: ResultStatus.ERROR });
                }
            )
        );
    };

    handleSubmit = (_selectedTransaction) => {
        this.resetFields(() => {
            this.props.onSubmitClick(_selectedTransaction);
        })
    }

    // onClose = (_reloadRequired) => {
    //     this.resetFields(() => this.props.onClose(_reloadRequired));        
    // }

    resetFields(_callback) {
        this.setState({
            transactionList : [],
            _selectedTransaction : null,
            errorMessage: null,
        }, _callback);
    }

    // // called on row-cell click
    // methodFromParent = (row_col, node) => {
    //     this.setState({ modalAgNode: { ...node, porziogsttransactionid: node.porzioGstTransactionId, transactionid: node.trid, sourceid: node.sourceId } });
    //     if (this.state.agGridUtils.isNotEditing()) {
    //         this.setState({ showTransactionDetailDialog: true });
    //     }
    // };

    render() {
        const { classes } = this.props;
        //this.props.tabConfig.ref = this; // 1/4) required by parent component
        const componentType = "Transactions";

        switch (this.state.fetchResult) {
            case ResultStatus.NOT_LOADED:
            case ResultStatus.LOADING:
                return <PageLoadingComponent small classes={classes} label={`Loading ${componentType}`} />;
            case ResultStatus.SAVING:
                return <PageLoadingComponent small classes={classes} label={`Saving ${componentType}`} />;
            case ResultStatus.LOADED:
            case ResultStatus.SUCCESS:
                return (
                    <React.Fragment>
                        <DialogTitle style={{ padding: 0 }} id="dialog-title">
                            <AppBar position="static">
                                <Toolbar>
                                    <Typography variant="h6" className={classes.root}>Transactions Search</Typography>
                                        {LayoutService.getIconButton(!this.state.selectedTransaction, MatIconService.LINK, "Link Transactions", () => {
                                            this.handleSubmit(this.state.selectedTransaction)
                                        }, "inherit")}
                                        {LayoutService.getIconButton(false, MatIconService.CANCEL, "Discard Changes",() => {this.props.onClose(false)}, "inherit")}
                                </Toolbar> 
                            </AppBar>
                        </DialogTitle>
                        <DialogContent>
                            <Box style={{ padding: "8px" }}>
                                <div {...LayoutService.getAgGridStyles(320)}>
                                    <AgGridReact
                                        pagination={true}
                                        paginationPageSize={100}
                                        rowData={this.state.transactionList}
                                        columnDefs={TransactionSearchDialogService.getColumnDefs(this, (_data) => {
                                            this.setState({ selectedTransaction: _data });
                                        })}
                                        frameworkComponents={this.state.agGridUtils.frameworkComponents}
                                        suppressClickEdit={true}
                                        gridOptions={{
                                            context: { componentParent: this },
                                            ...AgGridColumnExt.getGridOptions(40),
                                            //...this.state.agGridUtils.bindInlineEditEvents(),
                                        }}
                                        onGridReady={(params) => {
                                            this.state.agGridUtils.setGridParams(params, false);
                                        }}>
                                    </AgGridReact>
                                </div>
                            </Box>
                        </DialogContent>
                   </React.Fragment>
                );

            case ResultStatus.ERROR:
            default:
                return (
                    <DialogErrorFragmentComponent title={`Error Loading ${componentType}`} description={`Error Loading ${componentType}`} classes={classes}
                        onClose={() => { this.props.onClose(false); }} onRetry={() => { this.fetchData(); }} />
                );
        }
    }

}
export default LayoutService.getHocComponenet(TransactionSearchDialogComponent);