import Cache from "../../../shared/store/cache";
import { ResultStatus } from "../../../shared/types/enums";
import { SubscriptionArray } from "../../../shared/services/dataService";
import ApiService from "../../../shared/services/apiService";

export default class RoleManagementService {
  // subscriptions
  static oSUBSCRIPTIONS = new SubscriptionArray();

  // cache
  static CACHE = new Cache(this.fetchFromApiObs);

  static getObs(_ignoreCache, context) {
    if (_ignoreCache || this.CACHE.NOT_EXISTS()) {
      return this.fetchFromApiObs(context);
    } else {
      return this.CACHE.getOBS();
    }
  }

  /**
   * Replace the static data inside of(...) with real ApiCall
   * @returns observable
   */
  static fetchFromApiObs(context) {
    // clear the cache before returning the actual subscription
    this.CACHE.CLEAR();
    return ApiService.fetchObsTenant(
      `/Role/GetAllRoles/${context.user.tenantId}`
    );
  }

  /** API Fetch */
  static fetchData(_component, _ignoreCache = false) {
    this.oSUBSCRIPTIONS.cancelAll();
    _component.setState({ fetchResult: ResultStatus.LOADING, data: [] });
    // save the subscription object
    this.oSUBSCRIPTIONS.add(
      this.getObs(_ignoreCache).subscribe(
        (o) => {
          // onResultStatus.SUCCESS
          if (!Array.isArray(o)) {
            o = new Array(o);
          }
          this.CACHE.SET(o);
          _component.setState({
            data: o,
            fetchResult: ResultStatus.SUCCESS,
          });
        },
        (o) => {
          // onResultStatus.ERROR
          _component.setState({
            fetchResult: ResultStatus.ERROR,
          });
        }
      )
    );
  }

  static getRows() {
    return [];
  }

  static dateFormatter(params) {
    return new Date(params.value).toLocaleDateString();
  }

  static getColumnDefs() {
    return [
      {
        headerName: "Role Name",
        field: "roleName",
        sortable: true,
        filter: "text",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 300,
        cellRenderer: "childMessageRendererComponent",
      },
      {
        headerName: "Role Description",
        field: "roleDescription",
        sortable: true,
        filter: "text",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 300,
      },
      // {
      //   headerName: "Role Type",
      //   field: "roleType",
      //   sortable: true,
      //   filter: "text",
      //   cellStyle: { "border-right": "1px solid lightgray" },
      //   resizable: true,
      //   width: 150,
      // },
      // {
      //   headerName: "Porzio Required",
      //   field: "isPlsRequired",headerTooltip:"Porzio Required",
      //   sortable: true,
      //   filter: "text",
      //   cellStyle: { "border-right": "1px solid lightgray", "text-align": "center"},
      //   resizable: true,
      //   width: 150,        cellRenderer: "isActiveIconRendererComponent",
      // },
      // {
      //   headerName: "Effective Date",
      //   field: "effectiveDate",
      //   sortable: true,
      //   filter: "text",
      //   cellStyle: { "border-right": "1px solid lightgray" },
      //   resizable: true, valueFormatter: (params)=> new Date(params.value).toLocaleDateString(),
      //   width: 150,
      // },
      {
        headerName: "Active?",
        width: 150,
        field: "isActive",
        sortable: true,
        filter: true,
        cellStyle: {
          "border-right": "1px solid lightgray",
          "text-align": "center",
        },
        resizable: true,
        cellRenderer: "isActiveIconRendererComponent",
      },
    ];
  }
  //---
}
