import ApiService from "../../../shared/services/apiService";
import Cache from "../../../shared/store/cache";

export default class UserListStore {
  // cache
  static CACHE = new Cache(this.fetchFromApiObs);

  static getObs(_ignoreCache, clientAuthContext) {
    if (_ignoreCache || this.CACHE.NOT_EXISTS()) {
      return this.fetchFromApiObs(clientAuthContext);
    } else {
      return this.CACHE.getOBS();
    }
  }

  /**
   * Replace the static data inside of(...) with real ApiCall
   * @returns observable
   */
  static fetchFromApiObs(clientAuthContext) {
    // clear the cache before returning the actual subscription
    this.CACHE.CLEAR();
    return ApiService.fetchObsTenant(`/User/GetAllUsers/${clientAuthContext.user.tenantId}`);
  }

  static getRows() {
    return [];
  }

  static getColumnDefs() {
    return [
      {
        headerName: "User Name",
        field: "loginName",
        sortable: true,
        filter: "text",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 225,
        cellRenderer: "childMessageRendererComponent",
      },
      {
        headerName: "First Name",
        field: "firstName",
        sortable: true,
        filter: "text",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 150,
      },
      {
        headerName: "Last Name",
        field: "lastName",
        sortable: true,
        filter: "text",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 150,
      },
      {
        headerName: "Title",
        field: "title",
        sortable: true,
        filter: "text",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 150,
      },
      {
        headerName: "Country",
        field: "countryName",
        sortable: true,
        filter: "text",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 150,
      },
      {
        headerName: "Role",
        field: "roles",
        sortable: true,
        filter: "text",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 150,
      },
      {
        headerName: "Active",
        field: "isActive",
        sortable: true,
        filter: "text",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 150,
        cellRenderer: "userActiveRendererComponent",
      },
      {
        headerName: "Last Login",
        field: "lastLogin",
        sortable: true,
        filter: "text",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 225,
      },
    ];
  }
}
