import ApiService from "../../../shared/services/apiService";
import { API_ENDPOINT } from "../../../shared/types/enums";

export default class FTPFolderService {

    static getFTPFilesByTenantId = (tenantId, sourceId) => {
        const targetUrl = `/Ftp/GetFTPFilesByTenantId/${tenantId}/${sourceId}`;
        return ApiService.getOBS(API_ENDPOINT.CORE, targetUrl);
    };

    static getSourceSystems = (tenantId, pageNumber, rowsPerPage) => {
        const targetUrl = `/SourceSystems?tenantId=${tenantId}&pageNumber=${pageNumber}&rowsPerPage=${rowsPerPage}`;
        return ApiService.getOBS(API_ENDPOINT.CORE, targetUrl);
    }

    static formatBytes = (fileSize) => {
        const units = ['B', 'KB', 'MB', 'GB', 'TB'];
        let bytes = fileSize, i;

        for (i = 0; bytes >= 1024 && i < 4; i++) {
            bytes /= 1024;
        }

        return bytes.toFixed(2) + units[i];
    }

    static getColumnDefs() {
        return [
            {
                headerName: "File Name",
                field: "modifiedFileName",
                sortable: true,
                filter: "text",
                flex: 1,
                headerClass: "center-text",
                cellStyle: { "border-right": "1px solid lightgray" },
                resizable: true,
                minWidth: 300,
            },
            {
                headerName: "Modified Date",
                field: "modifiedDate",
                sortable: true,
                filter: "text",
                flex: 1,
                headerClass: "center-text",
                cellStyle: { "border-right": "1px solid lightgray" },
                resizable: true,
            },
            {
                headerName: "Size",
                field: "size",
                sortable: true,
                filter: "text",
                flex: 1,
                headerClass: "center-text",
                cellStyle: { "border-right": "1px solid lightgray" },
                resizable: true,
                valueFormatter: params => this.formatBytes(params.data.size),
            },
            {
                headerName: "Extension",
                field: "extension",
                sortable: true,
                filter: "text",
                flex: 1,
                headerClass: "center-text",
                cellStyle: { "border-right": "1px solid lightgray" },
                resizable: true,
            }
        ];
    }
}