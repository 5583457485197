import React from "react";
import { DialogTitle, DialogContent, Box, Typography, Grid, Divider } from "@material-ui/core";
import { of, combineLatest } from "rxjs";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css";
import MatThemeService from "../../../../shared/services/theme/matThemeService";
import AgGridEditButtonCellRendererComponent from "../../../../shared/components/elements/agGridEditButtonCellRendererComponent";
import { AgGridUtil } from "../../../../shared/services/ag-grid/agGridUtil";
import { AuthContext } from "../../../../shared/store/authProvider";
import { DataService, SubscriptionArray } from "../../../../shared/services/dataService";
import { API_ENDPOINT, CrudAction, ENTITY_TYPE, ResultStatus } from "../../../../shared/types/enums";
import { MatIconService } from "../../../../shared/services/theme/matIconService";
import LayoutService from "../../../../shared/services/layoutService";
import PageLoadingComponent from "../../../../shared/components/page/pageLoadingComponent";
import DialogErrorFragmentComponent from "../../../../shared/components/page/dialogErrorFragmentComponent";
import AgGridCheckboxCellRendererComponent from "../../../../shared/components/elements/agGridCheckboxCellRendererComponent";
import ProfileCountCellRendererComponent from "../../expanded-view/profileCountCellRendererComponent";
import ProfileDetailCellRendererComponent from "../profileDetailCellRendererComponent";
import AgreementDetailCellRendererComponent from "../../../agreements/agreementDetailCellRendererComponent";
import ChildMessageRendererComponent from "../../../../shared/components/childMessageRendererComponent";
import ProfileDetailDialogComponent from "../profileDetailDialogComponent";
import ProfileConsentService from "./profileConsentService";
import SectionComponent from "../../../../shared/components/sectionComponent";
import AgGridDatePickerCellRendererComponent from "../../../../shared/components/elements/agGridDatePickerCellRendererComponent";
import ApiService from "../../../../shared/services/apiService";
import { AgGridColumnExt } from "../../../../shared/services/ag-grid/agGridColumnExt";
import RolePermissionService from "../../../../shared/role-permissions/rolePermissionService";
import TransactionDetailDialogComponent from "../../../transaction-center/transaction-detail/transactionDetailDialogComponent";
import AgreementDetailDialogComponent from "../../../agreements/agreement-detail/agreementDetailDialogComponent";

class ProfileConsentComponent extends React.Component {
  static contextType = AuthContext;
  apiSubscriptions = new SubscriptionArray();

  constructor(props) {
    super(props);
    // init state
    this.state = {
      agmtData: [],
      transData: [],
      fetchResult: ResultStatus.NOT_LOADED,
      isEditing: this.props.inputAction === CrudAction.UPDATE,

      porzioSearch: "",
      agGridUtils: new AgGridUtil("lastname", {
        inlineEditButtonCellRendererComponent: AgGridEditButtonCellRendererComponent,
        profileCountCellRendererComponent: ProfileCountCellRendererComponent,
        profileDetailCellRendererComponent: ProfileDetailCellRendererComponent,
        agreementDetailCellRendererComponent: AgreementDetailCellRendererComponent,
        childMessageRendererComponent: ChildMessageRendererComponent
      }),
      agGridComplianceUtils: new AgGridUtil("profileConsent", {
        inlineEditButtonCellRendererComponent: AgGridEditButtonCellRendererComponent,
        isActiveCellRenderer: AgGridCheckboxCellRendererComponent,
        dateCellRenderer: AgGridDatePickerCellRendererComponent,
      }),
    };
  }
  componentWillUnmount() {
    this.apiSubscriptions.cancelAll();
  }
  componentDidMount() {
    if (this.state.isEditing) {
      this.setState({ fetchResult: ResultStatus.LOADING });
      this.fetchData();
    }
  }

  /** API Fetch */
  fetchData = () => {
    this.apiSubscriptions.cancelAll();
    this.setState({ fetchResult: ResultStatus.LOADING });

    /**
     * rxjs <combineLatest> operator takes multiple obs as argument and emits their result in the same order
     * this operator is very handy as we don't have to independently wait for each result
     */
    this.apiSubscriptions.add(
      combineLatest([
        ProfileConsentService.getAgreementConsentAsOBS(this.context.user.tenantId, this.context.user.userId, this.props.modalAgNode),
        ProfileConsentService.getTransactionConsentAsOBS(this.context.user.tenantId, this.context.user.userId, this.props.modalAgNode, this.context.user.currencyId)
      ]).subscribe(
        // success
        ([_agmtData, _transData]) => {
          _agmtData = _agmtData.map((r) => {
            return ({
              ...r,
              consentExecutedDate: r.consentExecutedDate ? new Date(r.consentExecutedDate).toISOString().substring(0, 10) : null,
              consentStartDate: r.consentStartDate ? new Date(r.consentStartDate).toISOString().substring(0, 10) : null,
              consentEndDate: r.consentEndDate ? new Date(r.consentEndDate).toISOString().substring(0, 10) : null,
            });
          });
          this.setState(
            {
              agmtData: DataService.hasElements(_agmtData) ? _agmtData : [],
              transData: DataService.hasElements(_transData) ? _transData : [],
            },
            // change the state after all the above are assigned
            () => {
              this.setState({ fetchResult: ResultStatus.LOADED });
            }
          );
        },
        // onError
        (error) => {
          console.log("Error:", error);
          this.setState({ fetchResult: ResultStatus.ERROR });
        }
      )
    );
  };

  // called on row-cell click
  methodFromParent = (row_col, node) => {
    this.setState({
      modalAgNode: {
        ...node,
        ...(node.trid && { porziogsttransactionid: node.porzioGSTTransactionID }),
        ...(node.trid && { transactionid: node.trid }),
        ...(node.trid && { sourceid: node.sourceid }),
        ...(node.agreementid && { agreementId: node.agreementid }),
      }
    });

    if (this.state.agGridUtils.isNotEditing()) {
      node.prid !== undefined ? this.setState({ showProfileDetailDialog: true })
        : node.trid !== undefined ? this.setState({ showTransactionDetailDialog: true })
          : this.setState({ showAgreementDetailDialog: true });
    }
  }

  TAB_PERMISSIONS = RolePermissionService.PROFILE_CONSENTS;
  render() {
    const { classes } = this.props;
    this.props.tabConfig.ref = this; // 1/4) required by parent component

    if (this.TAB_PERMISSIONS.cannotView) {
      return RolePermissionService.getAccessDeniedComponent(); // this is required to prevent Url navigation
    }
    else {
      const componentType = "Consent";
      const isReadOnly = this.props.isReadOnly || this.TAB_PERMISSIONS.cannotEdit;
      this.state.agGridComplianceUtils.setReadOnlyMode(isReadOnly);

      switch (this.state.fetchResult) {
        case ResultStatus.NOT_LOADED:
        case ResultStatus.LOADING:
          return <PageLoadingComponent small classes={classes} label={`Loading ${componentType}`} />;
        case ResultStatus.SAVING:
          return <PageLoadingComponent small classes={classes} label={`Saving ${componentType}`} />;
        case ResultStatus.LOADED:
        case ResultStatus.SUCCESS:
          return (
            <React.Fragment>
              <DialogTitle disableTypography id="dialogTitle" />
              <DialogContent>
                <React.Fragment key={"addressFragment"}>
                  <Box key={"agreementsBox"} style={{ paddingLeft: 16, paddingRight: 16, paddingTop: 8, paddingBottom: 16, backgroundColor: MatThemeService.getReverseAlternatingBG(2) }}>
                    <div id="MainRoleGrid2">
                      <Grid container direction="row" justify="space-between" alignItems="center" >
                        <Typography variant="h6" className={classes.sectionHeader} style={{ margin: 8 }}>Agreement Consent</Typography>
                      </Grid>
                      <div style={{ height: `172px`, width: `100%`, backgroundColor: MatThemeService.getAlternatingBG(2) }} {...LayoutService.getAgGridTheme()}>
                        <AgGridReact
                          pagination={false}
                          paginationPageSize={100}
                          rowData={this.state.agmtData}
                          columnDefs={ProfileConsentService.getAgreementColumnDefs(this, this.props.isReadOnly, this.state.agGridComplianceUtils)}
                          frameworkComponents={this.state.agGridUtils.frameworkComponents}
                          suppressClickEdit={true}
                          gridOptions={{
                            context: { componentParent: this },
                            ...AgGridColumnExt.getGridOptions(56),
                            ...this.state.agGridUtils.bindInlineEditEvents(),
                          }}
                          onGridReady={(params) => {
                            this.state.agGridUtils.setGridParams(params, true);
                          }}></AgGridReact>
                      </div>
                    </div>
                  </Box>

                  <Box key={"transactionsBox"} style={{ paddingLeft: 16, paddingRight: 16, paddingTop: 8, paddingBottom: 16, backgroundColor: MatThemeService.getReverseAlternatingBG(3) }}>
                    <div id="MainRoleGrid3">
                      <Grid container direction="row" justify="space-between" alignItems="center" >
                        <Typography variant="h6" className={classes.sectionHeader} style={{ margin: 8 }}>Transaction Consent</Typography>
                      </Grid>
                      <div style={{ height: `172px`, width: `100%` }} {...LayoutService.getAgGridTheme()}>
                        <AgGridReact
                          pagination={false}
                          paginationPageSize={100}
                          rowData={this.state.transData}
                          columnDefs={ProfileConsentService.getTransactionColumnDefs(this, this.props.isReadOnly, this.state.agGridComplianceUtils)}
                          frameworkComponents={this.state.agGridUtils.frameworkComponents}
                          suppressClickEdit={true}
                          gridOptions={{
                            context: { componentParent: this },
                            ...AgGridColumnExt.getGridOptions(40),
                            ...this.state.agGridUtils.bindInlineEditEvents(),
                          }}
                          onGridReady={(params) => {
                            this.state.agGridUtils.setGridParams(params, true);
                            this.state.agGridComplianceUtils.setReadOnlyMode(this.props.isReadOnly);
                          }}>
                        </AgGridReact>
                      </div>
                    </div>
                  </Box>
                  <Divider />
                </React.Fragment>
              </DialogContent>
              <TransactionDetailDialogComponent standAloneProps={this.props.standAloneProps ? LayoutService.getContainedDialogProps(false) : null}
                inputAction={CrudAction.UPDATE} modalAgNode={this.state.modalAgNode}
                open={this.state.showTransactionDetailDialog || false} onClose={() => { this.setState({ showTransactionDetailDialog: false }); /*this.fetchData(true);*/ }} />
              <AgreementDetailDialogComponent standAloneProps={this.props.standAloneProps ? LayoutService.getContainedDialogProps(false) : null}
                inputAction={CrudAction.UPDATE} modalAgNode={this.state.modalAgNode} open={this.state.showAgreementDetailDialog || false}
                onClose={() => this.setState({ showAgreementDetailDialog: false })} />
            </React.Fragment >
          );

        case ResultStatus.ERROR:
        default:
          return (
            <DialogErrorFragmentComponent title={`Error Loading ${componentType}`} description={`Error Loading ${componentType}`} classes={classes}
              onClose={() => { this.props.onClose(false); }} onRetry={() => { this.fetchData(); }} />
          );
      }
    }
  }


  /** 2/4 Required in Parent */
  isDirtyCallback = () => {
    return false;
    // do any additional checkings if needed
    // if (this.fPropsDynamic) {
    //   return this.fPropsDynamic.dirty;
    // } else {
    //   return false;
    // }
  }

  /** 3/4 Required in Parent */
  resetCallback = () => {
    // if (this.fPropsDynamic) {
    //   this.fPropsDynamic.resetForm();
    // }
    // do any additional resetting if needed
  }

  /** 4/4 Required in Parent */
  postCallbackOBS = () => {
    return of(null);
    // if (DataService.isNullOrUndefined(this.fPropsDynamic)) { return of(null); }
    // else {

    // }
  }

}
/** HOC */
export default LayoutService.getHocComponenet(ProfileConsentComponent);
