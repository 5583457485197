import React from "react";
import { combineLatest } from "rxjs";
import { Formik } from "formik";
import * as Yup from "yup";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ApiService from "../../../shared/services/apiService";
import { DialogTitle, DialogContent, AppBar, Toolbar, Typography, Grid, Divider, FormControl, Chip, FormHelperText, TextField } from "@material-ui/core";
import ToastService from "../../../shared/services/toastService";
import { DataService, SubscriptionArray } from "../../../shared/services/dataService";
import { FILTER_FIELD_TYPE, ReportCategory, ResultStatus, API_ENDPOINT } from "../../../shared/types/enums";
import { AuthContext } from "../../../shared/store/authProvider";

import { MatIconService } from "../../../shared/services/theme/matIconService";
import LayoutService from "../../../shared/services/layoutService";
import PageLoadingComponent from "../../../shared/components/page/pageLoadingComponent";
import DialogErrorFragmentComponent from "../../../shared/components/page/dialogErrorFragmentComponent";

import ReportSettingsService from "./reportSettingsService";
import LookupService from "../../../shared/services/lookupService";
import SectionComponent from "../../../shared/components/sectionComponent";

class ReportSettingsComponent extends React.Component {

    static contextType = AuthContext;
    oSubscriptions = new SubscriptionArray();
    US_JURISDICTION = "united states";
    objAll = { 'lovId': '[ALL]', 'localLovKey': '[ALL]' };

    constructor(props) {
        super(props);

        this.state = {
            fetchResult: ResultStatus.LOADING,
            errorMessage: '',
            reportId: this.props.modalAgNode.reportId,
            izendaReportId: this.props.modalAgNode.izendaReportId,
            izendaDisplayName: this.props.modalAgNode.izendaDisplayName,
            izendaCurrentTenantId: null,
            reportFilters: [],
            isGemboxReport: true,
            isComplianceTemplateReport: this.props.category === ReportCategory.COMPLIANCE_TEMPLATES,
            isUSJursdiction: (this.props.modalAgNode.jurisdiction + "").toLowerCase() === this.US_JURISDICTION,
            isLobbyReport: this.props.modalAgNode.isLobbyReport
        }
    }

    componentDidMount() { this.configure(); }
    componentWillUnmount() { this.oSubscriptions.cancelAll(); }

    configure = () => {
        this.oSubscriptions.cancelAll();
        this.setState({
            fetchResult: ResultStatus.LOADING,
            loadingMessage: 'Loading Report Settings...',
            errorMessage: '',
        });

        this.oSubscriptions.add(
            combineLatest([
                ReportSettingsService.getReportFilters(this.context.user.tenantId, this.state.reportId),
                ReportSettingsService.getReportSaveFileTypes(),
                LookupService.getFieldLOVByIdsAsOBS(this.context.user.tenantId, 304),
                LookupService.getFieldLOVByIdsAsOBS(this.context.user.tenantId, 305),
            ]).subscribe(
                ([_reportFilters, _reportFileExtensionList, _reportLibraryList, _finalDispositionList]) => {
                    const reportPathName = (_reportFilters || [])[0]?.reportPathName;
                    ReportSettingsService.getLanguagesByParentIzendaAsOBS(this.context.user.tenantId, reportPathName)
                        .subscribe(
                            (languages) => {
                                this.setState({
                                    reportFilters: (_reportFilters || []).sort((a, b) => a.ordinal > b.ordinal),
                                    reportLibraryList: _reportLibraryList,
                                    reportFileExtensionList: _reportFileExtensionList.map((x, index) => ({ id: index + 1, ...x })),
                                    finalDispositionList: _finalDispositionList,
                                    languageslist: languages ?? [],
                                    fetchResult: ResultStatus.LOADED
                                });
                            },
                            (errorObj) => {
                                this.setState({
                                    fetchResult: ResultStatus.ERROR,
                                    errorMessage: 'Error fetching languages.'
                                });
                            }
                        );
                },
                (errorObj) => {
                    this.setState({
                        fetchResult: ResultStatus.ERROR,
                        errorMessage: 'Error fetching report filters.'
                    });
                }
            )
        );
    }


    getInitialValues() {
        let initialObject = {};

        /* --- Select Reports:---------------------------- */
        initialObject.isEnglishReport = true;
        initialObject.isCompanionReport = false;

        let arr = this.state.languageslist;
        for (var i = 0; i < arr.length; i++) {
            const zKey = arr[i].translatedReportLanguage;
            initialObject["is" + zKey + "Report"] = arr[i].selected;
        }

        {/* --- (DYNAMIC) Select Filters:---------------------------- */ }
        this.state.reportFilters.forEach((x, index) => {
            switch (x.filterType) {
                case FILTER_FIELD_TYPE.DATE:
                    initialObject[x.filterName] = new Date((x.defaultFilterValue + "").split('-')) || null;
                    break;
                case FILTER_FIELD_TYPE.TEXT:
                    initialObject[x.filterName] = x.defaultFilterValue || '';
                    break;
                case FILTER_FIELD_TYPE.LOV: // since lovId is also string in this impl
                    if (x.isMultiSelect) {
                        const lovOptions = this.objAll.localLovKey == x.defaultFilterValue ? [this.objAll, ...x.customLovList] : x.customLovList;
                        const defaultValue = lovOptions.find(o => o.lovId.toLowerCase() === (x.defaultFilterValue || '')?.toLowerCase());
                        initialObject[x.filterName] = defaultValue ? [defaultValue] : [];
                    } else {
                        const lovOptions = x.customLovList.filter(o => o.lovId !== 0); // autoSelect
                        const hasDefaultValue = lovOptions.filter(o => o.lovId === x.defaultFilterValue).length === 1;
                        const defaultValue = hasDefaultValue ? x.defaultFilterValue : '';
                        initialObject[x.filterName] = defaultValue;
                    }
                    break;
                default:
                    console.warn('dynmaic control field type not implemented', x.filterType);
                    break;
            }

        });

        /* --- Save Report Details:---------------------------- */
        initialObject.reportLibraryId = 0;
        initialObject.reportFileExtensionId = 0;
        initialObject.reportName = "";
        initialObject.reportDescription = "";

        initialObject.finalDispositionId = 0;
        initialObject.finalDispositionDate = null;
        initialObject.finalDispositionComments = "";

        if (initialObject.IZENDADISPLAYNAME.includes("Generic") || initialObject.IZENDADISPLAYNAME.includes("Medicines for Europe") || 
            initialObject.IZENDADISPLAYNAME.includes("EFPIA Patient Organizations -2022")
        ) {
            initialObject.CURRENCY = "13";
            initialObject.GENERICCURRENCY = "13";
        }
        console.log(initialObject);

        return initialObject;
    }

    validationSchema = Yup.object().shape({});
    getValidationSchema() {

        let dynamicSchemas = {};
        {/* --- (DYNAMIC) Select Filters:---------------------------- */ }
        this.state.reportFilters.forEach((x, index) => {

            // required validation
            if (x.isMandatory) {
                switch (x.filterType) {
                    case FILTER_FIELD_TYPE.DATE:
                        dynamicSchemas[x.filterName] = Yup.date().required(x.errorMessage);
                        break;
                    case FILTER_FIELD_TYPE.TEXT:
                    case FILTER_FIELD_TYPE.LOV: // since lovId is also string in this impl
                        dynamicSchemas[x.filterName] = Yup.string().required(x.errorMessage);
                        break;
                    default:
                        console.warn('dynmaic control field type not implemented', x.filterType);
                        break;
                }
            }

            /** TODO: the following has to be sent by the api
             * -> min value validation
             * -> max value validation
             */
        });


        // construct the validation schema object
        this.validationSchema = Yup.object().shape({

            /* --- Select Reports:---------------------------- */
            isEnglishReport: Yup.boolean().required("Required"),
            isCompanionReport: Yup.boolean().required("Required"),
            // TODO: atleast the above one should be selected


            /* --- Save Report Details:---------------------------- */
            reportLibraryId: Yup.number().required().min(1, "Select a Report Library"),
            reportFileExtensionId: this.state.isUSJursdiction ? Yup.number().nullable(true) : Yup.number().min(1, "Select a Report Type"),
            reportName: Yup.string().required("Enter a Report Name").max(256, "Must be 256 characters or less"),
            reportDescription: Yup.string().max(256, "Must be 256 characters or less"),

            // TODO: remove the min(0) and replace the nullables eg: LayoutService.getNullableValidation(Yup.number())
            finalDispositionId: Yup.number().min(0, "Select a Final Disposition"),
            finalDispositionDate: Yup.date().nullable(true).min("1/1/2000", "On or after 1/1/2000").max("1/1/2099", "On or before 1/1/2099"),
            finalDispositionComments: Yup.string().max(256, "Must be 256 characters or less"),

            /* --- Spread the Dynamic Schemas :---------------------------- */
            ...dynamicSchemas,


        }).test('oneOfRequired', 'Please select the report type', (obj) => {
            if (obj.isEnglishReport || obj.isCompanionReport) {
                return true;
            } else {
                ToastService.showWarning('Please check your input.');
                return new Yup.ValidationError('Please select the report type', null, 'isEnglishReport');
            }
        });


        // return
        return this.validationSchema;
    }

    render() {
        const { classes } = this.props;

        switch (this.state.fetchResult) {
            case ResultStatus.NOT_LOADED:
            case ResultStatus.LOADING:
                return <PageLoadingComponent small classes={classes} label={this.state.loadingMessage} />;
            case ResultStatus.SAVING:
                return <PageLoadingComponent small classes={classes} label="Saving Report Settings" />;
            case ResultStatus.LOADED:
            case ResultStatus.SUCCESS:
                return (
                    <>
                        <Formik initialValues={this.getInitialValues()} validationSchema={this.getValidationSchema()}
                            validationSchemaOptions={{ showMultipleFieldErrors: true }}                        >
                            {(fProps) => (
                                <form>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DialogTitle>
                                            <AppBar position="static">
                                                <Toolbar>
                                                    <Typography variant="h6" className={classes.root}>Report Settings: {this.props.modalAgNode.reportName}</Typography>
                                                    {LayoutService.getIconButton(false, MatIconService.OK, "Save Reports", () => { this.handleSubmit(fProps) }, "inherit", "keySave")}
                                                    {LayoutService.getIconButton(false, MatIconService.CANCEL, "Cancel", this.props.handleClose, "secondary", "keyCancel")}
                                                </Toolbar>
                                            </AppBar>
                                        </DialogTitle>
                                        <DialogContent style={{ padding: 24, paddingBottom: 40 }}>

                                            {/* --- Select Reports:---------------------------- */}
                                            <SectionComponent classes={classes} marginBottom={8} label={"Select Reports"} />
                                            <Grid container>
                                                {[
                                                    <Grid item xs={12} sm={6} key="english">
                                                        {LayoutService.getSwitch(this.state.isReadOnly, classes, fProps, "isEnglishReport", "English Report")}
                                                    </Grid>,
                                                    <Grid item xs={12} sm={6} key="porzio">
                                                        {this.state.isComplianceTemplateReport &&
                                                            LayoutService.getSwitch(this.state.isReadOnly, classes, fProps, "isCompanionReport", "Porzio Analysis Report")}
                                                    </Grid>,
                                                    ...this.state.languageslist.map((language, index) => (    
                                                        <Grid item xs={12} sm={6} key={index}>
                                                            {LayoutService.getSwitch(
                                                                this.state.isReadOnly,
                                                                classes,
                                                                fProps,
                                                                "is" + language.translatedReportLanguage + "Report",
                                                                language.translatedReportLanguage.charAt(0).toUpperCase() + language.translatedReportLanguage.slice(1) + " Report",
                                                            )}
                                                            </Grid>
                                                    ))
                                                ]}
                                            </Grid>


                                            {/* --- (DYNAMIC) Select Filters:---------------------------- */}
                                            {this.state.reportFilters.length > 0 ?
                                                <SectionComponent classes={classes} marginBottom={16} label={"Select Filters"} /> : null}
                                            <Grid item xs={12} sm={12}>
                                                {this.state.reportFilters.filter(x => x.isVisible).map((x, index) => {
                                                    return this.getDynamicControl(classes, fProps, x, index);
                                                })}
                                            </Grid>


                                            {/* --- Save Report Details:------------------------ */}
                                            <SectionComponent classes={classes} marginTop={32} marginBottom={16} label={"Save Report Details"} />
                                            <Grid item xs={12}>
                                                {LayoutService.getSelectControl(this.state.isReadOnly, classes, fProps,
                                                    "reportLibraryId", "Report Library *", this.state.reportLibraryList, "lovId", "lovKey", "47%", true)}
                                                {/* {this.state.isUSJursdiction ? null
                                                    : LayoutService.getSelectControl(this.state.isReadOnly, classes, fProps,
                                                        "reportFileExtensionId", "Report Extension", this.state.reportFileExtensionList, "id", "fileType", "47%", true)
                                                } */}
                                                {
                                                    this.renderReportExtensionDropdown(this.state.isLobbyReport, classes, fProps)
                                                }
                                            </Grid>
                                            <Grid item sm={12}>
                                                {LayoutService.getInputTextBox(this.state.isReadOnly, false, 0, classes.dialogControl, fProps, this.validationSchema,
                                                    "reportName", "Report Name", "string", "96%")}
                                            </Grid>
                                            <Grid item sm={12}>
                                                {LayoutService.getInputTextBox(this.state.isReadOnly, true, 1, classes.dialogControl, fProps, this.validationSchema,
                                                    "reportDescription", "Report Description", "string", "96%")}
                                            </Grid>

                                            {this.state.isComplianceTemplateReport ?
                                                <>
                                                    <Grid item xs={12} >
                                                        {LayoutService.getSelectControl(this.state.isReadOnly, classes, fProps,
                                                            "finalDispositionId", "Final Disposition", this.state.finalDispositionList, "lovId", "lovKey", "47%", true)}
                                                        {LayoutService.getDatePicker(this.state.isReadOnly, classes.dialogControl, fProps, this.validationSchema,
                                                            "finalDispositionDate", "Final Disposition Date")}
                                                    </Grid>

                                                    <Grid item sm={12}>
                                                        {LayoutService.getInputTextBox(this.state.isReadOnly, true, 1, classes.dialogControl, fProps, this.validationSchema,
                                                            "finalDispositionComments", "Final Disposition Comments", "string", "96%")}
                                                    </Grid>
                                                </>
                                                : null}


                                        </DialogContent>
                                    </MuiPickersUtilsProvider>
                                </form>
                            )}
                        </Formik>
                    </>
                );
            case ResultStatus.ERROR:
            default:
                return (
                    <DialogErrorFragmentComponent classes={classes} description={this.state.errorMessage} onRetry={() => { this.configure(); }} />
                );
        }
    }

    getDynamicControl = (_classes, _fProps, _config, index) => {
        switch (_config.filterType) {
            case FILTER_FIELD_TYPE.DATE:
                return LayoutService.getDatePicker(this.state.isReadOnly, _classes.dialogControl, _fProps, this.validationSchema,
                    _config.filterName, _config.filterDisplayName, null, null, "47%"
                );

            case FILTER_FIELD_TYPE.TEXT:
                return LayoutService.getInputTextBox(this.state.isReadOnly, false, 0, _classes.dialogControl, _fProps, this.validationSchema,
                    _config.filterName, _config.filterDisplayName, 'text', "96%"
                );

            case FILTER_FIELD_TYPE.LOV:
                if (_config.isMultiSelect) { // multiple
                    const lovOptions = this.objAll.localLovKey == _config.defaultFilterValue ? [this.objAll, ..._config.customLovList] : _config.customLovList;
                    return LayoutService.getMultiSelect(this.state.isReadOnly, _classes, _fProps,
                        _config.filterName, _config.filterDisplayName, lovOptions, "lovId", "localLovKey", this.handleMultiSelect, "96%");
                } else { // single select
                    const lovOptions = _config.customLovList.filter(x => x.lovId !== 0); // autoSelect
                    return LayoutService.getDropDown(this.state.isReadOnly, _classes.dialogControl, _classes.menuPaper, _fProps, this.validationSchema,
                        _config.filterName, _config.filterDisplayName, lovOptions, "lovId", "localLovKey", null, false, "96%"
                    );
                }
            default:
                console.warn('dynamic control field type not implemented', _config.filterType);
                return <></>;
        }
    }

    handleMultiSelect = async (_key, _fProps, _newValues) => {
        if (DataService.hasElements(_newValues)) {
            let _selectedValues = [].concat(..._newValues);
            // check for [ALL] Item, if present then clear other items
            const itemAll = DataService.getFirstOrDefault(_selectedValues.filter((x) => x['lovId'] === this.objAll.lovId));
            if (itemAll && !_fProps.values[_key].includes(itemAll)) {
                await _fProps.setFieldValue(_key, [itemAll], true);
            } else {
                // if <all> was originally selected and a new value is selected, then remove <all>
                if (_fProps.values[_key].includes(itemAll)) {
                    _selectedValues = _selectedValues.filter(f => f != itemAll);
                }
                await _fProps.setFieldValue(_key, _selectedValues, true);
            }
        }
    }

    renderReportExtensionDropdown = (_isLobbyReport, _classes, _fProps) => {
        //console.log("Render" + JSON.stringify(this.state.reportFileExtensionList));
        _fProps.values["reportFileExtensionId"] = this.state.reportFileExtensionList.find(x => x.fileTypeExtension === "doc").id;
        if (_isLobbyReport)
            return LayoutService.getSelectControl(true, _classes, _fProps,
                "reportFileExtensionId", "Report Extension", this.state.reportFileExtensionList, "id", "fileType", "47%", true);
        return <></>
    }

    //TODO : translated report logic not implemented for now
    handleSubmit = async (_formikProps) => {
        if (!_formikProps.isSubmitting && _formikProps.isValid) {
            await this.validationSchema.validate(_formikProps.values, { abortEarly: false })
                .then((x) => {
                    // 1) set the status
                    this.setState({ fetchResult: ResultStatus.SAVING });
                    const getFileType = () => {
                        if (this.state.isLobbyReport) {
                            return "WORD";
                        }
                        return "EXCEL";
                    };

                    const getFileTypeExtension = () => {
                        if (this.state.isLobbyReport) {
                            return "doc";
                        }
                        return "xlsx";
                    };

                    const getFilterValue = (_formikProps, filter) => {
                        if (filter.filterType == FILTER_FIELD_TYPE.DATE) {
                            return new Date(_formikProps.values[filter.filterName].getTime() - (_formikProps.values[filter.filterName].getTimezoneOffset() * 60000)).toISOString().split("T")[0];
                        }
                        else if (Array.isArray(_formikProps.values[filter.filterName]))
                            return _formikProps.values[filter.filterName].map(x => x.lovId).join(',');
                        return _formikProps.values[filter.filterName];
                    }

                    //create filter fields payload
                    let filterFields = this.state.reportFilters.map(f => {
                        let displayValue = "";
                        let filterDisplayName = "";
                        if (f.filterType === FILTER_FIELD_TYPE.LOV) {
                            if (!f.isMultiSelect)
                                displayValue = f.customLovList.filter(x => x.lovId === getFilterValue(_formikProps, f))[0]?.localLovKey;

                            else {
                                displayValue = getFilterValue(_formikProps, f) === "[ALL]" ? "[ALL]" : f.customLovList.filter(x => _formikProps.values[f.filterName].map(x => x.lovId).includes(x.lovId)).map(x => x.localLovKey).join(",");

                            }
                        } else {
                            displayValue = getFilterValue(_formikProps, f);
                        }
                        return {
                            displayValue: displayValue,
                            actualValue: getFilterValue(_formikProps, f),
                            filterName: f.filterName,
                            filterDisplayName: f.filterDisplayName
                        }
                    });

                    
                    // 2) extract the formik values into an object
                    let mappedObj = {
                        isGemboxReport: this.props.modalAgNode.isGemboxReport,
                        IzendaCurrentTenantId: this.state.izendaCurrentTenantId,
                        reportDetailID: 0,
                        izendaReportID: this.props.modalAgNode.reportId,
                        fileType: getFileType(),
                        fileTypeExtension: getFileTypeExtension(),
                        reportId: this.props.modalAgNode.reportId,
                        reportNameForJsonMapping: this.props.modalAgNode.reportName,
                        reportDetailName: _formikProps.values.reportName,
                        reportDetailDescription: _formikProps.values.reportDescription,
                        reportLibraryId: _formikProps.values.reportLibraryId,
                        reportLibrary: this.state.reportLibraryList.find(r => r.lovId == _formikProps.values.reportLibraryId)?.lovKey,
                        startDate: new Date().toISOString(),
                        isActive: true,
                        reportName: this.props.modalAgNode.izendaDisplayName,
                        countryID: 0,
                        jurisdiction: this.props.modalAgNode.jurisdiction,
                        finalDispositionName: this.state.finalDispositionList.find((o) => o.lovId === _formikProps.values.finalDispositionId)?.lovKey,
                        reportCreatedDate: new Date().toISOString(),
                        launchDocument: true,
                        porzioGSTReportID: "DEVTK43F440C42",//TODO : need to revisit these
                        workflowID: 0,
                        porzioGSTWorkflowID: "",
                        isComplianceSave: _formikProps.values.isEnglishReport,
                        isCompanionSave: this.props.category === ReportCategory.COMPLIANCE_TEMPLATES ? _formikProps.values.isCompanionReport : false,
                        languagesList: this.state.languageslist
                            .map((x) => ({
                                selected: _formikProps.values["is" + x.translatedReportLanguage + "Report"],
                                translatedReportLanguage: x.translatedReportLanguage,
                                translatedReportName: x.translatedReportName,
                                izendaReportName: x.izendaReportName,
                                izendaReportId: x.izendaReportId,
                            }))
                            .filter((item) => item.selected === true),
                        translatedReportLanguageList: "",
                        genStatus: "InProgress",
                        statusReason: "",
                        izendaReportFilters: null,//TODO
                        filters: filterFields,
                        ...(this.props.category === ReportCategory.COMPLIANCE_TEMPLATES && {
                            finalDisposition: _formikProps.values.finalDispositionId,
                            finalDispositionDate: _formikProps.values.finalDispositionDate?.toISOString(),
                            dispositionComments: _formikProps.values.finalDispositionComments,
                            endDate: _formikProps.values.finalDispositionDate?.toISOString(),
                        }),
                    }

                    // 3) determine the action and assign the appropriate props
                    const actionVerb = "POST"
                    const targetUrl = `/Reports/SaveReportToLibrary?tenantId=${this.context.user.tenantId}&accessToken=${this.context.user.sessionToken}&userId=${this.context.user.userId}`;

                    // 4) save to Api and subscribe for the result
                    this.oSubscriptions.add(ApiService.setOBS(actionVerb, API_ENDPOINT.REPORTS, targetUrl, JSON.stringify(mappedObj))
                        .subscribe((successResult) => {
                            if (successResult) {
                                ToastService.showSuccess("Report(s) are queued for processing. An email will be sent once completed.");
                                this.props.handleClose();
                            } else {
                                console.error("Error: falsey successResult while saving report", successResult);
                                ToastService.showError("Error: Report not Saved");
                                this.setState({ fetchResult: ResultStatus.ERROR });
                            }
                        }, (errors) => {
                            console.error("Error while saving report", errors);
                            this.setState({ fetchResult: ResultStatus.ERROR });
                        }));
                })
                .catch((errorObj) => {
                    console.log(errorObj.message);
                    if (errorObj.inner) {
                        errorObj.inner.forEach(err => {
                            _formikProps.setFieldError(err.path, err.message);
                        });
                    }
                });
        }
    }



    //---
}
export default LayoutService.getHocComponenet(ReportSettingsComponent);
