import React, { Component } from "react";
import icon_bad from "../../public/images/icon_bad.png";
import icon_process from "../../public/images/icon_process.png";
import icon_verygood from "../../public/images/icon_verygood.png";
import icon_good from "../../public/images/icon_good.png";
import icon_blank from "../../public/images/icon_blank.png";
import icon_delete_black from "../../public/images/icon_delete_black.gif";

export default class DataCenterFileStatusImageRenderer extends Component {
  constructor(props) {
    super(props);
    this.invokeParentMethod = this.invokeParentMethod.bind(this);
  }

  invokeParentMethod() {
    this.props.context.componentParent.fileStatusMethodFromParent(
      `Row: ${this.props.node.rowIndex}, Col: ${this.props.colDef.headerName}`,
      this.props.node.data
    );
  }

  render() {
    switch (this.props.value) {
      case "icon_bad.png":
        return (
          <img
            src={icon_bad}
            alt="Icon Bad"
            onClick={this.invokeParentMethod}
            style={{
              marginBottom: 20 + "px", width: 30 + "px",
              display: "block", marginLeft: "auto", marginRight: "auto"
            }}
          />
        );
      case "icon_process.png":
        return (
          <img
            src={icon_process}
            alt="Icon Process"
            onClick={this.invokeParentMethod}
            style={{
              marginBottom: 20 + "px", width: 30 + "px",
              display: "block", marginLeft: "auto", marginRight: "auto"
            }}
          />
        );
      case "icon_verygood.png":
        return (
          <img
            src={icon_verygood}
            alt="Very Good"
            onClick={this.invokeParentMethod}
            style={{
              marginBottom: 20 + "px", width: 30 + "px",
              display: "block", marginLeft: "auto", marginRight: "auto"
            }}
          />
        );
      case "icon_good.png":
        return (
          <img
            src={icon_good}
            alt="Good"
            onClick={this.invokeParentMethod}
            style={{
              marginBottom: 20 + "px", width: 30 + "px",
              display: "block", marginLeft: "auto", marginRight: "auto"
            }}
          />
        );
      case "icon_blank.png":
        return (
          <img
            src={icon_blank}
            alt="Blank"
            onClick={this.invokeParentMethod}
            style={{
              marginBottom: 20 + "px", width: 30 + "px",
              display: "block", marginLeft: "auto", marginRight: "auto"
            }}
          />
        );
      case "icon_delete_black.gif":
        return (
          <img
            src={icon_delete_black}
            alt="Delete"
            //onClick={this.invokeParentMethod}
            style={{
              marginBottom: 20 + "px", width: 30 + "px",
              display: "block", marginLeft: "auto", marginRight: "auto"
            }}
          />
        );
      default:
        return "n/a";
    }
  }
}
