import React from "react";
import {
  withStyles,
  DialogTitle,
  DialogContent,
  Tabs,
  Tab,
  Box,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  Divider,
} from "@material-ui/core";
import { ResultStatus } from "../../../../shared/types/enums";
import { MatClassService } from "../../../../shared/services/theme/matClassService";
import PageLoadingComponent from "../../../../shared/components/page/pageLoadingComponent";
import PageErrorComponent from "../../../../shared/components/page/pageErrorComponent";
import { MatIconService } from "../../../../shared/services/theme/matIconService";
import EditAffiliateModalComponent from "./editAffiliateModalComponent";
import { AuthContext } from "../../../../shared/store/authProvider";
import { SubscriptionArray } from "../../../../shared/services/dataService";
import EditAffiliateService from "./editAffiliateService";

class AffiliateCompaniesDialogComponent extends React.Component {
  static contextType = AuthContext;
  oSubscriptions = new SubscriptionArray();

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      fetchResult: ResultStatus.NOT_LOADED,
      selectedTabIndex: 0,
      affiliate: {},
    };
  }

  componentWillUnmount() { }
  componentDidMount() {
    this.oSubscriptions.add(
      EditAffiliateService.getAffiliateAsOBS(
        this.context.user.tenantId,
        this.props.affiliate.companyAffiliateId
      ).subscribe((_affiliate) => {
        this.setState({ affiliate: _affiliate });
      })
    );
    this.setState({ fetchResult: ResultStatus.SUCCESS });
  }

  onTabChange = (event, newValue) => {
    this.setState({ selectedTabIndex: newValue });
  };

  /**Render */
  render() {
    const { classes } = this.props;

    switch (this.state.fetchResult) {
      case ResultStatus.NOT_LOADED:
      case ResultStatus.LOADING:
        return (<PageLoadingComponent small classes={classes} label="Loading Affiliate Company Details" />);
      case ResultStatus.SUCCESS:
        return (
          <React.Fragment>
            {/* Header */}
            <DialogTitle disableTypography id="dialogTitle">
              <AppBar position="static">
                <Toolbar>
                  <Typography variant="h6" className={classes.root}>Affiliate Company Details</Typography>
                  <IconButton color="secondary" onClick={() => { this.props.onClose(false); }}>{MatIconService.CANCEL}</IconButton>
                </Toolbar>
              </AppBar>
            </DialogTitle>
            {/* Content */}
            <DialogContent>
              <Tabs value={this.state.selectedTabIndex} onChange={this.onTabChange} indicatorColor="primary" textColor="inherit" variant="fullWidth" scrollButtons="auto">
                <Tab label="Edit Affiliate" id="taskTab" />
              </Tabs>
              <div role="tabpanel" hidden={this.state.selectedTabIndex !== 0} id="taskTabPanel" >
                <Divider />
                <Box style={{ paddingLeft: 16, paddingRight: 32, paddingTop: 8, paddingBottom: 32, minHeight: "60vh", maxHeight: "60vh", minWidth: "80vh", maxWidth: "80vh", }} >
                  <EditAffiliateModalComponent inputAction={this.props.inputAction} submitCreateWasClicked={false} affiliate={this.props.affiliate} onClose={this.props.onClose} refreshAfiliatesList={this.props.refreshAfiliatesList} />
                </Box>
              </div>
            </DialogContent>
          </React.Fragment>
        );

      case ResultStatus.ERROR:
      default:
        return (<PageErrorComponent small label="Error Loading Task Details" classes={classes} onRetry={() => { }} />);
    }
  }
}

/** HOC */
export default withStyles(MatClassService)(AffiliateCompaniesDialogComponent);
