import React from "react";
import { AgGridReact } from "ag-grid-react";
import { Grid, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Switch, Divider } from "@material-ui/core";
import { AuthContext } from "../../../shared/store/authProvider";
import { SubscriptionArray } from "../../../shared/services/dataService";
import FTPFolderService from "./ftpFolderService";
import { ResultStatus } from "../../../shared/types/enums";
import LayoutService from "../../../shared/services/layoutService";
import { MatIconService } from "../../../shared/services/theme/matIconService";
import PageLoadingComponent from "../../../shared/components/page/pageLoadingComponent";
import PageErrorComponent from "../../../shared/components/page/pageErrorComponent";
import PageDynamicHeaderComponent from "../../../shared/components/page/pageDynamicHeaderComponent";
import "./ftpFolderComponent.css";

class FTPFolderComponent extends React.Component {
    static contextType = AuthContext;
    oSubscriptions = new SubscriptionArray();
    STORE = FTPFolderService;
    state = {
        sourceSystemData: [],
        ftpFiles: [],
        modifiedFtpFileData: [],
        selectedSourceId: null,
        showArchiveFiles: false,
        fetchResult: ResultStatus.NOT_LOADED,
    };

    componentDidMount() {
        this.fetchSourceSystems();
    }

    componentWillUnmount() {
        this.oSubscriptions.cancelAll();
    }

    fetchSourceSystems = () => {
        this.oSubscriptions.cancelAll();
        this.setState({ fetchResult: ResultStatus.LOADING, sourceSystemData: [] });

        const pageNumber = 1;
        const rowsPerPage = 250;

        this.oSubscriptions.add(
            this.STORE.getSourceSystems(this.context.user.tenantId, pageNumber, rowsPerPage).subscribe(
                (response) => {
                    this.setState({
                        sourceSystemData: response.filter(s => s.isFtpSource ===  1),
                        fetchResult: ResultStatus.SUCCESS
                    });
                },
                (error) => {
                    this.setState({ fetchResult: ResultStatus.ERROR });
                    console.log(error);
                }
            ));
    };

    handleSourceChange = (event) => {
        const selectedSourceId = event.target.value;
        this.setState({ selectedSourceId });
        if (selectedSourceId) {
            this.fetchFTPFiles(selectedSourceId);
        }
    };

    getFtpFileData = (ftpFileData, showArchiveFiles) => {
        const filteredFiles = showArchiveFiles
            ? ftpFileData.filter((ftpFile) => ftpFile.fileName.toLowerCase().includes("-archive") )
            : ftpFileData.filter((ftpFile) => !ftpFile.fileName.toLowerCase().includes("-archive") );

        const sortedFiles = filteredFiles.slice().sort((a, b) => new Date(b.modifiedDate).getTime() - new Date(a.modifiedDate).getTime());
        return sortedFiles;
    }

    fetchFTPFiles = (sourceId) => {
        this.STORE.getFTPFilesByTenantId(this.context.user.tenantId, sourceId).subscribe(
            (response) => {
                const ftpFileData = response;
                const modFtpFileData = (ftpFileData || []).map((data) => ({
                    ...data,
                    modifiedFileName: data.fileName.split('/').pop().split('.')[0],
                    modifiedDate: new Date(data.modifiedDate).toUTCString()
                }));

                const filteredFtpData = this.getFtpFileData(modFtpFileData, this.state.showArchiveFiles);

                this.setState({
                    ftpFiles: filteredFtpData,
                    modifiedFtpFileData: modFtpFileData
                });
            },
            (error) => {
                console.log('Error fetching FTP Files:', error);
            }
        );
    };

    handleToggleChange = () => {
        this.setState({
            showArchiveFiles: !this.state.showArchiveFiles
        }, () => {
            const filteredFileData = this.getFtpFileData(this.state.modifiedFtpFileData, this.state.showArchiveFiles);
            this.setState({
                ftpFiles: filteredFileData
            });
        });
    };

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    };

    render() {
        const { classes } = this.props;
        switch (this.state.fetchResult) {
            case ResultStatus.NOT_LOADED:
            case ResultStatus.LOADING:
                return (<PageLoadingComponent classes={classes} label="Loading FTP Folders" />);
            case ResultStatus.SUCCESS:
                return (
                    <div id="MainRoleGrid">
                        <div style={{ backgroundColor: "#ddd", textAlign: "right" }}></div>
                        <div className="IconBg marginTop10px tableTitleHead">
                            {/* Header Componenet */}
                            {<PageDynamicHeaderComponent classes={classes} label="FTP Folders" rightPadding={16}
                                leftActions={[{ icon: MatIconService.BACK, title: "Back", iconColor: "secondary", onClick: this.props.history.goBack }]}
                            />}
                        </div>
                        <Grid container spacing={1} alignItems="center" style={{ paddingBottom: 16 }}>
                            <Grid item xs={6} sm={6}>
                                <FormControl style={{ minWidth: "90%" }} className={classes.dialogControl}>
                                    <InputLabel id="source-system-label">Source System</InputLabel>
                                    <Select fullWidth labelId="source-system-label" id="source-system" value={this.state.selectedSourceId}
                                        onChange={this.handleSourceChange}>
                                        {(this.state.sourceSystemData || []).map((system, idx) => (
                                            <MenuItem key={system.systemId} value={system.systemId}>{system.sourceLabel}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <FormControlLabel control={
                                    <Switch name="switchShowArchiveFiles" checked={this.state.showArchiveFiles} onChange={this.handleToggleChange}
                                        inputProps={{ "aria-label": "show archive files checkbox" }} />} label="Show Archive Files" />
                            </Grid>
                        </Grid>
                        <div {...LayoutService.getAgGridStyles(240)}>
                            <AgGridReact
                                columnDefs={this.STORE.getColumnDefs(this)}
                                rowData={this.state.ftpFiles}
                                pagination={true}
                                paginationPageSize={50}
                                frameworkComponents={this.state.frameworkComponents}
                                gridOptions={{
                                    tooltipShowDelay: 0,
                                    context: { componentParent: this },
                                    defaultColDef: { sortable: true }
                                }}
                                onGridReady={this.onGridReady}
                            ></AgGridReact>
                        </div>
                    </div>
                );
            case ResultStatus.ERROR:
            default:
                return (
                    <PageErrorComponent label="Error Loading FTP Folders" classes={classes} onRetry={() => { this.fetchData(true); }} />
                );
        }
    }   
}

export default LayoutService.getHocComponenet(FTPFolderComponent);
