import React from "react";
import { of, combineLatest } from "rxjs";
import { Formik } from "formik";
import * as Yup from "yup";
import { Box, Dialog, DialogTitle, DialogContent, Typography, AppBar, Toolbar, IconButton, TextField, Grid, FormGroup } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { MatIconService } from "../../../../shared/services/theme/matIconService";
import { AuthContext } from "../../../../shared/store/authProvider";
import { ResultStatus, CrudAction, API_ENDPOINT, } from "../../../../shared/types/enums";
import { SubscriptionArray } from "../../../../shared/services/dataService";
import LayoutService from "../../../../shared/services/layoutService";
import LookupService from "../../../../shared/services/lookupService";
import PageLoadingComponent from "../../../../shared/components/page/pageLoadingComponent";
import DialogErrorFragmentComponent from "../../../../shared/components/page/dialogErrorFragmentComponent";
import ApiService from "../../../../shared/services/apiService";
import SalesRepDetailsService from "./salesRepDetailsService";
import RolePermissionService from "../../../../shared/role-permissions/rolePermissionService";

class SalesRepDetailsComponent extends React.Component {
  static contextType = AuthContext;
  oSubscriptions = new SubscriptionArray();

  constructor(props) {
    super(props);
    // init state
    this.state = {
      isReadOnly: this.props.inputAction === CrudAction.UPDATE || this.props.inputAction === CrudAction.READ ? true : false,
      isEditing: this.props.inputAction === CrudAction.UPDATE || this.props.inputAction === CrudAction.READ ? true : false,
      fetchResult: ResultStatus.NOT_LOADED
    };
  }

  componentWillUnmount() {
    this.oSubscriptions.cancelAll();
  }

  componentDidMount() {
    this.fetchSalesRepDetails();
  }

  fetchSalesRepDetails = () => {
    this.setState({ fetchResult: ResultStatus.LOADING });
    /**
 * rxjs <combineLatest> operator takes multiple obs as argument and emits their result in the same order
 * this operator is very handy as we don't have to independently wait for each result
 */
    this.oSubscriptions.add(
      combineLatest([
        (this.props.inputAction === CrudAction.CREATE ? of(null) : SalesRepDetailsService.getReportValueDropdownAsOBS(this.context.user.tenantId, this.props.modalAgNode.srId, this.props.modalAgNode.companySalesRepId)),
        LookupService.getFormattedCountriesAsOBS(this.context, null),
      ]).subscribe(([_salesRepObj, _countryList,]) => {
        this.setState(
          {
            salesRepObj: _salesRepObj,
            countryList: [{ id: 0, value: "Select" }, ..._countryList],
          },
          () => {
            // change the state after all the above are assigned
            this.setState({ fetchResult: ResultStatus.LOADED });
          }
        );
      }
      )
    );
  }

  getInitialValues() {
    if (this.props.inputAction === CrudAction.CREATE) {
      return {
        companySalesRepId: undefined,
        salesRepFirstName: undefined,
        salesRepMiddleName: undefined,
        salesRepLastName: undefined,
        province: undefined,
        country: "",
        licenseChicago: undefined,
        licenseConnecticut: undefined,
        licenseDC: undefined,
        licenseNevada: undefined,
        licenseMiamiIndicator: undefined,
        isActive: true,
        licenseOregon: undefined,
      };
    } else {
      return {
        companySalesRepId: this.state.salesRepObj.companySalesRepId,
        salesRepFirstName: this.state.salesRepObj.salesrepfirstname ?? undefined,
        salesRepMiddleName: this.state.salesRepObj.salesrepmiddlename ?? undefined,
        salesRepLastName: this.state.salesRepObj.salesreplastname,
        province: this.state.salesRepObj.province ?? undefined,
        country: this.state.salesRepObj.countryId,
        licenseChicago: this.state.salesRepObj.licenseChicago ?? undefined,
        licenseConnecticut: this.state.salesRepObj.licenseConnecticut ?? undefined,
        licenseDC: this.state.salesRepObj.licenseDC ?? undefined,
        licenseNevada: this.state.salesRepObj.licenseNevada ?? undefined,
        licenseMiamiIndicator: this.state.salesRepObj.licenseMiamiIndicator,
        isActive: this.state.salesRepObj.isActive,
        licenseOregon: this.state.salesRepObj.licenseOregon ?? undefined,
      };
    }
  }

  validationSchema = Yup.object().shape({});
  getValidationSchema() {
    this.validationSchema = Yup.object().shape({
      companySalesRepId: Yup.string().required("Required")
        .min(2, "Must be at least 2 characters long")
        .max(256, "Must be 256 characters or less")
        .test(
          "checkDuplicateCompanySAlesRepId",
          "Company Sales Rep Id already exists",
          async (_formikValue) => {
            if (this.props.inputAction === CrudAction.UPDATE) {
              if (_formikValue === this.state.salesRepObj.companySalesRepId) {
                return true;
              }
            }
            try {
              const result = await fetch(
                `${process.env.REACT_APP_TENANT_HTTP}/SalesRep/CompanySalesRepExists/${this.context.user.tenantId}/${_formikValue}`
              );
              const isExists = await result.json();
              return !isExists;
            } catch (error) {
              this.setState({
                fetchResult: ResultStatus.ERROR,
              });
            }
          }
        ),

      salesRepFirstName: Yup.string().max(100, "Must be 100 characters or less"),
      salesRepMiddleName: Yup.string().max(100, "Must be 100 characters or less"),
      salesRepLastName: Yup.string().required("Required").min(2, "Must be at least 2 characters long").max(100, "Must be 100 characters or less"),
      province: Yup.string().max(100, "Must be 100 characters or less"),
      licenseChicago: Yup.string().max(256, "Must be 256 characters or less"),
      licenseConnecticut: Yup.string().max(256, "Must be 256 characters or less"),
      licenseDC: Yup.string().max(256, "Must be 256 characters or less"),
      licenseNevada: Yup.string().max(256, "Must be 256 characters or less"),
      licenseOregon: Yup.string().max(256, "Must be 256 characters or less"),
    });
    return this.validationSchema;
  }

  handleSubmit = async (_formikProps) => {
    if (!_formikProps.isSubmitting && _formikProps.isValid) {
      await this.validationSchema.validate(_formikProps.values, { abortEarly: false })
        .then((x) => {

          // 1) set the status
          this.setState({ fetchResult: ResultStatus.SAVING });

          // 2) extract the formik values into an object
          var mappedObj = {
            companySalesRepId: _formikProps.values.companySalesRepId,
            salesRepFirstName: _formikProps.values.salesRepFirstName,
            salesRepMiddleName: _formikProps.values.salesRepMiddleName,
            salesRepLastName: _formikProps.values.salesRepLastName,
            province: _formikProps.values.province,
            countryId: _formikProps.values.country ? _formikProps.values.country : 0,
            licenseChicago: _formikProps.values.licenseChicago,
            licenseConnecticut: _formikProps.values.licenseConnecticut,
            licenseDC: _formikProps.values.licenseDC,
            licenseNevada: _formikProps.values.licenseNevada,
            licenseMiamiIndicator: _formikProps.values.licenseMiamiIndicator,
            isActive: _formikProps.values.isActive,
            licenseOregon: _formikProps.values.licenseOregon,
          };

          // 3) determine the action and assign the appropriate props
          let apiUrl = "";
          var actionVerb = "";
          if (this.state.isEditing) {
            // UPDATE
            actionVerb = "PUT";
            apiUrl = `/SalesRep/EditSalesRep/${this.context.user.tenantId}`
            mappedObj.srId = this.state.salesRepObj.srId;
            mappedObj.updatedBy = this.context.user.userId;

          } else {
            // CREATE
            actionVerb = "POST";
            apiUrl = `/SalesRep/AddSalesRep/${this.context.user.tenantId}`
            mappedObj.srId = 0;
            mappedObj.createdBy = this.context.user.userId;
          }

          // 4) save to Api and subscribe for the result
          ApiService.setOBS(actionVerb, API_ENDPOINT.TENANT, apiUrl, JSON.stringify(mappedObj)).subscribe(
            (successResult) => {
              if (successResult) {
                this.setState({ fetchResult: ResultStatus.SUCCESS, isReadOnly: true }, () => { this.fetchSalesRepDetails(); });
                this.props.refreshGrid(true);
                this.props.onClose(false);
              } else {
                this.setState({ fetchResult: ResultStatus.ERROR });
              }
            },
            (errorResult) => {
              console.error("Error while saving Details", errorResult);
              this.setState({ fetchResult: ResultStatus.ERROR });
            }
          );
        })
        .catch((erroObj) => {
          console.log(erroObj);
          if (erroObj.inner) {
            erroObj.inner.forEach(err => { _formikProps.setFieldError(err.path, err.message); });
          }
        });
    }
  }

  render() {
    const { classes } = this.props;

    if (RolePermissionService.SALESREP_DETAIL.cannotView && this.props.inputAction === CrudAction.UPDATE) {
      return RolePermissionService.getAccessDeniedComponent(classes, () => { this.props.history.goBack() }); // this is required to prevent Url navigation
    } else {
      switch (this.state.fetchResult) {
        case ResultStatus.NOT_LOADED:
        case ResultStatus.LOADING:
          return (<PageLoadingComponent small classes={classes} label="Loading SalesRep Detail" />);
        case ResultStatus.SAVING:
          return (<PageLoadingComponent small classes={classes} label="Saving SalesRep Detail" />);
        case ResultStatus.LOADED:
        case ResultStatus.SUCCESS:
          return (

            <Formik initialValues={this.getInitialValues()} validationSchema={this.getValidationSchema()} validationSchemaOptions={{ showMultipleFieldErrors: true }}>
              {(fProps) => (
                <form>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    {/* Dialog Title */}
                    <DialogTitle disableTypography id="dialogTitle">
                      <AppBar position="static">
                        <Toolbar variant="dense">
                          <Typography variant="h6" className={classes.root}>SalesRep Detail</Typography>
                          {this.props.inputAction === CrudAction.UPDATE ? RolePermissionService.SALESREP_DETAIL.cannotEdit ? LayoutService.getIconButton(false, MatIconService.CANCEL, "Discard Changes", () => { this.props.onClose(false) }, "secondary", "keyActionCancel")
                            : LayoutService.getReadOnlyActions(this, !this.state.isEditing, () => { this.props.onClose(false) }, () => { this.handleSubmit(fProps) })
                            : LayoutService.getReadOnlyActions(this, !this.state.isEditing, () => { this.props.onClose(false) }, () => { this.handleSubmit(fProps) })
                          }
                        </Toolbar>
                      </AppBar>
                    </DialogTitle>
                    {/* Dialog Content */}
                    <DialogContent>
                      <Box style={{ paddingLeft: 16, paddingRight: 32, paddingTop: 16, paddingBottom: 32, minWidth: "80vh", maxWidth: "80vh", }}>
                        {this.state.fetchResult === ResultStatus.SUCCESS ? (<h1>Your SalesRep has been updated!!</h1>) : null}
                        <Grid container spacing={1}>
                          <Grid item xs={12} sm={12}><TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "companySalesRepId", "SalesRep Id")} fullWidth /></Grid>
                          <Grid item xs={12} sm={4}><TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "salesRepFirstName", "First Name", false)} fullWidth />{" "}</Grid>
                          <Grid item xs={12} sm={4}><TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "salesRepMiddleName", "Middle Name", false)} fullWidth /></Grid>
                          <Grid item xs={12} sm={4}><TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "salesRepLastName", "Last Name")} fullWidth /></Grid>
                          <Grid item xs={12} sm={6}><TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "province", "Province/State", false)} fullWidth /></Grid>
                          <Grid item xs={12} sm={6}>{LayoutService.getSelectControl(this.state.isReadOnly, classes, fProps, "country", "Country", this.state.countryList, "id", "value", "98%")}</Grid>
                          <Grid item xs={12} sm={6}><TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "licenseChicago", "License Chicago", false)} fullWidth /></Grid>
                          <Grid item xs={12} sm={6}><TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "licenseConnecticut", "License Connecticut", false)} fullWidth /></Grid>
                          <Grid item xs={12} sm={6}><TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "licenseDC", "License DC", false)} fullWidth /></Grid>
                          <Grid item xs={12} sm={6}><TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "licenseNevada", "License Nevada", false)} fullWidth /></Grid>
                          <Grid item xs={12} sm={6}><TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "licenseOregon", "License Oregon", false)} fullWidth /></Grid>
                          <Grid item xs={12} sm={6}>
                            <FormGroup style={{ paddingLeft: "4px", paddingTop: "8px" }}>
                              {LayoutService.getSwitch(this.state.isReadOnly, classes, fProps, "licenseMiamiIndicator", "License Miami Indicator")}
                            </FormGroup>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormGroup style={{ paddingLeft: "4px", paddingTop: "8px" }}>
                              {LayoutService.getSwitch(this.state.isReadOnly, classes, fProps, "isActive", "Active")}
                            </FormGroup>
                          </Grid>
                        </Grid>
                      </Box>
                    </DialogContent>
                  </MuiPickersUtilsProvider>
                </form>
              )}
            </Formik>

          );
        case ResultStatus.ERROR:
        default:
          return (
            <DialogErrorFragmentComponent title="Error" description="Error in SalesRep Details"
              classes={classes} onClose={() => { this.props.onClose(false); }} onRetry={() => { console.log("Retry Clicked"); }} />
          );
      }
    }
  }
}

/** HOC */
export default LayoutService.getHocComponenet(SalesRepDetailsComponent);