import React, { Component } from "react";
import { fade, withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { AppBar } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import Dialog from "@material-ui/core/Dialog";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import { MatIconService } from "../../shared/services/theme/matIconService";
import MatThemeService from "../../shared/services/theme/matThemeService";
import MatSpecService from "../../shared/services/theme/matSpecService";
import { Divider, ListItemIcon, Menu, MenuItem, Tooltip } from "@material-ui/core";
import { AuthContext } from "../../shared/store/authProvider";
import { CrudAction, TenantSelectList } from "../../shared/types/enums";
import LogoComponent from "../../shared/components/logo/logoComponent";
import InternationalComponent from "../tenant/international/internationalComponent";
import UserDialogComponent from "../user-management/users/user-details/userDialogComponent";
import { Link } from "react-router-dom";
import { from, BehaviorSubject } from "rxjs";
import { DataService, SubscriptionArray } from "../../shared/services/dataService";
import { mergeMap, debounceTime, distinctUntilChanged, } from "rxjs/operators";
import GlobalSearchContainerComponent from "./../global-search/globalSearchContainerComponent";
import SessionService from "../../shared/services/sessionService";
import { withOktaAuth } from '@okta/okta-react';
import ToastService from "../../shared/services/toastService";
import AdvancedSearchComponent from "../global-search/advanced-search/advancedSearchComponent";
import RolePermissionService from "../../shared/role-permissions/rolePermissionService";
import MomentService from "../../shared/services/momentService";
import ApiService from "../../shared/services/apiService";
import OktaAuthService from "../../public/authentication/oktaAuthService";


class TopBarComponent extends Component {
  static contextType = AuthContext;

  scopedSubscriptions = new SubscriptionArray();
  state = {
    anchorEl: null,
    personalSettingsModalShow: false,
    openAccountMenu: false,
    openLanguageDialog: false,
    openCurrencyDialog: false,
    openSearchDialog: false,
    openAdvancedSearchDialog: false,
    searchValue: "",
    utcTime: "" //MomentService.getUtcTime(),
  };

  startUtcCountDown = () => {
    // setInterval(() => {
    //   this.setState({ utcTime: MomentService.getUtcTime() })
    // }, 1000);
  }

  searchSubject = new BehaviorSubject("");
  // componentWillUnmount() {
  //   this.scopedSubscriptions.cancelAll();
  // }
  componentDidMount() {
    // this.startUtcCountDown();
    var searchOBS = this.searchSubject.pipe(
      debounceTime(750),
      distinctUntilChanged(),
      mergeMap((_searchValue) => from(this.searchRecipient(_searchValue)))
    );
    //this.scopedSubscriptions.add(searchOBS.subscribe());
  }
  searchRecipient = (_searchValue) => {
    this.apiSubscriptions.cancelAll();
    if (DataService.stringShouldBe(_searchValue, 3)) {
      this.setState({ openSearchDialog: true, searchValue: _searchValue });
    }
    return [];
  }
  handleAccountMenuClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
      openAccountMenu: true,
    });
  };

  handleThemeChange = (_closeMenu) => {
    MatThemeService.cycleTheme();
    this.props.onThemeChange();
    if (_closeMenu) {
      this.handleAccountMenuClose();
    }
  };

  handleAccountMenuClose = () => {
    this.setState({ anchorEl: null, openAccountMenu: false });
  };

  handlePersonalSettingsModalClose = () => {
    this.setState({ personalSettingsModalShow: false });
  };

  handleLanguageDialogClose = () => {
    this.setState({ openLanguageDialog: false });
  };

  handleCurrencyDialogClose = () => {
    this.setState({ openCurrencyDialog: false });
  };

  logout = async () => {
    OktaAuthService.cancelSession();
    await this.props.oktaAuth.signOut();
    await this.props.oktaAuth.tokenManager.clear();
    SessionService.clearAll(this.context);
  }

  render() {
    const { classes } = this.props;
    const { anchorEl, openAccountMenu } = this.state;

    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar} color={this.props.topBarColor}>
          <Toolbar>
            {/* Menu */}
            <Tooltip enterDelay={MatSpecService.tooltipEnterDelay} title="Show Menu" placement="bottom" arrow>
              <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={this.props.toggleSideNav}>
                {MatIconService.MENU}
              </IconButton>
            </Tooltip>
            <LogoComponent />
            {/* Tenant Name */}
            <Grid container spacing={1}>
              <Grid item xs={12}></Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" className={classes.title} noWrap >{this.context.user.tenantName}</Typography>
                <Typography variant="subtitle2" className={classes.title} noWrap >{this.state.utcTime}</Typography>
              </Grid>
            </Grid>

            {/* Search */}
            <div className={classes.search}>
              <InputBase placeholder="Search…"
                classes={{ root: classes.inputRoot, input: classes.inputInput, }}
                inputProps={{ "aria-label": "search" }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' && !DataService.isStringNullOrEmpty(e.target.value)) {
                    this.setState({ openSearchDialog: true, searchValue: e.target.value });
                  }
                }}
              />
            </div>

            {/* Advanced Search */}
            <Tooltip enterDelay={MatSpecService.tooltipEnterDelay} title="Click here for Advanced Search" placement="bottom" arrow>
              <IconButton edge="start" className={classes.searchIcon} color="inherit" aria-label="menu"
                onClick={(event) => { this.setState({ openAdvancedSearchDialog: true }); }}>{MatIconService.FIND_IN_PAGE}
              </IconButton>
            </Tooltip>

            <div>

              <Tooltip enterDelay={MatSpecService.tooltipEnterDelay} title="Account" placement="bottom" arrow>
                <IconButton aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={(event) => this.handleAccountMenuClick(event)} color="inherit">
                  {MatIconService.ACCOUNT}
                </IconButton>
              </Tooltip>

              <Menu id="menu-appbar" anchorEl={anchorEl} keepMounted
                PaperProps={{ style: { width: 256 } }}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                open={openAccountMenu || false}
                onClose={this.handleAccountMenuClose}
              >

                <MenuItem onClick={(event) => { this.setState({ personalSettingsModalShow: true }); this.handleAccountMenuClose(); }}>
                  <ListItemIcon>{MatIconService.PERSONAL}</ListItemIcon>
                  <Typography variant="inherit">Personal Settings</Typography>
                </MenuItem>

                <Divider />

                <MenuItem onClick={() => { this.setState({ openLanguageDialog: true }); this.handleAccountMenuClose(); }}>
                  <ListItemIcon>{MatIconService.LANGUAGE}</ListItemIcon>
                  <Typography variant="inherit">Languages</Typography>
                </MenuItem>

                <MenuItem onClick={() => { this.setState({ openCurrencyDialog: true }); this.handleAccountMenuClose(); }}>
                  <ListItemIcon>{MatIconService.CURRENCY}</ListItemIcon>
                  <Typography variant="inherit">Currency</Typography>
                </MenuItem>

                <MenuItem disabled={RolePermissionService.hasOnlyPowerBIAccess} component={Link} to="/tenant-config" onClick={() => { this.handleAccountMenuClose(); }}>
                  <ListItemIcon>{MatIconService.SETTINGS}</ListItemIcon>
                  <Typography variant="inherit">Configuration</Typography>
                </MenuItem>

                <Divider />

                <MenuItem component={Link} to="/contact-us" onClick={() => { this.handleAccountMenuClose(); }}>
                  <ListItemIcon>{MatIconService.CONTACT_US}</ListItemIcon>
                  <Typography variant="inherit">Contact Us</Typography>
                </MenuItem>

                <Divider />

                <MenuItem onClick={() => { this.logout(); this.handleAccountMenuClose(); }}>
                  <ListItemIcon>{MatIconService.LOGOUT}</ListItemIcon>
                  <Typography variant="inherit" color="error">Logout</Typography>
                </MenuItem>

              </Menu>
            </div>
          </Toolbar>
        </AppBar>

        <Dialog open={this.state.personalSettingsModalShow || false} scroll={true ? "paper" : "body"} maxWidth="md" fullWidth >
          <UserDialogComponent
            onClose={this.handlePersonalSettingsModalClose}
            userId={this.context.user.userId}
            refreshParentGrid={() => { console.log("Closed UserDialogComponent") }}
            inputAction={CrudAction.UPDATE}
          />
        </Dialog>

        {/* Global Search */}
        {this.state.openSearchDialog ?
          <Dialog open={this.state.openSearchDialog || false} scroll={true ? "paper" : "body"} fullWidth={true} maxWidth={"lg"} onClose={() => { this.setState({ openSearchDialog: false }) }} >
            <GlobalSearchContainerComponent searchValue={this.state.searchValue} openDialog={this.state.openSearchDialog || false} close={() => {
              this.setState({ openSearchDialog: false })
            }} /></Dialog> : null}

        {/* Advanced Search */}
        {this.state.openAdvancedSearchDialog ?
          <Dialog open={this.state.openAdvancedSearchDialog || false} scroll={true ? "paper" : "body"} fullWidth={true} maxWidth={"lg"} onClose={() => { this.setState({ openAdvancedSearchDialog: false }) }} >
            <AdvancedSearchComponent
              open={this.state.openAdvancedSearchDialog || false}
              close={() => {
                this.setState({ openAdvancedSearchDialog: false })
              }} /></Dialog> : null}
        <InternationalComponent openDialog={this.state.openLanguageDialog} handleDialogClose={this.handleLanguageDialogClose} internationalList={TenantSelectList.LANGUAGE} />
        <InternationalComponent openDialog={this.state.openCurrencyDialog} handleDialogClose={this.handleCurrencyDialogClose} internationalList={TenantSelectList.CURRENCY} />
      </div>
    );
  }
}

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  hide: {
    display: "none",
  },

  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: MatThemeService.drawerWidth,
    width: `calc(100% - ${MatThemeService.drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },

  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    marginRight: 16,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    //position: "absolute",
    //pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
});

export default withStyles(styles)(withOktaAuth(TopBarComponent));
