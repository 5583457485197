import config from '../../config';
import OktaSignIn from '@okta/okta-signin-widget';
import ApplicationService from '../../shared/services/applicationService';
import porzio_gst_icon from "../../public/images/porzio_global_spend_transparency_logo_small.png";

export default class OktaAuthService {

    static authWidget = {};
    static tokensCache = null;
    static authClient = null;
    static renewTokenTimerRef = null;
    static renewBufferTimeInMs = 5 * 60 * 1000; // 5 minutes before expiry

    static getAccessToken = () => {
        // check expiry if expired, renew()-> replace old with new -> and pass the new Token
        return OktaAuthService.tokensCache?.accessToken?.accessToken;
    }

    static INIT = () => {
        const { issuer, clientId, redirectUri, scopes } = config.oidc;
        const oktaTitle = `${ApplicationService.applicationName} - ${ApplicationService.appGstVersionString}`;

        OktaAuthService.authWidget = new OktaSignIn({
            baseUrl: issuer.split('/oauth2')[0],
            clientId: clientId,
            redirectUri: redirectUri,
            logo: porzio_gst_icon,
            authParams: { issuer, scopes },
            features: { idpDiscovery: true },
            idpDiscovery: { requestContext: ApplicationService.OktaRequestContext },
            i18n: { en: { 'primaryauth.title': oktaTitle, }, }
        });
        // cache
        OktaAuthService.authClient = OktaAuthService.authWidget.authClient;

        /** subscription required by okta for not throwing warnings in the console */
        OktaAuthService.authClient.authStateManager.subscribe((resultObj) => {
            // console.log("auth state changed", resultObj);
        });
    }


    static checkSingleSignOn = (onSuccessCallback, onErrorCallback) => {
        OktaAuthService.authClient.token.getWithoutPrompt({
            responseType: ['id_token', 'token'], // or array of types
        })
            .then((successObj) => OktaAuthService.handleSuccess(successObj, onSuccessCallback))
            .catch((errorObj) => OktaAuthService.handleError(errorObj, onErrorCallback))
    }

    static showSignInWidget = (_renderEl, onSuccessCallback, onErrorCallback) => {
        OktaAuthService.authWidget.renderEl({ el: _renderEl },
            (successObj) => OktaAuthService.handleSuccess(successObj, onSuccessCallback),
            (errorObj) => OktaAuthService.handleError(errorObj, onErrorCallback)
        );
    }

    //---
    static handleError = (errorObj, onErrorCallback) => {

        console.log(errorObj);

        // clear the cache and stop the monitor
        OktaAuthService.cancelSession();

        // call the errorCallback
        if (onErrorCallback) {
            onErrorCallback(errorObj);
        }
    }

    static handleSuccess = (successObj, onSuccessCallback) => {

        // cache the access-token for api calls
        OktaAuthService.tokensCache = successObj?.tokens;

        // set the token in the okta-token manager (application-storage)
        OktaAuthService.authClient.tokenManager.setTokens(successObj.tokens);

        // auto-renew-session before expiry
        const expiresAt = successObj?.tokens.accessToken.expiresAt;
        OktaAuthService.scheduleTokenRenewInterval(expiresAt);

        // call the callback
        if (onSuccessCallback) {
            onSuccessCallback(successObj);
        }
    }

    // +++++++-refresh-session-logic-++++++++++++++++++++++++
    static cancelSession = () => {
        OktaAuthService.tokensCache = null;
        OktaAuthService.stopTokenRenewInterval();
    }

    static stopTokenRenewInterval = () => {
        if (OktaAuthService.renewTokenTimerRef) {
            clearTimeout(OktaAuthService.renewTokenTimerRef);
        }
    }

    static scheduleTokenRenewInterval(tokenExpiresAt) {
        // clear,... just in case not stopped already
        OktaAuthService.stopTokenRenewInterval();

        // Convert to MilliSeconds
        let expiresAtInMs = tokenExpiresAt * 1000;
        // incase of nullValue use 15 mins as default
        expiresAtInMs = expiresAtInMs > 0 ? expiresAtInMs : 15 * 60 * 1000; 
          
        // Calculate how many seconds remain until expiration from current time
        const timeUntilExpiryInMs = expiresAtInMs - Date.now();

        // calculate the renewval interval with Buffer time ( 5 mins before expiry)
        let renewTimeInMs = (timeUntilExpiryInMs - OktaAuthService.renewBufferTimeInMs);
        renewTimeInMs = renewTimeInMs > 0 ? renewTimeInMs : 0;

        // print for help
        // console.clear();
        console.log(`OKTA-Token is set to expire at: ${OktaAuthService.getHoursAndMinutes(timeUntilExpiryInMs)}`);
        console.log(`OKTA-Token will get renewed at: ${OktaAuthService.getHoursAndMinutes(renewTimeInMs)}`);

        // start the timer
        OktaAuthService.renewTokenTimerRef = setTimeout(() => {
            OktaAuthService.renewToken();
        }, renewTimeInMs);

    }

    static getHoursAndMinutes(timeInMs) {
        // Get the current time
        const currentTime = new Date();
    
        // Add the milliseconds to the current time
        const diffTime = new Date(currentTime.getTime() + timeInMs);
    
        // Get hours and minutes from the renewal time
        const hours = diffTime.getHours().toString().padStart(2, '0');
        const minutes = diffTime.getMinutes().toString().padStart(2, '0');
        const seconds = diffTime.getMinutes().toString().padStart(2, '0');
    
        // Print the token renewal time in HH:MM format
        return `${hours}:${minutes}:${seconds}`;
    }

    static renewToken = () => {
        console.log('Token renewal requested...');
        OktaAuthService.authClient.tokenManager.renew('accessToken')
            .then((accessTokenObj) => {
                // ---
                var updatedTokens = {
                    ...OktaAuthService.tokensCache,
                    accessToken: accessTokenObj
                };

                //  cache
                OktaAuthService.tokensCache = updatedTokens;
                OktaAuthService.authClient.tokenManager.setTokens(updatedTokens);
                OktaAuthService.scheduleTokenRenewInterval(accessTokenObj.expiresAt);
                console.log('Token renewed before expiry:', updatedTokens);
            })
            .catch(err => {
                console.error('Error renewing token before expiry:', err);
            })
    }
    
   //---
}