import React from "react";
import { combineLatest } from "rxjs";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  Box,
  DialogTitle,
  DialogContent,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  TextField,
  Grid,
  FormGroup,
  FormHelperText,
  Input,
} from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { MatIconService } from "../../../../shared/services/theme/matIconService";
import { AuthContext } from "../../../../shared/store/authProvider";
import {
  ResultStatus,
  CrudAction,
  API_ENDPOINT,
} from "../../../../shared/types/enums";
import { SubscriptionArray } from "../../../../shared/services/dataService";
import LayoutService from "../../../../shared/services/layoutService";
import LookupService from "../../../../shared/services/lookupService";
import PageLoadingComponent from "../../../../shared/components/page/pageLoadingComponent";
import DialogErrorFragmentComponent from "../../../../shared/components/page/dialogErrorFragmentComponent";
import { DataService } from "../../../../shared/services/dataService";
import ApiService from "../../../../shared/services/apiService";
import NewsFeedDetailsService from "./newsFeedDetailsService";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import '../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

class NewsfeedSummaryComponent extends React.Component {
  static contextType = AuthContext;
  oSubscriptions = new SubscriptionArray();
  lastValidatedMaterialName = null;
  lastValidatedMaterialResult = false;

  constructor(props) {
    super(props);
    // init state
    this.state = {
      newsfeedTypeList: [],
      fetchResult: ResultStatus.NOT_LOADED,
    };
  }

  componentWillUnmount() {
    this.oSubscriptions.cancelAll();
  }

  componentDidMount() {
    this.setState({ fetchResult: ResultStatus.LOADING });

    /**
     * rxjs <combineLatest> operator takes multiple obs as argument and emits their result in the same order
     * this operator is very handy as we don't have to independently wait for each result
     */
    try {
      this.oSubscriptions.add(
        combineLatest([
          LookupService.getNewsfeedTypesAsOBS(this.context),
          NewsFeedDetailsService.getNewsFeedByIdAsOBS(this.context, this.props.updateData.newsfeedId),
        ]).subscribe(([_newsfeedTypeList, _newsFeedById]) => {
          const blocksFromHtml = htmlToDraft(_newsFeedById.newsfeedText);
          const { contentBlocks, entityMap } = blocksFromHtml;
          const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
          const newEditorState = EditorState.createWithContent(contentState);
          this.setState(
            {
              newsfeedTypeList: _newsfeedTypeList,
              newsFeedById: _newsFeedById,
              editorState: newEditorState
            },
            () => {
              this.setState({
                fetchResult: ResultStatus.LOADED,
              });
            }
          );
        })
      );
    } catch {
      this.setState({
        fetchResult: ResultStatus.ERROR,
      });
    }
  }

  render() {
    const { classes } = this.props;
    switch (this.state.fetchResult) {
      case ResultStatus.NOT_LOADED:
      case ResultStatus.LOADING:
        return (
          <PageLoadingComponent
            small
            classes={classes}
            label="Loading Newsfeed Details"
          />
        );
      case ResultStatus.SAVING:
        return (
          <PageLoadingComponent
            small
            classes={classes}
            label="Saving Newsfeed Details"
          />
        );
      case ResultStatus.LOADED:
      case ResultStatus.SUCCESS:
        return (
          <>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DialogTitle disableTypography id="dialogTitle">
                <AppBar position="static">
                  <Toolbar variant="dense">
                    <Typography variant="h6" className={classes.root}>
                      {this.state.newsfeedTypeList.find(x => x.newsfeedTypeId === this.state.newsFeedById.newsfeedTypeId).newsfeedTypeText}
                    </Typography>
                    <IconButton
                      color="secondary"
                      onClick={() => {
                        this.props.onClose(false);
                      }}
                    >
                      {MatIconService.CANCEL}
                    </IconButton>
                  </Toolbar>
                </AppBar>
              </DialogTitle>
              <DialogContent>
                <Box
                  style={{
                    paddingLeft: 16,
                    paddingRight: 32,
                    paddingTop: 16,
                    paddingBottom: 32,
                    minWidth: "80vh",
                    maxWidth: "80vh",
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                      <Typography variant="h8" className={classes.root}>
                        {this.state.newsFeedById.newsfeedTitle}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Editor
                        toolbarHidden={true}
                        editorState={this.state.editorState}
                      />
                      {/* <Typography variant="h8" className={classes.root}>
                        {this.state.newsFeedById.newsfeedText}
                      </Typography> */}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Typography variant="h8" className={classes.root}>
                        {this.state.newsFeedById.newsfeedStartDate.substring(0, 10)}
                      </Typography>
                    </Grid>
                    <div style={{ display: "flex" }}>
                      <Typography variant="body1" className={classes.root} color="secondary" display="inline" >Attached File:</Typography>
                      <Typography variant="body1" className={classes.root} display="inline" >{this.state.newsFeedById.attachment}</Typography>
                    </div>
                  </Grid>
                </Box>
              </DialogContent>
            </MuiPickersUtilsProvider>

          </>
        );
      case ResultStatus.ERROR:
      default:
        return (
          <DialogErrorFragmentComponent
            title="Error"
            description="Error in Newsfeed"
            classes={classes}
            onClose={() => {
              this.props.onClose(false);
            }}
            onRetry={() => {
              console.log("Retry Clicked");
            }}
          />
        );
    }
  }
}

/** HOC */
export default LayoutService.getHocComponenet(NewsfeedSummaryComponent);
