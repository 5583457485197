import React from "react";
import { withStyles, AppBar, Box, Dialog, DialogContent, DialogTitle, Divider, IconButton, Tab, Tabs, Toolbar, Typography } from "@material-ui/core";
import { MatIconService } from "../../../shared/services/theme/matIconService";
import SectionComponent from "../../../shared/components/sectionComponent";
import LayoutService from "../../../shared/services/layoutService";
import ProfileTabComponent from "../../profile-center/profile-detail/tabs/profileTabComponent";
import TransactionTabComponent from "../../transaction-center/transaction-detail/transaction-tab/transactionTabComponent";
import { CrudAction, ENTITY_TYPE } from "../../../shared/types/enums";
import DataTransactionComponent from "./data-transaction/dataTransactionComponent";

class DataDetailDialogComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedTabIndex: 0,
        };

        this.tabIndexMap = new Map();
        this.tabIndexMap.set(0, { ref: null });
    }

    onTabChange = (event, newValue) => {
        this.setState({
            selectedTabIndex: newValue,
        });
    };

    render() {
        const { classes } = this.props;

        return (
            <Dialog open={this.props.open || false} scroll={true ? "paper" : "body"} fullWidth={true} maxWidth={"md"} onClose={this.onClose}>
                <DialogTitle disableTypography id="dialogTitle">
                    <AppBar position="static">
                        <Toolbar>
                            <Typography variant="h6" className={classes.root}>
                                {this.props.entityId === ENTITY_TYPE.PROFILE ? "Profile" : "Transaction"} Details
                            </Typography>
                            <IconButton
                                color="secondary"
                                onClick={() => {
                                    this.props.onClose(false);
                                }}
                            >
                                {MatIconService.CANCEL}
                            </IconButton>

                        </Toolbar>
                    </AppBar>
                </DialogTitle>
                <DialogContent>
                    <Tabs value={this.state.selectedTabIndex} onChange={this.onTabChange} indicatorColor="primary" textColor="inherit"
                        variant="fullWidth"
                        scrollButtons="auto">
                        <Tab label="Profile" id="profileTab" />
                    </Tabs>

                    <div role="tabpanel" hidden={this.state.selectedTabIndex !== 0} id={"profileTabPanel"} >
                        <Divider />
                        <Box style={{ padding: "0px" }} >
                            {this.props.entityId === ENTITY_TYPE.PROFILE ?
                                <ProfileTabComponent tabConfig={this.tabIndexMap.get(0)} isReadOnly={true} inputAction={CrudAction.UPDATE}
                                    modalAgNode={this.props.modalAgNode} from={"DataCenter"}
                                    onProfileTabLoaded={(_selectedProfile) => { console.log(_selectedProfile) }} /> :
                                <TransactionTabComponent tabConfig={this.tabIndexMap.get(0)} isReadOnly={true} inputAction={CrudAction.UPDATE}
                                    modalAgNode={this.props.modalAgNode} porzioGstId={this.props.modalAgNode.porziogsttransactionid}
                                    onTransactionTabLoaded={(_selectedTransaction, _relatedTransactions) => { console.log(_selectedTransaction) }} />}
                        </Box>
                    </div>

                </DialogContent>
            </Dialog>
        );
    }
}

export default LayoutService.getHocComponenet(DataDetailDialogComponent);
