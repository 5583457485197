import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import 'fontsource-roboto';
import App from './App';
import { LicenseManager } from "ag-grid-enterprise";
// import * as Keycloak from 'keycloak-js'

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE_KEY);

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={null}>
      <App />
    </Suspense>
  </React.StrictMode >,
  document.getElementById('root')
);

/**Below is the code to invoke keycloak from client side.
 * Configure initOptions as per you local setup to call keycloack api.
 **/
// let initOptions = {
//   url: 'http://localhost:8080/auth/', realm: 'demo', clientId: 'react-test-app', onLoad: 'login-required'
// }


// let keycloak = Keycloak(initOptions);

// keycloak.init({ onLoad: initOptions.onLoad }).success((auth) => {

//   if (!auth) {
//       window.location.reload();
//   } else {
//       console.info("Authenticated");
//   }

//   //React Render

//   ReactDOM.render(
//   <React.StrictMode>
//   <App />
//   </React.StrictMode>,
//   document.getElementById('root')
//   );

//   localStorage.setItem("react-token", keycloak.token);
//   localStorage.setItem("react-refresh-token", keycloak.refreshToken);

//   setTimeout(() => {
//       keycloak.updateToken(70).success((refreshed) => {
//           if (refreshed) {
//               console.debug('Token refreshed' + refreshed);
//           } else {
//               console.warn('Token not refreshed, valid for '
//                   + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
//           }
//       }).error(() => {
//           console.error('Failed to refresh token');
//       });


//   }, 60000)

// }).error(() => {
//   console.error("Authenticated Failed");
// });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
