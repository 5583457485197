import { AgGridColumnExt } from "../../../../shared/services/ag-grid/agGridColumnExt";
import ApiService from "../../../../shared/services/apiService";
import { API_ENDPOINT,ENTITY_TYPE } from "../../../../shared/types/enums";
import { AgGridBulkEditUtil } from "../../../../shared/components/ag-grid/bluk-edit/agGridBulkEditUtil";

export default class DocumentsService {

  static getDocumentsAsOBS = (_tenantId, _modalAgNode) => {
    const targetUrl = `/Document/GetDocumentsByEntity/${_tenantId}/${ENTITY_TYPE.TRANSACTION}/${_modalAgNode.transactionid}?page=1&pageSize=100`;
    return ApiService.getOBS(
      API_ENDPOINT.CORE,
      targetUrl,
    );
  };

  /**
   * Refer url for more details: https://www.ag-grid.com/javascript-grid-column-properties/
   * @param _componentThisRef pass {this} keyword from the calling componenet
   */
  static getColumnDefs(_componentThisRef) {
    return [
      AgGridColumnExt.GET(true, "text", AgGridBulkEditUtil.selectColumnName, "Select").fixedWidth(80).headerClass("center-text")
      .cellRenderer("isCheckedCellRenderer", { onChange: _componentThisRef.toggleSubmitButton }).SET(),
      AgGridColumnExt.GET(false, "text", "porzioGSTDocumentID", "Porzio GST Document ID").headerClass("center-text").centerAlignText().SET(),
      AgGridColumnExt.GET(false, "text", "documentName", "Document Name").cellRenderer("childMessageRendererComponent").flexWeight(1).tooltip().valueSetter(this._setRuleViolations).SET(),
      AgGridColumnExt.GET(false, "text", "documentDescription", "Description").flexWeight(1).tooltip().valueSetter(this._setRuleViolations).SET(),
      AgGridColumnExt.GET(false, "text", "documentType", "Category Name").flexWeight(1).tooltip().valueSetter(this._setRuleViolations).SET(),
      AgGridColumnExt.GET(false, "text", "filePath", "File Name").cellRenderer("downloadFileAgRenderer").flexWeight(1).tooltip().valueSetter(this._setRuleViolations).SET(),
      //AgGridColumnExt.GET(true, "text", "fileType", "File Type").fixedWidth(90).valueSetter(this._setRuleViolations).SET(),
    ];
  }
  
}