import ApiService from "../../shared/services/apiService";
import { API_ENDPOINT } from "../../shared/types/enums";

export default class WorkflowTemplateListService {

  static getWorkflowTemplateListAsOBS = (_tenantId,) => {
    return ApiService.getOBS(
      API_ENDPOINT.CORE,
      `/Workflow/FetchWorkflowList?tenantId=${_tenantId}&pageNumber=1&pageSize=1000`,
    );
  };

  static getRows() {
    return [];
  }

  static getColumnDefs() {
    return [
      {
        headerName: "Template Name",
        field: "workflowTemplateName",
        sortable: true,
        filter: "text",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 225,
        cellRenderer: "childMessageRendererComponent",
      },
      {
        headerName: "Approvers",
        field: "approvers",
        sortable: true,
        filter: "text",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 150,
      },
      {
        headerName: "Approval Order",
        headerHeight: 35,
        field: "approvalOrderName",
        sortable: true,
        filter: "text",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 150,
        height: 30,
      },
      {
        headerName: "End On First Approval",
        field: "endOnFirstApproval",
        sortable: true,
        filter: "text",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 150,
        cellRenderer: "isActiveIconRendererComponent",
      },
      {
        headerName: "End On First Rejection",
        field: "endOnFirstRejection",
        sortable: true,
        filter: "text",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 150,
        cellRenderer: "isActiveIconRendererComponent",
      },
      {
        headerName: "Jurisdiction",
        field: "jurisdictionName",
        sortable: true,
        filter: "agTextColumnFilter",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 150,
      },

    ];
  }
}
